import React from "react";
import { Modal } from "antd";
import { withRouter } from 'react-router'
import {
    FaTimesCircle
  } from "react-icons/fa";
import { ModalContainer } from "./style"

const ModalOutlet = (props) => {

  const confirmModal = () => {
    props.toggleModal()
    props.modalCallback(true)
  }

  return ( 
    <>
      <Modal
        title={false}
        visible={props.modalVisible}
        onCancel={() => {
          props.toggleModal()
        }}
        className="outlet-modal"
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        // getContainer={() => document.getElementById("stamp-container")}
        width='80%'
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <ModalContainer>
          
          <div className="header">
              {props.header}
          </div>

          <div className="body">
              {props.body}
          </div>
          
          <div className='footer'>
            <button onClick={() => props.toggleModal()} className='btn btnEmpty mr-1'>Cancel</button>
            <button onClick={() => {
              props.toggleModal()
              props.getOutletModal(false)
            }} className='btn btnEmpty mr-1'>Don't remind me again</button>
            <button onClick={() => {
              props.history.push('/admin/merchant/merchantDetails')
              props.toggleModal()
              props.getOutletModal(false)
            }} className='btn btnCancel mr-1'>No, I don't have a physical location</button>
            <button onClick={() => confirmModal()} className='btn btnConfirm ml-1'>Proceed</button>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
}
 
export default withRouter(ModalOutlet);