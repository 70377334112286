import React, { useRef, useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, InputGroup } from "react-bootstrap";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import getSymbolFromCurrency from "currency-symbol-map";
import client from "feathers.js";
import { isEmpty } from "lodash";

import Preview from "./Preview";
import { PageContainer } from "../../style";
import StyledButton from "components/Elements/StyledButton";
import ModalLanuchEmpty from "components/Extra/ModalLanuchEmpty";
import { DataContext } from "contexts/DataContextContainer";
import { AccessDenied } from "assets/images";
import InfoCard from "components/InfoCard/InfoCard";
import DetailsSection from "components/DetailsSection/RowContainer";
import { useHistory } from "react-router-dom";
import StyledRadio from "components/Elements/StyledRadio";

const BusinessSchema = Yup.object().shape({
  // stripePublishableKey: Yup.string().when('modePayment', {
  //   is: (e) => e.includes('creditCard'),
  //   then: Yup.string().required("Required!"),
  // }),
  // stripeSecretKey: Yup.string().when('modePayment', {
  //   is: (e) => e.includes('creditCard'),
  //   then: Yup.string().required("Required!"),
  // }),
  // stripePublishableKey: Yup.string().required("Required!"),
  // stripeSecretKey: Yup.string().required("Required!"),
});

// const quillModules = {
//   toolbar: [
//     ["bold", "italic", "underline", "strike", "blockquote"],
//     [
//       { list: "ordered" },
//       { list: "bullet" },
//       { indent: "-1" },
//       { indent: "+1" },
//     ],
//   ],
// };

const customOptions = [
  {
    value: "true",
    label: "Yes",
  },
  {
    value: "false",
    label: "No",
  },
];

const Wallet = (props) => {
  const history = useHistory();
  const submitRef = useRef(null);
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [campaignData, setCampaignData] = useState({});
  const [tos, setTos] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [action, setAction] = useState("all");
  const [allowCustom, setAllowCustom] = useState('false');

  const inputGroupStyle = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexWrap: "nowrap",
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const modalCallback = () => {
    setLoading(true);
    submitRef.current.click();
    toggleModal();
  };

  const getCampaignData = () => {
    setLoading(true);
    client
      .authenticate()
      .then(() => {
        return client.service("wallets").find({
          query: {
            userId: props.userInfo._id,
            $limit: 1,
          },
        });
      })
      .then((res) => {
        // Is Edit
        if (res.data.length > 0) {
          setCampaignData(res.data[0]);
          setTos(res.data[0].tos);
          setLoading(false);
        } else {
          // Is Create
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
        // No stop loading
        // setLoading(false)
      });
  };

  const handleSubmit = (value) => {
    value.tos = tos;

    setLoading(true);
    if (campaignData._id) {
      client
        .authenticate()
        .then(() => {
          return client.service("wallets").patch(campaignData._id, value);
        })
        .then((res) => {
          setLoading(false);
          showToast("success", "Edit Successful!");
        })
        .catch((err) => {
          console.log(err);
          showToast("error", err.message);
          setLoading(false);
        });
    } else {
      client
        .authenticate()
        .then(() => {
          return client.service("wallets").create(value);
        })
        .then((res) => {
          setCampaignData(res);
          setLoading(false);
          showToast("success", "Edit Successful!");
        })
        .catch((err) => {
          console.log(err);
          showToast("error", err.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Tools", props.title]);
  }, []);

  useEffect(() => {
    if (props.userInfo.business_verified) {
      getCampaignData();
    } else {
      // Submit Form
    }
    /* eslint-disable-next-line */
  }, [props.userInfo._id, props.userInfo.business_verified]);

  return (
    <PageContainer>
      {action === "all" && (
        <>
          {props.userInfo.subscription === "copper" ? (
            <div className='access-denied'>
              <div>
                <h1>OOPS!</h1>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '1000px'}}>
                  <p>You don’t have access to this feature.</p>
                  <p>
                    This feature is exclusively available for our Bronze tier and
                    above members. Upgrade your membership today to access this
                    exciting feature and enjoy even more perks and privileges.
                  </p>
                </div>
                <img src={AccessDenied} alt='' width={700} />
                <button
                  className='btn-upgrade'
                  onClick={() => props.history.push("/admin/upgrade")}
                >
                  Upgrade Now!
                </button>
              </div>
            </div>
          ) : props.userInfo.business_verified ? (
            <>
              <InfoCard desc='Establish your own internal wallet system, empowering your customers to conveniently top up funds and utilize them for transactions within your various campaigns seamlessly.' />

              <Formik
                initialValues={{
                  stripePublishableKey: campaignData.stripePublishableKey
                    ? campaignData.stripePublishableKey
                    : "",
                  stripeSecretKey: campaignData.stripeSecretKey
                    ? campaignData.stripeSecretKey
                    : "",
                  topupAmounts: !isEmpty(campaignData)
                    ? campaignData.topupAmounts.length > 0
                      ? campaignData.topupAmounts
                      : [
                          { amount: 0 },
                          { amount: 0 },
                          { amount: 0 },
                          { amount: 0 },
                        ]
                    : [
                        { amount: 0 },
                        { amount: 0 },
                        { amount: 0 },
                        { amount: 0 },
                      ],
                  customAmountDesc: campaignData.customAmountDesc
                    ? campaignData.customAmountDesc
                    : {
                        minAmount: 0,
                        multipleAmount: 0,
                      },
                  customAmount: campaignData.customAmount
                    ? campaignData.customAmount.toString()
                    : "false",
                }}
                enableReinitialize
                validationSchema={BusinessSchema}
                onSubmit={handleSubmit}
              >
                {({ values, handleChange }) => (
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                      gap: "80px",
                    }}
                  >
                    <DetailsSection
                      leftHeader='Preset Top up tiers'
                      leftDesc='Predefine top up amounts to allow customers to top up funds and utilize them for transactions within your campaigns.'
                      leftContent={
                        <div style={{ color: "#6B7280", fontWeight: "500" }}>
                          {"(Leave blank or '0' to disable tier)"}
                        </div>
                      }
                      rightContent={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            columnGap: "40px",
                            rowGap: "20px",
                            flexWrap: "wrap",
                          }}
                        >
                          {values.topupAmounts.length > 0 &&
                            values.topupAmounts.map((topup, index) => (
                              <div
                                className='input-container'
                                style={{ width: "calc(50% - 20px)" }}
                              >
                                {`Tier ${index + 1}`}
                                <InputGroup style={inputGroupStyle}>
                                  <InputGroup.Text
                                    style={{ borderRadius: "8px 0 0 8px" }}
                                  >
                                    $
                                  </InputGroup.Text>
                                  <input
                                    type='number'
                                    min='0'
                                    step='1'
                                    className='input'
                                    placeholder='Enter Amount'
                                    id={`topupAmounts.${index}.amount`}
                                    name={`topupAmounts.${index}.amount`}
                                    style={{
                                      borderRadius: "0 8px 8px 0",
                                      display: "inline-block",
                                      appearance: "none",
                                    }}
                                    value={Number(
                                      values.topupAmounts[index].amount
                                    ).toString()}
                                    onChange={handleChange}
                                  />
                                  <div className='inc-dec-wrapper'>
                                    {getSymbolFromCurrency(
                                      props.userInfo.currency
                                    )}
                                  </div>
                                </InputGroup>
                              </div>
                            ))}
                        </div>
                      }
                    />

                    <DetailsSection
                      leftHeader=''
                      leftDesc=''
                      rightContent={
                        <>
                          <div
                            className='input-container'
                            id='my-radio-group'
                            style={{ color: "#344051", fontSize: "14px" }}
                          >
                            Do you allow custom top up amount?
                            <div
                              role='group'
                              aria-labelledby='my-radio-group'
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "25px",
                                width: "100px",
                              }}
                            >
                              <StyledRadio
                                options={customOptions}
                                value={allowCustom}
                                onChange={(e) => setAllowCustom(e.target.value)}
                                className='col-md-6'
                              />
                            </div>
                          </div>

                          {allowCustom === true ||
                            (allowCustom === "true" && (
                              <div
                                className='input-container'
                                style={{ flexDirection: "row", gap: "40px" }}
                              >
                                <div className='input-container'>
                                  Min amount
                                  <InputGroup style={inputGroupStyle}>
                                    <InputGroup.Text
                                      style={{ borderRadius: "8px 0 0 8px" }}
                                    >
                                      $
                                    </InputGroup.Text>
                                    <input
                                      type='number'
                                      className='input'
                                      id='customAmountDesc.minAmount'
                                      name='customAmountDesc.minAmount'
                                      value={Number(
                                        values.customAmountDesc.minAmount
                                      ).toString()}
                                      onChange={handleChange}
                                      min='0'
                                      step='1'
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      style={{
                                        borderRadius: "0 8px 8px 0",
                                        display: "inline-block",
                                        appearance: "none",
                                      }}
                                    />
                                    <div className='inc-dec-wrapper'>
                                      {getSymbolFromCurrency(
                                        props.userInfo.currency
                                      )}
                                    </div>
                                  </InputGroup>
                                  Specify the minimum amount customers have to
                                  top up
                                </div>

                                <div className='input-container'>
                                  In multiples of
                                  <InputGroup style={inputGroupStyle}>
                                    <InputGroup.Text
                                      style={{ borderRadius: "8px 0 0 8px" }}
                                    >
                                      $
                                    </InputGroup.Text>
                                    <input
                                      type='number'
                                      className='input'
                                      id='customAmountDesc.multipleAmount'
                                      name='customAmountDesc.multipleAmount'
                                      value={Number(
                                        values.customAmountDesc.multipleAmount
                                      ).toString()}
                                      onChange={handleChange}
                                      min='0'
                                      step='1'
                                      onWheel={(event) =>
                                        event.currentTarget.blur()
                                      }
                                      style={{
                                        borderRadius: "0 8px 8px 0",
                                        display: "inline-block",
                                        appearance: "none",
                                      }}
                                    />
                                    <div className='inc-dec-wrapper'>
                                      {getSymbolFromCurrency(
                                        props.userInfo.currency
                                      )}
                                    </div>
                                  </InputGroup>
                                  Provide the subsequent top-ups in multiples.
                                </div>
                              </div>
                            ))}
                        </>
                      }
                    />

                    <DetailsSection
                      leftHeader=''
                      leftDes=''
                      rightContent={
                        <>
                          <div className='button-wrapper' style={{maxWidth: '100%'}}>
                            <button
                              type='button'
                              className='cancel-button'
                              onClick={() => history.goBack()}
                            >
                              Back
                            </button>

                            <button
                              type='button'
                              className='primary-button'
                              onClick={() => history.push('/admin/topup')}
                            >
                              Topup
                            </button>
                            <StyledButton
                              title='Preview'
                              type='button'
                              onClick={toggleModal}
                              className='primary-button'
                              bgcolor={"#567877"}
                            />
                          </div>
                        </>
                      }
                      align='flex-end'
                    />

                    <button
                      type='submit'
                      className='d-none'
                      ref={submitRef}
                    ></button>

                    <ModalLanuchEmpty
                      modalVisible={modalVisible}
                      toggleModal={toggleModal}
                      modalCallback={modalCallback}
                      header={
                        <>
                          <span className='title1 text-center'>
                            <br />
                            Campaign Preview
                          </span>
                        </>
                      }
                      body={
                        <>
                          <div className='preview-wrapper'>
                            <div className='preview'>
                              <Preview
                                merchant={props.userInfo}
                                walletSetting={values}
                                modePayment={
                                  campaignData.modePayment
                                    ? campaignData.modePayment
                                    : {}
                                }
                                balance={100}
                              />
                            </div>
                          </div>
                        </>
                      }
                      canEdit={true}
                      noFooter={true}
                      textAlign={"left"}
                    />
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <div className='access-denied'>
              <div>
                <h1>OOPS!</h1>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '1000px'}}>
                  <p>You don’t have access to this feature.</p>
                  <p>To start using wallet tool, please verify your business.</p>
                </div>
                <img src={AccessDenied} alt='' width={700} />
                <button
                  className='btn-upgrade'
                  onClick={() => props.history.push("/admin/tools/business")}
                >
                  Verify Now
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
