import moment from 'moment';

const columns = () => [
    // {
    //   title: 'No.',
    //   dataIndex: 'no',
    //   render: (record, records, index) => 
    //   <span>{index+1}</span>
    // },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => 
        <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'Merchant Name',
      dataIndex: 'merchantName',
      key: 'merchantName'
    },
    {
      title: 'Ori Amount',
      dataIndex: 'oriAmount',
      key: 'oriAmount',
      render: (record) => 
      <span>${record?record.toFixed(2):0}</span>
    },
    {
      title: 'Commission',
      dataIndex: 'commision',
      key: 'commision',
      render: (record) => 
      <span>${record?record.toFixed(2):0}</span>
    },
    {
      title: 'Paid Amount',
      dataIndex: 'comAmount',
      key: 'comAmount',
      render: (record) => 
      <span>${record?record.toFixed(2):0}</span>
    },
  ]

export default columns;
