import { breakpoints } from "style";
import styled from "styled-components";

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  h2 {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #3B3054;    
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
  }
  ul {
    padding-left: 14px;
  }
  span, ul li {
    font-weight: 400;
    font-size: 14px;
    color: #434343;
  }
  p {
    font-size: 14px;
  }
  .ant-picker {
    margin: 10px 0px 20px 0;
    width: 100%;
    border-radius: .25rem;
    padding: 12px 10px;
    // text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: #3b7779;
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
