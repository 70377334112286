import React, { useState, useContext, useEffect } from "react";
import { Modal, Table, Pagination } from "antd";
import { PageContainer } from "../../style";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";
import Edit from "./Edit";
import columns from "./columns";
import { cloneDeep } from "lodash";

import client from "feathers.js";
import axios from "axios";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const PointsSubmissions = (props) => {
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [action, setAction] = useState("approve");
  const [activeValue, setActiveValue] = useState({});
  const [tableAction, setTableAction] = useState("all");
  const [dataEdit, setDataEdit] = useState({});
  const [pagination, setPagination] = useState({});

  const back = () => {
    setTableAction("all");
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);

  useEffect(() => {
    getCampaignData(0, 1, props.userInfo._id);
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  // const back = () => {
  //   setAction('all')
  // }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setLoading(true);

    let status = "approve";

    if (action === "approve") {
      status = "approve";

      client
        .authenticate()
        .then((auth) => {
          return axios.post(
            `${client.io.io.uri}approveReceiptAndEarnPoints`,
            {
              status,
              _id: activeValue._id,
              merchantId: props.userInfo._id,
              merchantUsername: props.userInfo.username,
              dollarPoint: props.userInfo.dollarPoint,
              pointPerDollar: props.userInfo.pointPerDollar,
              userId: activeValue.userId,
              amount: activeValue.amount,
            },
            {
              headers: {
                Authorization: auth.accessToken,
              },
            }
          );
        })
        .then((res) => {
          let cloneData = cloneDeep(campaignData);
          let findKey = cloneData.findIndex((e) => e._id === res.data._id);
          if (findKey !== -1) {
            cloneData[findKey] = res.data;
          }
          setCampaignData(cloneData);

          setLoading(false);
          showToast("success", "Receipt is approved");
          setIsModalVisible(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setIsModalVisible(false);
          showToast("error", err.message);
        });
    } else {
      status = "reject";

      client
        .authenticate()
        .then(() => {
          return client.service("points-submissions").patch(activeValue._id, {
            status,
          });
        })
        .then((res) => {
          let cloneData = cloneDeep(campaignData);
          let findKey = cloneData.findIndex((e) => e._id === res._id);
          if (findKey !== -1) {
            cloneData[findKey] = res;
          }
          setCampaignData(cloneData);

          setLoading(false);
          showToast("success", "Receipt is approved");
          setIsModalVisible(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setIsModalVisible(false);
          showToast("error", err.message);
        });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getCampaignData = (skip, currentPage, userId) => {
    setLoading(true);
    client
      .authenticate()
      .then(() => {
        return client.service("points-submissions").find({
          query: {
            $skip: skip,
            $limit: PAGESIZE,
            merchantId: userId,
            $sort: { createdAt: -1 },
          },
        });
      })
      .then((res) => {
        setCampaignData(res.data);
        setPagination(paginationCount(res.total, currentPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  const downloadFile = (fileName) => {
    window.location.href =
      `${client.io.io.uri}downloadUploadFile?fileName=` + fileName;
  };

  const approveSubmission = (record) => {
    setActiveValue(record);
    setAction("approve");
    showModal();
  };

  const rejectSubmission = (record) => {
    setActiveValue(record);
    setAction("reject");
    showModal();
  };

  return (
    <PageContainer>
      {tableAction === "all" ? (
        <>
          <div className='row-container'>
            <Table
              rowKey='_id'
              columns={columns(
                setTableAction,
                setDataEdit,
                props.userInfo.currency,
                downloadFile,
                approveSubmission,
                rejectSubmission,
                props.userInfo.pointPerDollar,
                props.userInfo.dollarPoint
              )}
              dataSource={campaignData}
              pagination={false}
            />
          </div>

          <div className='row-container'>
            <Pagination
              pageSize={PAGESIZE}
              total={pagination.total}
              showSizeChanger={false}
              onChange={(e) => {
                setPagination(paginationCount(pagination.total, e));
                getCampaignData((e - 1) * PAGESIZE, e, props.userInfo._id);
              }}
            />
          </div>
        </>
      ) : (
        <Edit
          userInfo={props.userInfo}
          fetchCampaignInfo={getCampaignData}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          action={tableAction}
          data={dataEdit}
          back={back}
        />
      )}

      <Modal
        title={<span className='text-capitalize'>Confirmation</span>}
        centered
        visible={isModalVisible}
        onClick={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          {action === "approve"
            ? "Proceed to approve this receipt?"
            : "Proceed to reject this receipt?"}
        </div>
      </Modal>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PointsSubmissions);
