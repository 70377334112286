import React from "react";
import { PaymentFail } from "assets/images";

const Success = () => {
    return (  
        <div className="text-center mt-5 pt-5">
            <img style={{width: 120}} src={PaymentFail} alt="" />
            <h3>Payment Cancelled</h3>
            <p>Your payment was cancelled. If you have any questions or concerns, please contact us.</p>
        </div>
    );
}
 
export default Success;