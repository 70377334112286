import React, { useState, useContext, useEffect } from 'react';
import { Table } from 'antd';
// import { useHistory } from "react-router-dom";
import { PageContainer } from "../style";
// import StyledButton from "components/Elements/StyledButton";
// import { Modal } from "antd";
import { connect } from 'react-redux'
import { DataContext } from "contexts/DataContextContainer";
// import Validation from 'data/validation'
import columns from './columns'
import { toolStatusTitle } from 'data/mockData'
import AllTools from './components';

import client from "feathers.js"

const Tools = props => {
  const { title, query } = props

  // const history = useHistory();
  const { setLoading, showToast } = useContext(DataContext);
  const [ action, setAction ] = useState('all' ) //edit, detail, new
  const [campaignData, setCampaignData] = useState([])

  useEffect(() => {
    getCampaignData(props.userInfo._id)
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  const back = (actionStatus) => {
    setAction(actionStatus)
    if (actionStatus === 'all') {
      getCampaignData(props.userInfo._id)
    }
  }

  const getCampaignData = (userId) => {
    setLoading(true)
    client.service('stamps').find({
      query: {
        userId,
      }
    })
    .then((res) => {
      setCampaignData(res.data)
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      if(err.name === "NotAuthenticated"){
            
      }else{
        showToast('error', err.message)
      }
    })
  }

  const Tool = AllTools[query.name];

  return (
    <PageContainer>
    {
      action === 'all' ?
      <>
        <div className='info-header'>
          <div className='title'>
            <h3>{title}</h3>
          </div>
          <div className='plan-info'>
            <div className='plan maximum'>
              <span>maximum</span>
              <span>3</span>
            </div>
            <div className='plan active'>
              <span>Active Campaign</span>
              <span>3</span>
            </div>
            <div className='plan balance'>
              <span>Balance</span>
              <span>3</span>
            </div>
          </div>
          {
            props.userInfo.subscription === 'copper'?
              <button className='btn-plan' onClick={() => props.history.push('/admin/upgrade')}>Upgrade Now</button>
            : null
          }
        </div>
        <div className='status-header'>
          <div className='status'>
            <span>{toolStatusTitle[query.name][0]}</span>
            <span>5 / 10</span>
          </div>
          <div className='status'>
            <span>{toolStatusTitle[query.name][1]}</span>
            <span>5 / 10</span>
          </div>
          <div className='status'>
            <span>{toolStatusTitle[query.name][2]}</span>
            <span>5 / 10</span>
          </div>
        </div>
        <div className='page-container'>
          {
            query.name !== 'wallet' &&
            <>
              <Table columns={columns[query.name]} dataSource={campaignData}/>
              <button className='btn-add' onClick={() => setAction('new')}>
                <span style={{marginTop: -8}}>+</span>
              </button>
            </>
          }
        </div>
      </>
      :
      <Tool action={action} data={{}} back={back}/>
    }
    </PageContainer>
  );
}

const mapStateToProps = state => ({
  auth: state.role.auth,
  userId: state.role.details.user?state.role.details.user._id:'',
  username: state.role.details.user?state.role.details.user.username:'',
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
