import React, { useEffect, useState, useContext, useRef } from "react";
import { PageContainer } from "./style";
import StyledButton from "components/Elements/StyledButton";
import { Modal } from "antd";
import ModalLanuchEmpty from "components/Extra/ModalLanuchEmpty";
import Cropper from "react-cropper";
import { connect } from "react-redux";
import { updateDetails } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import { IconCamera } from "assets/images";
import { IconPlaceholder } from "assets/images";
import { Radio, Space } from "antd";
import AppPreview from "components/AppPreview";
import Resizer from "react-image-file-resizer";

import axios from "axios";
import client from "feathers.js";
import classNames from "classnames";
import InfoCard from "components/InfoCard/InfoCard";

import { AccordianArrow, UploadFile, RemoveFile } from "assets/svg";
import DetailsSection from "components/DetailsSection/RowContainer";

const MerchantBrand = (props) => {
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [showModal, setShowModal] = useState(null);
  const [value, setValue] = useState(props.userInfo.notification || "");
  const [data, setData] = useState({});

  const [modalVisible, setModalVisible] = useState(false);
  const [logoName, setLogoName] = useState("");
  const [bannerName, setBannerName] = useState("");
  const [category, setCategory] = useState("logo");

  const [bannerImage, setBannerImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [croppedLogoUrl, setCroppedLogoUrl] = useState(null);
  const [croppedBannerUrl, setCroppedBannerUrl] = useState(null);
  const [cropper, setCropper] = useState(null);

  const [openSelection, setOpenSelection] = useState([]);
  const [currentIndexSelected, setCurrentIndexSelected] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const modalCallback = () => {
    setLoading(true);
    launch();
  };

  // const fetchCampaign = (key) => {
  //   setActiveTab(key)
  // setLoading(true)
  // }

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Page", props.title]);
    setCroppedBannerUrl(
      `${process.env.REACT_APP_API_URL}/${props.userInfo.banner}`
    );
    setCroppedLogoUrl(
      `${process.env.REACT_APP_API_URL}/${props.userInfo.logo}`
    );
  }, []);

  useEffect(() => {
    if (props.userInfo._id) {
      client
        .authenticate()
        .then((auth) => {
          return axios.post(
            `${client.io.io.uri}getNotificationOptions`,
            {
              userId: props.userInfo._id,
            },
            {
              headers: {
                Authorization: auth.accessToken,
              },
            }
          );
        })
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }

    // Do not do this
    // Looping set Value
    // setValue(props.userInfo.notification)

    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  const onChange = (e, i) => {
    setCurrentIndexSelected(i);
    setValue(e.target.value);
  };

  function getImageSize(file) {
    return new Promise((resolve) => {
      var fr = new FileReader();

      fr.onload = function () {
        var img = new Image();

        img.onload = function () {
          return resolve({
            width: img.width,
            height: img.height,
          });
        };

        img.src = fr.result;
      };

      fr.readAsDataURL(file);
    });
  }

  const resizeFile = (file, getSize) => {
    let compressPercent = 50;
    // 100kb
    if (file.size > 300000) {
      compressPercent = 80;
    }

    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        getSize.width / 2,
        getSize.height / 2,
        file.type,
        compressPercent,
        0,
        (uri) => {
          return resolve(uri);
        },
        "file"
      );
    });
  };

  const handleFile = async (e, category) => {
    e.preventDefault();

    setCategory(category);

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files[0].size > 300000) {
      let getSize = await getImageSize(files[0]);
      const image = await resizeFile(files[0], getSize);

      const reader = new FileReader();
      reader.onload = () => {
        if (category === "logo") {
          setLogoName(files[0].name);
          setLogoImage(reader.result);
        } else {
          setBannerName(files[0].name);
          setBannerImage(reader.result);
        }
        setShowModal(true);
      };
      reader.readAsDataURL(image);
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        if (category === "logo") {
          setLogoName(files[0].name);
          setLogoImage(reader.result);
        } else {
          setBannerName(files[0].name);
          setBannerImage(reader.result);
        }
        setShowModal(true);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      if (category === "logo") {
        setCroppedLogoUrl(cropper.getCroppedCanvas().toDataURL());
      } else {
        setCroppedBannerUrl(cropper.getCroppedCanvas().toDataURL());
      }
    }
  };

  const launch = () => {
    // if (croppedLogoUrl || croppedBannerUrl) {

    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}updateBrandAndDelete`,
          {
            _id: props.userInfo._id,
            logo: logoImage && croppedLogoUrl,
            banner: bannerImage && croppedBannerUrl,
            oldLogoName: props.userInfo.logo,
            oldBannerName: props.userInfo.banner,
            notification: value,
          },
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        if (res.data) {
          props.updateDetails(res.data);
          toggleModal();
          showToast("success", "Merchant Brand Updated Successfully!");
        } else {
          showToast("error", "Please Login to try again!");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // } else {
    //   showToast('error', 'Please choose logo or banner image')
    // }
  };

  const handleOpenSelection = (index) => {
    if (openSelection.includes(index)) {
      setOpenSelection(openSelection.filter((id) => id !== index));
    } else {
      setOpenSelection([...openSelection, index]);
    }
  };

  const cancelMedia = (cat) => {
    setShowModal(false);

    if (cat === "logo") {
      setLogoImage(null);
      setLogoName("");
    } else {
      setBannerImage(null);
      setBannerName("");
    }
  };

  const removeFileHandler = (e, cat) => {
    if (cat === "logo") {
      setLogoImage(null);
      setLogoName("");
      setCroppedLogoUrl(null);
    } else {
      setBannerImage(null);
      setBannerName("");
      setCroppedBannerUrl(null);
    }
    document.getElementById("cancel-button").addEventListener("click", (e) => {
      e.stopPropagation();
    });
  };

  return (
    <PageContainer
      logo={croppedLogoUrl}
      banner={croppedBannerUrl}
      croppedBannerUrl={
        croppedBannerUrl
          ? croppedBannerUrl
          : client.io.io.uri + props.userInfo.banner || IconPlaceholder
      }
      croppedLogoUrl={
        croppedLogoUrl
          ? croppedLogoUrl
          : client.io.io.uri + props.userInfo.logo || IconCamera
      }
    >
      <InfoCard
        desc={
          "Your campaign appearance page will be how your relevant business and campaign details be displayed to customers. Get ready to unleash your creativity and create your personalized campaigns that leave a lasting impression on your customers!"
        }
      />

      <DetailsSection
        leftHeader="Logo"
        leftDesc="Upload your business logo here!"
        rightContent={
          <>
            <div className="right-content">
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <div className="logo-wrapper">
                  <img
                    style={{ borderRadius: "50%" }}
                    src={
                      croppedLogoUrl
                        ? croppedLogoUrl
                        : client.io.io.uri + props.userInfo.logo || IconCamera
                    }
                    alt=""
                    width="64px"
                    height="64px"
                  />
                </div>
                <div className="input-container">
                  <label
                    htmlFor="img-logo"
                    className="label-wrapper"
                    style={{ padding: "25px", minHeight: "155px" }}
                  >
                    {logoImage ? (
                      <>{logoName}</>
                    ) : (
                      <>
                        <img
                          src={UploadFile}
                          alt=""
                          width="40px"
                          height="40px"
                        />
                        <div className="left-content-text">
                          <div style={{ color: "#567877", fontWeight: "750" }}>
                            Click to upload
                          </div>
                          &nbsp;or drag and drop
                        </div>
                        <div className="left-content-text">
                          SVG, PNG, JPG or GIF (max. 800x400px)
                        </div>
                      </>
                    )}
                  </label>
                  <input
                    id="img-logo"
                    type="file"
                    accept=".svg, .png, .jpg, .gif"
                    onChange={(e) => handleFile(e, "logo")}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    style={{ display: "none" }}
                  />
                  {logoImage && (
                    <button
                      type="button"
                      id="cancel-button"
                      onClick={(e) => removeFileHandler(e, "logo")}
                      className="remove-file-button"
                    >
                      <img src={RemoveFile} alt="" width="20px" height="20px" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        }
      />

      <DetailsSection
        leftHeader="Page cover"
        leftDesc="Upload an image that best showcases your business."
        rightContent={
          <>
            <div className="right-content">
              <div className="input-container">
                <label
                  htmlFor="page-cover"
                  className="label-wrapper"
                  style={{ padding: "60px", minHeight: "223px" }}
                >
                  {croppedBannerUrl ? (
                    <>
                      <img
                        src={croppedBannerUrl}
                        alt=""
                        width="auto"
                        height="155px"
                      />
                      <div className="left-content-text">
                        <div style={{ color: "#567877", fontWeight: "750" }}>
                          Click to upload
                        </div>
                        &nbsp;or drag and drop
                      </div>
                      <div className="left-content-text">
                        SVG, PNG, JPG ( Recommended 1200x400px )
                      </div>
                    </>
                  ) : (
                    <>
                      <img src={UploadFile} alt="" width="40px" height="40px" />
                      <div className="left-content-text">
                        <div style={{ color: "#567877", fontWeight: "750" }}>
                          Click to upload
                        </div>
                        &nbsp;or drag and drop
                      </div>
                      <div className="left-content-text">
                        PNG, JPG ( Recommended 1200x400px )
                      </div>
                    </>
                  )}
                  <input
                    id="page-cover"
                    type="file"
                    accept=".png, .jpg"
                    onChange={(e) => handleFile(e, "banner")}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    style={{ display: "none" }}
                  />
                </label>
                {bannerImage && (
                  <button
                    type="button"
                    id="cancel-button"
                    onClick={(e) => removeFileHandler(e, "banner")}
                    className="remove-file-button"
                  >
                    <img src={RemoveFile} alt="" width="20px" height="20px" />
                  </button>
                )}
              </div>
            </div>
          </>
        }
      />

      <DetailsSection
        leftHeader="Tagline"
        leftDesc="You can select the best campaign promotion to show your customer. Customer can click on the tagline and go directly to the campaign."
        rightContent={
          <>
            <div className="right-content">
              <div className="tagline-container">
                <Radio.Group onChange={(e) => onChange(e)} value={value}>
                  <Space direction="vertical">
                    <Radio value={``}>
                      Welcome to {props.userInfo.business_name}
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
              {Object.entries(data).map((item, index) => {
                if (item[1].length > 0) {
                  return (
                    <div className="tagline-container">
                      <div className="tagline-content">
                        <div
                          className="tagline-header"
                          style={{
                            textDecoration: `${
                              currentIndexSelected === index ? "underline" : ""
                            }`,
                          }}
                        >
                          {item[0]}
                          <button
                            type="button"
                            onClick={() => handleOpenSelection(index)}
                            style={{
                              background: "transparent",
                              border: "none",
                            }}
                          >
                            <img
                              src={AccordianArrow}
                              alt=""
                              width="24px"
                              height="24px"
                              style={{
                                transform: openSelection.includes(index)
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                            />
                          </button>
                        </div>
                        {openSelection.includes(index) && (
                          <Radio.Group
                            onChange={(e) => onChange(e, index)}
                            value={value}
                          >
                            <Space direction="vertical">
                              {item[1].length > 0 &&
                                item[1].map((x1, i1) => {
                                  return (
                                    <Radio
                                      key={i1}
                                      value={item[0] + "||" + x1.title}
                                    >
                                      {x1.title}
                                    </Radio>
                                  );
                                })}
                            </Space>
                          </Radio.Group>
                        )}
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </>
        }
      />

      <div className="row-container" style={{ justifyContent: "flex-end" }}>
        <StyledButton
          form="form1"
          sizeh={48}
          title="Preview"
          type="submit"
          className="preview-button"
          bgcolor={"#567877"}
          onClick={toggleModal}
        />
      </div>

      {showModal && (
        <Modal
          title="Drag or Resize to get right image"
          visible={true}
          closable={false}
          maskClosable={false}
          wrapClassName="brand-form"
          centered
          mask={true}
          onOk={() => {
            getCropData();
            setShowModal(false);
          }}
          onCancel={() => cancelMedia(category)}
          className="modal-edit-image"
        >
          <div style={{ width: "100%" }}>
            <Cropper
              style={{ height: 400, width: "100%" }}
              // zoomTo={0.5}
              aspectRatio={category === "logo" ? 1 / 1 : 3 / 1}
              // initialAspectRatio={3/1}
              className={classNames({ circularCrop: category === "logo" })}
              preview=".img-preview"
              src={category === "logo" ? logoImage : bannerImage}
              viewMode={1}
              // zoomOnWheel={false}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </div>
        </Modal>
      )}

      <ModalLanuchEmpty
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className="title1 text-center">
              <br />
              Campaign Preview
            </span>
          </>
        }
        body={
          <>
            <AppPreview
              croppedBannerUrl={
                croppedBannerUrl
                  ? croppedBannerUrl
                  : client.io.io.uri + props.userInfo.banner || IconPlaceholder
              }
              croppedLogoUrl={
                croppedLogoUrl
                  ? croppedLogoUrl
                  : client.io.io.uri + props.userInfo.logo || IconCamera
              }
              notification={value}
              userInfo={props.userInfo}
            />
          </>
        }
        canEdit={true}
        noFooter={true}
        textAlign={"left"}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantBrand);
