import React, { useEffect, useState } from "react";
import { ContactContainer } from "./style";
import CountryList from "country-list-with-dial-code-and-flag";

import client from "feathers.js";

const Contact = (props) => {
  var color1 = "#3b7779";
  var color2 = "#FDB256";

  const facebookUrl = props.facebook.url.split("/");
  const facebookId = facebookUrl[facebookUrl.length - 1];

  const instagramUrl = props.instagram.url.split("/");
  const instagramId = instagramUrl[instagramUrl.length - 1];

  const twitterUrl = props.twitter.url.split("/");
  const twitterId = twitterUrl[twitterUrl.length - 1];

  const [country, setCountry] = useState("");

  useEffect(() => {
    let findCountry = CountryList.find((e) => e.code === props.country_visible);
    if (findCountry) {
      setCountry(findCountry.name);
    }
  }, [props.country_visible]);

  return (
    <div
      style={{
        width: 388,
      }}
    >
      <ContactContainer color1={color1} color2={color2}>
        <div className='contact-container' id='contact-container'>
          <div className='title-nav'>
            <span className='btn-back'>
              <i className='fas fa-angle-left'></i> <span>Back</span>
            </span>
            <div className='title'>
              <span>Contact Us</span>
            </div>
          </div>

          <div className='contact-page-body'>
            <div className='logo'>
              <div
                className='profile-icon'
                style={{
                  backgroundImage: `url("${client.io.io.uri}${
                    props.merchant.logo ? props.merchant.logo : null
                  }")`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  position: "relative",
                }}
              ></div>
              {/* <p>{props.merchant.business_name}</p> */}
            </div>
            <div className='card-wrapper'>
              <h1 className='mb-2'>{props.merchant.business_name}</h1>
              <div className='address'>
                {/* <h1>Business Details</h1> */}
                <p>{props.address1_visible}</p>
                <p>
                  {props.address2_visible} {props.city_visible}
                </p>
                <p>{props.zip_visible}</p>
                <p>{country}</p>
              </div>
              {props.about ? (
                <div className='about'>
                  <h4>About Us</h4>
                  <span>{props.about}</span>
                </div>
              ) : null}
            </div>

            <div className='card-wrapper'>
              <div className='contact'>
                {props.contact_email ? (
                  <h1>
                    <i className='fa fa-envelope'></i>
                    {props.contact_email}
                  </h1>
                ) : null}
                {props.phonePrefix_1 && props.contact_phone_1 && (
                  <p>
                    <i className='fa fa-phone'></i>
                    {props.phonePrefix_1} {props.merchant.contact_phone_1}
                  </p>
                )}
              </div>
            </div>

            <div className='social'>
              {props.facebook.url ? (
                <div className='card-wrapper mb-2'>
                  <p>
                    <i className='fab fa-facebook'></i>
                    <a
                      href={props.facebook.url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {facebookId
                        ? facebookId
                        : props.facebook.url
                        ? props.facebook.url.split("/").pop()
                        : "-"}
                    </a>
                  </p>
                </div>
              ) : null}
              {props.instagram.url ? (
                <div className='card-wrapper mb-2'>
                  <p>
                    <i className='fab fa-instagram'></i>
                    <a
                      href={props.instagram.url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {instagramId
                        ? instagramId
                        : props.instagram.url
                        ? props.instagram.url.split("/").pop()
                        : "-"}
                    </a>
                  </p>
                </div>
              ) : null}
              {props.twitter.url ? (
                <div className='card-wrapper'>
                  <p>
                    <i className='fab fa-twitter'></i>
                    <a
                      href={props.twitter.url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {twitterId
                        ? twitterId
                        : props.twitter.url
                        ? props.twitter.url.split("/").pop()
                        : "-"}
                    </a>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </ContactContainer>
    </div>
  );
};

export default Contact;
