import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageContainer } from "./style";
// import StyledButton from "components/Elements/StyledButton";
// import { Modal } from "antd";
// import { connect } from 'react-redux'
// import { DataContext } from "contexts/DataContextContainer";
// import API from 'utils/api'
// import Actions from 'actions'
// import ApiEndpoints from 'data/apiEndpoints.js'
// import Validation from 'data/validation.js'

const QRCode = props => {
  const history = useHistory();
  // const { setLoading, showToast } = useContext(DataContext);

  useEffect(() => {

  }, []);

  return (
    <PageContainer>
      <div className='header'>
        <button className='btn-back' onClick={() => history.goBack()}><ArrowLeftOutlined /></button>
        <div className='breadcrumbs'>
          <div className='child'>{props.title}</div>
        </div>
      </div>
      {/* <h3 className='title'>{props.title}</h3> */}
      <div className='page-container'>
        <h3>Coming Soon</h3>
      </div>
    </PageContainer>
  );
}

export default QRCode;
