import { Menu, Dropdown } from "antd";
import {
  EllipsisOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { ActionMenuContainer } from "../style";
import moment from "moment";

const menu = (record, setAction, setDataEdit, toggleModal) => {
  return (
    <ActionMenuContainer>
      <Menu.Item
        key='0'
        onClick={() => {
          setAction("edit");
          setDataEdit(record);
        }}
      >
        <FormOutlined /> Edit
      </Menu.Item>
      <Menu.Item
        key='1'
        onClick={() => {
          setAction("editPassword");
          setDataEdit(record);
        }}
      >
        <FormOutlined /> Edit Password
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => {
          toggleModal();
          setDataEdit(record);
        }}
      >
        {record.status === "baned" ? (
          <>
            <PlayCircleOutlined /> Activate
          </>
        ) : (
          <>
            <PauseOutlined /> Deactivate
          </>
        )}
      </Menu.Item>
    </ActionMenuContainer>
  );
};

const columns = (setAction, setDataEdit, toggleModal) => [
  {
    title: "Staff ID",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Name",
    dataIndex: "owner_name",
    key: "owner_name",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) =>
      status === "baned" ? (
        <span className='status-inactive'>Inactive</span>
      ) : (
        <span className='status-active'>Active</span>
      ),
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (record) => (
      <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    ),
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    render: (record) => (
      <Dropdown
        overlay={menu(record, setAction, setDataEdit, toggleModal)}
        trigger={["click"]}
      >
        <a
          href='/#'
          className='ant-dropdown-link'
          onClick={(e) => e.preventDefault()}
          style={{ color: "gray" }}
        >
          <EllipsisOutlined style={{ fontSize: 20 }} />
        </a>
      </Dropdown>
    ),
  },
];

export default columns;
