import { useContext, useEffect } from "react";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { loginDetails, rememberMe } from "redux/actions/roleActions";
import StyledButton from "components/Elements/StyledButton";
import { getBalance, getEarnings } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import { LoginContainer } from "./style";

import client from "feathers.js";
import axios from "axios";
import { MRZLogo } from "assets/svg";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Required!"),
  password: Yup.string().required("Required!"),
});

function Login(props) {
  const { setLoading, showToast } = useContext(DataContext);

  const signin = async (value) => {
    setLoading(true);

    // localStorage.clear()

    client
      .authenticate({
        strategy: "merchant-local",
        username: value.username,
        password: value.password,
      })
      .then((res) => {
        setLoading(false);
        // props.rememberMe(value.username, value.rememberMe)
        props.loginDetails(res);

        client
          .authenticate()
          .then((auth) => {
            return axios.post(
              `${client.io.io.uri}getMerchantCreditBalance`,
              {
                userId: res.user._id,
                currency: "usd",
              },
              {
                headers: {
                  Authorization: auth.accessToken,
                },
              }
            );
          })
          .then((res) => {
            props.getBalance(res.data.balance);
            props.getEarnings(res.data.earnings.comAmount);
          });

        setTimeout(() => {
          props.history.push("/admin/dashboard");
        }, 500);
      })
      .catch((err) => {
        showToast("error", err.message);
        setLoading(false);
      });
  };

  const onChangeDomain = (name, setFieldValue) => {
    if (name.length <= 50) {
      const value = name.toLowerCase();
      /* eslint-disable-next-line */
      const regex = /^[0-9a-zA-Z\_]+$/;
      if (value.match(regex) || value === "") {
        setFieldValue("username", value);
      }
    }
  };

  return (
    // <LoginContainer>
    //   <div className="row no-gutters home-container">
    //     <div className="col-md-6 my-auto left-image" />
    //     <div className="col-md-6 my-auto">
    //       <div className="signup-form mx-auto">
    //         <h2>Login Your Account</h2>
    //         <h4>Login with your social media account or Merchant ID</h4>
    //         <Formik
    //           validationSchema={LoginSchema}
    //           onSubmit={signin}
    //           initialValues={{
    //             username: '',
    //             password: '',
    //           }}
    //         >
    //           {({
    //             handleSubmit,
    //             handleChange,
    //             values,
    //             isValid,
    //             errors,
    //             dirty,
    //           }) => (
    //             <Form onSubmit={handleSubmit}>
    //               <Form.Row className="mt-4">
    //                 <Col md={12}>
    //                   <Form.Control
    //                     type="text"
    //                     name="username"
    //                     value={values.username}
    //                     onChange={handleChange}
    //                     isInvalid={!!errors.username}
    //                     placeholder="Enter Merchant ID"
    //                   />
    //                   <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
    //                 </Col>
    //               </Form.Row>
    //               <Form.Row className="mt-4">
    //                 <Col md={12}>
    //                   <Form.Control
    //                     type="password"
    //                     name="password"
    //                     value={values.password}
    //                     onChange={handleChange}
    //                     isInvalid={!!errors.password}
    //                     placeholder="Enter Password"
    //                   />
    //                   <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
    //                 </Col>
    //               </Form.Row>
    //               <StyledButton
    //                 disabled={!(isValid && dirty)}
    //                 type="submit"
    //                 className="btn-blue mt-5"
    //                 title="Login"
    //               />
    //             </Form>
    //           )}
    //         </Formik>
    //         <span>Don't have an account? <Link to="/signup">Sign up</Link> now!</span>
    //       </div>
    //     </div>
    //   </div>
    // </LoginContainer>
    // <MorerewardzLayout>

    <LoginContainer>
      <div
        className='signup-form elementor-element elementor-element-55cf268 e-container--column e-container'
        data-id='55cf268'
        data-element_type='container'
      >
        <div
          className='text-center elementor-element elementor-element-0a881c5 elementor-widget elementor-widget-heading'
          data-id='0a881c5'
          data-element_type='widget'
          data-widget_type='heading.default'
        >
          <img
            src={MRZLogo}
            className='img'
            alt=''
            width={300}
            style={{ marginBottom: "40px" }}
          />
          <div className='elementor-widget-container'>
            <h2 className='elementor-heading-title elementor-size-default'>
              Login Your Account
            </h2>
          </div>
        </div>
        <div
          className='text-center elementor-element elementor-element-f46e7bf elementor-widget elementor-widget-text-editor'
          data-id='f46e7bf'
          data-element_type='widget'
          data-widget_type='text-editor.default'
        >
          {/* <div className="elementor-widget-container">
                <h4>Login with your social media account or Merchant ID</h4>
              </div> */}
        </div>

        <div
          className='elementor-element elementor-element-4f80bd6 elementor-button-align-stretch elementor-widget elementor-widget-form'
          data-id='4f80bd6'
          data-element_type='widget'
          id='register_form'
          data-settings='{"step_next_label":"Next","step_previous_label":"Previous","button_width":"100","step_type":"number_text","step_icon_shape":"circle"}'
          data-widget_type='form.default'
        >
          <Formik
            validationSchema={LoginSchema}
            onSubmit={signin}
            initialValues={{
              username: "",
              password: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values,
              isValid,
              errors,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Row className='mt-3'>
                  <Col md={12}>
                    <div className='elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100'>
                      {/* <label htmlFor="form-field-name" className="elementor-field-label elementor-screen-only">
                    Merchant ID
                  </label> */}
                      <Form.Label htmlFor='username'>Merchant ID</Form.Label>
                      <Form.Control
                        size={1}
                        type='text'
                        name='username'
                        id='username'
                        value={values.username}
                        onChange={(e) =>
                          onChangeDomain(e.target.value, setFieldValue)
                        }
                        isInvalid={!!errors.username}
                        className='elementor-field elementor-size-sm  elementor-field-textual'
                        placeholder='Your Merchant ID'
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.username}
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                </Form.Row>

                <Form.Row className='mt-3'>
                  <Col md={12}>
                    <div className='mb-0 elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100'>
                      {/* <label htmlFor="form-field-name" className="elementor-field-label elementor-screen-only">
                    Password
                  </label> */}
                      <Form.Label htmlFor='password'>Password</Form.Label>
                      <Form.Control
                        size={1}
                        type='password'
                        name='password'
                        id='password'
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                        className='elementor-field elementor-size-sm  elementor-field-textual'
                        placeholder='Enter Password'
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.password}
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                </Form.Row>
                <div className='d-flex justify-content-end mb-2 mt-2'>
                  <a href='https://app.morerewardz.com/forgot-password'>
                    Forgot Password
                  </a>
                </div>

                <div className='d-flex justify-content-center'>
                  <StyledButton
                    type='submit'
                    sizeh={56}
                    width={"100%"}
                    bgcolor={"#567877"}
                    radius={12}
                    className='btn-blue mt-3'
                    title='Login'
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <span
          className='elementor-element elementor-element-5cb92bc elementor-widget elementor-widget-text-editor'
          data-id='5cb92bc'
          data-element_type='widget'
          data-widget_type='text-editor.default'
        >
          <div className='elementor-widget-container'>
            Don't have an account?{" "}
            <a href={process.env.REACT_APP_REGISTER_LINK}>Sign up</a> now!
          </div>
        </span>
      </div>
    </LoginContainer>
    // </MorerewardzLayout>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  loginDetails: loginDetails,
  rememberMe: rememberMe,
  getBalance: getBalance,
  getEarnings: getEarnings,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
