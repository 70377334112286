import { breakpoints, colors } from "style";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > .ant-modal-content {
    border-radius: 12px !important;

    * > .ant-modal-close {
      display: none;
    }
  }
  .form-styling {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .row-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    gap: 10px;
    margin: 50px 0 0 0;
  }
  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
  }
  .password-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 50px 0 0 0;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
    font-size: 14px;
    min-height: 110px;
    height: 100%;
    color: #6b7280;
  }
  .input {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    width: 100%;
    border: 1px solid #eaecee;
    border-radius: 10px;
    height: 48px;
    position: relative;
    gap: 50px;
    background: white;
    margin: 8px 0 0 0;
  }
  .cancel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 114px;
    height: 48px;
    border: 1px solid #eaecee;
    background: white;
    color: #6b7280;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
  }
  .save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 160px;
    color: white;
    font-size: 14px;
    border: none;
    height: 48px;
    border-radius: 10px;
    font-weight: 500;
    background: #567877;
  }
  .password-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    border: none;
    background: transparent;
    position: absolute;
    bottom: 45px;
    right: 10px;
    cursor: pointer;
  }
  .ant-btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 160px;
    color: white;
    font-size: 14px;
    border: none !important;
    height: 48px;
    border-radius: 10px;
    font-weight: 500;
    background: #567877 !important;
  }

  .title {
    margin-bottom: 24px;
    // font-weight: bold;
    color: ${colors.purple};
  }
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .home-container {
      flex: 1;
    }
    .signup-form {
      width: 100%;
      max-width: 800px;
      padding: 40px;
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 16px;
      h2 {
        color: ${colors.purple};
      }
      h4 {
        color: ${colors.grey};
      }
      .business-name {
        display: flex;
        justify-content: space-between;
        margin: 40px 0;
        .domain {
          display: flex;
          flex: 1;
          .bdomain {
            margin-right: 8px;
            margin-top: 6px;
          }
          .bname {
            margin-right: 40px;
            max-width: 500px;
          }
          .bname-err {
            border-color: ${colors.red};
          }
        }
        .check-avilability {
          color: ${colors.red};
          &:focus {
            box-shadow: unset;
          }
        }
        display: flex;
        align-items: center;
      }
      .form-control {
        height: 40px;
        border: 1px solid ${colors.textGrey};
        &:focus,
        &:active {
          outline: 0px !important;
          -webkit-appearance: none;
          box-shadow: none !important;
        }
      }
      .input-group {
        border: 1px solid ${colors.textGrey};
        border-radius: 0.25rem;
        padding-right: 2px;
        .input-group-text,
        .form-control {
          border: none;
          background-color: white;
        }

        .facebook {
          color: #3d88ce;
          font-size: 24px;
        }
        .twitter {
          color: #6dcef1;
          font-size: 24px;
        }
        .google {
          color: #f04c28;
          font-size: 24px;
        }
      }
      .country button {
        font-weight: normal;
      }
      .input-group-feedback {
        display: block;
      }
      .section-social {
        margin-top: 16px;
      }
      .business-social {
        margin-top: 40px;
        .btn {
          width: 100%;
          height: 40px;
          color: ${colors.white};
          font-size: 14px;
          margin-bottom: 18px;
          border-radius: 6px;
        }
        .btn-facebook {
          background: #3785cc;
        }
        .btn-twitter {
          background: #68cdf1;
        }
        .btn-google {
          background: #f04621;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
