import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import client from "feathers.js";
import { cloneDeep, isEmpty } from "lodash";
import { Input } from "antd";

import { PreviewContainer } from "../../styleEdit";
import { PageContainer } from "../../style";
import { DataContext } from "contexts/DataContextContainer";
import { Decrement, Increment, Success } from "assets/svg";
import StyledQuill from "components/Elements/StyledQuill";
import StyledButton from "components/Elements/StyledButton";
import StyledRadio from "components/Elements/StyledRadio";
import ModalLanuch from "components/Extra/ModalLanuch";
import QuestionCard from "components/QuestionCard";
import StepsCounter from "components/StepsCounter";

const durationOptions = [
  {
    value: "days",
    label: "Day(s)",
  },
  {
    value: "weeks",
    label: "Week(s)",
  },
  {
    value: "months",
    label: "Month(s)",
  },
  {
    value: "years",
    label: "Year(s)",
  },
];

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      // { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
  ],
};

const Edit = (props) => {
  const { setLoading, showToast } = useContext(DataContext);
  const [title, setTitle] = useState("");
  const [cost, setCost] = useState(1);
  const [campaignValidity, setCampaignValidity] = useState(1);
  const [campaignValidityUnit, setCampaignValidityUnit] = useState("months");
  const [canEdit, setCanEdit] = useState(false);
  const [tos, setTos] = useState("");
  const [title_count, setTitle_count] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [showSuccessCreate, setShowSuccessCreate] = useState(false);

  const description = [
    "Give your campaign a name",
    "Set up validity period for reward redemption",
    "Set the no. of stamps required for your campaign",
    "Add extra terms & Conditions",
  ];

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleTitle = (value) => {
    if (value.length <= 50) {
      setTitle(value);
      setTitle_count(value.length);
    }
  };

  const modalCallback = () => {
    if (canEdit) {
      setLoading(true);
      if (props.action === "edit") {
        const campaignDetail = {
          title,
          campaign_validity: campaignValidity,
          campaign_validity_unit: campaignValidityUnit,
          cost,
          tos,
        };

        client
          .authenticate()
          .then(() => {
            return client
              .service("points")
              .patch(props.data._id, campaignDetail);
          })
          .then((res) => {
            let cloneData = cloneDeep(props.campaignData);
            let findKey = cloneData.findIndex((e) => e._id === props.data._id);
            if (findKey !== -1) {
              res.totalCollected = cloneData[findKey].totalCollected;
              res.totalRedeemed = cloneData[findKey].totalRedeemed;
              cloneData[findKey] = res;
            }

            props.fetchCampaignInfo();
            props.setCampaignData(cloneData);

            setLoading(false);
            showToast("success", "Campaign is created successfully");
            props.back();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            showToast("error", err.message);
          });
      } else {
        const campaignDetail = {
          // merchant_id: props.currentMerchant._id,
          // campaign_type: 'package',
          // userId: props.userInfo._id,
          title,
          campaign_validity: campaignValidity,
          campaign_validity_unit: campaignValidityUnit,
          cost,
          tos,
        };

        client
          .authenticate()
          .then(() => {
            return client.service("points").create(campaignDetail);
          })
          .then((res) => {
            let cloneData = cloneDeep(props.campaignData);
            res.totalCollected = 0;
            res.totalRedeemed = 0;
            props.setTotal(props.total + 1);

            props.fetchCampaignInfo();
            props.setCampaignData(cloneData.concat(res));

            setLoading(false);
            showToast("success", "Campaign is created successfully");
            props.back();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            showToast("error", err.message);
          });
      }
    } else {
      showToast(
        "error",
        "Customer already redeemed this Voucher! Not allow to edit!"
      );
    }
  };

  const renderIcons = (value, setValue) => {
    const incrementCounter = () => {
      setValue(value + 1);
    };
    const decrementCounter = () => {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    };
    return (
      <div className='inc-dec-wrapper'>
        <button
          type='button'
          className='increment-button'
          onClick={() => incrementCounter()}
        >
          <img
            src={Increment}
            alt=''
            width='20px'
            height='20px'
            draggable={false}
          />
        </button>
        <button
          type='button'
          className='decrement-button'
          onClick={() => decrementCounter()}
        >
          <img
            src={Decrement}
            alt=''
            width='20px'
            height='18px'
            draggable={false}
          />
        </button>
      </div>
    );
  };

  const onKeyPressedChecking = (event, value, setValue) => {
    if (event.key === "ArrowUp") {
      setValue(value + 1);
    } else if (event.key === "ArrowDown") {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    }
  };

  const inputChecking = (value, setValue) => {
    const cleanedValue = String(value)
      .replace(/[^0-9]/g, "")
      .replace(/^0+/, "");

    const newValue = cleanedValue || "0";
    const parsedValue = parseInt(newValue);

    setValue(parsedValue);
  };

  const handleBackButton = () => {
    if (questionNumber === 1) {
      setQuestionNumber(1);
      return;
    }
    setQuestionNumber(questionNumber - 1);
  };

  const handleNextButton = () => {
    if (questionNumber === 1 && title.trim().length > 0) {
      setQuestionNumber(2);
    }
    if (questionNumber === 2 && cost > 0) {
      setQuestionNumber(3);
    }
    if (questionNumber === 3 && campaignValidity > 0 && campaignValidityUnit) {
      setQuestionNumber(4);
    }
    if (questionNumber === 4) {
      toggleModal();
    }
  };

  const renderQuestionCard = (q, d, c) => {
    return (
      <QuestionCard
        question={q}
        descriptions={d}
        totalQuestion={4}
        currentActive={questionNumber}
        onClickBackButton={handleBackButton}
        onClickNextButton={handleNextButton}
        noPaginator
        questionHeight='auto'
        answerHeight='120px'
        children={c}
      />
    );
  };

  const renderFirstTimeEditContent = () => {
    return (
      <div className='first-time-edit-content'>
        <div className='first-time-step-container'>
          <div className='first-time-step-wrapper'>
            <StepsCounter
              currentStepNumber={questionNumber}
              stepDescriptions={description}
              left='48.75%'
            />
          </div>
        </div>

        <div className='first-time-step-details-container'>
          {questionNumber === 1 &&
            renderQuestionCard(
              "What will the reward be?",
              <div className='description-text'>
                Provide an interesting reward which will capture customer’s
                attention.
              </div>,
              <div className='input-container'>
                <input
                  type='text'
                  className='first-time-input'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder='Eg. $20 NTUC fairprice voucher'
                />
                <p className='answer-input-description'>
                  Characters ({title.trim().length}/50)
                </p>
              </div>
            )}

          {questionNumber === 2 &&
            renderQuestionCard(
              "What is the validity period of redemption?",
              <div className='description-text'>
                Set the start and end date for this points period.
              </div>,
              <div className='input-container'>
                <div
                  className='input-container'
                  style={{ margin: "0 0 5px 0" }}
                >
                  <Input
                    id='campaignValidity'
                    className='first-time-input'
                    type='text'
                    size='large'
                    value={campaignValidity}
                    onKeyDown={(e) =>
                      onKeyPressedChecking(
                        e,
                        campaignValidity,
                        setCampaignValidity
                      )
                    }
                    onChange={(e) =>
                      campaignValidity <= 100
                        ? inputChecking(e.target.value, setCampaignValidity)
                        : setCampaignValidity(100)
                    }
                    onBlur={() => [
                      campaignValidity === 0 && setCampaignValidity(1),
                      campaignValidity > 100 && setCampaignValidity(100),
                    ]}
                  />
                  {renderIcons(campaignValidity, setCampaignValidity)}
                </div>
                <div className='number-preset'>
                  <button
                    className={campaignValidityUnit === "days" ? "active" : ""}
                    onClick={() => setCampaignValidityUnit("days")}
                  >
                    Day(s)
                  </button>
                  <button
                    className={campaignValidityUnit === "weeks" ? "active" : ""}
                    onClick={() => setCampaignValidityUnit("weeks")}
                  >
                    Week(s)
                  </button>
                  <button
                    className={
                      campaignValidityUnit === "months" ? "active" : ""
                    }
                    onClick={() => setCampaignValidityUnit("months")}
                  >
                    Month(s)
                  </button>
                  <button
                    className={campaignValidityUnit === "years" ? "active" : ""}
                    onClick={() => setCampaignValidityUnit("years")}
                  >
                    Year(s)
                  </button>
                </div>
              </div>
            )}

          {questionNumber === 3 &&
            renderQuestionCard(
              "How many points are required to earn this reward?",
              <div className='description-text'>
                Specify the number of points required to redeem this reward.
                <br />
                <br />
                <span>Note* your points conversion is 1 point = $10</span>
              </div>,
              <div className='input-container'>
                <div
                  className='input-container'
                  style={{ margin: "0 0 5px 0" }}
                >
                  <Input
                    className='first-time-input'
                    type='text'
                    size='large'
                    value={cost}
                    onKeyDown={(e) => onKeyPressedChecking(e, cost, setCost)}
                    onChange={(e) =>
                      cost <= 100
                        ? inputChecking(e.target.value, setCost)
                        : setCost(100)
                    }
                    onBlur={() => [
                      cost === 0 && setCost(1),
                      cost > 100 && setCost(100),
                    ]}
                  />
                  {renderIcons(cost, setCost)}
                </div>
                <div className='number-preset'>
                  <button
                    className={cost === 3 ? "active" : ""}
                    onClick={() => setCost(3)}
                  >
                    3
                  </button>
                  <button
                    className={cost === 5 ? "active" : ""}
                    onClick={() => setCost(5)}
                  >
                    5
                  </button>
                  <button
                    className={cost === 10 ? "active" : ""}
                    onClick={() => setCost(10)}
                  >
                    10
                  </button>
                  <button
                    className={cost === 20 ? "active" : ""}
                    onClick={() => setCost(20)}
                  >
                    20
                  </button>
                  <button
                    className={
                      cost !== 3 && cost !== 5 && cost !== 10 && cost !== 20
                        ? "active"
                        : ""
                    }
                    onClick={() => setCost(1)}
                  >
                    Others
                  </button>
                </div>
              </div>
            )}

          {questionNumber === 4 &&
            renderQuestionCard(
              <>
                <div>Final step!</div>
                <div>Any other terms & conditions?</div>
              </>,
              <div className='description-text'>
                <ul className='description-list'>
                  <li>
                    This voucher is valid for {campaignValidity}{" "}
                    {campaignValidityUnit.slice(0, -1)}(s) upon first collect
                  </li>
                </ul>
              </div>,
              <StyledQuill
                readOnly={false}
                modules={quillModules}
                value={tos}
                onChange={async (value) => {
                  setTos(value);
                }}
                style={{ height: "100px", marginBottom: "50px" }}
              />
            )}
        </div>
      </div>
    );
  };

  const successCreate = () => {
    return (
      <div className='first-time-edit-content'>
        <div className='success-container'>
          <img src={Success} alt='' width='50px' height='50px' />
          Almost Done!
          <StyledButton
            title='Preview'
            onClick={toggleModal}
            type='submit'
            className='primary-button'
            bgcolor={"#567877"}
          />
        </div>
      </div>
    );
  };

  const renderEditContent = () => {
    return (
      <div className='edit-content'>
        <div className='input-container'>
          Rewards
          <Input
            size='large'
            placeholder='Get a free cup of coffee worth $6'
            className='input'
            value={title}
            onChange={(e) => handleTitle(e.target.value)}
          />
        </div>

        <div className='input-container'>
          Required points to redeem
          <Input
            type='text'
            className='input'
            size='large'
            value={cost}
            onKeyDown={(e) => onKeyPressedChecking(e, cost, setCost)}
            onChange={(e) => inputChecking(e.target.value, setCost)}
            onBlur={() => cost === 0 && setCost(1)}
          />
          {renderIcons(cost, setCost)}
        </div>

        <div className='input-container'>
          <div className='input-container'>
            Required points to redeem
            <Input
              className='input'
              type='text'
              size='large'
              value={campaignValidity}
              onKeyDown={(e) =>
                onKeyPressedChecking(e, campaignValidity, setCampaignValidity)
              }
              onChange={(e) =>
                inputChecking(e.target.value, setCampaignValidity)
              }
              onBlur={() => campaignValidity === 0 && setCampaignValidity(1)}
            />
            {renderIcons(campaignValidity, setCampaignValidity)}
          </div>
          <StyledRadio
            options={durationOptions}
            value={campaignValidityUnit}
            onChange={(e) => setCampaignValidityUnit(e.target.value)}
            className='col-md-6'
          />
        </div>

        <div className='input-container'>
          Additional Terms & Condition
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
            }}
          >
            <li>
              This voucher is valid for {campaignValidity}{" "}
              {campaignValidityUnit.slice(0, -1)}(s) upon first collect
            </li>
          </ul>
          <StyledQuill
            readOnly={false}
            modules={quillModules}
            value={tos}
            onChange={async (value) => {
              setTos(value);
            }}
          />
        </div>

        <div className='button-wrapper-container'>
          <button
            type='button'
            onClick={() => props.back()}
            className='cancel-button'
          >
            Back
          </button>
          <StyledButton
            title='Preview'
            onClick={toggleModal}
            type='submit'
            className='primary-button'
            bgcolor={"#567877"}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (props.data._id) {
      client
        .authenticate()
        .then(() => {
          return client.service("voucher-pouches").find({
            query: {
              pointId: props.data._id,
              merchantId: props.userInfo._id,
              $limit: 1,
            },
          });
        })
        .then((res) => {
          console.log(res);
          if (res.data.length > 0) {
            showToast(
              "error",
              "Customer already redeemed this Voucher! Not allow to edit!"
            );
          } else {
            setCanEdit(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    } else {
      setCanEdit(true);
    }
    /* eslint-disable-next-line */
  }, [props.data._id, props.userInfo._id]);

  useEffect(() => {
    if (!isEmpty(props.data)) {
      setTitle(props.data.title);
      setTitle_count(props.data.title.length);
      setCampaignValidity(props.data.campaign_validity);
      setCampaignValidityUnit(props.data.campaign_validity_unit);
      setCost(props.data.cost);
      setTos(props.data.tos);
    }
  }, [props.data._id, props.data]);

  return (
    <PageContainer>
      {props.firstTime
        ? showSuccessCreate
          ? successCreate()
          : renderFirstTimeEditContent()
        : renderEditContent()}

      <ModalLanuch
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        campaign='point'
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Campaign Preview
            </span>
          </>
        }
        body={
          <PreviewContainer>
            <div className='preview-wrapper'>
              <div className='preview'>
                {/* <span>Preview</span> */}
                <div className='details'>
                  <div className='title'>
                    <h4>{title ? title : "--"}</h4>
                    <p style={{ fontWeight: 500, marginBottom: 8 }}>
                      Points Required: {cost}
                    </p>
                    <div className='tag'>Voucher</div>
                  </div>
                  <div className='stamps'>
                    <h4>{`Voucher Expiry : ${campaignValidity} ${campaignValidityUnit.slice(
                      0,
                      -1
                    )}(s) from upon of Redemption`}</h4>
                  </div>
                  <div className='terms'>
                    <h4>Terms & Condition</h4>
                    <ul style={{ marginBottom: 0 }}>
                      <li>
                        This voucher is valid for {campaignValidity}{" "}
                        {campaignValidityUnit.slice(0, -1)}(s) upon first
                        collect
                      </li>
                    </ul>
                    {<div>{parse(tos)}</div>}
                  </div>
                </div>
              </div>
            </div>
          </PreviewContainer>
        }
        canEdit={canEdit}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
