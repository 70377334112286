import { StepContainer } from "./style";
import { DatePicker, Space } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;

function CampaignStep3(props) {
  const onChange = (dates, dateStrings) => {
    props.setPeriodStart(dates[0])
    props.setPeriodEnd(dates[1])
  }

  return (
    <StepContainer>
      <div className='container-center'>
      <p className='text-center mb-0' style={{color: '#3B7779', fontWeight: 500}}>Question</p>
      <h2>When do you want to start & end this stamp card campaign?</h2>
      <br/>
        <p className='text-center mb-0' style={{color: '#3B7779', fontWeight: 500}}>Answer</p>
        <Space direction="vertical" size={12} className="w-100">
          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
            disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current < moment(customDate, "YYYY-MM-DD");
            }} 
            value={[props.periodStart, props.periodEnd]}
            onChange={onChange}
          />
        </Space>

        <div className='box'>
          <p>
          This stamp card campaign will only be visible and available for your customer to collect within the campaign period.
          </p>
          <p>
          For example, if you set the start date as 10 Jan 2023 and end date as 31 Dec 2023, it will not show up in your campaign page before the start date or after the end date.
          </p>
        </div>
      </div>
    </StepContainer>
  );
}

export default CampaignStep3;
