import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PageContainer } from "./style";
import { connect } from "react-redux";
import axios from "axios";
import client from "feathers.js";
import { DataContext } from "contexts/DataContextContainer";
import InfoCard from "components/InfoCard/InfoCard";
import DetailsSection from "components/DetailsSection/RowContainer";

const GASetup = (props) => {
  /* eslint-disable-next-line */
  const { title, query } = props;

  const history = useHistory();

  const { setLocation, setBreadcrumb } = useContext(DataContext);
  const [googleAnalyticsTrackingId, setGoogleAnalyticsTrackingId] =
    useState("");
  const [storeAuth, setStoreAuth] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [currentTrackingId, setCurrentTrackingId] = useState("");
  const [renderElement, setRenderElement] = useState(false);

  const [toggleEdit, setToggleEdit] = useState(false);

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Tools", props.title]);
  }, []);

  useEffect(() => {
    const getGAID = async () => {
      const storeMerchantGAID = await axios.get(
        `${client.io.io.uri}getGATrackingId`,
        {
          headers: {
            authorization: `Bearer ${storeAuth}`,
          },
          params: {
            merchantId: merchantId,
          },
        }
      );

      setCurrentTrackingId(storeMerchantGAID.data.googleAnalyticsTrackingId);
    };

    getGAID();
  });

  client.authenticate().then((auth) => {
    setStoreAuth(auth.accessToken);
    setMerchantId(auth.user._id);
  });

  const onSubmitHandler = () => {
    setRenderElement(true);
    setToggleEdit(false);

    if (googleAnalyticsTrackingId !== "") {
      axios
        .post(
          `${client.io.io.uri}setGATrackingId`,
          {
            googleAnalyticsTrackingId,
            merchantId,
          },
          {
            headers: {
              Authorization: storeAuth,
            },
          }
        )
        .then(() => {
          setRenderElement(false);
          setGoogleAnalyticsTrackingId("");
        });
    }
  };

  return (
    <PageContainer>
      <InfoCard desc='By setting up your Google Analytics ID, it allows you to effortlessly connect Google Analytics to your web page where you can gain valuable insights into user behaviour, traffic, and online performance to make data-driven decisions for your online' />

      <DetailsSection
        leftHeader='Google Analytics ID'
        leftDesc='Setup your Google Analytics ID.'
        rightContent={
          <>
            {renderElement === false && (
              <input
                type='text'
                id='googleAnalyticsTrackingId'
                name='googleAnalyticsTrackingId'
                placeholder={`${
                  !currentTrackingId
                    ? "Enter your google analytics id"
                    : currentTrackingId
                }`}
                value={googleAnalyticsTrackingId.split(" ").join("")}
                onChange={(e) => setGoogleAnalyticsTrackingId(e.target.value)}
                className='input'
              />
            )}
            <div className='button-wrapper'>
              <button
                type='button'
                className='submit-button'
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
            </div>
          </>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GASetup);
