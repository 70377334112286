import { Input, Select } from 'antd';
import { StepContainer } from "./style";
// import StyledRadio from "components/Elements/StyledRadio";

const durationOptions = [
  {
    value: "days",
    label: "Day(s)",
  },
  {
    value: "weeks",
    label: "Week(s)",
  },
  {
    value: "months",
    label: "Month(s)",
  },
  {
    value: "years",
    label: "Year(s)",
  }
]

const { Option } = Select;

function CampaignStep4(props) {
  return (
    <StepContainer>
      <div className='container-center'>
      <p className='text-center mb-0' style={{color: '#3B7779', fontWeight: 500}}>Question</p>
      <h2>After customer collected the first stamp, by when must they complete the stamp card?</h2>
      <br/>
      <p className='text-center mb-0' style={{color: '#3B7779', fontWeight: 500}}>Answer</p>

      <div className='d-flex justify-content-center align-items-center'>
        <div>
          <span style={{fontWeight: 500}}>{" "}Within</span>
          <Input 
            type='number' 
            min={1} 
            size="large" 
            placeholder='3'
            value={props.campaignValidity} 
            onChange={e => props.setCampaignValidity(e.target.value)}
          />
          <Select 
            value={props.campaignValidityUnit}
            onChange={e => props.setCampaignValidityUnit(e)}
          >
            {
              durationOptions.map((x, i) => {
                return <Option key={i} value={x.value}>{x.label}</Option>
              })
            }
          </Select>
        </div>
      </div>

        {/* <div className='stamp-duration'>
          
          <StyledRadio
            options={durationOptions}
            value={props.campaignValidityUnit}
            onChange={e => props.setCampaignValidityUnit(e.target.value)}
            style={{
              margin: '0px 0px 20px 0'
            }}
          />
        </div> */}

        <div className='box'>
          <p>
          You can set the time frame to complete the card in day, week, month or year.
          </p>
          <p>
          For example, if you set the time frame as 6 months,
          </p>
          <p className='mb-0'>
          If customer gets the first stamp on 23 Jan 2023 Customer will need to complete the stamp card by 22 Jul 2023 (6 months)
          </p>
          <p>
          If customer fails to complete the stamp card within the time frame, the stamp card will expire.
          </p>
        </div>
        
      </div>
    </StepContainer>
  );
}

export default CampaignStep4;
