import { breakpoints, colors } from "style";
import styled from "styled-components";

export const RewardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  position: relative;
  box-shadow: 0px 6px 17px rgb(217 217 217 / 25%);
  .reward-container {
    width: 100%;
    .status-icon-success {
      font-size: 100px;
      color: ${colors.green};
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .status-icon-fail {
      font-size: 100px;
      color: red;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .top {
      background: #3b7779;
      text-align: center;
      padding: 18px 0px;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      h3 {
        font-weight: 700;
        font-size: 24px;
        color: #f2ffe5;
        margin: 0;
      }
    }
    .wrapepr {
      padding: 14px 14px 0 14px;
    }
    .header {
      flex:1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      text-align: center;
      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #3b7779;
      }
      h5 {
        font-weight: 400;
        font-size: 14px;
        color: #575757;
      }
    }
    .voucher {
      border: 1px dashed #FDB256;
      border-radius: 7px;
      background: rgba(255, 184, 79, 0.09);
      border-style: dashed;
      padding: 12px 10px;
      text-align: center;
      margin: 4px 0;
      span {
        font-weight: 500;
        font-size: 20px;
        color: #ffb605;

      }
    }
    .tos {
      margin: 8px 0;
      text-align: center;
      .title {
        font-weight: 600;
        font-size: 12px;
        color: #3B3054;
      }
      .detail {
        font-weight: 400;
        font-size: 12px;
        color: #757575;
        ul {
          padding-left: 24px;
          list-style: none;
        }
      }
    }
    .accept {
      width: 100%;
      padding: 0px 14px;
      text-align: center;
      button {
        background: #3b7779;
        box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
        border-radius: 7px;
        width: 100%;
        padding: 14px;
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 15px;
        text-transform: uppercase;
        color: #FFFFFF;
        :active, : hover {
          background-color: transparent;
          border: solid 1px #DB5655;
          color: #DB5655;
        }
      }
    }

    .success, .fail {
      text-align: center;
      margin-bottom: 28px;
      img {
        margin-bottom: 20px;
      }
      h5 {
        font-weight: 400;
        font-size: 15px;
        color: #575757;
        // line-height: 1;
      }
    }
    .pouch {
      width: 100%;
      padding: 0px 14px;
      text-align: center;
      button {
        background: #3b7779;
        box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
        border-radius: 7px;
        font-weight: 700;
        font-size: 15px;        
        text-transform: uppercase;
        color: #FFFFFF;
        
        width: 100%;
        padding: 14px;
        margin-bottom: 12px;
        :active, : hover {
          background-color: transparent;
          border: solid 1px #DB5655;
          color: #DB5655;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
