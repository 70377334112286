import React, { useState, useContext } from 'react';
import StyledQuill from "components/Elements/StyledQuill";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { 
  Row, 
  Col
} from "react-bootstrap";
import { Input, Space } from 'antd';
import { PageContainer } from "./styleEdit";
// import StyledButton from "components/Elements/StyledButton";
import { DataContext } from "contexts/DataContextContainer";
// import moment from 'moment';
import { cloneDeep } from 'lodash';

import client from "feathers.js"

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

const Edit = props => {
  const { setLoading, showToast } = useContext(DataContext);
  const [tos, setTos] = useState(props.data.tos)

  const handleApprove = async (status) => {
    setLoading(true)

    var sts = 'reject'
    var verify = false

    if(status === 'approved'){
      sts = 'approved'
      verify = true
    }

    await client.service('requests').patch(props.data._id, {
      tos,
      status: sts
    })
    .then(() => {
      client.service('merchants').patch(props.data.userId, {
        business_verified: verify
      })

      let cloneData = cloneDeep(props.campaignData)
      let findKey = cloneData.findIndex(e => e._id === props.data._id)
      if(findKey !== -1){
        cloneData[findKey].status = sts
        cloneData[findKey].tos = tos
      }
      props.setCampaignData(cloneData)
      
      setLoading(false)
      showToast('success', `Request ${sts} successfully`)
      props.back()
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      showToast('error', err.message)
    })
  }

  const downloadFile = (fileName) => {
    window.location.href = `${client.io.io.uri}downloadUploadFile?fileName=`+fileName
  }

  return (
    <PageContainer>
      <div className='header'>
        <button className='btn-back' onClick={() => props.back()}><ArrowLeftOutlined /></button>
        <h3>Request Review</h3>
      </div>
      <div className='page-content'>
        <Space className='detail' size={32} direction='vertical'>
          <Row>
            <Col md="6">
              <Space className='section' size={8} direction='vertical'>
                <span className='title'>Files</span>
                {
                  props.data.files.length > 0?
                  props.data.files.map((x, i) => {
                    return (
                      <div key={i}>
                        <p className='mb-0' style={{textDecoration: 'underline'}}>{x.name}</p>
                        <p style={{color: '#0069c2', cursor: 'pointer', wordBreak: 'break-all'}} onClick={() => downloadFile(x.file)}>{x.file}</p>
                      </div>
                    )
                  }): null
                }
              </Space>

              <Space className='section' size={8} direction='vertical'>
                <span className='title'>Name</span>
                <Input 
                  type="text" 
                  size="large" 
                  value={props.data.name} 
                  disabled
                />
              </Space>

              <Space className='section' size={8} direction='vertical'>
                <span className='title'>Contact Number</span>
                <Row className='no-gutters'>
                  <Col md="4" className='pr-2'>
                    <Input 
                      type='text' 
                      size="large" 
                      value={props.data.phonePrefix} 
                      disabled
                    />
                  </Col>
                  <Col md="8">
                    <Input 
                      type='text' 
                      size="large" 
                      value={props.data.contact_phone} 
                      disabled
                    />
                  </Col>
                </Row>
              </Space>

              <Space className='section' size={8} direction='vertical'>
                <span className='title'>Email</span>
                <Input 
                  type='text' 
                  size="large" 
                  value={props.data.contact_email} 
                  disabled
                />
              </Space>
            </Col>
            <Col md="6">
              <Space className='section' size={8} direction='vertical'>
                <span className='title'>Additional Files</span>
                {
                  props.data.moreFiles.length > 0?
                  props.data.moreFiles.map((x, i) => {
                    return (
                      <div key={i}>
                        {/* <p className='mb-0' style={{textDecoration: 'underline'}}>{x.fileName}</p> */}
                        <p style={{color: '#0069c2', cursor: 'pointer', wordBreak: 'break-all'}} onClick={() => downloadFile(x.fileName)}>{x.fileName}</p>
                      </div>
                    )
                  }): null
                }
              </Space>
            </Col>
          </Row>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>Message to Merchant</span>
            <StyledQuill
              readOnly={false}
              modules={quillModules}
              value={tos}
              onChange={async (value) => {
                setTos(value)
              }}
            />
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <div className='actions d-flex' style={{justifyContent: 'space-evenly'}}>
              <button
                className="btn-default w-100 mt-4 mx-2"
                onClick={() => props.back()}
              >
                Back
              </button>
              <button
                className="btn-danger w-100 mt-4 mx-2"
                onClick={() => handleApprove('reject')}
              >
                Reject
              </button>

              <button
                className="btn-primary w-100 mt-4 mx-2"
                onClick={() => handleApprove('approved')}
              >
                Approve
              </button>
            </div>
          </Space>
        </Space>
      </div>
    </PageContainer>
  );
}

export default Edit;
