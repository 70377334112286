import client from "feathers.js"
import { isEmpty } from "lodash";
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'

export default async function loadCsvData(
  activeTab,
  merchantId,
  setCsvData,
  setSpin,
  setLoading,
  pagination,
  startDate,
  endDate,
  showToast,
  customerId,
  campaignId,
  voucherType
) {
  
  if(activeTab === 0){

    let tmpData = []
    setLoading(false)

    const stackData = (index) => {
      var query = {
        $skip: index*pagination.per_page,
        getStampInfo: true,
        getActivities: true,
        merchantId,
        $sort: {
          createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: index*pagination.per_page,
          getStampInfo: true,
          getActivities: true,
          merchantId,
          $sort: {
            createdAt: -1
          }
        }
      }

      if(customerId){
        query.userId = customerId
      }else{
        delete query.userId
      }
      if(campaignId){
        query.stampId = campaignId
      }else{
        delete query.stampId
      }

      return new Promise(resolve => {
        client.authenticate()
        .then(()=>{
          return client.service('stamp-pouches').find({
            query: query
          })
        })
        .then((res) => {
          let newData = []
          res.data.map(x => {
            if(x.activities.length > 0)
            x.activities.campaignType = "activity"
            newData = newData.concat(x.activities)
            newData.push(x)
            return x
          })

          let d = []
           
          for(let i = 0; i < newData.length; i++){
            d.push({
              "Date/Time": moment(newData[i].createdAt).format("DD/MM/YYYY - HH:mm"),
              Campaign: newData[i].campaignType? newData[i].title: newData[i].stampInfo.title,
              Email: newData[i].campaignType? newData[i].customer_email: newData[i].userInfo.email,
              Actions: newData[i].campaignType? (newData[i].action === "redeem" || newData[i].redeem)? "Redeem": "Stamp Given": "Stamp Card Collected",
              "Staff ID": newData[i].campaignType? newData[i].repName: "-",
              "Stamp Left": newData[i].campaignType? (newData[i].countLeft?newData[i].countLeft-1:''): newData[i].stampInfo.count,
              "Stamp Card Expiry": newData[i].campaignType? "-": (
                newData[i].hasOneActivityDate?
                moment(newData[i].hasOneActivityDate).add(parseInt(newData[i].stampInfo.campaign_validity), newData[i].stampInfo.campaign_validity_unit).format('DD/MM/YYYY - HH:mm')
                :
                moment(newData[i].stampInfo.period_end).format('DD/MM/YYYY - HH:mm')
              ),
              Remarks: newData[i].remarks? newData[i].remarks: '-',
              // "First Name": newData[i].userInfo.firstName,
              // "Last Name": newData[i].userInfo.lastName,
              // "Phone Prefix": newData[i].userInfo.phonePrefix,
              // "Contact Phone": newData[i].userInfo.contact_phone,
              // Gender: newData[i].userInfo.gender,
              // "Address 1": newData[i].userInfo.address1,
              // "Address 2": newData[i].userInfo.address2,
              // City: newData[i].userInfo.city,
              // Zip: newData[i].userInfo.zip,
              // Country: newData[i].userInfo.country,
            })
          }
          
          tmpData = tmpData.concat(d)
          resolve(newData)
        })
        .catch((err)=>{
          if(err.name === "NotAuthenticated"){
            showToast('error', "Please Sign-in to continue!")
          }else{
            showToast('error', err.message)
          }
        })
      })
    }
    
    let numOfLoop = []
    for(let i = 0; i < pagination.last_page; i++){
      numOfLoop.push(i)
    }

    let process = await numOfLoop.map(async(value, index) => {
      let data = await stackData(index);
      return data
    })

    let result = await Promise.all(process)

    if(result){
      setCsvData(tmpData)
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
      }, 2000);
    }
    
  }else if(activeTab === 1){

    let tmpData = []
    setLoading(false)

    const stackData = (index) => {
      var query = {
        $skip: index*pagination.per_page,
        getPackageInfo: true,
        getActivities: true,
        merchantId,
        $sort: {
          createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: index*pagination.per_page,
          getPackageInfo: true,
          getActivities: true,
          merchantId,
          $sort: {
            createdAt: -1
          }
        }
      }

      if(customerId){
        query.userId = customerId
      }else{
        delete query.userId
      }
      if(campaignId){
        query.packageId = campaignId
      }else{
        delete query.packageId
      }

      return new Promise(resolve => {
        client.authenticate()
        .then(()=>{
          return client.service('package-purchases').find({
            query: query
          })
        })
        .then((res) => {
          let newData = []
          res.data.map(x => {
            if(x.activities.length > 0)
            x.activities.campaignType = "activity"
            newData = newData.concat(x.activities)
            newData.push(x)
            return x
          })

          function getPaymentType(value){
            if(value === "cash"){
              return "Cash Payment"
            }if(value === "card"){
              return "Card Payment"
            }else{
              // wallet
              return "Wallet Payment"
            }
          }
          
          let d = []
           
          for(let i = 0; i < newData.length; i++){
            d.push({
              "Date/Time": moment(newData[i].createdAt).format("DD/MM/YYYY - HH:mm"),
              Campaign: newData[i].campaignType? newData[i].title: newData[i].packageInfo.title,
              Email: newData[i].campaignType? newData[i].customer_email: newData[i].userInfo.email,
              Actions: newData[i].campaignType? (newData[i].action === "ultilised")? "Ultilised": "Stamp Given": "Package Purchased",
              "Payment Type": newData[i].campaignType? "-": getPaymentType(newData[i].paymentType),
              "Staff ID": newData[i].repName? newData[i].repName: "-",
              "Package Balance": newData[i].campaignType? newData[i].countLeft-1: newData[i].packageInfo.count,
              "Package Expiry": newData[i].campaignType? "-": (
                moment(newData[i].createdAt).add(parseInt(newData[i].packageInfo.campaign_validity), newData[i].packageInfo.campaign_validity_unit).format('DD/MM/YYYY - HH:mm')
              ),
              Remarks: newData[i].remarks? newData[i].remarks: '-',
            })
          }
          
          tmpData = tmpData.concat(d)
          resolve(newData)
        })
        .catch((err)=>{
          if(err.name === "NotAuthenticated"){
            showToast('error', "Please Sign-in to continue!")
          }else{
            showToast('error', err.message)
          }
        })
      })
    }
    
    let numOfLoop = []
    for(let i = 0; i < pagination.last_page; i++){
      numOfLoop.push(i)
    }

    let process = await numOfLoop.map(async(value, index) => {
      let data = await stackData(index);
      return data
    })

    let result = await Promise.all(process)

    if(result){
      setCsvData(tmpData)
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
      }, 2000);
    }
    
  }else if(activeTab === 2){

    let tmpData = []
    setLoading(false)

    const stackData = (index) => {
      var query = {
        $skip: index*pagination.per_page,
        getVoucherInfo: true,
        merchantId,
        $sort: {
          createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: index*pagination.per_page,
          getVoucherInfo: true,
          merchantId,
          $sort: {
            createdAt: -1
          }
        }
      }

      if(customerId){
        query.userId = customerId
      }else{
        delete query.userId
      }
      if(campaignId){
        if(voucherType === 'voucher'){
          query.voucherId = campaignId
        }else if(voucherType === 'point'){
          query.pointId = campaignId
        }else if(voucherType === 'referral'){
          query.referralSahreId = campaignId
        }
      }else{
        delete query.voucherId
        delete query.pointId
        delete query.referralSahreId
      }

      return new Promise(resolve => {
        client.authenticate()
        .then(()=>{
          return client.service('voucher-pouches').find({
            query: query
          })
        })
        .then((res) => {    
          let newData = []
          res.data.map(x => {
            // if(x.activities.length > 0)
            // x.activities.campaignType = "activity"
            // newData = newData.concat(x.activities)
            newData.push(x)
            return x
          })

          let d = []

          function getValue(value){
            if(!isEmpty(value.referralShareValue)){
              return value.referralShareValue
            }else if(!isEmpty(value.voucherInfo)){
              return value.voucherInfo
            }else{
              return value.pointValue
            }
          }

          function getType(value){
            if(!isEmpty(value.referralShareValue)){
              return "Referral"
            }else if(!isEmpty(value.voucherInfo)){
              return "Voucher"
            }else{
              return "Point Redeem"
            }
          }
           
          for(let i = 0; i < newData.length; i++){
            d.push({
              "Date/Time": moment(newData[i].createdAt).format("DD/MM/YYYY - HH:mm"),
              Campaign: getValue(newData[i]).title,
              "Type": getType(newData[i]),
              Email: newData[i].campaignType? newData[i].customer_email: newData[i].userInfo.email,
              "Voucher Expiry": newData[i].campaignType? "-": (
                newData[i].voucherInfo? 
                moment(newData[i].createdAt).add(parseInt(newData[i].voucherInfo.campaign_validity), newData[i].voucherInfo.campaign_validity_unit).format('DD/MM/YYYY - HH:mm')
                :
                moment(newData[i].createdAt).format('DD/MM/YYYY - HH:mm')
              ),
              Actions: newData[i].campaignType? (newData[i].action === "redeem" || newData[i].redeem)? "Redeemed": "-": "Voucher Collected",
              "Staff ID": newData[i].campaignType? newData[i].repName: "-",
              Remarks: newData[i].remarks? newData[i].remarks: '-',
            })
          }
          
          tmpData = tmpData.concat(d)
          resolve(newData)
        })
        .catch((err)=>{
          if(err.name === "NotAuthenticated"){
            showToast('error', "Please Sign-in to continue!")
          }else{
            showToast('error', err.message)
          }
        })
      })
    }
    
    let numOfLoop = []
    for(let i = 0; i < pagination.last_page; i++){
      numOfLoop.push(i)
    }

    let process = await numOfLoop.map(async(value, index) => {
      let data = await stackData(index);
      return data
    })

    let result = await Promise.all(process)

    if(result){
      setCsvData(tmpData)
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
      }, 2000);
    }

  }else if(activeTab === 3){


    var tmpData = []
    setLoading(false)

    const stackData = (index) => {
      var query = {
        $skip: index*pagination.per_page,
        merchantId: merchantId,
        // type: "points",
        $sort: {
          createdAt: -1
        }
      }
      var query1 = {
        $skip: index*pagination.per_page,
        merchantId: merchantId,
        type: "points",
        $sort: {
          createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: index*pagination.per_page,
          merchantId: merchantId,
          // type: "points",
          $sort: {
            createdAt: -1
          }
        }
        query1 = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: index*pagination.per_page,
          merchantId: merchantId,
          type: "points",
          $sort: {
            createdAt: -1
          }
        }
      }

      return new Promise(resolve => {
        client.authenticate()
        .then(()=>{
          return client.service('transactions').find({
            query: query
          })
        })
        .then(async(res) => {
          let d = []

          let transactions = await client.service('transactions').find({
            query: query1,
          })
          if(transactions.data.length > 0){
            transactions.data.map(x => {
              x.campaignType = "transaction"
              return x
            })
          }  

          res.data = res.data.concat(transactions.data)
           
          for(let i = 0; i < res.data.length; i++){
            d.push({
              "Date/Time": moment(res.data[i].createdAt).format("DD/MM/YYYY - HH:mm"),
              Email: res.data[i].userInfo.email,
              "Receipt Number": res.data[i].campaignType === "transaction"? "-": res.data[i].receiptNumber,
              "Receipt Date": res.data[i].campaignType === "transaction"? "-": moment(res.data[i].datePurchase).format("DD/MM/YYYY"),
              Actions: res.data[i].campaignType === "transaction"? (res.data[i].campaignName?"Redeem Points": "Collect Points"): "Points Submissions",
              Points: res.data[i].campaignType === "transaction"? (res.data[i].creditDebit === 'credit'? '+ ' + res.data[i].amount: '- ' + res.data[i].amount): res.data[i].amount,
              "Voucher": res.data[i].campaignName,
              Note: res.data[i].note,
            })
          }
          
          tmpData = tmpData.concat(d)
          resolve(res.data)
        })
        .catch((err)=>{
          if(err.name === "NotAuthenticated"){
            showToast('error', "Please Sign-in to continue!")
          }else{
            showToast('error', err.message)
          }
        })
      })
    }
    
    let numOfLoop = []
    for(let i = 0; i < pagination.last_page; i++){
      numOfLoop.push(i)
    }

    let process = await numOfLoop.map(async(value, index) => {
      let data = await stackData(index);
      return data
    })

    let result = await Promise.all(process)

    if(result){
      setCsvData(tmpData)
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
      }, 2000);
    }

  }else{

    let tmpData = []
    setLoading(false)

    const stackData = (index) => {
      var query = {
        $skip: index*pagination.per_page,
        merchantId: merchantId,
        type: "wallet",
        // creditDebit: "credit",
        $sort: {
          createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: index*pagination.per_page,
          merchantId: merchantId,
          type: "wallet",
          // creditDebit: "credit",
          $sort: {
            createdAt: -1
          }
        }
      }

      function paymentType(x){
        if(x.paymentType === 'cash'){
          return "Over Counter"
        }else if(x.paymentType === 'card'){
          return "Credit Card"
        }else{
          return "Wallet"
        }
      }

      function purpose(x){
        if(x.creditDebit === 'credit'){
          return "Topup Wallet"
        }else{
          return "Payment"
        }
      }

      return new Promise(resolve => {
        client.authenticate()
        .then(()=>{
          return client.service('transactions').find({
            query: query
          })
        })
        .then((res) => {
          let d = []
           
          for(let i = 0; i < res.data.length; i++){
            d.push({
              "Date/Time": moment(res.data[i].createdAt).format("DD/MM/YYYY - HH:mm"),
              Email: res.data[i].userInfo.email,
              "Payment Type": paymentType(res.data[i]),
              "Purpose": purpose(res.data[i]),
              Amount: res.data[i].creditDebit === 'credit'? '+ ' + getSymbolFromCurrency(res.data[i].currency) + " " + res.data[i].amount: '- ' + getSymbolFromCurrency(res.data[i].currency) + " " + res.data[i].amount,
              Remarks: res.data[i].remark === 'upgrade'?res.data[i].fromSubscription + " -> " + res.data[i].toSubscription: '-',
              // Remarks: res.data[i].remark === 'upgrade'?moment(res.data[i].substart).format("DD/MM/YYYY - HH:mm") + " / " + moment(res.data[i].subEnd).format("DD/MM/YYYY - HH:mm"): '-',
            })
          }
          
          tmpData = tmpData.concat(d)
          resolve(res.data)
        })
        .catch((err)=>{
          if(err.name === "NotAuthenticated"){
            showToast('error', "Please Sign-in to continue!")
          }else{
            showToast('error', err.message)
          }
        })
      })
    }
    
    let numOfLoop = []
    for(let i = 0; i < pagination.last_page; i++){
      numOfLoop.push(i)
    }

    let process = await numOfLoop.map(async(value, index) => {
      let data = await stackData(index);
      return data
    })

    let result = await Promise.all(process)

    if(result){
      setCsvData(tmpData)
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
      }, 2000);
    }

  }
}