import React, { useState, useContext, useEffect } from 'react';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { Input, Space, DatePicker } from 'antd';
import { PageContainer } from "../../styleEdit";
import StyledButton from "components/Elements/StyledButton";
import { connect } from 'react-redux'
import { DataContext } from "contexts/DataContextContainer";
import { cloneDeep, isEmpty } from 'lodash';

import client from "feathers.js"
import moment from 'moment';

const Edit = props => {
  const { setLoading, showToast } = useContext(DataContext);
  const [receiptNumber, setReceiptNumber] = useState('')
  const [datePurchase, setDatePurchase] = useState(null)
  /* eslint-disable-next-line */
  const [receiptFile, setReceiptFile] = useState({})
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    if(!isEmpty(props.data)){
      setReceiptNumber(props.data.receiptNumber)
      setDatePurchase(moment(new Date(props.data.datePurchase),'DD/MM/YYYY'))
      setReceiptFile(props.data.receiptFile)
      setAmount(props.data.amount)
    }
  }, [props.data._id, props.data]);

  const downloadFile = (fileName) => {
    window.location.href = `${client.io.io.uri}downloadUploadFile?fileName=`+fileName
  }
  
  const launch = async () => {
    setLoading(true)

    client.authenticate()
    .then(() => {
      return client.service('points-submissions').patch(props.data._id, {
        receiptNumber,
        datePurchase,
        amount,
      })
    })
    .then((res) => {
      let cloneData = cloneDeep(props.campaignData)
      let findKey = cloneData.findIndex(e => e._id === props.data._id)
      if(findKey !== -1){
        cloneData[findKey] = res
      }
      props.setCampaignData(cloneData)

      setLoading(false)
      showToast('success', 'Receipt is edited successfully')
      props.back()
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      showToast('error', err.message)
    })
  }

  return (
    <PageContainer>
      <div className='header'>
        <button className='btn-back' onClick={() => props.back()}><ArrowLeftOutlined /></button>
        <div>
          <h3>
            Point Setup 
          </h3>
          <p className="text-capitalize">
            ({props.action})
          </p>
        </div>
      </div>
      <div className='page-content'>
        <Space className='detail' size={32} direction='vertical'>
          <Space className='section' size={8} direction='vertical'>
            <span className='title'>Receipt Number</span>
            <Input size="large" placeholder="Receipt Number" value={receiptNumber} onChange={e => setReceiptNumber(e.target.value)} />
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>Receipt Date</span>
            <DatePicker size="large" placeholder="Receipt Date" value={datePurchase} onChange={e => setDatePurchase(e)} />
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>Amount</span>
            <Input size="large" placeholder="Amount" value={amount} onChange={e => setAmount(e.target.value)} />
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>Receipt File</span>
            {
              props.data.receiptFile.fileName? 
                <button className='btn-primary' onClick={() => downloadFile(props.data.receiptFile.fileName)}>
                  <span className='mr-1'>Download</span>
                  <DownloadOutlined style={{cursor: 'pointer'}} />
                </button>
              :
              "No File"
            }
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <div className='actions'>
              <StyledButton
                width={'45%'}
                title='Back'
                onClick={() => props.back()}
                sizeh={44}
                className="btn-blue mt-5"
                radius="7"
                bgcolor={"#FDB256"}
                textTransform="uppercase"
                weight={700}
              />
              <StyledButton
                width={'45%'}
                title="Update"
                onClick={launch}
                sizeh={44}
                type="submit"
                className="btn-blue mt-5"
                radius="7"
                bgcolor={"#3b7779"}
                textTransform="uppercase"
                weight={700}
              />
            </div>
          </Space>
        </Space>
      </div>
    </PageContainer>
  );
}

const mapStateToProps = state => ({
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
