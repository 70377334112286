import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { BrandContainer } from "./style";
import StyledButton from "components/Elements/StyledButton";
import Cropper from "react-cropper";
import 'react-image-crop/dist/ReactCrop.css'
import { IconCamera } from "assets/images";
import { IconPlaceholder } from "assets/images";
import { UploadFile } from "assets/images";
import { Modal } from "antd";
import { connect } from 'react-redux'
import { updateDetails } from 'redux/actions/roleActions';
import { getLogoBanner } from 'redux/actions/extraActions';
import { DataContext } from "contexts/DataContextContainer";
import ModalLanuchEmpty from 'components/Extra/ModalLanuchEmpty';
import AppPreview from "components/AppPreview"
import classNames from 'classnames';
import Resizer from "react-image-file-resizer";
import StepsCounter from 'components/StepsCounter';
import CampaignSetup from '../CampaignSetup';

const Brand = props => {
  const history = useHistory();
  const { setLoading, showToast } = useContext(DataContext);
  const [showModal, setShowModal] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  // const [ logoObj, setLogoObj ] = useState({})
  // const [ bannerObj, setBannerObj ] = useState({})
  const [ logoName, setLogoName ] = useState('')
  const [ bannerName, setBannerName ] = useState('')
  const [ category, setCategory ] = useState('logo')

  const [bannerImage, setBannerImage] = useState(null)
  const [logoImage, setLogoImage] = useState(null)
  const [croppedLogoUrl, setCroppedLogoUrl] = useState(null)
  const [croppedBannerUrl, setCroppedBannerUrl] = useState(null)
  const [cropper, setCropper] = useState(null);

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const modalCallback = () => {
    setLoading(true)
    uploadLogo()
  }

  useEffect(() => {
    if (!props.userInfo._id || !props.userInfo.username || (props.userInfo.set_done === "done")) {
      // window.location.href = '/'
    }
  }, [props.userInfo._id, props.userInfo.username, props.userInfo.set_done]);

  useEffect(() => {
    setCroppedLogoUrl(props.logo)
    setCroppedBannerUrl(props.banner)
  }, [props.logo, props.banner])

  // useEffect(() => {
    // setBtnBannerHeight(`${btnBanner.current.clientWidth * 0.7}px`)
    // setBtnBannerHeight(120)
  // }, []);


  function getImageSize(file){
    return new Promise(resolve => {
      var fr = new FileReader();

      fr.onload = function() { 
          var img = new Image();
  
          img.onload = function() {
            return resolve({
              width: img.width,
              height: img.height
            }); 
          };
  
          img.src = fr.result; 
      };
  
      fr.readAsDataURL(file);
    })
  }

  const resizeFile = (file, getSize) => {
    let compressPercent = 50
    // 100kb
    if(file.size > 300000){
      compressPercent = 80
    }

    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        getSize.width / 2,
        getSize.height / 2,
        file.type,
        compressPercent,
        0,
        (uri) => {
          return resolve(uri);
        },
        "file"
      );
    });
  }


  const handleFile = async(e, category) => {
    e.preventDefault();

    setCategory(category)

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }


    if(files[0].size > 300000){

      let getSize = await getImageSize(files[0])
      const image = await resizeFile(files[0], getSize);

      const reader = new FileReader();
      reader.onload = () => {
        if (category === 'logo') {
          setLogoName(files[0].name)
          setLogoImage(reader.result)
        } else {
          setBannerName(files[0].name)
          setBannerImage(reader.result)
        }
        setShowModal(true)
      };
      reader.readAsDataURL(image)

    }else{

      const reader = new FileReader();
      reader.onload = () => {
        if (category === 'logo') {
          setLogoName(files[0].name)
          setLogoImage(reader.result)
        } else {
          setBannerName(files[0].name)
          setBannerImage(reader.result)
        }
        setShowModal(true)
      };
      reader.readAsDataURL(files[0]);

    }
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      if(category === 'logo'){
        setCroppedLogoUrl(cropper.getCroppedCanvas().toDataURL());
      }else{
        setCroppedBannerUrl(cropper.getCroppedCanvas().toDataURL());
      }
    }
  };

  const uploadLogo = async () => {
    if (props.userInfo._id && props.userInfo.username) {
      if (croppedLogoUrl && croppedBannerUrl) {
        props.getLogoBanner({
          logo: croppedLogoUrl,
          banner: croppedBannerUrl
        })
        setLoading(false)
        history.push('/setup')
      } else {
        showToast('error', 'Please choose logo and cover image')
      }
    }else{
      showToast('error', 'No ID and Domain Found!')
    }
  }

  const back = () => {
    history.goBack()
  }

  const stepsList = [
    "Create your Business Profile",
    "Enter your details",
    "Create your first stamp campaign",
  ]

  return (
    <BrandContainer logo={croppedLogoUrl} banner={croppedBannerUrl}>
      <div className="steps-group">
        <StepsCounter
          stepDescriptions={stepsList}
          currentStepNumber={2}
          />
      </div>
      <div className="brand-container">
        {/* <div className="col-md-3 my-auto left-image" /> */}
        <div className="">
          <div className="brand-form">
            <h2>Campaign Page Design</h2>

            <div className="mb-4">
              {/* <h4>Logo <small>(Jpg, Png, Svg)</small></h4> */}
              
                {/* <img src={IconCamera} alt='camera'/> */}
                <div className='mb-2 text-center'>
                  <div className='mb-2'>
                  <p className='m-0' style={{fontSize: 18, fontWeight: 500}}>Logo</p>
                  <p style={{color: '#3B7779', fontSize: 14}} className="mb-1">
                    (Recommended size 500px x 500px)
                  </p>
                  </div>

                  <div className='mb-4 mx-auto' style={{width: 100}}>
                    <img className='w-100' style={{borderRadius: '50%'}} src={croppedLogoUrl? croppedLogoUrl: IconCamera} alt="" />
                  </div>

                  <label htmlFor="img-logo" className='logo-upload-btn w-100' style={{cursor: 'pointer'}}>
                    <div className='mb-1'>
                      <div style={{padding: 0}} className='btn btn-sm btn-primary'>
                        <img width={100} src={UploadFile} alt="" />
                      </div>
                    </div>
                    <div>
                      <p className='m-0' style={{color: '#3B7779', fontSize: 14}}>Accepted files: PNG, JPG</p>
                      <small style={{color: '#B3B9C7'}}>{logoName}</small>
                    </div>
                  </label>
                </div>
              <input id="img-logo" type="file" onChange={e => handleFile(e, 'logo')} onClick={(event)=> {event.target.value = null}} style={{display: 'none'}} />
            </div>

            <div className="">
              {/* <h4>Page Banner <small>(Jpg, Png, Svg)</small></h4> */}
                {/* <img src={IconPlaceholder} alt='placeholder'/> */}
                {/* <h4>+ Upload Banner</h4> */}

                <div className='mb-2 text-center'>
                  <div className='mb-2'>
                    <p className='m-0' style={{fontSize: 18, fontWeight: 500}}>Page Cover</p>
                    <p style={{color: '#3B7779', fontSize: 14}} className="mb-1">
                      (Recommended size 1200px x 400px)
                    </p>
                  </div>

                  <div className='mb-4' style={{width: 350}}>
                    <img className='w-100' src={croppedBannerUrl? croppedBannerUrl: IconPlaceholder} alt="" />
                  </div>

                  <label 
                    htmlFor="img-banner" 
                    className='banner-upload-btn w-100' 
                    // style={btnBannerHeight ? {height: btnBannerHeight} : {height: 120}}
                    style={{cursor: 'pointer'}}
                  >
                    <div className='mb-1'>
                      <div style={{padding: 0}} className='btn btn-sm btn-primary'>
                        <img width={100} src={UploadFile} alt="" />
                      </div>
                    </div>
                    <div>
                      <p className='m-0' style={{color: '#3B7779', fontSize: 14}}>Accepted files: PNG, JPG</p>
                      <small style={{color: '#B3B9C7'}}>{bannerName}</small>
                    </div>
                  </label>
                </div>
              <input id="img-banner" type="file" onChange={e => handleFile(e, 'banner')} onClick={(event)=> {event.target.value = null}} style={{display: 'none'}} />
            </div>

            <div style={{
              background: '#FFFFFF',
              borderRadius: 15,
            }}>
            <div className='actions'>
              <button
                className="check-avilability-again mt-5 mr-2"
                onClick={back}
              >Back</button>
              <button
                className=" confirm-btn mt-5 ml-2"
                onClick={toggleModal}
              >Preview</button>
            </div>
            </div>
          </div>
          { showModal &&
            <Modal
              title='Drag or Resize to get right image'
              visible={true}
              closable={false}
              maskClosable={false}
              wrapClassName="brand-form"
              centered
              mask={true}
              onOk={() => {
                getCropData()
                setShowModal(false)
              }}
              onCancel={() => setShowModal(false)}
              className='modal-edit-image'
            >
             <div style={{ width: "100%" }}>
                <Cropper
                  style={{ height: 400, width: "100%" }}
                  // zoomTo={0.5}
                  aspectRatio={category === 'logo'? 1/1: 3/1}
                  // initialAspectRatio={3/1}
                  className={classNames({"circularCrop": category === 'logo'})}
                  preview=".img-preview"
                  src={category === "logo"? logoImage: bannerImage}
                  viewMode={1}
                  // zoomOnWheel={false}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
              </div>
            </Modal>

          }

          <ModalLanuchEmpty 
            modalVisible={modalVisible}
            toggleModal={toggleModal}
            modalCallback={modalCallback}
            header={
              <>
                <span className='title1 text-center'>
                  <br />
                  Campaign Preview
                </span>
              </>
            }
            body={
              <>
                <AppPreview 
                  width={'100%'}
                  croppedLogoUrl = {croppedLogoUrl? croppedLogoUrl: IconCamera}
                  croppedBannerUrl = {croppedBannerUrl || IconPlaceholder}
                  userInfo = {props.userInfo}
                />
              </>
            }
            canEdit={croppedLogoUrl && croppedBannerUrl}
            // style={{overflow: 'hidden'}}
            noFooter={true}
            textAlign={'left'}
          />
        </div>
      </div>

    </BrandContainer>
  );
}

const mapStateToProps = state => ({
  userInfo: state.role.details.user?state.role.details.user:{},
  logo: state.extra.logo,
  banner: state.extra.banner,
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
  getLogoBanner: getLogoBanner
};

export default connect(mapStateToProps, mapDispatchToProps)(Brand);
