import client from "feathers.js"
const PAGESIZE = process.env.REACT_APP_PAGESIZE;

export default function getCampaignData(
    skip, currentPage, userId, key, startDate, endDate,

    // functions
    showToast, // 7
    setLoading, // 8
    setPagination, // 9
    setCampaignData, // 10
    paginationCount, // 11

    customerId,
    campaignId,
    voucherType
){
    setLoading(true)

    if(key === 0){

      let query = {
        $skip: skip,
        getStampInfo: true,
        getActivities: true,
        merchantId: userId, 
        $limit: PAGESIZE,
        $sort: {
          createdAt: -1
        }
      }
      
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: skip,
          getStampInfo: true,
          getActivities: true,
          merchantId: userId,
          $limit: PAGESIZE,
          $sort: {
            createdAt: -1
          }
        }
      }
      
      if(customerId){
        query.userId = customerId
      }else{
        delete query.userId
      }
      if(campaignId){
        query.stampId = campaignId
      }else{
        delete query.stampId
      }

      client.authenticate()
      .then((auth) => {
        return client.service('stamp-pouches').find({
          query,
        })
      })
      .then((res) => {
        let newData = []
        res.data.map(x => {
          if(x.activities.length > 0)
          x.activities.campaignType = "activity"
          newData = newData.concat(x.activities)
          newData.push(x)
          return x
        })
        
        setPagination(paginationCount(res.total, currentPage))
        setCampaignData(newData)
        setLoading(false)
      })
      .catch((err)=>{
        console.log(err)
        showToast('error', err.message)
        setLoading(false)
      })

    }else if(key === 1){

      let query = {
        $skip: skip,
        getPackageInfo: true,
        getActivities: true,
        merchantId: userId,
        $limit: PAGESIZE,
        $sort: {
          createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: skip,
          getPackageInfo: true,
          getActivities: true,
          merchantId: userId,
          $limit: PAGESIZE,
          $sort: {
            createdAt: -1
          }
        }
      }

      if(customerId){
        query.userId = customerId
      }else{
        delete query.userId
      }
      if(campaignId){
        query.packageId = campaignId
      }else{
        delete query.packageId
      }

      client.authenticate()
      .then((auth) => {
        return client.service('package-purchases').find({
          query,
        })
      })
      .then((res) => {
        let newData = []
        res.data.map(x => {
          if(x.activities.length > 0)
          x.activities.campaignType = "activity"
          newData = newData.concat(x.activities)
          newData.push(x)
          return x
        })
        setPagination(paginationCount(res.total, currentPage))
        setCampaignData(newData)
        setLoading(false)
      })
      .catch((err)=>{
        console.log(err)
        showToast('error', err.message)
        setLoading(false)
      })

    }else if(key === 2){

      let query = {
        $skip: skip,
        getActivities: true,
        getVoucherInfo: true,
        merchantId: userId,
        $limit: PAGESIZE,
        // $or: [
        //   {
        //     referralSahreId: {
        //         $ne: null
        //       },
        //   },
        //   {
        //     voucherId: {
        //       $ne: null
        //     },
        //   }
        // ],
        $sort: {
          createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
          $skip: skip,
          getActivities: true,
          getVoucherInfo: true,
          merchantId: userId,
          $limit: PAGESIZE,
          // $or: [
          //   {
          //     referralSahreId: {
          //         $ne: null
          //       },
          //   },
          //   {
          //     voucherId: {
          //       $ne: null
          //     },
          //   }
          // ],
          $sort: {
            createdAt: -1
          }
        }
      }

      if(customerId){
        query.userId = customerId
      }else{
        delete query.userId
      }
      if(campaignId){
        if(voucherType === 'voucher'){
          query.voucherId = campaignId
        }else if(voucherType === 'point'){
          query.pointId = campaignId
        }else if(voucherType === 'referral'){
          query.referralSahreId = campaignId
        }
      }else{
        delete query.voucherId
        delete query.pointId
        delete query.referralSahreId
      }

      client.authenticate()
      .then((auth) => {
        return client.service('voucher-pouches').find({
          query,
        })
      })
      .then((res) => {
        // res.data = res.data.filter(e => !isEmpty(e.referralShareValue) || !isEmpty(e.voucherInfo))
        
        let newData = []
        res.data.map(x => {
          if(x.activities.length > 0)
          x.activities.campaignType = "activity"
          newData = newData.concat(x.activities)
          newData.push(x)
          return x
        })
        setPagination(paginationCount(res.total, currentPage))
        setCampaignData(newData)
        setLoading(false)
      })
      .catch((err)=>{
        console.log(err)
        showToast('error', err.message)
        setLoading(false)
      })
      
    }else if(key === 3){
     
      let query = {
        $skip: skip,
        merchantId: userId,
        // type: "points",
        $limit: PAGESIZE,
        $sort: {
          createdAt: -1
        }
      }
      let query1 = {
        $skip: skip,
        merchantId: userId,
        type: "points",
        $limit: PAGESIZE,
        $sort: {
          createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
            $skip: skip,
            merchantId: userId,
            // type: "points",
            $limit: PAGESIZE,
            $sort: {
              createdAt: -1
            }
        }
        query1 = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
            $skip: skip,
            merchantId: userId,
            type: "points",
            $limit: PAGESIZE,
            $sort: {
              createdAt: -1
            }
        }
      }

      client.authenticate()
      .then((auth) => {
        return client.service('points-submissions').find({
          query,
        })
      })
      .then(async(res) => {

        let transactions = await client.service('transactions').find({
          query: query1,
        })
        if(transactions.data.length > 0){
          transactions.data.map(x => {
            x.campaignType = "transaction"
            return x
          })
        }
        
        // let newData = []
        // res.data.map(x => {
        //   if(!isEmpty(x.transaction)){
        //     x.transaction.campaignType = "transaction"
        //     newData.push(x.transaction)
        //     newData.push(x)
        //   }else{
        //     newData.push(x)
        //   }
        //   return x
        // })
        // console.log(newData)

        setCampaignData(res.data.concat(transactions.data))
        setLoading(false)
      })
      .catch((err)=>{
        console.log(err)
        showToast('error', err.message)
        setLoading(false)
      })

    }else if(key === 4){
     
      let query = {
        $skip: skip,
        // type: {
        //   $nin: ['points', 'balance']
        // },
        $limit: PAGESIZE,
        merchantId: userId,
        type: "wallet",
        // creditDebit: "credit",
        $sort: {
            createdAt: -1
        }
      }
  
      if(startDate && endDate){
        query = {
          $and: [
            { 
              createdAt: {
                $gte: new Date(startDate).getTime()
              },
            },
            { 
              createdAt: {
                $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
              },
            }
          ],
            $skip: skip,
            // type: {
            //   $nin: ['points', 'balance']
            // },
            $limit: PAGESIZE,
            merchantId: userId,
            type: "wallet",
            // creditDebit: "credit",
            $sort: {
                createdAt: -1
            }
        }
      }

      client.authenticate()
      .then((auth) => {
        return client.service('transactions').find({
          query,
        })
      })
      .then((res) => {
        setCampaignData(res.data)
        setLoading(false)
      })
      .catch((err)=>{
        console.log(err)
        showToast('error', err.message)
        setLoading(false)
      })

    }
  }