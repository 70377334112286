import React from 'react';
import parse from 'html-react-parser';
import { RewardContainer } from "./rewardStyle";
import moment from 'moment';

const Reward = props => {
  const merchant = props.merchant || {}

  return (
    <div>
      <RewardContainer>
        <div className='reward-container'>
              <>
              <div className='top'>
                <h3 className='w-100'>Get MoreREWARDZ</h3>
              </div>
              <div className='wrapepr'>
                <div className='header' style={{marginBottom: 0}}>
                  <div className='w-100'>
                    {/* <h5>Your friend, {" "}
                      {!isEmpty(userShared)?
                        userShared.lastName || userShared.firstName?
                        userShared.lastName + " " + userShared.firstName
                        :
                        userShared.email.split("@")[0]
                      :""}, 
                      wants to share this voucher with you</h5> */}
                      <h3>Welcome to <span style={{fontWeight: 700}} className='text-capitalize'>{merchant.business_name}</span></h3>
                  </div>
                  <div className='w-100 text-center'>
                    <span>Redeem the following gift:</span>
                  </div>
                </div>
                <div className='voucher'>
                  <span>{props.new_signup_title}</span>
                </div>
                <div className='tos'>
                  <span className='title'>Terms & Conditions</span>
                  <div className='detail'>
                  <ul style={{marginBottom: 0}}>
                    <li>
                      Campaign Period {props.periodStart?moment(props.periodStart).format('DD/MM/YYYY'):''} - {props.periodEnd?moment(props.periodEnd).format('DD/MM/YYYY'):''}
                    </li>
                    <li>
                      This card is valid for {props.new_signup_validity} {props.new_signup_validity_unit.slice(0, -1)}(s) upon first collect
                    </li>
                  </ul>
                    {/* <ul style={{marginBottom: 0}}>
                      <li>
                        This link is valid until {moment(new Date()).add(props.new_signup_validity, props.new_signup_validity_unit).format('DD/MM/YYYY - HH:mm')}
                      </li>
                      <li>
                        The validity of gift redemption is {props.new_signup_validity} {props.new_signup_validity_unit?props.new_signup_validity_unit.slice(0, -1): ''}(s)
                      </li>
                      <li>
                        Valid for {props.applicableTo === 'new'? "new customer" : "exsiting customer"}
                      </li>
                    </ul> */}
                    {
                      props.tos &&
                      parse(props.tos)
                    }
                  </div>
                </div>
                <div className='accept'>
                  <button type="button">REDEEM NOW</button>
                </div>
              </div>
              </>
        </div>
      </RewardContainer>
    </div>
  );
}

export default Reward;
