import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PageContainer } from "./style";
import { Input } from "antd";
import { DataContext } from "contexts/DataContextContainer";
import client from "feathers.js";
import InfoCard from "components/InfoCard/InfoCard";
import DetailsSection from "components/DetailsSection/RowContainer";
import { Decrement, Increment, Reminder } from "assets/svg";

const CampaignExpiry = (props) => {
  /* eslint-disable-next-line */
  const { title, query } = props;

  const history = useHistory();

  const { showToast, setLocation, setBreadcrumb } = useContext(DataContext);
  const [renderElement, setRenderElement] = useState(false);

  const [stampFirst, setStampFirst] = useState(0);
  const [stampSecond, setStampSecond] = useState(0);
  const [stampLast, setStampLast] = useState(0);
  const [packageFirst, setPackageFirst] = useState(0);
  const [packageSecond, setPackageSecond] = useState(0);
  const [packageLast, setPackageLast] = useState(0);
  const [voucherHours, setVoucherHours] = useState(0);
  const [referralHours, setReferralHours] = useState(0);

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Tools", props.title]);
  }, []);

  useEffect(() => {
    client.authenticate().then((auth) => {
      if (auth.user.stampReminderHours) {
        setStampFirst(auth.user.stampReminderHours.first);
        setStampSecond(auth.user.stampReminderHours.second);
        setStampLast(auth.user.stampReminderHours.last);
      }
      if (auth.user.packageReminderHours) {
        setPackageFirst(auth.user.packageReminderHours.first);
        setPackageSecond(auth.user.packageReminderHours.second);
        setPackageLast(auth.user.packageReminderHours.last);
      }
      if (auth.user.voucherReminderHours) {
        setVoucherHours(auth.user.voucherReminderHours);
      }
      if (auth.user.referralReminderHours) {
        setReferralHours(auth.user.referralReminderHours);
      }
    });
  }, []);

  const saveReminder = () => {
    if (stampSecond > stampFirst || packageSecond > packageFirst) {
      showToast("error", "Second hours cannot be higher than the first");
    } else if (
      (stampLast > stampSecond && stampLast > stampFirst) ||
      (packageLast > packageSecond && packageLast > packageFirst)
    ) {
      showToast("error", "Last hours cannot be higher than first and second");
    } else {
      client
        .authenticate()
        .then((auth) => {
          setRenderElement(true);

          return client.service("merchants").patch(auth.user._id, {
            stampReminderHours: {
              first: stampFirst,
              second: stampSecond,
              last: stampLast,
            },
            packageReminderHours: {
              first: packageFirst,
              second: packageSecond,
              last: packageLast,
            },
            voucherReminderHours: voucherHours,
            referralReminderHours: referralHours,
          });
        })
        .then(() => {
          setRenderElement(false);
          showToast("success", "Expiry Reminder is set!");
        });
    }
  };

  const renderIcons = (value, setValue) => {
    const incrementCounter = () => {
      setValue(value + 1);
    };
    const decrementCounter = () => {
      if (value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    };
    return (
      <div className='inc-dec-wrapper'>
        <button
          type='button'
          className='increment-button'
          onClick={() => incrementCounter()}
        >
          <img
            src={Increment}
            alt=''
            width='20px'
            height='20px'
            draggable={false}
          />
        </button>
        <button
          type='button'
          className='decrement-button'
          onClick={() => decrementCounter()}
        >
          <img
            src={Decrement}
            alt=''
            width='20px'
            height='18px'
            draggable={false}
          />
        </button>
      </div>
    );
  };

  const onKeyPressedChecking = (event, value, setValue) => {
    if (event.key === "ArrowUp") {
      setValue(value + 1);
    } else if (event.key === "ArrowDown") {
      if (value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    }
  };

  const inputChecking = (value, setValue) => {
    const cleanedValue = String(value)
      .replace(/[^0-9]/g, "")
      .replace(/^0+/, "");

    const newValue = cleanedValue || "0";
    const parsedValue = parseInt(newValue);

    setValue(parsedValue);
  };

  return (
    <PageContainer>
      {!renderElement && (
        <>
          <InfoCard desc="The alerts serve as reminders to customers to take action and redeem their rewards before they expore. This helps ensure that customers don't miss out on the benefits they have earned." />

          <DetailsSection
            leftHeader='Stamp Card Campaign Reminder'
            leftDesc="Choose when you'd like to remind your customers about your stamp card rewards before they expire."
            rightContent={
              <>
                <div className='input-container'>
                  <div className='input-title'>First alert</div>
                  <div className='timeframe-wrapper'>
                    <div className='timeframe-text'>Set reminder timeframe</div>
                    <div className='input-wrapper'>
                      <div className='input-with-icon'>
                        <Input
                          type='text'
                          size='large'
                          className='input'
                          placeholder={stampFirst}
                          value={stampFirst}
                          onKeyDown={(e) =>
                            onKeyPressedChecking(e, stampFirst, setStampFirst)
                          }
                          onChange={(e) => [
                            inputChecking(e.target.value, setStampFirst),
                          ]}
                          onBlur={() => stampFirst === 0 && setStampFirst(1)}
                        />
                        <img
                          src={Reminder}
                          alt=''
                          width='24px'
                          height='24px'
                          className='reminder-icon'
                          draggable={false}
                        />
                      </div>
                      <div className='input-button'>hour(s)</div>
                      {renderIcons(stampFirst, setStampFirst)}
                    </div>
                  </div>
                </div>

                <div className='input-container'>
                  <div className='input-title'>Second alert</div>
                  <div className='input-wrapper'>
                    <div className='input-with-icon'>
                      <Input
                        type='text'
                        size='large'
                        className='input'
                        placeholder={stampSecond}
                        value={stampSecond}
                        onKeyDown={(e) =>
                          onKeyPressedChecking(e, stampSecond, setStampSecond)
                        }
                        onChange={(e) =>
                          inputChecking(e.target.value, setStampSecond)
                        }
                        onBlur={() => stampSecond === 0 && setStampSecond(1)}
                      />
                      <img
                        src={Reminder}
                        alt=''
                        width='24px'
                        height='24px'
                        className='reminder-icon'
                      />
                    </div>
                    <div className='input-button'>hour(s)</div>
                    {renderIcons(stampSecond, setStampSecond)}
                  </div>
                </div>

                <div className='input-container'>
                  <div className='input-title'>Final alert</div>
                  <div className='input-wrapper'>
                    <div className='input-with-icon'>
                      <Input
                        type='text'
                        size='large'
                        className='input'
                        placeholder={stampLast}
                        value={stampLast}
                        onKeyDown={(e) =>
                          onKeyPressedChecking(e, stampLast, setStampLast)
                        }
                        onChange={(e) =>
                          inputChecking(e.target.value, setStampLast)
                        }
                        onBlur={() => stampLast === 0 && setStampLast(1)}
                      />
                      <img
                        src={Reminder}
                        alt=''
                        width='24px'
                        height='24px'
                        className='reminder-icon'
                      />
                    </div>
                    <div className='input-button'>hour(s)</div>
                    {renderIcons(stampLast, setStampLast)}
                  </div>
                </div>

                <div className='button-wrapper'>
                  <button
                    type='button'
                    className='submit-button'
                    onClick={() => saveReminder()}
                  >
                    Submit
                  </button>
                </div>
              </>
            }
          />

          <DetailsSection
            leftHeader='Package Campaign Reminder'
            leftDesc="Choose when you'd like to remind your customers about your package before they expire."
            rightContent={
              <>
                <div className='input-container'>
                  <div className='input-title'>First alert</div>
                  <div className='timeframe-wrapper'>
                    <div className='timeframe-text'>Set reminder timeframe</div>
                    <div className='input-wrapper'>
                      <div className='input-with-icon'>
                        <Input
                          type='text'
                          size='large'
                          className='input'
                          placeholder={packageFirst}
                          value={packageFirst}
                          onKeyDown={(e) =>
                            onKeyPressedChecking(
                              e,
                              packageFirst,
                              setPackageFirst
                            )
                          }
                          onChange={(e) => [
                            inputChecking(e.target.value, setPackageFirst),
                          ]}
                          onBlur={() =>
                            packageFirst === 0 && setPackageFirst(1)
                          }
                        />
                        <img
                          src={Reminder}
                          alt=''
                          width='24px'
                          height='24px'
                          className='reminder-icon'
                        />
                      </div>
                      <div className='input-button'>hour(s)</div>
                      {renderIcons(packageFirst, setPackageFirst)}
                    </div>
                  </div>
                </div>

                <div className='input-container'>
                  <div className='input-title'>Second alert</div>
                  <div className='input-wrapper'>
                    <div className='input-with-icon'>
                      <Input
                        type='text'
                        size='large'
                        className='input'
                        placeholder={packageSecond}
                        value={packageSecond}
                        onKeyDown={(e) =>
                          onKeyPressedChecking(
                            e,
                            packageSecond,
                            setPackageSecond
                          )
                        }
                        onChange={(e) => [
                          inputChecking(e.target.value, setPackageSecond),
                        ]}
                        onBlur={() =>
                          packageSecond === 0 && setPackageSecond(1)
                        }
                      />
                      <img
                        src={Reminder}
                        alt=''
                        width='24px'
                        height='24px'
                        className='reminder-icon'
                      />
                    </div>
                    <div className='input-button'>hour(s)</div>
                    {renderIcons(packageSecond, setPackageSecond)}
                  </div>
                </div>

                <div className='input-container'>
                  <div className='input-title'>Final alert</div>
                  <div className='input-wrapper'>
                    <div className='input-with-icon'>
                      <Input
                        type='text'
                        size='large'
                        className='input'
                        placeholder={packageLast}
                        value={packageLast}
                        onKeyDown={(e) =>
                          onKeyPressedChecking(e, packageLast, setPackageLast)
                        }
                        onChange={(e) => [
                          inputChecking(e.target.value, setPackageLast),
                        ]}
                        onBlur={() => packageLast === 0 && setPackageLast(1)}
                      />
                      <img
                        src={Reminder}
                        alt=''
                        width='24px'
                        height='24px'
                        className='reminder-icon'
                      />
                    </div>
                    <div className='input-button'>hour(s)</div>
                    {renderIcons(packageLast, setPackageLast)}
                  </div>
                </div>

                <div className='button-wrapper'>
                  <button
                    type='button'
                    className='submit-button'
                    onClick={() => saveReminder()}
                  >
                    Submit
                  </button>
                </div>
              </>
            }
          />

          <DetailsSection
            leftHeader='Voucher Campaign Reminder'
            leftDesc="Choose when you'd like to remind your customers about your voucher before they expire."
            rightContent={
              <>
                <div className='input-container'>
                  <div className='input-title' />
                  <div className='timeframe-wrapper'>
                    <div className='timeframe-text'>Set reminder timeframe</div>
                    <div className='input-wrapper'>
                      <div className='input-with-icon'>
                        <Input
                          type='text'
                          size='large'
                          className='input'
                          placeholder={voucherHours}
                          value={voucherHours}
                          onKeyDown={(e) =>
                            onKeyPressedChecking(
                              e,
                              voucherHours,
                              setVoucherHours
                            )
                          }
                          onChange={(e) => [
                            inputChecking(e.target.value, setVoucherHours),
                          ]}
                          onBlur={() =>
                            voucherHours === 0 && setVoucherHours(1)
                          }
                        />
                        <img
                          src={Reminder}
                          alt=''
                          width='24px'
                          height='24px'
                          className='reminder-icon'
                        />
                      </div>
                      <div className='input-button'>hour(s)</div>
                      {renderIcons(voucherHours, setVoucherHours)}
                    </div>
                  </div>
                </div>

                <div className='button-wrapper'>
                  <button
                    type='button'
                    className='submit-button'
                    onClick={() => saveReminder()}
                  >
                    Submit
                  </button>
                </div>
              </>
            }
          />

          <DetailsSection
            leftHeader='Referral Campaign Reminder'
            leftDesc="Choose when you'd like to remind your customers about your referral before they expire."
            rightContent={
              <>
                <div className='input-container'>
                  <div className='input-title' />
                  <div className='timeframe-wrapper'>
                    <div className='timeframe-text'>Set reminder timeframe</div>
                    <div className='input-wrapper'>
                      <div className='input-with-icon'>
                        <Input
                          type='text'
                          size='large'
                          className='input'
                          placeholder={referralHours}
                          value={referralHours}
                          onKeyDown={(e) =>
                            onKeyPressedChecking(
                              e,
                              referralHours,
                              setReferralHours
                            )
                          }
                          onChange={(e) => [
                            inputChecking(e.target.value, setReferralHours),
                          ]}
                          onBlur={() =>
                            referralHours === 0 && setReferralHours(1)
                          }
                        />
                        <img
                          src={Reminder}
                          alt=''
                          width='24px'
                          height='24px'
                          className='reminder-icon'
                        />
                      </div>
                      <div className='input-button'>hour(s)</div>
                      {renderIcons(referralHours, setReferralHours)}
                    </div>
                  </div>
                </div>

                <div className='button-wrapper'>
                  <button
                    type='button'
                    className='submit-button'
                    onClick={() => saveReminder()}
                  >
                    Submit
                  </button>
                </div>
              </>
            }
          />
        </>
      )}
    </PageContainer>
  );
};

export default CampaignExpiry;
