import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";
import client from "feathers.js";
import axios from "axios";
import { cloneDeep } from "lodash";
import { Table, Pagination } from "antd";

import env from "env";
import columns from "./columns";
import { useHistory } from "react-router-dom";

import Edit from "./Edit";
import { PageContainer } from "../../style";
import { updateDetails } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import ModalConfirmation from "components/Extra/ModalConfirmation";
import DataCard from "components/DataCard";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const Referral = (props) => {
  /* eslint-disable-next-line */
  const {
    setLoading,
    showToast,
    setLocation,
    setBreadcrumb,
    toolsAction,
    setToolsAction,
  } = useContext(DataContext);
  const [action, setAction] = useState("all"); //edit, detail, create
  const [campaignData, setCampaignData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  /* eslint-disable-next-line */
  const [pagination, setPagination] = useState({});
  const [campaignInfo, setCampaignInfo] = useState({});
  const [confirmAction, setConfirmAction] = useState("activate"); // activate, delete
  const [reRender, setReRender] = useState(false);

  const massagedData = campaignData.map((item) => {
    return {
      ...item,
      status: !(dayjs(item.period_end) < dayjs(new Date()))
        ? item.active
          ? "Active"
          : "Inactive"
        : "Expired",
    };
  });

  const activatedReferrals = massagedData.filter(
    (item) => item.status === "Active"
  ).length;

  const fetchCampaignInfo = () => {
    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}getReferralsInformation`,
          {
            userId: props.userInfo._id,
          },
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        setCampaignInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
        showToast("error", err.message);
      });
  };

  const back = () => {
    setAction("all");
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const modalCallback = () => {
    setLoading(true);

    if (confirmAction === "activate") {
      let status = dataEdit.active ? false : true;

      client
        .authenticate()
        .then(() => {
          return client
            .service("referrals")
            .patch(dataEdit._id, { active: status });
        })
        .then((res) => {
          let cloneData = cloneDeep(campaignData);
          let findKey = cloneData.findIndex((e) => e._id === dataEdit._id);
          if (findKey !== -1) {
            cloneData[findKey] = res;
          }

          fetchCampaignInfo();
          setCampaignData(cloneData);

          setLoading(false);
          showToast("success", "Campaign is Edited successfully");

          // If Deactive
          if (!res.active) {
            let campaignTitle = "Referral||" + dataEdit.member_title;

            if (campaignTitle === props.userInfo.notification) {
              client
                .service("merchants")
                .patch(props.userInfo._id, {
                  notification: "",
                })
                .then((res) => {
                  props.updateDetails(res);
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    } else {
      client
        .authenticate()
        .then(() => {
          return client.service("referrals").remove(dataEdit._id);
        })
        .then((res) => {
          let cloneData = cloneDeep(campaignData);
          let findKey = cloneData.findIndex((e) => e._id === dataEdit._id);
          if (findKey !== -1) {
            cloneData.splice(findKey, 1);
          }

          fetchCampaignInfo();
          setCampaignData(cloneData);

          setLoading(false);
          showToast("success", "Campaign is Deleted successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    }
  };

  const getCampaignData = (skip, currentPage, userId) => {
    setLoading(true);
    client
      .service("referrals")
      .find({
        query: {
          $skip: skip,
          $limit: PAGESIZE,
          userId,
        },
      })
      .then((res) => {
        setCampaignData(res.data);
        setPagination(paginationCount(res.total, currentPage));
        setLoading(false);
        setToolsAction("all");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  const createCampaign = () => {
    if (
      activatedReferrals <
      env.subscription[props.userInfo.subscription || "copper"].referral
    ) {
      setAction("create");
      setDataEdit({});
    } else {
      showToast("error1", `to unlock additional limits and features!`);
    }
  };

  const tablePage = () => {
    return (
      <>
        <div className='row-header'>
          <div className='status-wrapper'>
            <DataCard
              title='Activated Referrals'
              data={activatedReferrals}
              total={
                env.subscription[props.userInfo.subscription || "copper"]
                  .referral
              }
            />
            <DataCard title='Referral Signups' data={campaignInfo.numSignup} />
            <DataCard
              title='Redeemed Referrals'
              data={campaignInfo.numRedeemed}
            />
          </div>

          <button
            className='primary-button'
            onClick={() => {
              createCampaign();
            }}
          >
            <span>+ Referrals</span>
          </button>
        </div>

        <div className='row-container'>
          <Table
            rowKey='_id'
            columns={columns(
              setAction,
              setDataEdit,
              toggleModal,
              setConfirmAction
            )}
            dataSource={massagedData}
            pagination={false}
          />
        </div>
        <div className='row-container'>
          <Pagination
            pageSize={PAGESIZE}
            total={pagination.total}
            showSizeChanger={false}
            onChange={(e) => {
              setPagination(paginationCount(pagination.total, e));
              getCampaignData((e - 1) * PAGESIZE, e, props.userInfo._id);
            }}
          />
        </div>
      </>
    );
  };

  const editPage = () => {
    return (
      <Edit
        userInfo={props.userInfo}
        campaignData={campaignData}
        fetchCampaignInfo={fetchCampaignInfo}
        setCampaignData={setCampaignData}
        action={action}
        data={dataEdit}
        currency={props.userInfo.currency}
        back={back}
        firstTime={massagedData.length <= 0}
      />
    );
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Tools", props.title]);
  }, []);

  useEffect(() => {
    if (toolsAction === "create") {
      setAction("create");
    } else if (campaignData && campaignData.length <= 0) {
      setAction("create");
    } else {
      setAction("all");
    }
  }, [campaignData]);

  useEffect(() => {
    getCampaignData(0, 1, props.userInfo._id);
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  useEffect(() => {
    fetchCampaignInfo();
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  return (
    <PageContainer>
      {action === "all" ? <>{!reRender && tablePage()}</> : editPage()}

      <ModalConfirmation
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              {"Confirmation"}
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>
              {confirmAction === "activate"
                ? dataEdit.active
                  ? "Proceed to Deactivate this Referral"
                  : "Proceed to Activate Referral"
                : "Proceed to Delete Referral"}
            </span>
          </>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
