import React, { useEffect } from 'react';
// import { useHistory } from "react-router-dom";
// import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageContainer } from "./style";
// import StyledButton from "components/Elements/StyledButton";
// import { Modal } from "antd";
import { connect } from 'react-redux'
// import { DataContext } from "contexts/DataContextContainer";
// import html2canvas from 'html2canvas';
// import QRCode from 'qrcode'
import { 
  // PosterTemp,
  PDFEnjoy,
  PDFScan,
  PDFSignup,
  LogoWhite
 } from "assets/images";
import QRCodeSvg from "qrcode-svg";

import { jsPDF } from "jspdf";

import client from "feathers.js"

const QRCodeComponent = props => {
  // const history = useHistory();
  // const { setLoading, showToast } = useContext(DataContext);
  // const [ logoData, setLogoData ] = useState('')
  // const [ bannerData, setBannerData ] = useState('')
  // const [ bannerSize, setBannerSize ] = useState({})

  useEffect(() => {
    var qrcode = new QRCodeSvg({
      content: (process.env.REACT_APP_BASE_URL + props.userInfo.username),
      container: "svg-viewbox", //Responsive use
      join: true //Crisp rendering and 4-5x reduced file size
    }).svg();
    document.getElementById('svg').innerHTML = qrcode

    downloadQrPdf('a3')
    /* eslint-disable-next-line */
  }, []);

  function fetchImg(url){
    return new Promise(resolve => {
      fetch(url)
      .then( response => response.blob() )
      .then( blob =>{
        var reader = new FileReader()
        reader.onload = function(){
          return resolve(this.result)
          }
        reader.readAsDataURL(blob)
      })
    })
  }

  const downloadQrPdf = async(format) => {
    // html2canvas(document.querySelector("#qr_code"), {
    //   useCORS: true,
    // }).then((canvas) => {

    var doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: format,
      putOnlyUsedFonts:true
    });

    var width = 0
    var height = 0

    if(format === 'a3'){
      width = 140
      height = 140
    }else if(format === 'a4'){
      width = 70
      height = 70
    }else if(format === 'a5'){
      width = 20
      height = 20
    }else if(format === 'a6'){
      width = 20
      height = 20
    }else{
      width = doc.internal.pageSize.getWidth();
      height = doc.internal.pageSize.getHeight();
    }

    function capitalize(s){
      return s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } );
    };

    // console.log(doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight())
    var logo = await fetchImg((client.io.io.uri + props.userInfo.logo))
    var banner = await fetchImg((client.io.io.uri + props.userInfo.banner))
    // var pdfPosterTemp = await fetchImg(PosterTemp)

    var pdfScanIcon = await fetchImg(PDFEnjoy)
    var pdfSignupIcon = await fetchImg(PDFScan)
    var pdfEnjoyIcon = await fetchImg(PDFSignup)

    var pdfLogoWhite = await fetchImg(LogoWhite)
    
    // let bsize = await getImageSize(dataURIToBlob(banner))
    // setBannerSize(bsize)

    // setLogoData(logo)
    // setBannerData(banner)

    // var captureImg = await html2canvas(document.querySelector("#capture-img"), {
    //   useCORS: true,
    // }).then((canvas) => {
    //   return canvas
    // })
    
    // QRCode.toDataURL((process.env.REACT_APP_BASE_URL + props.userInfo.username), function (err, url) {
      // var imgData = canvas.toDataURL("image/jpeg")
      const element = document.querySelector('#svg svg')

      // doc.addImage(pdfPosterTemp, 'jpeg', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
      // doc.addImage(imgData, 'jpeg', 8, 9, 281.5, 0);

      // doc.text(137, 140, props.userInfo.username
      let text = props.userInfo.username
      var xOffset = doc.internal.pageSize.width / 2

      doc.addImage(banner, 'jpeg', 0, 0, doc.internal.pageSize.getWidth(), 0);
      doc.addImage(logo, 'jpeg', 30, 105, 50, 0);
      
      doc.setFontSize(40);
      doc.setFont(undefined, 'bold')
      doc.text(90, 125, capitalize(text));
      
      doc.setFontSize(24);
      // doc.setFont(undefined, '500')
      doc.text(90, 140, "Scan now & start enjoying the rewardz")

      doc.setFillColor(237, 237, 237);
      doc.rect(0, 160, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), 'F');

      doc.addImage(pdfSignupIcon, 'jpeg', 86, 176, 40, 0);
      doc.addImage(pdfEnjoyIcon, 'jpeg', 130, 175, 40, 0);
      doc.addImage(pdfScanIcon, 'jpeg', 170, 175, 40, 0);

      // doc.text(text, xOffset, 140, {align: 'center'});
      // doc.addImage(url, 'svg', 80, 200, width, height);
      doc.svg(element, {x: 80, y: 220, width, height, });
      // doc.setFontSize(28);
      doc.setFontSize(16);
      doc.text('Or enter URL in browser', xOffset, 370, {align: 'center'});
      doc.setFontSize(22);
      doc.text((process.env.REACT_APP_BASE_URL + props.userInfo.username), xOffset, 380, {align: 'center'});

      doc.setFillColor(59, 119, 121);
      doc.rect(0, 405, 297, 50, 'F');
      doc.addImage(pdfLogoWhite, 'jpeg', 250, 406, 40, 0);
      // doc.output('dataurlnewwindow');
      // var blob = doc.output("blob");
      // window.open(URL.createObjectURL(blob));

      setTimeout(() => {
        // doc.save(`${props.username}_Poster.pdf`);
        doc.save(`${props.username}_Poster.pdf`, {returnPromise:true}).then(() => {
          setTimeout(() => {
            window.close();
          }, 5000);
        });
      }, 500);

    // })

    // var imgData = canvas.toDataURL()
    // var imgData = document.querySelector("#react-qrcode-logo").toDataURL("image/jpeg")

    // doc.setFontSize(40);
    // // doc.text(50, 50, props.userInfo.business_name);
    // doc.addImage(imgData, 'jpeg', 70, 50, width, height);
    // doc.save(`${props.username}_merchant_page.pdf`);

    // })
  }

  return (
    <PageContainer>
      {/* <h3 className='title'>{props.title}</h3> */}
      <div className='a4-overlay'>
        {/* <div className='' style={{width: "2480px"}}>

          <div id='app-capture-id'>
          <AppPreview 
              croppedBannerUrl = {
                ((client.io.io.uri + props.userInfo.banner))
              }
              croppedLogoUrl = {
                ((client.io.io.uri + props.userInfo.logo))
              }
              userInfo = {props.userInfo}
          />
          </div>

        </div> */}

        <div id="svg" className='w-100'></div>

        {/* <div 
          id="capture-img"
          style={{
            width: bannerSize.width,
            height: ((bannerSize.height || 0) + ((bannerSize.width / 8) || 0)),
          }}
          >
          <div
            style={{
              position: 'relative',
              width: bannerSize.width,
              height: bannerSize.height,
              backgroundImage: `url(${bannerData})`
            }}
          >
              <div>
              {
                logoData?
                <img 
                  id="logo-crop" 
                  src={logoData} 
                  width={bannerSize.width/6}
                  style={{
                    borderRadius: '50%', 
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '70%',
                    left: 0,
                    right: 0,
                    margin: 'auto'
                  }} 
                  alt="" />
                : null
              }
            </div>
          </div>
        </div> */}

      </div>
    </PageContainer>
  );
}

const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  username: state.role.details.user?state.role.details.user.username:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeComponent);
