import { breakpoints, colors } from "style";
import styled from "styled-components";
import { DropdownArrow } from "assets/svg";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 75px 0 0 0;
  .row-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 100px;
  }
  .right-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    * > .input-group > select.form-control {
      padding-right: 30px;
    }

    * > .input-group > .form-control {
      padding: 20px;
      border-left: none;
      border-radius: 0 8px 8px 0;
    }
  }
  .input {
    padding: 23px 20px;
  }
  .icon-wrapper {
    position: absolute;
    top: 25%;
    right: 10px;
    width: auto;
    height: auto;
  }
  .day-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 64px;
    border: 1px solid #047857;
    background: transparent;
    border-radius: 35px;
    color: #191d23;

    @media (max-width: 1325px) {
      width: 54px;
      height: 52px;
    }
  }
  .day-button.checked {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 64px;
    border: 1px solid #047857;
    background: #567877;
    border-radius: 35px;
    color: white;

    @media (max-width: 1325px) {
      width: 54px;
      height: 52px;
    }
  }
  .time-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    max-width: 535px;
    margin: 30px 0 0 0;
  }
  .time-label {
    display: none;

    &:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      gap: 60px;
    }
  }
  .time-label-text {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 183px;
    color: #344051;
    font-size: 14px;

    @media (max-width: 1325px) {
      max-width: 143px;
    }
  }
  .time-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;

    @media (max-width: 1325px) {
      gap: 10px;
    }
  }
  .custom-hr {
    width: 22px;
    border: 1px solid black;
  }
  .input-container-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 183px;
    position: relative;

    @media (max-width: 1325px) {
      max-width: 143px;
    }
  }
  .cancel-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 114px;
    padding: 13.5px;
    border: 1px solid black;
    color: #6b7280;
    border-radius: 10px;
    font-size: 14px;
  }
  .preview-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 194px;
    padding: 13.5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    background: #567877;
    border-radius: 10px;
  }
  .MuiOutlinedInput-root {
    min-width: 183px;

    @media (max-width: 1325px) {
      min-width: 143px;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e4e6f1;
    border-radius: 8px;
  }
  .MuiOutlinedInput-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    color: #b6b9ce;
    padding: 10px;
    height: 48px;
  }
`;
