import moment from 'moment';

const columns = () => [
    // {
    //   title: 'No.',
    //   dataIndex: 'no',
    //   render: (record, records, index) => 
    //   <span>{index+1}</span>
    // },
    {
      title: 'Date Register',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => 
        <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'Name',
      dataIndex: 'owner_name',
      key: 'owner_name'
    },
    {
      title: 'Contact',
      dataIndex: 'contact_phone',
      render: (record, records) => 
      <span>{records.phonePrefix + " " + records.contact_phone}</span>
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ]

export default columns;
