import React, { useState, useContext, useEffect } from "react";
import { CSVDownload } from "react-csv";
import { connect } from "react-redux";
import moment from "moment";
import client from "feathers.js";
import { jsPDF } from "jspdf";
import axios from "axios";
import { Table, Pagination, Spin } from "antd";
import getSymbolFromCurrency from "currency-symbol-map";

import columns from "./columns";
import { DataContext } from "contexts/DataContextContainer";
import { PageContainer } from "../style";
import Datepicker from "../../../../components/Datepicker";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const CustomerManagement = (props) => {
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [action, setAction] = useState("all"); //edit, detail, create
  const [campaignData, setCampaignData] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [pagination, setPagination] = useState({});
  const [spin, setSpin] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [csvData, setCsvData] = useState([]);

  // function test(){
  //   fetch(raw)
  //   .then((r) => r.text())
  //   .then(text  => {
  //     console.log(text)
  //     const template = Handlebars.compile(text);
  //     var htmlString = template({
  //       owner_name: 'asdsad'
  //     });
  //     console.log(htmlString)

  //     // var htmlString = "<div>asdsad</div>"

  //     let div = document.createElement('div')
  //     div.innerHTML = htmlString
  //     // div.getElementsByTagName('table')[0].classList.remove("table-striped");

  //     // document.getElementById('aaa').innerHTML = res.data.html

  //     var doc = new jsPDF();

  //     doc.html(div, {
  //       html2canvas: {
  //         scale: .23 // default is window.devicePixelRatio
  //       },
  //       x: 10,
  //       y: 10,
  //       callback: function (doc) {
  //         doc.save(props.userInfo.username + "-" + 'v.invoicePrefix' + " "+ ".pdf");
  //       },
  //     });
  //   })
  // }

  const back = () => {
    setAction("all");
  };

  const searchDate = (startDate, endDate) => {
    if (startDate && endDate) {
      getCampaignData(0, 1, props.userInfo._id, startDate, endDate);
    }
  };

  const getCampaignData = (skip, currentPage, userId, startDate, endDate) => {
    setLoading(true);

    let query = {
      $skip: skip,
      $limit: PAGESIZE,
      userId,
      $sort: {
        createdAt: -1,
      },
    };

    if (startDate && endDate) {
      query = {
        $and: [
          {
            createdAt: {
              $gte: new Date(
                moment(new Date(startDate)).format("YYYY-MM-DD hh:mm")
              ).getTime(),
            },
          },
          {
            createdAt: {
              $lte: new Date(
                new Date(endDate).setHours(23, 59, 59, 999)
              ).getTime(),
            },
          },
        ],
        $skip: skip,
        $limit: PAGESIZE,
        userId,
        $sort: {
          createdAt: -1,
        },
      };
    }

    client
      .authenticate()
      .then((auth) => {
        return client.service("transactions").find({
          query,
        });
      })
      .then((res) => {
        setCampaignData(res.data);
        setPagination(paginationCount(res.total, currentPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  const downloadCSV = async () => {
    if (props.userInfo.subscription === "copper") {
      showToast("error", "Only Bronze and above allow to download CSV!");
      return false;
    }

    let tmpData = [];
    setLoading(false);

    const stackData = (index) => {
      var query = {
        $skip: index * pagination.per_page,
        userId: props.userInfo._id,
        $sort: {
          createdAt: -1,
        },
      };

      if (startDate && endDate) {
        query = {
          $and: [
            {
              createdAt: {
                $gte: new Date(startDate).getTime(),
              },
            },
            {
              createdAt: {
                $lte: new Date(
                  new Date(endDate).setHours(23, 59, 59, 999)
                ).getTime(),
              },
            },
          ],
          $skip: index * pagination.per_page,
          userId: props.userInfo._id,
          $sort: {
            createdAt: -1,
          },
        };
      }

      return new Promise((resolve) => {
        client
          .authenticate()
          .then(() => {
            return client.service("transactions").find({
              query: query,
            });
          })
          .then((res) => {
            let d = [];

            for (let i = 0; i < res.data.length; i++) {
              d.push({
                Type:
                  res.data[i].creditDebit === "credit"
                    ? "Topup"
                    : res.data[i].remark === "upgrade"
                    ? "Upgrade"
                    : "Renew",
                Payment:
                  res.data[i].creditDebit === "credit"
                    ? "+" +
                      getSymbolFromCurrency(
                        res.data[i].currency ? res.data[i].currency : "usd"
                      ) +
                      " " +
                      res.data[i].amount
                    : "-" +
                      getSymbolFromCurrency(
                        res.data[i].currency ? res.data[i].currency : "usd"
                      ) +
                      " " +
                      res.data[i].amount,
                Desc:
                  res.data[i].remark === "upgrade"
                    ? res.data[i].fromSubscription +
                      " -> " +
                      res.data[i].toSubscription +
                      "(" +
                      moment(res.data[i].substart).format(
                        "DD/MM/YYYY - HH:mm"
                      ) +
                      " / " +
                      moment(res.data[i].subEnd).format("DD/MM/YYYY - HH:mm") +
                      ")"
                    : "-",
                Status: res.data[i].status,
                "Created Date": moment(res.data[i].createdAt).format(
                  "DD/MM/YYYY - HH:mm"
                ),
              });
            }

            tmpData = tmpData.concat(d);
            resolve(res.data);
          })
          .catch((err) => {
            if (err.name === "NotAuthenticated") {
              showToast("error", "Please Sign-in to continue!");
            } else {
              showToast("error", err.message);
            }
          });
      });
    };

    let numOfLoop = [];
    for (let i = 0; i < pagination.last_page; i++) {
      numOfLoop.push(i);
    }

    let process = await numOfLoop.map(async (value, index) => {
      let data = await stackData(index);
      return data;
    });

    let result = await Promise.all(process);

    if (result) {
      setCsvData(tmpData);
      setSpin(true);
      setTimeout(() => {
        setSpin(false);
      }, 2000);
    }
  };

  const downloadPdf = (v) => {
    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}downloadPdf`,
          {
            userId: props.userInfo._id,
            transactionId: v._id,
            toSubscription: v.toSubscription,
            subStart: v.subStart,
            subEnd: v.subEnd,
            amount: v.amount,
          },
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        let div = document.createElement("div");
        div.innerHTML = res.data.html;
        // div.getElementsByTagName('table')[0].classList.remove("table-striped");

        // document.getElementById('aaa').innerHTML = res.data.html

        var doc = new jsPDF();

        doc.html(div, {
          html2canvas: {
            scale: 0.23, // default is window.devicePixelRatio
          },
          x: 10,
          y: 10,
          callback: function (doc) {
            // doc.save(props.userInfo.username + "-inv"  + (res.data.invoiceNo) + ".pdf");
            window.open(doc.output("bloburl"), "_blank");
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    searchDate(dates[0], dates[1]);
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);

  useEffect(() => {
    // test()
    getCampaignData(0, 1, props.userInfo._id);
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  return (
    <PageContainer>
      <div className='row-header'>
        <div>
          <h4>Search by Date</h4>
          <Datepicker
            periodStart={startDate}
            periodEnd={endDate}
            onChange={onChangeCampaignPeriod}
            noLabel
            dateDisable={true}
          />
        </div>

        <button
          type='button'
          className='primary-button'
          disabled={props.userInfo.subscription === "copper" ? true : spin}
          onClick={downloadCSV}
        >
          {spin ? <Spin /> : "Download CSV"}
        </button>
        {spin && <CSVDownload data={csvData} target='_blank' />}
      </div>

      <div className='row-container'>
        <Table
          rowKey='_id'
          columns={columns(setAction, setDataEdit, downloadPdf)}
          dataSource={campaignData}
          pagination={false}
        />
      </div>

      <div className='row-container'>
        <Pagination
          pageSize={PAGESIZE}
          total={pagination.total}
          showSizeChanger={false}
          onChange={(e) => {
            setPagination(paginationCount(pagination.total, e));
            getCampaignData(
              (e - 1) * PAGESIZE,
              e,
              props.userInfo._id,
              startDate,
              endDate
            );
          }}
        />
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);
