import styled from "styled-components";

export const GoogleContainer = styled.div`
  width: 100%;
  height: 100%;
  span {
    font-size: unset !important;
  }
  .line-clamp-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .line-clamp-desc {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .fRzIon {
    border-bottom: 1px hidden rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgb(32 33 36 / 28%) 0px 1px 6px;
    font-family: Arial, Roboto-Regular, HelveticaNeue, sans-serif;
    max-width: 400px;
    box-sizing: border-box;
    font-size: 14px;
  }
  .kEHeZL:first-child {
    margin-bottom: -16px;
  }
  .kEHeZL {
    padding: 12px 16px;
  }
  .BolQT {
    display: inline-block;
    cursor: pointer;
    position: relative;
    max-width: 90%;
    white-space: nowrap;
    font-size: 14px;
    vertical-align: top;
  }
  .gYnHlo {
    display: inline-block;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-bottom: 12px;
    padding-top: 1px;
    line-height: 20px;
    vertical-align: top;
  }
  .hQKFKz {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    vertical-align: middle;
  }
  .bxIhfU {
    font-size: 12px;
    line-height: 20px;
    color: rgb(60, 64, 67);
  }
  .jFdUzS {
    color: rgb(60, 64, 67);
  }
  .dMErEv {
    color: rgb(25, 103, 210);
    text-decoration: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    margin: 0px;
    display: inline-block;
    overflow: hidden;
    max-width: 600px;
    vertical-align: top;
    text-overflow: ellipsis;
  }
  .iLndVK {
    display: inline-block;
    max-height: 40px;
    padding-top: 1px;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .fIsZzD {
    color: rgb(60, 64, 67);
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    position: relative;
    max-width: 600px;
    padding-bottom: 6px;
    min-height: 104px;
  }
  .hafxWX {
    float: right;
    width: 104px;
    height: 104px;
    margin: 4px 0px 4px 16px;
    border-radius: 8px;
    overflow: hidden;
  }
  .czWTEy.czWTEy.czWTEy {
    display: block;
    width: 104px;
    height: 104px;
    object-fit: cover;
  }
  .eeMkVu {
    color: rgb(112, 117, 122);
  }
`;
