import React from "react";
import { Container } from "react-bootstrap";
import { FooterContainer } from "./style";

function Footer() {
  return (
    <>
      {/* <div style={{height: 120}}></div> */}
      <FooterContainer className="w-100">
        <Container>
          <p>© {new Date().getFullYear()} COPYRIGHT MOREREWARDZ. ALL RIGHTS RESERVED</p>
        </Container>
      </FooterContainer>
    </>
  );
}

export default Footer;
