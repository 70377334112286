import React, { useContext, useEffect } from "react";
import { PageContainer } from "./style";
import {
  ToolsCardArrow,
  StampsIcon,
  PackageIcon,
  ReferralIcon,
  VoucherIcon,
  PointsIcon,
  WalletIcon,
} from "assets/svg";
import { DataContext } from "contexts/DataContextContainer";
import { useHistory } from "react-router-dom";

const All = () => {
  const { setToolsAction, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const history = useHistory();

  const renderToolList = (icon, header, text, route) => {
    return (
      <div className='card-container'>
        <div className='info-wrapper'>
          <div className='icon-wrapper'>
            <img src={icon} alt='' width='30px' height='30px' />
          </div>
          <div className='card-header'>
            {header}
            <img src={ToolsCardArrow} alt='' width='20px' height='20px' />
          </div>
          <div className='card-text'>
            <ul className='ul-styling'>
              {text.map((item) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className='button-wrapper'>
          <button
            type='button'
            className='create-button'
            onClick={() => [
              setToolsAction("create"),
              history.push(`/admin/tools/${route}`),
            ]}
          >
            Create now
          </button>
          <button
            type='button'
            className='view-button'
            onClick={() => [
              setToolsAction("all"),
              history.push(`/admin/tools/${route}`),
            ]}
          >
            View all records
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setLocation("All");
    setBreadcrumb(["Campaign Tools", "All"]);
  }, []);

  return (
    <PageContainer>
      <div className='page-header'>
        Which campaign tools would like to get started on today?
      </div>
      <div className='row-container'>
        {renderToolList(
          StampsIcon,
          "Stamp cards",
          [
            "Most common loyalty program",
            "Allow customers to earn stamps and redeem attractive rewards and incentives.",
          ],
          "stamp"
        )}

        {renderToolList(
          PackageIcon,
          "Package",
          [
            "Allow customers to purchase a combination of offerings in advance, providing them with added value and savings.",
          ],
          "package"
        )}

        {renderToolList(
          ReferralIcon,
          "Referral",
          [
            "Allow customers to invite their friends to patronise your business with a reward.",
          ],
          "referral"
        )}

        {renderToolList(
          VoucherIcon,
          "Vouchers",
          [
            "Create advanced promotional vouchers to allow customers to collect and redeem on specific dates.",
          ],
          "voucher"
        )}

        {renderToolList(
          PointsIcon,
          "Points",
          [
            "Allow customers accumulate points from their purchases and redeem it from your rewards category.",
          ],
          "points"
        )}

        {renderToolList(
          WalletIcon,
          "Wallet",
          [
            "Establish your own internal wallet system, allowing your customers to conveniently top up funds and utilize them for transactions within your various campaigns.",
          ],
          "wallet"
        )}
      </div>
      <div className='row-container' />
    </PageContainer>
  );
};

export default All;
