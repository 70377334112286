import { Button } from "react-bootstrap";
import { colors, breakpoints } from "style";
import styled from "styled-components";

export const StyledButtonContainer = styled(Button)`
  position: relative;
  color: ${(props) => (props.outlined ? colors.red : "white")};
  height: ${(props) => (props.sizeh ? props.sizeh : 64)}px;
  width: ${(props) => props.width};
  background: ${(props) =>
    props.bgcolor ? props.bgcolor : colors.red} !important;
  // border-radius: ${(props) =>
    props.radius ? props.radius : 32}px !important;
  font-size: 18px;
  line-height: 23px;
  border-width: ${(props) => (props.outlined ? "2px" : 0)} !important;
  border-color: ${(props) =>
    props.bgcolor ? props.bgcolor : colors.red} !important;
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "unset"} !important;
  font-weight: 600;
  cursor: pointer;
  // align-self: center;
  &:hover,
  &:active,
  &:focus {
    outlined: none;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.16);
  }
  &.disabled:hover {
    transform: unset;
  }
  &.btn-blue {
    border: solid 1px ${colors.blueDark};
    background: ${colors.blueDark};
  }
  &.btn-transparent {
    border: solid 1px ${colors.white};
    background: transparent;
  }
  &.btn-whtie {
    border: solid 1px ${colors.blueDark};
    background: transparent;
    color: ${colors.blueDark};
  }
  @media (max-width: ${breakpoints.xl}px) {
  }
`;
