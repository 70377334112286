import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { logout } from 'redux/actions/roleActions';
import { getLogoBanner } from 'redux/actions/extraActions';
import { connect } from 'react-redux'
import { DatePicker, Space, Steps } from 'antd';
import { DataContext } from "contexts/DataContextContainer";
import { CampaignSetupContainer } from "./style";
import StyledButton from "components/Elements/StyledButton";
import stepComponents from './steps'

import axios from "axios";
import client from "feathers.js"
import StepsCounter from 'components/StepsCounter';
import QuestionCard from 'components/QuestionCard';
import moment from 'moment';
import StyledQuill from 'components/Elements/StyledQuill';
import Preview from './steps/Preview';
import { CalendarIcon } from 'assets/images';
import CustomDatePicker from 'components/Datepicker';

const { Step } = Steps;

var CryptoJS = require("crypto-js");

const CampaignSetup = props => {
  const history = useHistory();
  const { setLoading, showToast } = useContext(DataContext);
  const [step, setStep] = useState(0);
  const [title, setTitle] = useState('');
  const [count, setCount] = useState(null);
  const [periodStart, setPeriodStart] = useState(null)
  const [periodEnd, setPeriodEnd] = useState(null)
  const [campaignValidity, setCampaignValidity] = useState('')
  const [campaignValidityUnit, setCampaignValidityUnit] = useState('months')
  const [redeemValidity, setRedeemValidity] = useState('')
  const [redeemValidityUnit, setRedeemValidityUnit] = useState('months')
  const [tos, setTos] = useState('<ul><li></li></ul>')
  const [questionNumber, setQuestionNumber] = useState(1);
  const [stepNumber, setStepNumber] = useState(3)

  // useEffect(() => {
  //   if (!props.profile._id || props.profile.set_done === "done") {
  //     window.location.href = '/'
  //   }
  // }, [props.profile._id, props.profile.set_done]);

  const launch = async () => {

    if (props.userInfo._id && props.userInfo.set_done === "sign") {
      setLoading(true)

      axios.post(`${client.io.io.uri}profileSetdone`, {
        _id: props.userInfo._id,
        username: props.userInfo.username,
        logo: props.logo,
        banner: props.banner,
      })
        .then((res) => {

          const campaignDetail = {
            // campaign_type: 'stamp',
            userId: props.userInfo._id,
            title,
            count,
            period_start: periodStart.toDate(),
            period_end: periodEnd.toDate(),
            campaign_validity: campaignValidity,
            campaign_validity_unit: campaignValidityUnit,
            redeem_validity: redeemValidity,
            redeem_validity_unit: redeemValidityUnit,
            tos
          }
          client.service('stamps').create(campaignDetail)
        })
        .then((res) => {
          showToast('success', 'Merchant Creation Success!')
          setLoading(false)
          setStep(step + 1)

          // Decrypt
          var bytes = CryptoJS.AES.decrypt(props.userInfo.ciphertext, 'my password');
          var originalText = bytes.toString(CryptoJS.enc.Utf8);

          axios.post(`${client.io.io.uri}merchant_creation_success`, {
            email: props.userInfo.email,
            owner_name: props.userInfo.owner_name,
            username: props.userInfo.username,
            password: originalText
          })
        })
        .then((res) => {
          props.getLogoBanner({
            logo: '',
            banner: ''
          })
          props.logout()
          window.location.href = '/'
        })
        .catch((err) => {
          console.log(err.message)
          showToast('error', err.message)
          setLoading(false)
        })
    } else {
      showToast('error', 'Account is done Setup!')
    }
  }


  const stepsList = [
    "Create your Business Profile",
    "Enter your details",
    "Create your first stamp campaign",
  ]

  const quillModules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        // { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        // { indent: "+1" },
      ],
    ],
  };

  const handleBackButton = () => {
    if (questionNumber === 1) {
      setQuestionNumber(1)
      return;
    }
    setQuestionNumber(questionNumber - 1)
  }

  const handleNextButton = () => {
    if (questionNumber === 1 && title.trim().length > 0) {
      setQuestionNumber(2)
    }
    if (questionNumber === 2 && count > 0) {
      setQuestionNumber(3)
    }
    if (questionNumber === 3 && periodStart && periodEnd) {
      setQuestionNumber(4)
    }
    if (questionNumber === 4 && campaignValidity.trim().length > 0 && campaignValidityUnit) {
      setQuestionNumber(5)
    }
    if (questionNumber === 5 && redeemValidity.trim().length > 0 && redeemValidityUnit) {
      setQuestionNumber(6)
    } if (questionNumber === 6) {
      setQuestionNumber(7)
      setStepNumber(stepNumber + 1)
    }
  }

  const onChange = (dates, dateStrings) => {
    setPeriodStart(dates[0])
    setPeriodEnd(dates[1])
  }

  const { RangePicker } = DatePicker;
  const renderQuestionCard = () => {
    if (questionNumber === 1) {
      return (
        <QuestionCard
          questionNumber="Q1."
          question="What will be the reward for this stamp card campaign?"
          descriptions={
            <div className='description-text'>
              Provide an interesting promotion title  which will capture customer's attention.
            </div>
          }
          totalQuestion={6}
          currentActive={questionNumber}
          onClickBackButton={handleBackButton}
          onClickNextButton={handleNextButton}
        >
          <input
            type='text'
            className='answer-input'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder='Eg. Receive a FREE cup of coffee worth $6!'
          />
          <p className='answer-input-description'>Characters ({title.trim().length}/50)</p>
        </QuestionCard>
      )
    }
    if (questionNumber === 2) {
      return (
        <QuestionCard
          questionNumber="Q2."
          question="How many stamps should customers collect to redeem this reward?"
          descriptions={
            <div className='description-text'>
              You can set any number of stamp(s) required. Example, 1,3,10, etc.
            </div>
          }
          totalQuestion={6}
          currentActive={questionNumber}
          onClickBackButton={handleBackButton}
          onClickNextButton={handleNextButton}
        >
          <input
            type='number'
            className='answer-input'
            value={count}
            onChange={(e) => setCount(e.target.value)}
            placeholder='Enter number of stamps'
            min='0'
            max='100'
          />
          <div className='number-preset'>
            <button
              className={count === 3 ? 'active' : ''}
              onClick={() => setCount(3)}
            >3</button>
            <button
              className={count === 5 ? 'active' : ''}
              onClick={() => setCount(5)}
            >5</button>
            <button
              className={count === 10 ? 'active' : ''}
              onClick={() => setCount(10)}
            >10</button>
            <button
              className={count === 20 ? 'active' : ''}
              onClick={() => setCount(20)}
            >20</button>
            <button
              className={![3, 5, 10, 20].includes(count) && count ? 'active' : ''}
            >Others</button>
          </div>
        </QuestionCard>
      )
    }
    if (questionNumber === 3) {
      return (
        <QuestionCard
          questionNumber="Q3."
          question="How long will this stamp card  campaign run for?"
          descriptions={
            <div className='description-text'>
              Provide the start and end date for your stamp campaign.
            </div>
          }
          totalQuestion={6}
          currentActive={questionNumber}
          onClickBackButton={handleBackButton}
          onClickNextButton={handleNextButton}
        >
          <CustomDatePicker
            periodStart={periodStart}
            periodEnd={periodEnd}
            onChange={onChange}
          />
        </QuestionCard>
      )
    }
    if (questionNumber === 4) {
      return (
        <QuestionCard
          questionNumber="Q4."
          question="What is the duration of the stamp card's validity starting from the collection of the first stamp?"
          descriptions={
            <div className='description-text'>
              Provide the period of time the stamp card stays valid the moment a customer collects the first stamp.
            </div>
          }
          totalQuestion={6}
          currentActive={questionNumber}
          onClickBackButton={handleBackButton}
          onClickNextButton={handleNextButton}
        >
          <input
            type='number'
            id='campaignValidity'
            className='answer-input'
            value={campaignValidity}
            onChange={(e) => setCampaignValidity(e.target.value)}
            placeholder='Enter number'
            min='0'
            max='100'
          />
          <div className='number-preset'>
            <button
              className={campaignValidityUnit === "days" ? 'active' : ''}
              onClick={() => setCampaignValidityUnit("days")}
            >Day(s)</button>
            <button
              className={campaignValidityUnit === "weeks" ? 'active' : ''}
              onClick={() => setCampaignValidityUnit("weeks")}
            >Week(s)</button>
            <button
              className={campaignValidityUnit === "months" ? 'active' : ''}
              onClick={() => setCampaignValidityUnit("months")}
            >Month(s)</button>
            <button
              className={campaignValidityUnit === "years" ? 'active' : ''}
              onClick={() => setCampaignValidityUnit("years")}
            >Year(s)</button>
          </div>
        </QuestionCard>
      )
    }
    if (questionNumber === 5) {
      return (
        <QuestionCard
          questionNumber="Q5."
          question="How long after do customers have to redeem their reward after collecting all their stamps for this stamp campaign?"
          descriptions={
            <div className='description-text'>
              Provide the period of time customers have to redeem their reward before the stamp card expires.
            </div>
          }
          totalQuestion={6}
          currentActive={questionNumber}
          onClickBackButton={handleBackButton}
          onClickNextButton={handleNextButton}
        >
          <input
            type='number'
            className='answer-input'
            id='redeemValidity'
            value={redeemValidity}
            onChange={(e) => setRedeemValidity(e.target.value)}
            placeholder='Enter number'
            min='0'
            max='100'
          />
          <div className='number-preset'>
            <button
              className={redeemValidityUnit === "days" ? 'active' : ''}
              onClick={() => setRedeemValidityUnit("days")}
            >Day(s)</button>
            <button
              className={redeemValidityUnit === "weeks" ? 'active' : ''}
              onClick={() => setRedeemValidityUnit("weeks")}
            >Week(s)</button>
            <button
              className={redeemValidityUnit === "months" ? 'active' : ''}
              onClick={() => setRedeemValidityUnit("months")}
            >Month(s)</button>
            <button
              className={redeemValidityUnit === "years" ? 'active' : ''}
              onClick={() => setRedeemValidityUnit("years")}
            >Year(s)</button>
          </div>
        </QuestionCard>
      )
    }
    if (questionNumber === 6) {
      return (
        <QuestionCard
          questionNumber="Q6."
          question={
            <>
              <div>Final step!</div>
              <div>Any other terms & conditions?</div>
            </>
          }
          descriptions={
            <div className='description-text'>
              <ul className='description-list'>
                <li>Campaign Period 18/01/2023 - 31/12/2023</li>
                <li>Complete this stamp card within 6 month(s) after collecting first stamp</li>
                <li>Redeem reward within 1 month(s) upon completing</li>
              </ul>
            </div>
          }
          totalQuestion={6}
          currentActive={questionNumber}
          onClickBackButton={handleBackButton}
          onClickNextButton={handleNextButton}
        >
          <StyledQuill
            readOnly={false}
            modules={quillModules}
            value={tos}
            onChange={async (value) => {
              setTos(value)
            }}
            style={{ height: "100px", marginBottom: '50px' }}
          />
        </QuestionCard>
      )
    }
    if (questionNumber === 7) {
      return (
        <Preview
          title={title}
          count={count}
          periodStart={periodStart}
          periodEnd={periodEnd}
          campaignValidity={campaignValidity}
          campaignValidityUnit={campaignValidityUnit}
          redeemValidity={redeemValidity}
          redeemValidityUnit={redeemValidityUnit}
          tos={tos}
        >
          <div className='w-100 d-flex justify-content-center mb-2'>
            <button
              type="button"
              className="btn check-avilability-again mr-1"
              onClick={() => [setStepNumber(3), setQuestionNumber(6)]}
            >
              Back
            </button>
            <button
              type="button"
              className="btn confirm-btn ml-1"
              onClick={() => launch()}
            >
              Launch
            </button>
          </div>
        </Preview>
      )
    }
    return null;
  }

  return (
    <CampaignSetupContainer>
      <div className="steps-group">
        <StepsCounter
          stepDescriptions={stepsList}
          currentStepNumber={stepNumber}
        />
      </div>
      <div className="home-container">
        {/* <div className="col-md-6 my-auto left-image" /> */}
        <div className="stamp-campaign">
          {renderQuestionCard()}
        </div>
      </div>
    </CampaignSetupContainer>
  );
}

const mapStateToProps = state => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
  logo: state.extra.logo,
  banner: state.extra.banner,
});

const mapDispatchToProps = {
  logout: logout,
  getLogoBanner: getLogoBanner
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSetup);
