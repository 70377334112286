import { Layout } from "antd";
import styled from "styled-components";

export const InfoCardContainer = styled(Layout)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: auto;
  background: #e8f1ff;
  padding: 20px;
  gap: 10px;
  border-radius: 20px;
  position: relative;

  .desc-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 0 20px;
  }

  .desc-header {
    font-size: 18px;
    font-weight: 800;
    color: #6b7280;
  }

  .desc-text {
    font-size: 14px;
    color: #6b7280;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    transition: ;
  }

  .error-wrapper {
    position: absolute;
    top: 18px;
    left: 15px;
  }
`;
