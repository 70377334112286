import React, { useContext, useEffect, useState } from "react";
import { PageContainer } from "./style";
import StyledButton from "components/Elements/StyledButton";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { updateDetails } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal } from "antd";

import client from "feathers.js";
import { PasswordImage } from "assets/images";
import { HidePassword, ShowPassword, Success } from "assets/svg";

const ProfileSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please Enter your password")
    .min(8, "Password must contain 8 or more characters"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required!"),
});

const MerchantDetails = (props) => {
  const history = useHistory();

  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([]);
  }, []);

  const onUpdateProfile = async (data) => {
    let cloneData = data;

    setLoading(true);
    client
      .service("merchants")
      .patch(props.userInfo._id, cloneData)
      .then((res) => {
        props.updateDetails(res);
        setOpenModal(true);
        setLoading(false);

        setTimeout(() => {
          setOpenModal(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <PageContainer>
      <div className='image-container'>
        <img src={PasswordImage} alt='' />
      </div>
      <Formik
        enableReinitialize
        validationSchema={ProfileSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onUpdateProfile}
        initialValues={{
          password: "",
          passwordConfirmation: "",
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <Form onSubmit={handleSubmit} id='form1' className='form-styling'>
            <div className='password-container'>
              <div className='input-container'>
                New Password
                <Form.Control
                  type={showNewPassword ? "text" : "password"}
                  name='password'
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password}
                  placeholder='New Password'
                  className='input'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.password || ""}
                </Form.Control.Feedback>
                <button
                  type='button'
                  className='password-button'
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  <img
                    src={showNewPassword ? ShowPassword : HidePassword}
                    alt=''
                    width='24px'
                    height='24px'
                  />
                </button>
              </div>

              <div className='input-container'>
                Confirm Password
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  name='passwordConfirmation'
                  value={values.passwordConfirmation}
                  isInvalid={!!errors.passwordConfirmation}
                  onChange={handleChange}
                  placeholder='Confirm Password'
                  className='input'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.passwordConfirmation}
                </Form.Control.Feedback>
                <button
                  type='button'
                  className='password-button'
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <img
                    src={showConfirmPassword ? ShowPassword : HidePassword}
                    alt=''
                    width='24px'
                    height='24px'
                  />
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className='row-container'>
        <button
          type='button'
          className='cancel-button'
          onClick={() => history.goBack()}
        >
          Cancel
        </button>
        <button type='submit' className='save-button' form='form1'>
          Save & Update
        </button>
      </div>

      <Modal
        centered
        footer={null}
        closeIcon={<></>}
        open={openModal}
        maskClosable={true}
        onCancel={() => setOpenModal(false)}
        bodyStyle={{
          height: "220px",
          width: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <img src={Success} alt='' width='60px' height='60px' />
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#101828",
          }}
        >
          Password Updated
        </div>
      </Modal>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDetails);
