import styled from "styled-components";
import { Menu } from "antd";
import { breakpoints, colors } from "style";

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
    .tabs-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border-bottom: 1px solid #E7EAEE;
    }
    .tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 108px;
        max-width: 108px;
        height: 35px;
        color: 191D23;
        font-size: 16px;
        font-weight: 750;
        cursor: pointer;
        background: transparent;
    }
    .tabs.active {
        border-bottom: 1px solid #047857;
    }
    .filter-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .filter-left-content {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
    }
    .filter-right-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        width: 100%;
        max-width: 350px;
    }
    .search-bar {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 240px;
        background: white;
        border: 1px solid #eaecee;
        border-radius: 10px;
        height: 48px;

        @media (max-width: 1300px) {
          max-width: 165px;
          min-width: 165px;
        }
    }
    .form-group, .input-group {
        display: flex;
        align-items: center;
        width: auto;
        background: white;
        height: 48px;
    }
    .input-group-text, .ant-picker-range {
        height: 100%;
    }
    .placeholder-text {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #A8A6A6;
        font-weight: 500;
    }
    .placeholder {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .primary-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 194px;
      padding: 13.5px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      background: #567877;
      height: 48px;
      border-radius: 10px;
    }
    .filter-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 144px;
      padding: 13.5px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      background: #567877;
      height: 48px;
      border-radius: 10px;
    }
    .table {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .spinner-styling {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        height: 42px;
        border-radius: 10px;
    }
    .ant-table-wrapper {
        width: 100%;
    }
    .ant-table-cell::before {
        display: none;
    }
    .table th {
        border-top: none;
    }
    .table thead th {
        border-bottom: none;
        background: #E7EAEE;
    }
    .ant-pagination {
        display: flex;
        align-items: center;
        width: auto;
        gap: 16px;
        margin: 50px 0 0 0;
      }
      .ant-pagination-prev {
        margin-right: 50px;
      }
      .ant-pagination-next {
        margin-left: 50px;
      }
      .ant-pagination-prev,
      .ant-pagination-item,
      .ant-pagination-next {
        background: white;
        color: #344051;
        border: 1px solid #F2F4F7;
        border-radius: 8px;
      }
      .ant-pagination-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ant-pagination-item-active {
        background: #F2F4F7;
        color: #344051;
        border: 1px solid #344051;
      }
      .ant-pagination-item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: #8b909a;
        border-radius: 8px
      }
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
      .ant-select:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 42px;
    }

  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    // margin-bottom: 40px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .title {
      padding-right: 20px;
      display: flex;
      align-items: center;
      border-right: 2px solid ${colors.bgGrey};
      h3 {
        font-size: 30px;
        // font-weight: bold;
        color: ${colors.purple};
        margin-bottom:; 0
      }
    }
    .plan-info {
      display: flex;
      text-transform: capitalize;
      .plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          font-weight: bold;
          font-size: 18px;
          &:nth-child(1) {
            color: ${colors.purple};
          }
        }
      }
      .maximum {
        span:nth-child(2) {
          color: ${colors.red};
        }
      }
      .active {
        span:nth-child(2) {
          color: ${colors.blue};
        }
      }
      .balance {
        span:nth-child(2) {
          color: ${colors.yellow};
        }
      }
    }
    .btn-plan {
      padding: 8px 12px;
      background: #3b7779;
      box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
      border-radius: 7px;
      font-weight: 700;
      font-size: 15px;
      color: #FFFFFF;      
    }
  }
  .status-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    .status {
      width: 30%;
      // height: 90px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(77, 121, 120, 0.08);
      box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.25);
      border-radius: 10px;
      label {
        font-weight: 600;
        color: #3b7779;
        margin: 0; 
      }
      h2 {
        display: inline-block;
      }
      p {
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        color: #3b7779;
      }
    }
  }
  .page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    height: 100%;
    background: white;
    border-radius: 8px;
    .ant-table-wrapper {
      width: 100%;
      th {
        font-weight: bold;
        color: ${colors.purple};
      }
    }
    .btn-add {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      background: #3b7779;
      border: none;
      width: 50px;
      height: 50px;
      color: white;
      border-radius: 25px;
      font-size: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
      position: relative;
      span {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: auto;
        transform: translate(0, -50%);
      }
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;

export const ActionMenuContainer = styled(Menu)`
  border-radius: 4px;
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    span {
      margin-right: 12px;
    }
  }
`;
