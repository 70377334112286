import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RouteWrapper from "./RouteWrapper";
import Login from "containers/merchant/Auth/Login";
import SignUp from "containers/merchant/Auth/SignUp";
import ForgotPassword from "containers/merchant/Auth/ForgotPassword";
import ResetPassword from "containers/merchant/Auth/ResetPassword";
import Verification from "containers/merchant/Auth/Verification";
import Profile from "containers/merchant/Profile";
import Brand from "containers/merchant/Brand";
import CampaignSetup from "containers/merchant/CampaignSetup";
import Poster from "containers/merchant/Admin/QRCode/poster";
import Success from "containers/merchant/Admin/Success";
import Fail from "containers/merchant/Admin/Fail";

import AdminLayout from 'layouts/AdminLayout'

function Routes() {
  return (
      <Router>
        <Switch>
          {/* merchant routes */}
          <RouteWrapper exact path="/" component={Login} />
          <RouteWrapper exact path="/signup" component={SignUp} />
          <RouteWrapper exact path="/verification" component={Verification} />
          <RouteWrapper exact path="/profile" component={Profile} />
          <RouteWrapper exact path="/brand" component={Brand} />
          <RouteWrapper exact path="/setup" component={CampaignSetup} />
          <RouteWrapper exact path="/forgot-password" component={ForgotPassword} />
          <RouteWrapper exact path="/reset-password/:id" component={ResetPassword} />
          <RouteWrapper exact path="/poster" component={Poster} />
          <RouteWrapper exact path="/payment/success" component={Success} />
          <RouteWrapper exact path="/payment/fail" component={Fail} />
          <Route path="/admin" component={AdminLayout} />
        </Switch>
      </Router>
  );
}

export default Routes;
