import { breakpoints, colors } from "style";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  width: 100%;
  .row-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .right-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    * > .input-group > select.form-control {
      padding-right: 30px;
    }

    * > .input-group > .form-control {
      padding: 20px;
      border-left: none;
      border-radius: 0 8px 8px 0;
    }
  }
  .input {
    padding: 23px 20px;
  }
  .url-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .url {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e4e6f1;
    border-radius: 8px 0 0 8px;
    color: #191d23;
    font-size: 14px;
  }
  .placeholder-text {
    font-size: 14px;
    color: #b6b9ce;
  }
  .icon-wrapper {
    position: absolute;
    top: 20%;
    right: 10px;
    transform: translation(-25%);
    width: auto;
    height: auto;
  }
  .preview-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    max-width: 160px;
    font-size: 14px;
    color: white;
  }

  .title {
    margin-bottom: 24px;
    // font-weight: bold;
    color: ${colors.purple};
  }
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    // margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .home-container {
      flex: 1;
    }
    .preview-wrapper {
      width: 100%;
      max-width: 400px;
      // display: flex;
      // flex-direction: column;
      .preview {
        width: 100%;
        // max-width: 400px;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
      }
      span {
        color: ${colors.purple};
        font-size: 24px;
        font-weight: bold;
      }
      h4 {
        margin-top: 16px;
        color: black !important;
      }
    }
    .signup-form {
      width: 100%;
      max-width: 800px;
      // padding: 40px;
      // display: flex;
      // flex-direction: column;
      background: white;
      border-radius: 16px;
      h2 {
        color: ${colors.purple};
      }
      h4 {
        color: ${colors.grey};
      }
      .business-name {
        display: flex;
        justify-content: space-between;
        margin: 40px 0;
        .domain {
          display: flex;
          flex: 1;
          .bdomain {
            margin-right: 8px;
            margin-top: 6px;
          }
          .bname {
            margin-right: 40px;
            max-width: 500px;
          }
          .bname-err {
            border-color: ${colors.red};
          }
        }
        .check-avilability {
          color: ${colors.red};
          &:focus {
            box-shadow: unset;
          }
        }
        display: flex;
        align-items: center;
      }
      .facebook {
        color: #3d88ce;
        font-size: 24px;
      }
      .twitter {
        color: #6dcef1;
        font-size: 24px;
      }
      .google {
        color: #f04c28;
        font-size: 24px;
      }
      .form-control {
        height: 40px;
        border: 1px solid ${colors.textGrey};
        &:focus,
        &:active {
          outline: 0px !important;
          -webkit-appearance: none;
          box-shadow: none !important;
        }
      }
      .input-group {
        border: 1px solid ${colors.textGrey};
        border-radius: 0.25rem;
        padding-right: 2px;
        .input-group-text,
        .form-control {
          border: none;
          background-color: white;
        }
      }
      .country button {
        font-weight: normal;
      }
      .input-group-feedback {
        display: block;
      }
      .section-social {
        margin-top: 16px;
      }
      .business-social {
        margin-top: 40px;
        .btn {
          width: 100%;
          height: 40px;
          color: ${colors.white};
          font-size: 14px;
          margin-bottom: 18px;
          border-radius: 6px;
        }
        .btn-facebook {
          background: #3785cc;
        }
        .btn-twitter {
          background: #68cdf1;
        }
        .btn-google {
          background: #f04621;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
