/* eslint-disable */
import { Menu, Dropdown, Tag } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { ActionMenuContainer } from "../../style";
import moment from 'moment';
import { EllipsisOutlined, DownloadOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons"
import getSymbolFromCurrency from 'currency-symbol-map'

const menu = (record, setTableAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item key="0" onClick={() => {
          setTableAction('edit')
          setDataEdit(record)
        }}>
        <FormOutlined /> Edit
      </Menu.Item>
      {/* <Menu.Item key="1">
        <PauseOutlined /> Pause
      </Menu.Item>
      <Menu.Item key="2">
        <FileTextOutlined /> Details
      </Menu.Item> */}
    </ActionMenuContainer>
  )
}

const columns = (setTableAction, setDataEdit, currency, downloadFile, approveSubmission, rejectSubmission, pointPerDollar, dollarPoint) => [
    {
      title: 'User Email',
      dataIndex: 'userInfo',
      key: 'userInfo.email',
      render: (record) => <span>{record.email}</span>
    },
    {
      title: 'Receipt Number',
      dataIndex: 'receiptNumber',
      key: 'receiptNumber'
    },
    {
      title: 'Date of Purchase',
      dataIndex: 'datePurchase',
      key: 'datePurchase',
      render: (record) => <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (record) => <span>{getSymbolFromCurrency(currency)+""+record}</span>
    },
    {
      title: 'Receipt Upload',
      dataIndex: 'receiptFile',
      render: (record) => record.fileName?<div style={{fontSize: 20}}>
        <DownloadOutlined style={{cursor: 'pointer'}} onClick={() => downloadFile(record.fileName)} />
        </div>: '-'
    },
    {
      title: 'Points to award',
      dataIndex: 'amount' + pointPerDollar,
      render: (record, records) => <span>{parseInt((records.amount / Math.trunc(dollarPoint)) * pointPerDollar)}</span>
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: status => status? 
      (status === 'approve'?
      <Tag color="#87d068" className='text-capitalize'>{status + "d"}</Tag>
      :
      <Tag color="#f50" className='text-capitalize'>{status + "ed"}</Tag>)
      : <span>-</span>
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (record, records) => !records.status?
      (
        <div className='d-flex'>
          <div>
            <button className='btn btn-success shadow' style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              padding: 0,
              margin: 0,
              position: 'relative',
              marginRight: 6
            }}>
              <CheckOutlined style={{
                cursor: 'pointer',
                position: 'absolute',
                left: 0,
                right: 0,
                top: '50%',
                transform: 'translate(0, -50%)'
                }} onClick={() => approveSubmission(record)} />
            </button>
            <button className='btn btn-danger shadow' style={{
              width: 30,
              height: 30,
              borderRadius: "50%",
              padding: 0,
              margin: 0,
              position: 'relative'
            }}>
              <CloseOutlined style={{
                cursor: 'pointer',
                position: 'absolute',
                left: 0,
                right: 0,
                top: '50%',
                transform: 'translate(0, -50%)'
                }} onClick={() => rejectSubmission(record)} />
            </button>
          </div>
          <Dropdown className='ml-1' overlay={menu(record, setTableAction, setDataEdit)} trigger={['click']}>
            <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
              <EllipsisOutlined style={{fontSize: 20}}/>
            </a>
        </Dropdown>
        </div>
      ): <></>,
    },
  ]

export default columns;
