import { LogoWhite } from "assets/images";
import React from "react";
import { Link, 
  // useHistory
 } from "react-router-dom";
import { 
  // Navbar, Nav, 
  Container,
  Image 
} from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { FaBars } from "react-icons/fa";
import { TopbarContainer } from "./style";
// import { colors } from "style";

function Topbar() {
  // const { t } = useTranslation();
  // const history = useHistory();

  // const back = () => {
  //   history.goBack()
  // }

  return (
    <TopbarContainer>
      <Container style={{position: 'relative'}}>
      {/* {
        window.location.pathname === '/' || window.location.pathname === '/profile'?
        // <div style={{marginRight: 40}}></div>
        <></>
        :
        <span className='btn-back' onClick={back} 
          style={{
            float: 'left', marginTop: 15,
            position: 'absolute',
            left: 0
            }}>
          <i className="fas fa-angle-left"></i> 
          {" "}
          <span>Back</span>
        </span>
      } */}

      <Link to="/">
        <Image src={LogoWhite} className="img" />
      </Link>

      {/* <Navbar collapseOnSelect expand="md" className="topbar-top">
        <div className="topbar-wrapper">
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FaBars color={colors.black} size={24} />
          </Navbar.Toggle>
          <Link to="/" className="navbar-brand">
            <Image src={LogoWhite} />
          </Link>
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Link to="/" className="link-nav">
              {t("home")}
            </Link>
            <Link to="/" className="link-nav">
              {t("how_it_works")}
            </Link>
            <Link to="/" className="link-nav">
              {t("benefits")}
            </Link>
            <Link to="/" className="link-nav">
              {t("features")}
            </Link>
            <Link to="/" className="link-nav">
              {t("examples")}
            </Link>
            <Link to="/" className="link-nav">
              {t("faqs")}
            </Link>
            <Link to="/" className="link-nav">
              {t("pricing")}
            </Link>
            <Link to="/" className="link-nav btn-campaign">
              {t("start_your_campaign_for_free")}
            </Link>
            <Link to="/" className="link-nav btn-login">
              {t("login")}
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar> */}
      </Container>
    </TopbarContainer>
  );
}

export default Topbar;
