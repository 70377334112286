import React from "react";
import { Radio, Space } from "antd";
import { StyledButtonContainer } from "./style";

function StyledRadio({
  options = [],
  onChange = () => {},
  value = null,
  style = {},
}) {
  return (
    <StyledButtonContainer>
      <Radio.Group onChange={onChange} value={value}>
        <Space size={24} style={style}>
          {options.map((_option, _i) => (
            <Radio key={_i} value={_option.value}>
              {_option.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </StyledButtonContainer>
  );
}

export default StyledRadio;
