/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { PaymentSuccess } from "assets/images";
import { useLocation } from 'react-router-dom';
import { GetSession } from "../../../api/stripe/GetSession"
import { GetSubscription } from "../../../api/stripe/GetSubscription"
import moment from "moment";
import client from "feathers.js"
import { logout } from 'redux/actions/roleActions';

function getDaysLeft(endDate) {
    if(moment(endDate).diff(new Date(), 'days') > 0){
      return (
        moment(endDate).diff(new Date(), 'days')
      );
    }
    return 0
}

const Success = (props) => {
    const location = useLocation();
    const [timeLeft, setTimeLeft] = useState(10);

    const stripeSession = async(sessionId) => {
        let result = await GetSession({
          accessToken: props.accessToken,
          sessionId,
        })
        if(result?.data?.data){

            let sessions = result?.data?.data
            let result1 = await GetSubscription({
                accessToken: props.accessToken,
                subscriptionId: sessions.subscription,
            })
            
            if(sessions?.metadata?.merchantId === props.userId){
                let subscription = result1?.data?.data;

                let dayLeft = getDaysLeft(new Date(props.userInfo?.subEnd));
                let subStart = moment.unix(subscription.current_period_start).format();
                let subEnd = moment.unix(subscription.current_period_end).add(dayLeft, 'days').format();

                // props.loginDetails(res) 

                client.service('merchants').patch(props.userId, {
                    subscriptionId: subscription.id,
                    subscription: subscriptionPlan,
                    // planIn: 'month',
                    subStart,
                    subEnd,
                })
            }
            
        }else{
          // showToast('error', result.error.message);
        }
    }

    // useEffect(() => {
    //   const searchParams = new URLSearchParams(location.search);
    //   const sessionId = searchParams.get('session_id');
      
    //   stripeSession(sessionId);

    // }, [location.search]);

    useEffect(() => {
      if (timeLeft === 0) {
        // Redirect to the home page after 5 seconds
        client.authenticate()
        .then((a) => {
            client.logout()
            props.logout()
        })
        .catch((err) => {
            props.logout()
        })
        setTimeout(() => {
            props.history.push('/')
        }, 500);
        
      } else {
        // Update the time left every second
        const timer = setTimeout(() => {
          setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
        }, 1000); // 1000 milliseconds = 1 second
  
        // Cleanup function to clear the timer if the component unmounts
        return () => clearTimeout(timer);
      }
    }, [timeLeft]); 

    
    return (  
        <div className="text-center mt-5 pt-5">
            <img style={{width: 120}} src={PaymentSuccess} alt="" />
            <h3>Payment Successful</h3>
            <p>Your payment was successfully processed. Thank you for your purchase!</p>
            <br/>
            <p className="mb-0">Please login and to continue.</p>
            <p className="text-sm">Redirecting to the home page in {timeLeft} seconds...</p>
        </div>
    );
}
 


const mapStateToProps = state => ({
    auth: state.role.auth,
    balance: state.role.balance?state.role.balance:0,
    userId: state.role.details.user?state.role.details.user._id:'',
    username: state.role.details.user?state.role.details.user.username:'',
    role: state.role.details.user?state.role.details.user.role:'',
    userInfo: state.role.details.user?state.role.details.user:{},
    accessToken: state.role.details.accessToken?state.role.details.accessToken:"",
  });
  
const mapDispatchToProps = {
    logout: logout,
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Success);