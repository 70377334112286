import React, { useContext, useEffect, useState } from "react";
import { PageContainer } from "./style";
import { updateDetails } from "redux/actions/roleActions";
import { connect } from "react-redux";
import { PDFEnjoy, PDFScan, PDFSignup, LogoWhite } from "assets/images";
import { jsPDF } from "jspdf";
import QRCodeSvg from "qrcode-svg";

import client from "feathers.js";
import { DataContext } from "contexts/DataContextContainer";
import InfoCard from "components/InfoCard/InfoCard";
import DetailsSection from "components/DetailsSection/RowContainer";
import { Download } from "assets/svg";
import { Spin } from "antd";

const QRCodeComponent = (props) => {
  const { setLocation, setBreadcrumb } = useContext(DataContext);
  const [spinQR, setSpinQR] = useState(false);
  const [spinPoster, setSpinPoster] = useState(false);
  let qr;

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Page", props.title]);
  }, []);

  useEffect(() => {
    var qrcode = new QRCodeSvg({
      content: process.env.REACT_APP_BASE_URL + props.userInfo.username,
      container: "svg-viewbox", //Responsive use
      join: true, //Crisp rendering and 4-5x reduced file size
    }).svg();
    document.getElementById("svg").innerHTML = qrcode;
    document.getElementById("svg-1").innerHTML = qrcode;
    /* eslint-disable-next-line */
  }, []);

  const downloadQrPdf = (format, isWindow) => {
    // html2canvas(document.querySelector("#qr_code"), {
    //   useCORS: true,
    // }).then((canvas) => {

    if (!props.userInfo.doneQR) {
      client
        .service("merchants")
        .patch(props.userInfo._id, {
          doneQR: true,
        })
        .then((res) => {
          props.updateDetails(res);
        })
        .then(() => {
          setSpinQR(false);
        });
    }

    if (isWindow) {
      window.open(
        window.location.origin + "/poster",
        "_blank",
        "noopener,noreferrer"
      );
      setTimeout(() => {
        setSpinPoster(false);
      }, 8000);
      return false;
    }

    var size = [170, 170];
    var width = 0;
    var height = 0;

    var doc = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: size,
      putOnlyUsedFonts: true,
    });

    if (format === "a3") {
      width = 140;
      height = 140;
    } else if (format === "a4") {
      width = 70;
      height = 70;
    } else if (format === "a5") {
      width = 20;
      height = 20;
    } else if (format === "a6") {
      width = 20;
      height = 20;
    } else {
      width = doc.internal.pageSize.getWidth();
      height = doc.internal.pageSize.getHeight();
    }

    function capitalize(s) {
      return s.toLowerCase().replace(/\b./g, function (a) {
        return a.toUpperCase();
      });
    }

    // var imgData = canvas.toDataURL()
    // var imgData = document.querySelector("#react-qrcode-logo").toDataURL("image/jpeg")
    const element = document.querySelector("#svg svg");

    let text = props.userInfo.username;
    var xOffset = doc.internal.pageSize.width / 2;

    doc.svg(element, { x: 15, y: 10, width, height });

    doc.setFontSize(22);
    doc.setFont(undefined, "bold");
    doc.text(capitalize(text), xOffset, 160, { align: "center" });

    // doc.setFontSize(40);
    // // doc.text(50, 50, props.userInfo.business_name);
    // doc.addImage(imgData, 'jpeg', 70, 50, width, height);
    // doc.output('dataurlnewwindow');
    // var blob = doc.output("blob");
    // window.open(URL.createObjectURL(blob));

    setTimeout(() => {
      doc.save(`${props.username}_QRCode.pdf`);
      setSpinQR(false);
    }, 500);
    // })
  };

  return (
    <PageContainer>
      <InfoCard desc='The poster and QR code feature in MoreRewardz enables you to effectively promote your campaign programs, attract customer attention, and encourage participation. It simplifies the process of customer engagement and provides a convenient way for customers to connect with your campaigns using their mobile devices.' />

      <DetailsSection
        leftHeader='QR code ( print-ready)'
        leftDesc='Easy download and print your QR code and stick it on your desk , front door of your outlet to get more engagement!'
        leftContent={
          <>
            <button
              type='button'
              className='download-button'
              onClick={() => [setSpinQR(true), downloadQrPdf("a3")]}
            >
              {spinQR ? (
                <Spin />
              ) : (
                <>
                  <img src={Download} alt='' width='16px' height='16px' />
                  Download{" "}
                </>
              )}
            </button>
          </>
        }
        rightContent={
          <>
            <div className='code-wrapper'>
              <div id='svg' className='qrcode-styling' />
            </div>
          </>
        }
        align='flex-end'
      />

      <DetailsSection
        leftHeader='Poster ( print ready )'
        leftDesc='Easy download and print your poster and stick it on your desk, front door of your outlet!'
        leftContent={
          <>
            <button
              type='button'
              className='download-button'
              onClick={() => [
                setSpinPoster(true),
                downloadQrPdf("a3", "window"),
              ]}
            >
              {spinPoster ? (
                <Spin />
              ) : (
                <>
                  <img src={Download} alt='' width='16px' height='16px' />
                  Download{" "}
                </>
              )}
            </button>
          </>
        }
        rightContent={
          <>
            <div className='poster-wrapper'>
              <img
                src={client.io.io.uri + props.userInfo.banner}
                alt=''
                width='100%'
                height='auto'
              />
              <div className='poster-header-container'>
                <img
                  src={client.io.io.uri + props.userInfo.logo}
                  alt=''
                  width='50px'
                  height='50px'
                />
                <div className='poster-header-wrapper'>
                  <div className='poster-header-text'>
                    {props.userInfo.username}
                  </div>
                  <div className='poster-header-message'>
                    Scan now & start enjoying the rewardz
                  </div>
                </div>
              </div>
              <div className='poster-body-container'>
                <div className='poster-body-icons'>
                  <img src={PDFSignup} alt='' width='40px' height='40px' />
                  <img src={PDFEnjoy} alt='' width='40px' height='40px' />
                  <img src={PDFScan} alt='' width='40px' height='40px' />
                </div>

                <div id='svg-1' className='poster-body-qr' />

                <div className='poster-body-text'>
                  Or enter URL in Browser
                  <b>
                    {process.env.REACT_APP_BASE_URL + props.userInfo.username}
                  </b>
                </div>
              </div>

              <div className='poster-footer-container'>
                <img src={LogoWhite} width='50px' alt='25px' />
              </div>
            </div>
          </>
        }
        align='flex-end'
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeComponent);
