import React, { useState, useContext, useEffect } from "react";
import { InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import parse from "html-react-parser";
import moment from "moment";
import { Input } from "antd";
import { cloneDeep, isEmpty } from "lodash";
import client from "feathers.js";
import getSymbolFromCurrency from "currency-symbol-map";

import StyledQuill from "components/Elements/StyledQuill";
import StyledButton from "components/Elements/StyledButton";
import StyledRadio from "components/Elements/StyledRadio";
import ModalLanuch from "components/Extra/ModalLanuch";
import { DataContext } from "contexts/DataContextContainer";
import { PageContainer } from "../../style";
import { PreviewContainer } from "../../styleEdit";
import { Decrement, Increment, Success } from "assets/svg";
import Datepicker from "../../../../../components/Datepicker";
import StepsCounter from "components/StepsCounter";
import QuestionCard from "components/QuestionCard";

const durationOptions = [
  {
    value: "days",
    label: "Day(s)",
  },
  {
    value: "weeks",
    label: "Week(s)",
  },
  {
    value: "months",
    label: "Month(s)",
  },
  {
    value: "years",
    label: "Year(s)",
  },
];

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      // { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
  ],
};

const Edit = (props) => {
  const { setLoading, showToast } = useContext(DataContext);
  const [title, setTitle] = useState("");
  const [count, setCount] = useState(1);
  const [cost, setCost] = useState(1);
  const [periodStart, setPeriodStart] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);
  const [campaignValidity, setCampaignValidity] = useState(1);
  const [campaignValidityUnit, setCampaignValidityUnit] = useState("months");
  const [canEdit, setCanEdit] = useState(false);
  const [tos, setTos] = useState("");
  const [title_count, setTitle_count] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showSuccessCreate, setShowSuccessCreate] = useState(false);

  const description = [
    "Give your package a name",
    "Set package availabillity period",
    "Set package usage",
    "Set package usage validity",
    "Set package price",
    "Add extra Terms & Conditions",
  ];

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleTitle = (value) => {
    if (value.length <= 50) {
      setTitle(value);
      setTitle_count(value.length);
    }
  };

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setPeriodStart(dates[0]);
    setPeriodEnd(dates[1]);
  };

  const modalCallback = () => {
    if (canEdit) {
      if (title.trim() === "" || !periodStart || !periodEnd) {
        showToast("error", "Please fill all necessary information");
      } else {
        setLoading(true);
        if (props.action === "edit") {
          const campaignDetail = {
            title,
            count,
            period_start: periodStart.toDate(),
            period_end: periodEnd.toDate(),
            campaign_validity: campaignValidity,
            campaign_validity_unit: campaignValidityUnit,
            cost,
            tos,
          };

          client
            .authenticate()
            .then(() => {
              return client.service("package-purchases").find({
                query: {
                  packageId: props.data._id,
                  merchantId: props.userInfo._id,
                  $limit: 1,
                },
              });
            })
            .then((res) => {
              if (res.data.length > 0) {
                setLoading(false);
                setCanEdit(false);
                showToast(
                  "error",
                  "Customer already purchased this Package! Not allow to edit!"
                );
              } else {
                return client
                  .service("packages")
                  .patch(props.data._id, campaignDetail)
                  .then((res) => {
                    let cloneData = cloneDeep(props.campaignData);
                    let findKey = cloneData.findIndex(
                      (e) => e._id === props.data._id
                    );
                    if (findKey !== -1) {
                      res.totalCollected = cloneData[findKey].totalCollected;
                      res.totalRedeemed = cloneData[findKey].totalRedeemed;
                      cloneData[findKey] = res;
                    }

                    props.fetchCampaignInfo();
                    props.setCampaignData(cloneData);

                    setLoading(false);
                    setCanEdit(true);
                    showToast("success", "Campaign is updated successfully");
                    props.back();
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              showToast("error", err.message);
            });
        } else {
          const campaignDetail = {
            // merchant_id: props.currentMerchant._id,
            // campaign_type: 'package',
            userId: props.userInfo._id,
            title,
            count,
            period_start: periodStart.toDate(),
            period_end: periodEnd.toDate(),
            campaign_validity: campaignValidity,
            campaign_validity_unit: campaignValidityUnit,
            cost,
            tos,
          };

          client
            .authenticate()
            .then(() => {
              return client.service("packages").create(campaignDetail);
            })
            .then((res) => {
              let cloneData = cloneDeep(props.campaignData);
              res.totalCollected = 0;
              res.totalRedeemed = 0;

              props.fetchCampaignInfo();
              props.setCampaignData(cloneData.concat(res));

              setLoading(false);
              showToast("success", "Campaign is created successfully");
              props.back();
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              showToast("error", err.message);
            });
        }
      }
    } else {
      showToast(
        "error",
        "Customer already purchased this Package! Not allow to edit!"
      );
    }
  };

  const renderIcons = (value, setValue) => {
    const incrementCounter = () => {
      setValue(value + 1);
    };
    const decrementCounter = () => {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    };
    return (
      <div className='inc-dec-wrapper'>
        <button
          type='button'
          className='increment-button'
          onClick={() => incrementCounter()}
        >
          <img
            src={Increment}
            alt=''
            width='20px'
            height='20px'
            draggable={false}
          />
        </button>
        <button
          type='button'
          className='decrement-button'
          onClick={() => decrementCounter()}
        >
          <img
            src={Decrement}
            alt=''
            width='20px'
            height='18px'
            draggable={false}
          />
        </button>
      </div>
    );
  };

  const onKeyPressedChecking = (event, value, setValue) => {
    if (event.key === "ArrowUp") {
      setValue(value + 1);
    } else if (event.key === "ArrowDown") {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    }
  };

  const inputChecking = (value, setValue) => {
    const cleanedValue = String(value)
      .replace(/[^0-9]/g, "")
      .replace(/^0+/, "");

    const newValue = cleanedValue || "0";
    const parsedValue = parseInt(newValue);

    setValue(parsedValue);
  };

  const onBackQuestion = () => {
    if (currentStep === 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onNextQuestion = () => {
    if (currentStep === 1 && title.length > 0) {
      setCurrentStep(2);
    } else if (currentStep === 2 && periodStart && periodEnd) {
      setCurrentStep(3);
    } else if (currentStep === 3 && count > 0) {
      setCurrentStep(4);
    } else if (currentStep === 4 && campaignValidity > 0) {
      setCurrentStep(5);
    } else if (currentStep === 5 && cost > 0) {
      setCurrentStep(6);
    } else if (currentStep === 6) {
      setShowSuccessCreate(true);
    }
  };

  const renderQuestionCard = (q, d, c) => {
    return (
      <QuestionCard
        question={q}
        descriptions={<div className='description-text'>{d}</div>}
        totalQuestion={6}
        currentActive={currentStep}
        onClickBackButton={onBackQuestion}
        onClickNextButton={onNextQuestion}
        noPaginator
        questionHeight='auto'
        answerHeight='100px'
        children={c}
      />
    );
  };

  const renderFirstTimeEditContent = () => {
    return (
      <div className='first-time-edit-content'>
        <div className='first-time-step-container'>
          <div className='first-time-step-wrapper'>
            <StepsCounter
              currentStepNumber={currentStep}
              stepDescriptions={description}
              left='48%'
            />
          </div>
        </div>

        <div className='first-time-step-details-container'>
          {currentStep === 1 &&
            renderQuestionCard(
              "What will be the reward for this package?",
              "Provide an interesting promotion title which will capture customer's attention.",
              <div className='input-container'>
                <Input
                  size='large'
                  placeholder='Get a free cup of coffee worth $6'
                  value={title}
                  onChange={(e) => handleTitle(e.target.value)}
                  className='first-time-input'
                />
                <p className='answer-input-description'>
                  Characters ({title.trim().length}/50)
                </p>
              </div>
            )}
          {currentStep === 2 &&
            renderQuestionCard(
              "Set package availability period",
              "Set the start and end date for this package period.",
              <>
                <Datepicker
                  periodStart={periodStart}
                  periodEnd={periodEnd}
                  onChange={onChangeCampaignPeriod}
                  noLabel
                />
              </>
            )}
          {currentStep === 3 &&
            renderQuestionCard(
              "How many times can customers use this package?",
              "Specify the number of times this package can be used.",
              <div className='input-container'>
                <div className='input-container'>
                  Package Usage
                  <Input
                    type='text'
                    className='first-time-input'
                    size='large'
                    value={count}
                    onKeyDown={(e) => onKeyPressedChecking(e, count, setCount)}
                    onChange={(e) =>
                      count <= 100 && inputChecking(e.target.value, setCount)
                    }
                    onBlur={() => count === 0 && setCount(1)}
                  />
                  {renderIcons(count, setCount)}
                </div>
                <div className='number-preset'>
                  <button
                    className={count === 3 ? "active" : ""}
                    onClick={() => setCount(3)}
                  >
                    3
                  </button>
                  <button
                    className={count === 5 ? "active" : ""}
                    onClick={() => setCount(5)}
                  >
                    5
                  </button>
                  <button
                    className={count === 10 ? "active" : ""}
                    onClick={() => setCount(10)}
                  >
                    10
                  </button>
                  <button
                    className={count === 20 ? "active" : ""}
                    onClick={() => setCount(20)}
                  >
                    20
                  </button>
                  <button
                    className={
                      count !== 3 && count !== 5 && count !== 10 && count !== 20
                        ? "active"
                        : ""
                    }
                    onClick={() => setCount(1)}
                  >
                    Others
                  </button>
                </div>
              </div>
            )}
          {currentStep === 4 &&
            renderQuestionCard(
              "What is the duration of the package's validity starting from the date of purchase?",
              "Provide the period of time the package stays valid the moment a customer makes a purchase.",
              <div className='input-container'>
                <div className='input-container'>
                  <Input
                    className='first-time-input'
                    type='text'
                    size='large'
                    value={campaignValidity}
                    onKeyDown={(e) =>
                      onKeyPressedChecking(
                        e,
                        campaignValidity,
                        setCampaignValidity
                      )
                    }
                    onChange={(e) =>
                      inputChecking(e.target.value, setCampaignValidity)
                    }
                    onBlur={() =>
                      campaignValidity === 0 && setCampaignValidity(1)
                    }
                  />
                  {renderIcons(campaignValidity, setCampaignValidity)}
                </div>
                <div className='number-preset'>
                  <button
                    className={campaignValidityUnit === "days" ? "active" : ""}
                    onClick={() => setCampaignValidityUnit("days")}
                  >
                    Day(s)
                  </button>
                  <button
                    className={campaignValidityUnit === "weeks" ? "active" : ""}
                    onClick={() => setCampaignValidityUnit("weeks")}
                  >
                    Week(s)
                  </button>
                  <button
                    className={
                      campaignValidityUnit === "months" ? "active" : ""
                    }
                    onClick={() => setCampaignValidityUnit("months")}
                  >
                    Month(s)
                  </button>
                  <button
                    className={campaignValidityUnit === "years" ? "active" : ""}
                    onClick={() => setCampaignValidityUnit("years")}
                  >
                    Year(s)
                  </button>
                </div>
              </div>
            )}
          {currentStep === 5 &&
            renderQuestionCard(
              "How much is the package price?",
              "Provide the price for the package.",
              <div className='input-container'>
                <div className='input-container'>
                  <InputGroup
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      flexWrap: "nowrap",
                    }}
                  >
                    <InputGroup.Text
                      style={{
                        width: "10%",
                        justifyContent: "center",
                        borderRadius: "8px 0 0 8px",
                      }}
                    >
                      {getSymbolFromCurrency(props.userInfo.currency)}
                    </InputGroup.Text>
                    <input
                      type='text'
                      className='input'
                      size='large'
                      style={{ borderRadius: "0 8px 8px 0" }}
                      value={Number(cost).toString()}
                      onWheel={(event) => event.currentTarget.blur()}
                      onKeyDown={(e) => onKeyPressedChecking(e, cost, setCost)}
                      onChange={(e) => inputChecking(e.target.value, setCost)}
                      onBlur={() => cost === 0 && setCost(1)}
                    />
                    {renderIcons(cost, setCost)}
                  </InputGroup>
                </div>
                <div className='number-preset'>
                  <button
                    className={cost === 3 ? "active" : ""}
                    onClick={() => setCost(3)}
                  >
                    3
                  </button>
                  <button
                    className={cost === 5 ? "active" : ""}
                    onClick={() => setCost(5)}
                  >
                    5
                  </button>
                  <button
                    className={cost === 10 ? "active" : ""}
                    onClick={() => setCost(10)}
                  >
                    10
                  </button>
                  <button
                    className={cost === 20 ? "active" : ""}
                    onClick={() => setCost(20)}
                  >
                    20
                  </button>
                  <button
                    className={
                      cost !== 3 && cost !== 5 && cost !== 10 && cost !== 20
                        ? "active"
                        : ""
                    }
                    onClick={() => setCost(1)}
                  >
                    Others
                  </button>
                </div>
              </div>
            )}
          {currentStep === 6 &&
            renderQuestionCard(
              <>
                <div>Final step!</div>
                <div>Any other terms & conditions?</div>
              </>,
              <ul className='description-list'>
                <li>
                  Campaign Period{" "}
                  {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""}{" "}
                  - {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
                </li>
                <li>
                  This package is valid for {campaignValidity}{" "}
                  {campaignValidityUnit.slice(0, -1)}(s) upon first purchase
                </li>
              </ul>,
              <StyledQuill
                readOnly={false}
                modules={quillModules}
                value={tos}
                onChange={async (value) => {
                  setTos(value);
                }}
              />
            )}
        </div>
      </div>
    );
  };

  const successCreate = () => {
    return (
      <div className='first-time-edit-content'>
        <div className='success-container'>
          <img src={Success} alt='' width='50px' height='50px' />
          Almost Done!
          <StyledButton
            title='Preview'
            onClick={toggleModal}
            type='submit'
            className='primary-button'
            bgcolor={"#567877"}
          />
        </div>
      </div>
    );
  };

  const renderEditContent = () => {
    return (
      <>
        <div className='edit-content'>
          <div className='input-container'>
            Package Name
            <Input
              size='large'
              placeholder='Get a free cup of coffee worth $6'
              value={title}
              onChange={(e) => handleTitle(e.target.value)}
              className='input'
            />
          </div>

          <div className='input-container'>
            Package Availability
            <Datepicker
              periodStart={periodStart}
              periodEnd={periodEnd}
              onChange={onChangeCampaignPeriod}
              noLabel
            />
          </div>

          <div className='input-container'>
            Package Usage
            <Input
              type='text'
              className='input'
              size='large'
              value={count}
              onKeyDown={(e) => onKeyPressedChecking(e, count, setCount)}
              onChange={(e) =>
                count <= 100 && inputChecking(e.target.value, setCount)
              }
              onBlur={() => count === 0 && setCount(1)}
            />
            {renderIcons(count, setCount)}
          </div>

          <div className='input-container'>
            <div className='input-container'>
              Vadility of package usage (from date of purchase)?
              <Input
                className='input'
                type='text'
                size='large'
                value={campaignValidity}
                onKeyDown={(e) =>
                  onKeyPressedChecking(e, campaignValidity, setCampaignValidity)
                }
                onChange={(e) =>
                  inputChecking(e.target.value, setCampaignValidity)
                }
                onBlur={() => campaignValidity === 0 && setCampaignValidity(1)}
              />
              {renderIcons(campaignValidity, setCampaignValidity)}
            </div>
            <StyledRadio
              options={durationOptions}
              value={campaignValidityUnit}
              onChange={(e) => setCampaignValidityUnit(e.target.value)}
              className='col-md-6'
            />
          </div>

          <div className='input-container'>
            Package price
            <InputGroup
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <InputGroup.Text
                style={{
                  width: "10%",
                  justifyContent: "center",
                  borderRadius: "8px 0 0 8px",
                }}
              >
                {getSymbolFromCurrency(props.userInfo.currency)}
              </InputGroup.Text>
              <input
                type='text'
                className='input'
                size='large'
                style={{ borderRadius: "0 8px 8px 0" }}
                value={Number(cost).toString()}
                onWheel={(event) => event.currentTarget.blur()}
                onKeyDown={(e) => onKeyPressedChecking(e, cost, setCost)}
                onChange={(e) => inputChecking(e.target.value, setCost)}
                onBlur={() => cost === 0 && setCost(1)}
              />
              {renderIcons(cost, setCost)}
            </InputGroup>
          </div>

          <div className='input-container'>
            Additional Terms and Conditions
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <li>
                Campaign Period{" "}
                {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""} -{" "}
                {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
              </li>
              <li>
                This package is valid for {campaignValidity}{" "}
                {campaignValidityUnit.slice(0, -1)}(s) upon first purchase
              </li>
            </ul>
            <StyledQuill
              readOnly={false}
              modules={quillModules}
              value={tos}
              onChange={async (value) => {
                setTos(value);
              }}
            />
          </div>

          <div className='button-wrapper-container'>
            <button
              type='button'
              onClick={() => props.back()}
              className='cancel-button'
            >
              Back
            </button>
            <StyledButton
              title='Preview'
              onClick={toggleModal}
              type='submit'
              className='primary-button'
              bgcolor={"#567877"}
            />
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (props.data._id) {
      client
        .authenticate()
        .then(() => {
          return client.service("package-purchases").find({
            query: {
              packageId: props.data._id,
              merchantId: props.userInfo._id,
              $limit: 1,
            },
          });
        })
        .then((res) => {
          if (res.data.length > 0) {
            showToast(
              "error",
              "Customer already purchased this Package! Not allow to edit!"
            );
          } else {
            setCanEdit(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    } else {
      setCanEdit(true);
    }
    /* eslint-disable-next-line */
  }, [props.data._id, props.userInfo._id]);

  useEffect(() => {
    if (!isEmpty(props.data)) {
      setTitle(props.data.title);
      setTitle_count(props.data.title.length);
      setCount(props.data.count);
      setPeriodStart(moment(new Date(props.data.period_start), "DD/MM/YYYY"));
      setPeriodEnd(moment(new Date(props.data.period_end), "DD/MM/YYYY"));
      setCampaignValidity(props.data.campaign_validity);
      setCampaignValidityUnit(props.data.campaign_validity_unit);
      setCost(props.data.cost);
      setTos(props.data.tos);
    }
  }, [props.data._id, props.data]);

  return (
    <PageContainer>
      {props.firstTime
        ? showSuccessCreate
          ? successCreate()
          : renderFirstTimeEditContent()
        : renderEditContent()}

      <ModalLanuch
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        campaign='package'
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Campaign Preview
            </span>
          </>
        }
        body={
          <PreviewContainer>
            <div className='preview-wrapper'>
              <div className='preview'>
                {/* <span>Preview</span> */}
                <div className='details'>
                  <div className='title'>
                    <h4>{title ? title : "--"}</h4>
                  </div>
                  <div className='stamps'>
                    <h4>{`Package Expiry : ${campaignValidity} ${campaignValidityUnit.slice(
                      0,
                      -1
                    )}(s) from first Stamp`}</h4>
                    <div className='cards'>
                      {[...Array(parseInt(count ? count : 1) + 1).keys()].map(
                        (item) => (
                          <div className='card' key={item}>
                            <span className='card-no'>{item + 1}</span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className='terms'>
                    <h4>Terms & Condition</h4>
                    <ul style={{ marginBottom: 0 }}>
                      <li>
                        Campaign Period{" "}
                        {periodStart
                          ? moment(periodStart).format("DD/MM/YYYY")
                          : ""}{" "}
                        -{" "}
                        {periodEnd
                          ? moment(periodEnd).format("DD/MM/YYYY")
                          : ""}
                      </li>
                      <li>
                        This package is valid for {campaignValidity}{" "}
                        {campaignValidityUnit.slice(0, -1)}(s) upon first
                        purchase
                      </li>
                    </ul>
                    {<div>{parse(tos)}</div>}
                  </div>
                </div>
              </div>
            </div>
          </PreviewContainer>
        }
        canEdit={canEdit}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
