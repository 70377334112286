import { breakpoints } from "style";
import styled from "styled-components";

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  h2 {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #3B3054;    
  }
  h4 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
  }
  ol {
    padding-left: 0px;
    list-style-type: none;
  }
  span, ol li {
    font-weight: 400;
    font-size: 14px;
  }
  input {
    margin-top: 8px;
    margin-bottom: 20px;
    // border: 1px dashed #3b7779;
    border-radius: .25rem;
    padding: 12px 10px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
