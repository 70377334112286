/* eslint-disable */
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { ActionMenuContainer } from "../../Admin/style";
import moment from 'moment';

const menu = (record, setAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item key="0" onClick={() => {
        setAction('edit')
        setDataEdit(record)
        }}>
        <FormOutlined /> Edit
      </Menu.Item>
      {/* <Menu.Item key="1">
        <PauseOutlined /> Pause
      </Menu.Item>
      <Menu.Item key="2">
        <FileTextOutlined /> Details
      </Menu.Item> */}
    </ActionMenuContainer>
  )
}

const columns = (setAction, setDataEdit) => [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (record, records) => <span>{(records.lastName || "-") + " " + (records.firstName || "-")}</span>
    },
    {
      title: 'Login as',
      dataIndex: 'loginAs',
      key: 'loginAs',
      render: (record, records) => {
        if(records.googleId){
          return "Google"
        }else if(records.facebookId){
          return "Facebook"
        }else if(records.twitterId){
          return "Twitter"
        }else{
          return "Email"
        }
      }
    },
    {
      title: 'Status',
      key: 'set_done',
      dataIndex: 'set_done',
      render: set_done => set_done !== 'done'? <span className='text-capitalize'>Verifying</span>: <span>-</span>
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   align: 'center',
    //   render: (record) => (
    //     <Dropdown overlay={menu(record, setAction, setDataEdit)} trigger={['click']}>
    //       <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
    //         <EllipsisOutlined style={{fontSize: 20}}/>
    //       </a>
    //     </Dropdown>
    //   ),
    // },
  ]

export default columns;
