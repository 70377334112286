const axios = require('axios');

const getBranches = (params) => axios
  .get(`${process.env.REACT_APP_API_URL}branches`, {
    params,
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then((response) => response?.data)
  .catch((error) => error);

module.exports = {
  getBranches,
};
