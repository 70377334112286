import { Menu } from "antd";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 80px;
  margin: 50px 0 0 0;
  .edit-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    gap: 32px;

    @media (max-width: 1325px) {
      width: 80%;
    }
  }
  .row-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1325px) {
      gap: 10px;
    }
  }
  .status-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;

    @media (max-width: 1325px) {
      gap: 5px;
    }
  }
  .inc-dec-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: absolute;
    bottom: 11px;
    right: 11px;
  }
  .increment-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 16px;
    height: 11px;
  }
  .decrement-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 16px;
    height: 11px;
  }
  .decrement-button:hover,
  .increment-button:hover {
    background: blue;
  }
  .primary-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 194px;
    padding: 13.5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    background: #567877;
    height: 48px;
    border-radius: 10px;

    @media (max-width: 1325px) {
      max-width: 144px;
    }
  }
  .cancel-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 144px;
    padding: 13.5px;
    color: #6b7280;
    font-size: 14px;
    font-weight: 500;
    background: white;
    border: 1px solid #eaecee;
    height: 48px;
    border-radius: 10px;
  }
  .filter-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 144px;
    padding: 13.5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    background: #567877;
    height: 48px;
    border-radius: 10px;

    @media (max-width: 1325px) {
      max-width: 94px;
    }
  }
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    position: relative;

    * > li {
      color: #6b7280;
    }
  }
  .input-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid #eaecf0;
    background: white;
    border-radius: 10px;
    position: relative;
  }
  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    cursor: pointer;
  }
  .remove-file-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .input {
    width: 100%;
    padding: 11.1px 20px;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    background: white;
  }
  .input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .left-content-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #a8a6a6;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
    max-width: 350px;
  }
  .search-bar {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 240px;
    max-width: 240px;
    background: white;
    border: 1px solid #eaecee;
    border-radius: 10px;
    height: 48px;

    @media (max-width: 1720px) {
      min-width: 180px;
      max-width: 180px;
    }

    @media (max-width: 1325px) {
      min-width: 165px;
      max-width: 165px;
    }
  }
  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .button-wrapper-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
  }
  .form-group,
  .input-group {
    display: flex;
    align-items: center;
    width: auto;
    background: white;
    height: 48px;
  }
  .input-group-text,
  .ant-picker-range {
    height: 100%;
  }
  .status-active {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    background: #ecfdf5;
    color: #064e3b;
    font-size: 14px;
    height: 20px;
    width: 60px;
    font-weight: 450;
  }
  .status-inactive {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    background: #fef2f2;
    color: #b85d5d;
    font-size: 14px;
    height: 20px;
    width: 60px;
    font-weight: 450;
  }
  .custom-modal-header {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: black;
  }
  .custom-modal-text {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
    color: #567877;
  }
  .custom-modal-text-alt {
    color: #6b7280;
  }
  .input-with-icon {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }
  .dropdown-icon {
    position: absolute;
    top: 12px;
    right: 2%;
    transform: translate(-2%);
  }
  .first-time-edit-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: white;
    border: 1px solid #eaecee;
    border-radius: 20px;
    box-shadow: 5px 10px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      5px -5px 10px rgba(220, 220, 220, 0);
  }
  .first-time-step-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 450px;
    width: 100%;
    max-width: 350px;
    margin: 0 0 0 50px;
    padding: 50px 0;
    border-right: 1px solid #eaecee;
  }
  .first-time-step-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .first-time-step-details-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin: 0 70px;

    @media (max-width: 1325px) {
      margin: 0 25px;
      min-width: 430px;
    }
  }
  .first-time-input {
    width: 100%;
    padding: 11.1px 10px;
    border: none;
    border-bottom: 1px solid #eaecf0;
    background: white;
  }
  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: black;
    font-size: 40px;
    font-weight: 500;
    padding: 200px;
  }
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table-thead > tr > th {
    background: #e7eaee;
  }
  .ant-table-row {
    background: white;
  }
  .ant-table-cell::before {
    display: none;
  }
  .ant-pagination {
    display: flex;
    align-items: center;
    width: auto;
    gap: 16px;
    margin: 50px 0 0 0;
  }
  .ant-pagination-prev {
    margin-right: 50px;
  }
  .ant-pagination-next {
    margin-left: 50px;
  }
  .ant-pagination-prev,
  .ant-pagination-item,
  .ant-pagination-next {
    background: white;
    color: #344051;
    border: 1px solid #f2f4f7;
    border-radius: 8px;
  }
  .ant-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-pagination-item-active {
    background: #f2f4f7;
    color: #344051;
    border: 1px solid #344051;
  }
  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #8b909a;
    border-radius: 8px;
  }
  .ant-spin-dot-item {
    background: white;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: 42px;
    border-radius: 10px;
  }
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 42px;
  }
  .access-denied {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h1 {
      color: #000;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
    p {
      margin: 0;
      color: #a8a6a6;
      font-size: 20px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
  }
  .btn-upgrade {
    color: #f2f4f7;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.09px;
    display: flex;
    width: 160px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    gap: 8px;
    border-radius: 10px;
    background: #567877;
  }
  .start-guide-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    min-height: 458px;
    background-color: #fafbfc;
    margin: 10px 0 0 0;
    border-radius: 20px;
    padding: 50px 60px;
    border: 1px solid #eaecee;
    box-shadow: 5px 10px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      5px -5px 10px rgba(220, 220, 220, 0);
  }
  .step-container {
    width: 100%;
    max-width: 350px;
  }
  .step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 335px;
    min-height: 365px;
    border-right: 1px solid #eaecee;
  }
  .step-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    height: 100%;
    min-height: 356px;
    padding: 30px 50px;
  }
  .step-details-header {
    font-size: 25px;
    font-weight: 500;
  }
  .step-details-text {
    font-size: 18px;
    line-height: 25px;
  }
  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #567877;
    padding: 10px;
    width: 100%;
    max-width: 160px;
    border-radius: 10px;
  }
  .button-next {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 10px;
    width: 100%;
    max-width: 160px;
    border-radius: 10px;
    border: 1px solid #567877;
  }
  .quill {
    width: 100%;
    border: 1px solid #eaecee;
    border-radius: 10px;
    position: relative;
    background: white;
    height: auto;
    min-height: 88px;
  }
  .ql-toolbar,
  .ql-container,
  .ql-snow {
    border: none;
  }
  .ql-toolbar {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .ql-toolbar.ql-snow {
    padding: 8px 8px 0 8px;
  }
  .points-campaign {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 550px;
  }
  .description-text {
    color: #6b7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .description-list {
    color: #6b7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding-left: 10px;
  }
  .answer-input {
    all: unset;
    width: 520px;
    border: unset;
    border-bottom: 1px solid #000;
    padding: 5px;
    margin-bottom: 15px;
    input::placeholder {
      color: #b6b9ce;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 111.111% */
      letter-spacing: 0.25px;
    }
  }
  .answer-input-description {
    color: #6b7280;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .number-preset {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;

    button {
      all: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #e7eaee;
      width: 78px;
      padding: 10px;
      font-size: 14px;

      &:hover {
        background: #e7eaee;
      }

      @media (max-width: 1325px) {
        width: 100%;
      }
    }
    .active {
      border: 1px solid #047857;
    }
  }
`;

export const ActionMenuContainer = styled(Menu)`
  border-radius: 4px;
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    span {
      margin-right: 12px;
    }
  }
`;
