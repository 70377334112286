import React from "react";
import { Modal } from "antd";
import {
    FaTimesCircle
  } from "react-icons/fa";
import { ModalContainer } from "./style"

const ModalPreview = (props) => {

  return ( 
    <>
    <Modal
        title={false}
        visible={props.modalVisible}
        onCancel={() => {
          props.toggleModal()
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        className="preview-modal"
        width="100%"
        // getContainer={() => document.getElementById("stamp-container")}
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <ModalContainer>
          
          <div className="header">
              {props.header}
          </div>

          <div className="body w-100">
              {props.body}
          </div>
          
          <div className='footer'>
            {/* <button onClick={() => props.toggleModal()} className='btn btnConfirm mr-1'>Cancel</button> */}
            <button onClick={() => props.toggleModal()} className='btn btnConfirm ml-1'>Close</button>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
}
 
export default ModalPreview;