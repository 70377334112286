/* eslint-disable */
import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { ActionMenuContainer } from "../style";
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'

const menu = (record, setAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item key="0" onClick={() => {
        setAction('edit')
        setDataEdit(record)
        }}>
        <FormOutlined /> Edit
      </Menu.Item>
      {/* <Menu.Item key="1">
        <PauseOutlined /> Pause
      </Menu.Item>
      <Menu.Item key="2">
        <FileTextOutlined /> Details
      </Menu.Item> */}
    </ActionMenuContainer>
  )
}

const columns = (setAction, setDataEdit) => [
    {
      title: 'Date/Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'Email',
      key: 'customer_email',
      // dataIndex: 'customer_email',
      render: (records) => records.userInfo.email? <span>{records.userInfo.email}</span>: <span>-</span>
    },
    {
      title: 'Payment Type',
      key: 'paymentType',
      render: (x) => 
      {
        if(x.paymentType === 'cash'){
          return <span>Over Counter</span>
        }else if(x.paymentType === 'card'){
          return <span>Credit Card</span>
        }else{
          return <span>Wallet</span>
        }
      }
    },
    {
      title: 'Purpose',
      key: 'purpose',
      render: (x) => 
      {
        if(x.creditDebit === 'credit'){
          return <span>Topup Wallet</span>
        }else{
          return <span>Payment</span>
        }
      }
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (x) => 
      {
        return (
          x.creditDebit === 'credit'? 
          <span className='text-success'>{'+ ' + getSymbolFromCurrency(x.currency) + " " + x.amount}</span>
          : 
          <span className='text-danger'>{'- ' + getSymbolFromCurrency(x.currency) + " " + x.amount}</span>
        )
      }
    },
    {
      title: 'Remarks',
      key: 'remarks',
      render: (x) => 
      {
        return (
          x.remark === 'upgrade'?
          <>
            <p className='text-capitalize m-0'>
              {
                x.fromSubscription + " -> " + x.toSubscription
              }
            </p>
            <p className='m-0'>
              {
                moment(x.substart).format("DD/MM/YYYY - HH:mm") + " / " + moment(x.subEnd).format("DD/MM/YYYY - HH:mm")
              }
            </p>
          </>
          : 
          '-'
        )
      }
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   align: 'center',
    //   render: (record) => (
    //     <Dropdown overlay={menu(record, setAction, setDataEdit)} trigger={['click']}>
    //       <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
    //         <EllipsisOutlined style={{fontSize: 20}}/>
    //       </a>
    //     </Dropdown>
    //   ),
    // },
  ]

export default columns;
