import { breakpoints, colors } from "style";
import styled from "styled-components";
import { Home1 } from "assets/images";

export const LoginContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    object-fit: cover;
  }
  .left-image {
    height: 100%;
    background-image: url(${Home1});
    background-size: cover;
    background-position: center;
  }
  .home-container {
    flex: 1;
  }
  .signup-form {
    width: 100%;
    min-width: 390px;
    max-width: 530px;
    padding: 25px 12px;
    display: flex;
    flex-direction: column;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #3B3054;
    }
    h4 {
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #979797;
    }
    .form-group {
      margin-bottom: .5rem;
    }
    .form-control {
      width: 171px;
      height: 48px;
      background: rgba(241, 244, 244, 0.68);
      opacity: 0.97;
      border-radius: 31px;
      width: 100%;
      &:focus, &:active {
        outline:0px !important;
        -webkit-appearance:none;
        box-shadow: none !important;
      }
    }
    .input-group {
      height: 48px;
      background: rgba(241,244,244,0.68);
      opacity: 0.97;
      border: 1px solid #ced4da;
      border-radius: 31px;
      .input-group-text {
        width: 15%;
        background: transparent;
      }
      .form-control {
        border: none;
        width: 85%;
        background: transparent;
      }
    }
    span {
      margin-top: 30px;
      align-self: center;
      color: ${colors.red};
      a {
        color: ${colors.red};
        text-decoration: underline;
        font-weight: bold;
      }
    }
    .country {
      button {
        height: 40px;
        font-weight: normal;
      }
      span {
        margin-top: 0;
      }
    }
    .input-group-feedback {
      display: block;
    }
  }
  .submit-button{
    border-radius: 8px;
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
