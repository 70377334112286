import { CalendarIcon } from "assets/images";
import moment from "moment";
import { DatePickerContainer } from "./style";
import { DatePicker } from "antd";

const CustomDatePicker = (props) => {
  const { periodStart, periodEnd, onChange, noLabel, isDisable, dateDisable } =
    props;
  const { RangePicker } = DatePicker;
  return (
    <>
      <DatePickerContainer noLabel={noLabel}>
        <div>
          {!noLabel && <p>Start Date</p>}
          <div className='answer-date-picker' />
          <img src={CalendarIcon} width={25} alt='' />
        </div>
        <div className='seperator' />
        <div>
          {!noLabel && <p>End Date</p>}
          <div className='answer-date-picker' />
          <img src={CalendarIcon} width={25} alt='' />
        </div>
        <RangePicker
          bordered={false}
          ranges={{
            Today: [moment(), moment()],
            "This Month": [moment().startOf("month"), moment().endOf("month")],
          }}
          disabled={isDisable}
          disabledDate={
            !dateDisable
              ? (current) => {
                  let customDate = moment().format("YYYY/MM/DD");
                  return current && current < moment(customDate, "YYYY/MM/DD");
                }
              : false
          }
          value={[periodStart, periodEnd]}
          onChange={onChange}
          className='range-picker'
        />
      </DatePickerContainer>
    </>
  );
};

export default CustomDatePicker;
