import React, { useState, useContext, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { PageContainer } from "./styleEdit";
import StyledButton from "components/Elements/StyledButton";
import CountryList from "country-list-with-dial-code-and-flag";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";
import classNames from "classnames";
import { cloneDeep, isEmpty } from "lodash";
import TextField from "@mui/material/TextField";
import moment from "moment";
import GoogleMapComponent from "components/Outlets/GoogleMapComponent";
import html2canvas from "html2canvas";

import client from "feathers.js";
import axios from "axios";
import { DropdownArrow } from "assets/svg";
import DetailsSection from "components/DetailsSection/RowContainer";

const format = "HH:mm";

const OutletSchema = Yup.object().shape({
  store_storeName: Yup.string().required("Required!"),
  // store_address1: Yup.string().when('physicalStore', {
  //   is: (e) => e === true || e === 'true',
  //   then: Yup.string().required("Required!"),
  // }),
  // store_address2: Yup.string().when('physicalStore', {
  //   is: (e) => e === true || e === 'true',
  //   then: Yup.string().required("Required!"),
  // }),
  // store_city: Yup.string().when('physicalStore', {
  //   is: (e) => e === true || e === 'true',
  //   then: Yup.string().required("Required!"),
  // }),
  // store_zip: Yup.string().when('physicalStore', {
  //   is: (e) => e === true || e === 'true',
  //   then: Yup.string().required("Required!"),
  // }),
  store_outletContactNumber: Yup.string().required("Required!"),
  store_outletEmail: Yup.string().required("Required!"),
});

const Edit = (props) => {
  const { setLoading, showToast } = useContext(DataContext);
  const [store_country, setStore_country] = useState("SG");
  const [store_phonePrefix, setStore_PhonePrefix] = useState("+65");
  const [workingHours, setWorkingHours] = useState({
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  });
  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(() => {
    if (!isEmpty(props.data)) {
      setStore_country(props.data.country);
      setStore_PhonePrefix(props.data.outletPhonePrefix);
      setWorkingHours(props.data.workingHours);
    }
  }, [props.data]);

  const addWorkingHour = (value) => {
    let cloneWorking = cloneDeep(workingHours);
    if (cloneWorking[value].length > 0) {
      cloneWorking[value] = [];
      setWorkingHours(cloneWorking);
    } else {
      cloneWorking[value].push({
        startTime: moment(new Date()).format(format),
        endTime: moment(new Date()).format(format),
      });
      setWorkingHours(cloneWorking);
    }
  };

  const handleHours = (value, x, timeAt) => {
    let cloneWorking = cloneDeep(workingHours);
    cloneWorking[x[0]][0][timeAt] = value;
    setWorkingHours(cloneWorking);
  };

  const captureImage = async () => {
    return await new Promise((resolve) => {
      html2canvas(document.querySelector("#google-map-capture"), {
        useCORS: true,
      }).then((canvas) => {
        var context = canvas.getContext("2d"); //context from originalCanvas

        var tmpCanvas = document.createElement("canvas");
        tmpCanvas.width = canvas.width;
        tmpCanvas.height = canvas.height;
        var context2 = canvas.getContext("2d"); //context from tmpCanvas

        var imageObj = new Image();

        imageObj.onload = function () {
          //setup: draw cropped image
          var sourceX = canvas.width / 2 - 120 / 2;
          var sourceY = canvas.height / 2 - 120 / 2;
          var sourceWidth = 120;
          var sourceHeight = 120;
          var destWidth = sourceWidth;
          var destHeight = sourceHeight;
          var destX = canvas.width / 2 - destWidth / 2;
          var destY = canvas.height / 2 - destHeight / 2;

          context2.drawImage(
            imageObj,
            sourceX,
            sourceY,
            sourceWidth,
            sourceHeight,
            destX,
            destY,
            destWidth,
            destHeight
          );
          var data = context2.getImageData(
            sourceX,
            sourceY,
            sourceWidth,
            sourceHeight
          );

          context.clearRect(0, 0, canvas.width, canvas.height); //clear originalCanvas
          canvas.width = sourceWidth;
          canvas.height = sourceHeight;

          context2.putImageData(data, 0, 0);

          resolve(canvas.toDataURL("image/png"));

          //memory!!!
          context.clearRect(0, 0, sourceWidth, sourceHeight); //clear originalCanvas
          context2.clearRect(0, 0, sourceWidth, sourceHeight); //clear tmpCanvas
          data = null;
          tmpCanvas = null;
          canvas = null;
          imageObj = null;
        };
        imageObj.src = tmpCanvas.toDataURL("image/png");
      });
    });
  };

  const uploadCanvasMap = (canvasMap) => {
    return axios
      .post(`${client.io.io.uri}uploadPublicImgAndDelete`, {
        _id: props.userInfo._id,
        fileData: canvasMap,
        deleteFileName: props.data.canvasMap,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const onPlaceChanged = (setFieldValue) => {
    if (autocomplete) {
      setFieldValue("place_id", autocomplete.getPlace().place_id);
      setFieldValue("adr_address", autocomplete.getPlace().adr_address);
      setFieldValue(
        "utc_offset_minutes",
        autocomplete.getPlace().utc_offset_minutes
      );
      setFieldValue(
        "formatted_address",
        autocomplete.getPlace().formatted_address
      );
      setFieldValue(
        "latitude",
        autocomplete.getPlace().geometry.location.lat()
      );
      setFieldValue(
        "longitude",
        autocomplete.getPlace().geometry.location.lng()
      );
      setFieldValue("google_map_url", autocomplete.getPlace().url);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const launch = async (value) => {
    setLoading(true);

    let canvasMap = "";
    // if(value.latitude && value.longitude){
    let captureCanvas = await captureImage();
    canvasMap = await uploadCanvasMap(captureCanvas);
    // }

    if (props.action === "edit") {
      let outlet = {
        storeName: value.store_storeName ? value.store_storeName : "",
        address1: value.store_address1 ? value.store_address1 : "",
        address2: value.store_address2 ? value.store_address2 : "",
        city: value.store_city ? value.store_city : "",
        zip: value.store_zip ? value.store_zip : "",
        country: store_country,
        outletPhonePrefix: store_phonePrefix,
        workingHours: workingHours,
        outletContactNumber: value.store_outletContactNumber
          ? value.store_outletContactNumber
          : "",
        outletEmail: value.store_outletEmail ? value.store_outletEmail : "",
        latitude: value.latitude ? value.latitude : "",
        longitude: value.longitude ? value.longitude : "",
        canvasMap: canvasMap,
        google_map_url: value.google_map_url ? value.google_map_url : "",
        place_id: value.place_id ? value.place_id : "",
        adr_address: value.adr_address ? value.adr_address : "",
        utc_offset_minutes: value.utc_offset_minutes
          ? value.utc_offset_minutes
          : "",
        formatted_address: value.formatted_address
          ? value.formatted_address
          : "",
      };

      client
        .authenticate()
        .then(() => {
          return client.service("outlets").patch(props.data._id, outlet);
        })
        .then((res) => {
          let cloneData = cloneDeep(props.campaignData);
          let findKey = cloneData.findIndex((e) => e._id === props.data._id);
          if (findKey !== -1) {
            cloneData[findKey] = res;
          }
          props.setCampaignData(cloneData);

          setLoading(false);
          showToast("success", "Outlet location is updated successfully");
          props.back();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    } else {
      let outlet = {
        storeName: value.store_storeName ? value.store_storeName : "",
        address1: value.store_address1 ? value.store_address1 : "",
        address2: value.store_address2 ? value.store_address2 : "",
        city: value.store_city ? value.store_city : "",
        zip: value.store_zip ? value.store_zip : "",
        country: store_country,
        outletPhonePrefix: store_phonePrefix,
        workingHours: workingHours,
        outletContactNumber: value.store_outletContactNumber
          ? value.store_outletContactNumber
          : "",
        outletEmail: value.store_outletEmail ? value.store_outletEmail : "",
        latitude: value.latitude ? value.latitude : "",
        longitude: value.longitude ? value.longitude : "",
        canvasMap: canvasMap,
        google_map_url: value.google_map_url ? value.google_map_url : "",
        place_id: value.place_id ? value.place_id : "",
        adr_address: value.adr_address ? value.adr_address : "",
        utc_offset_minutes: value.utc_offset_minutes
          ? value.utc_offset_minutes
          : "",
        formatted_address: value.formatted_address
          ? value.formatted_address
          : "",
      };

      client
        .authenticate()
        .then(() => {
          return client.service("outlets").create(outlet);
        })
        .then((res) => {
          let cloneData = cloneDeep(props.campaignData);
          props.setCampaignData(cloneData.concat(res));

          setLoading(false);
          showToast("success", "Campaign is created successfully");
          props.back();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message ? err.message : err);
        });
    }
  };

  return (
    <PageContainer>
      <Formik
        enableReinitialize
        validationSchema={OutletSchema}
        // validateOnChange={false}
        // validateOnBlur={false}
        onSubmit={launch}
        initialValues={{
          store_storeName: props.data.storeName ? props.data.storeName : "",
          store_address1: props.data.address1 ? props.data.address1 : "",
          store_address2: props.data.address2 ? props.data.address2 : "",
          store_city: props.data.city ? props.data.city : "",
          store_zip: props.data.zip ? props.data.zip : "",
          // store_country: props.data.country?props.data.country: "",
          store_outletContactNumber: props.data.outletContactNumber
            ? props.data.outletContactNumber
            : "",
          store_outletEmail: props.data.outletEmail
            ? props.data.outletEmail
            : "",
          latitude: props.data.latitude ? props.data.latitude : "1.3521",
          longitude: props.data.longitude ? props.data.longitude : "103.8198",
          google_map_url: props.data.google_map_url
            ? props.data.google_map_url
            : "",
          place_id: props.data.place_id ? props.data.place_id : "",
          adr_address: props.data.adr_address ? props.data.adr_address : "",
          utc_offset_minutes: props.data.utc_offset_minutes
            ? props.data.utc_offset_minutes
            : "",
          formatted_address: props.data.formatted_address
            ? props.data.formatted_address
            : "",
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <Form
            onSubmit={handleSubmit}
            id='form1'
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
              gap: "80px",
            }}
          >
            <DetailsSection
              leftHeader='Outlet Contact Details'
              leftDesc='Provide the details of your outlet. This enables customers to reach out for inquiries or assistance if required.'
              rightContent={
                <>
                  <div className='input-container'>
                    Outlet Name
                    <Form.Control
                      type='text'
                      name='store_storeName'
                      value={values.store_storeName}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        e.which === 13 && e.preventDefault();
                      }}
                      isInvalid={!!errors.store_storeName}
                      placeholder='Outlet Name  '
                      className='input'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.store_storeName}
                    </Form.Control.Feedback>
                  </div>

                  <div className='input-container'>
                    Outlet Email
                    <Form.Control
                      type='text'
                      name='store_outletEmail'
                      value={values.store_outletEmail}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        e.which === 13 && e.preventDefault();
                      }}
                      isInvalid={!!errors.store_outletEmail}
                      placeholder='Outlet Email'
                      className='input'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.store_outletEmail}
                    </Form.Control.Feedback>
                  </div>

                  <div className='right-content'>
                    <div
                      className='input-container'
                      style={{ maxWidth: "161px" }}
                    >
                      Country
                      <InputGroup>
                        <Form.Control
                          as='select'
                          aria-label='Default select example'
                          onChange={(e) => setStore_PhonePrefix(e.target.value)}
                          value={store_phonePrefix}
                          style={{
                            height: "48px",
                            padding: "0 10px",
                            appearance: "none",
                            borderRadius: "0.25rem",
                          }}
                        >
                          {CountryList.sort(function (a, b) {
                            if (
                              a.name === "Malaysia" ||
                              a.name === "Singapore"
                            ) {
                              return -1;
                            } else {
                              return a.name - b.name;
                            }
                          }).map((v, i) => {
                            return (
                              <option key={i} value={v.dial_code}>
                                {v.flag + " " + v.dial_code}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <div className='icon-wrapper'>
                          <img
                            src={DropdownArrow}
                            alt=''
                            width='20px'
                            height='20px'
                          />
                        </div>
                      </InputGroup>
                    </div>
                    <div className='input-container'>
                      Phone Number
                      <Form.Control
                        type='text'
                        name='store_outletContactNumber'
                        value={values.store_outletContactNumber}
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          e.which === 13 && e.preventDefault();
                        }}
                        isInvalid={!!errors.store_outletContactNumber}
                        placeholder='Phone Number'
                        className='input'
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.store_outletContactNumber}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              }
            />
            <DetailsSection
              leftHeader='Operating Hours'
              leftDesc='Specify the opening and closing times of your outlet. This information helps customers plan their visits accordingly'
              rightContent={
                <>
                  <div className='input-container'>
                    <div
                      className='right-content'
                      style={{ gap: "12px", width: "auto" }}
                    >
                      {Object.keys(workingHours).map((x, i) => {
                        return (
                          <>
                            <button
                              type='button'
                              className={classNames("day-button", {
                                checked: workingHours[x].length > 0,
                              })}
                              onClick={() => addWorkingHour(x)}
                            >
                              <span className='text-capitalize'>{x[0]}</span>
                            </button>
                          </>
                        );
                      })}
                    </div>
                    <div className='time-table'>
                      {Object.entries(workingHours).map((x, i) => {
                        if (x[1].length > 0) {
                          return (
                            <>
                              <div className='time-label'>
                                <div className='time-label-text'>
                                  Start Time
                                </div>
                                <div className='time-label-text'>End Time</div>
                              </div>
                              <div key={i} className='time-container'>
                                <div
                                  style={{
                                    minWidth: "90px",
                                  }}
                                >
                                  <span className='text-capitalize'>
                                    {x[0]}
                                  </span>
                                </div>
                                <div className='input-container-content'>
                                  <TextField
                                    type='time'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    // className='input-container-content'
                                    value={x[1][0].startTime}
                                    onChange={(e) =>
                                      handleHours(
                                        e.target.value,
                                        x,
                                        "startTime"
                                      )
                                    }
                                  />
                                </div>
                                <hr className='custom-hr' />
                                <TextField
                                  type='time'
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  className='input-container-content'
                                  value={x[1][0].endTime}
                                  onChange={(e) =>
                                    handleHours(e.target.value, x, "endTime")
                                  }
                                />
                              </div>
                            </>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                </>
              }
            />
            <DetailsSection
              leftHeader='Map Location'
              leftDesc='By inputting your location here, customers would be able to access google maps instantly'
              rightContent={
                <div
                  id='google-map-capture'
                  style={{ width: "100%", height: "300px" }}
                >
                  <GoogleMapComponent
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                    setFieldValue={setFieldValue}
                    latitude={values.latitude}
                    longitude={values.longitude}
                  />
                </div>
              }
            />
            <div
              className='row-container'
              style={{ justifyContent: "flex-end", gap: "12px" }}
            >
              <StyledButton
                sizeh={48}
                className='cancel-button'
                title='Cancel'
                bgcolor={"transparent"}
                type='button'
                onClick={() => props.back()}
              />
              <StyledButton
                sizeh={48}
                className='preview-button'
                bgcolor={"#567877"}
                title={"Save & Preview"}
                type='submit'
              />
            </div>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
