import React, { useState, useContext, useEffect } from 'react';
import { 
  EllipsisOutlined, 
  FormOutlined 
} from '@ant-design/icons';
import { ActionMenuContainer } from "../../Admin/style";
import { Dropdown, Menu, Table, Pagination } from 'antd';
import { PageContainer } from "../../Admin/style";
// import { Modal } from "antd";
import { connect } from 'react-redux'
import { DataContext } from "contexts/DataContextContainer";
import columns from './columns'
import EditChild from './EditChild';
import Edit from './Edit';

import client from "feathers.js"
import moment from 'moment';

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const AffiliateSubmissions = props => {
  const { title } = props

  const { setLoading, showToast } = useContext(DataContext);
  const [campaignData, setCampaignData] = useState([])
  const [ dataEdit, setDataEdit ] = useState({})
  const [ parentData, setParentData ] = useState({})
  const [ action, setAction ] = useState('all' ) //edit, detail, new
  const [ pagination, setPagination ] = useState({})

  useEffect(() => {
    getCampaignData(0, 1, props.userInfo._id)
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  const back = () => {
    setAction('all')
  }

  const getCampaignData = (skip, currentPage, userId) => {
    setLoading(true)
    client.authenticate()
    .then(() => {
      return client.service('affiliates').find({
        query: {
          checkMerchants: true,
          $skip: skip,
          $limit: PAGESIZE,
        }
      })
    })
    .then((res) => {
      setCampaignData(res.data)
      setPagination(paginationCount(res.total, currentPage))
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      if(err.name === "NotAuthenticated"){
            
      }else{
        showToast('error', err.message)
      }
    })
  }

  const expandedRowRender = (e) => {
    let data = []

    let filterData = campaignData.filter(item => item._id === e._id)
    if(filterData.length > 0){
      data = filterData[0].merchants
    }

    const columns = [
      {
        title: 'Signup ID',
        dataIndex: 'signup',
        key: 'signup',
        render: (record, records) => 
        <span>{records.username}</span>
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency'
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        render: (record, records) => 
        <span>{records.phonePrefix + " " + records.contact_phone}</span>
      },
      {
        title: 'Subscription',
        dataIndex: 'subscription',
        key: 'subscription',
        render: (record) => 
        <span className='text-capitalize'>{record}</span>
      },
      {
        title: 'Created Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (record) => 
          <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (record) => (
          <Dropdown overlay={() => {
            return (
              <ActionMenuContainer>
                <Menu.Item key="0" onClick={() => {
                  setAction('editChild')
                  setDataEdit(record)
                  setParentData(e)
                  }}>
                  <FormOutlined /> Edit
                </Menu.Item>
                {/* <Menu.Item key="1">
                  <PauseOutlined /> Pause
                </Menu.Item>
                <Menu.Item key="2">
                  <FileTextOutlined /> Details
                </Menu.Item> */}
              </ActionMenuContainer>
            )
          }} trigger={['click']}>
            <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
              <EllipsisOutlined style={{fontSize: 20}}/>
            </a>
          </Dropdown>
        ),
      },
    ];

    return <Table 
      rowKey="_id" 
      columns={columns} 
      dataSource={data} 
      pagination={false} 
    />
  }
  
  return (
    <PageContainer>
      {
      action === 'all' ?
        <>
          <div className='info-header'>
            <div className='title'>
              <h3>{title}</h3>
            </div>
            {/* <div className='plan-info'>
              <div className='plan maximum'>
                <span>maximum</span>
                <span>3</span>
              </div>
              <div className='plan active'>
                <span>Active Campaign</span>
                <span>3</span>
              </div>
              <div className='plan balance'>
                <span>Balance</span>
                <span>3</span>
              </div>
            </div> */}
          </div>
        
          <div className='page-container'>
           <Table
            rowKey="_id" 
            columns={columns(setAction, setDataEdit)} 
            dataSource={campaignData}
            expandable={{
              expandedRowRender,
            }}
            pagination={false}
          />
            <div className='d-flex justify-content-end w-100 mt-3'>
              <Pagination pageSize={PAGESIZE} total={pagination.total} showSizeChanger={false} onChange={(e) => {
                setPagination(paginationCount(pagination.total, e))
                getCampaignData((e-1)*PAGESIZE, e, props.userInfo._id)
              }} />
            </div>
          </div>
        </>
        :
        (
          action === 'edit'? (
            <Edit 
              userInfo={props.userInfo}
              campaignData={campaignData}
              setCampaignData={setCampaignData}
              action={action} 
              data={dataEdit} 
              back={back}
            />
          ): (
            <EditChild
              userInfo={props.userInfo}
              campaignData={campaignData}
              setCampaignData={setCampaignData}
              action={action} 
              data={dataEdit} 
              parentData={parentData}
              back={back}
            />
          )
        )
      }
    </PageContainer>
  );
}

const mapStateToProps = state => ({
  auth: state.role.auth,
  userId: state.role.details.user?state.role.details.user._id:'',
  username: state.role.details.user?state.role.details.user.username:'',
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateSubmissions);
