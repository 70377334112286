/* eslint-disable */
import { Menu, Dropdown } from "antd";
import { EllipsisOutlined, FormOutlined } from "@ant-design/icons";
import { ActionMenuContainer } from "../style";
import moment from "moment";
import { isEmpty } from "lodash";

const menu = (record, setAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item
        key='0'
        onClick={() => {
          setAction("edit");
          setDataEdit(record);
        }}
      >
        <FormOutlined /> Edit
      </Menu.Item>
    </ActionMenuContainer>
  );
};

const columns = (setAction, setDataEdit) => [
  {
    title: "Date/Time",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (record) => (
      <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    ),
  },
  {
    title: "Campaign",
    key: "title",
    render: (records) => {
      if (records.campaignType) {
        return <span>{records.title}</span>;
      } else if (!isEmpty(records.referralShareValue)) {
        return records.referralShareValue.title ? (
          <span>{records.referralShareValue.title}</span>
        ) : (
          <span>-</span>
        );
      } else if (!isEmpty(records.pointValue)) {
        return records.pointValue.title ? (
          <span>{records.pointValue.title}</span>
        ) : (
          <span>-</span>
        );
      } else {
        return records.voucherInfo.title ? (
          <span>{records.voucherInfo.title}</span>
        ) : (
          <span>-</span>
        );
      }
    },
  },
  {
    title: "Type",
    key: "type",
    render: (records) => {
      if (!isEmpty(records.referralShareValue)) {
        return <span>Referral</span>;
      } else if (!isEmpty(records.pointValue)) {
        return <span>Point</span>;
      } else {
        return <span>Voucher</span>;
      }
    },
  },
  {
    title: "Customer's Email",
    key: "customer_email",
    render: (records) =>
      records.campaignType ? (
        <span>{records.customer_email}</span>
      ) : (
        <span>{records.userInfo.email}</span>
      ),
  },
  {
    title: "Actions",
    key: "action",
    render: (records) =>
      records.campaignType ? (
        <span>
          {records.action === "redeem" || records.redeem ? "Redeemed" : "-"}
        </span>
      ) : (
        <span>Voucher Collect</span>
      ),
  },
  {
    title: "Voucher Expiry",
    key: "voucherExpiry",
    render: (records) =>
      records.campaignType ? (
        <span>-</span>
      ) : (
        <span>
          {records.voucherInfo
            ? moment(records.createdAt)
                .add(
                  parseInt(records.voucherInfo.campaign_validity),
                  records.voucherInfo.campaign_validity_unit
                )
                .format("DD/MM/YYYY - HH:mm")
            : moment(records.createdAt).format("DD/MM/YYYY - HH:mm")}
        </span>
      ),
  },
  {
    title: "Staff ID",
    key: "staffId",
    render: (records) =>
      records.campaignType ? <span>{records.repName}</span> : <span>-</span>,
  },
  {
    title: "Remarks",
    key: "remarks",
    dataIndex: "remarks",
    render: (record) => (record ? <span>{record}</span> : <span>-</span>),
  },
  // {
  //   title: 'Redeem Date',
  //   dataIndex: 'redeemDate',
  //   key: 'redeemDate',
  //   render: (record) => record?<span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>: <span>-</span>
  // },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   align: 'center',
  //   render: (record) => (
  //     <Dropdown overlay={menu(record, setAction, setDataEdit)} trigger={['click']}>
  //       <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
  //         <EllipsisOutlined style={{fontSize: 20}}/>
  //       </a>
  //     </Dropdown>
  //   ),
  // },
];

export default columns;
