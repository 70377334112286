import React from "react";
import { OverlayContainer } from "./style"

const OverlayLock = (props) => {

  return ( 
    <OverlayContainer>
        <div className="layer"></div>
        <div className="layer-body">
            {props.body}
        </div>
    </OverlayContainer>
  );
}
 
export default OverlayLock;