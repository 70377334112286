import styled from "styled-components";

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 50px;

  .left-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 350px;
    min-width: 350px;
    gap: 8px;
  }
  .left-content-header {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #191d23;
    font-weight: 650;
  }
  .left-content-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #a8a6a6;
  }
  .right-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
  }
`;
