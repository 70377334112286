import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justfy-content: center;
  align-items: center;
  .header {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #263238;
    margin-bottom: 12px;
  }
  .body {
    font-size: 14px;
    color: #575757;
  }
  .check-availability-again {
    margin-top: 40px;
    display: flex;
    width: 45%;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid #567877;
    background: #fff;
    color: #567877;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &:focus {
      box-shadow: unset;
    }
  }
  .confirm-btn {
    margin-top: 40px;
    display: flex;
    width: 50%;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background: #567877;
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &:focus {
      box-shadow: unset;
    }
    a {
      width: 100%;
      height: 100%;
      color: #ffffff;
      background: transparent;
      text-decoration: none;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 20px;
    .btnEmpty {
      // height: 40px;
      border-radius: 20px;
    }
    .btnConfirm {
      background: #3b7779;
      box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
      border-radius: 7px;
      font-weight: 700;
      font-size: 15px;
      color: #ffffff;
    }
    .btnCancel {
      background: #fdb256;
      box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
      border-radius: 7px;
      font-weight: 700;
      font-size: 15px;
      color: #ffffff;
    }
  }
`;

export const OverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  .layer {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    // margin-left: -20px;
    // border-radius: 12px;
    z-index: 9;
    opacity: 0.4;
    background: black;
    // border-radius: 16px;
    // box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    // backdrop-filter: blur(5px);
    // border: 1px solid rgba(255, 255, 255, 0.3);
    // margin-top: -20px;
  }
  .layer-body {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    margin: auto;
    top: 47%;
    transform: translate(0, -50%);
    text-align: center;
    h3 {
      font-size: 27px;
      font-weight: 600;
    }
  }
`;
