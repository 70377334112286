import React from "react";
import { Modal } from "antd";
import { FaTimesCircle } from "react-icons/fa";
import StyledButton from "components/Elements/StyledButton";
import { ModalContainer } from "./style";
import { IphonePreviewEmpty } from "assets/images";

const ModalLanuchEmpty = (props) => {
  const confirmModal = () => {
    props.toggleModal();
    props.modalCallback(true);
  };

  return (
    <>
      <Modal
        title={false}
        visible={props.modalVisible}
        onCancel={() => {
          props.toggleModal();
        }}
        centered
        closable={true}
        footer={false}
        // maskClosable={false}
        // getContainer={() => document.getElementById("stamp-container")}
        // width='80%'
        className='modal-preview-content'
        closeIcon={<FaTimesCircle className='close-icon' />}
      >
        <ModalContainer>
          <div className='header'>{props.header}</div>

          <div className='body w-100' style={{ position: "relative" }}>
            <img src={IphonePreviewEmpty} className='w-100' alt='' />
            <div className='campaign-empty-preview' style={{ ...props.style }}>
              <div
                style={{
                  transform: "scale(.703)",
                  transformOrigin: "39px 0",
                  height: 0,
                }}
              >
                {props.body}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => props.toggleModal()}
              className='check-availability-again'
            >
              Cancel
            </button>
            <button
              disabled={!props.canEdit}
              onClick={confirmModal}
              type='submit'
              className='confirm-btn'
            >
              Confirm
            </button>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default ModalLanuchEmpty;
