import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { Image } from "react-bootstrap";
import { HeaderContainer } from "./style";
import { UserLogo } from "assets/images";
import { FaAngleDown } from "react-icons/fa";

import {
  Profile,
  Password,
  Invoice,
  Support,
  Logout,
  Notification,
  TopupWallet,
} from "assets/svg";

import client from "feathers.js";
import { DataContext } from "contexts/DataContextContainer";

const AdminHeader = (props) => {
  const { userInfo } = props;
  const history = useHistory();
  const { location } = useContext(DataContext);

  const menuStyle = {
    display: "flex",
    alignItems: "center",
    gap: "7.5px",
    textDecoration: "none",
  };

  const menu = (
    <Menu>
      <Menu.Item key='businessProfile'>
        <a style={menuStyle} href='/admin/businessProfile'>
          <img src={Profile} alt='' width='16px' height='16px' />
          Business Profile
        </a>
      </Menu.Item>
      <Menu.Item key='editPassword'>
        <a style={menuStyle} href='/admin/account/details'>
          <img src={Password} alt='' width='16px' height='16px' />
          Edit Password
        </a>
      </Menu.Item>
      <Menu.Item key='wallet'>
        <a style={menuStyle} href='/admin/tools/wallet'>
          <img src={TopupWallet} alt='' width='16px' height='16px' />
          Top Up MR Wallet
        </a>
      </Menu.Item>

      <hr
        style={{
          border: "1px solid black",
          width: "100%",
          opacity: "5%",
          margin: "5px 0",
        }}
      />

      <Menu.Item key='topup'>
        <a style={menuStyle} href='/admin/billing'>
          <img src={Invoice} alt='' width='16px' height='16px' />
          View Invoices
        </a>
      </Menu.Item>
      <Menu.Item key='supportTicket'>
        <a
          style={menuStyle}
          target='_blank'
          rel='noopener noreferrer'
          href='https://wa.me/601110899091'
        >
          <img src={Support} alt='' width='16px' height='16px' />
          Support
        </a>
      </Menu.Item>

      <hr
        style={{
          border: "1px solid black",
          width: "100%",
          opacity: "5%",
          margin: "0",
        }}
      />

      <Menu.Item key='logout' onClick={() => props.signout()}>
        <div style={menuStyle}>
          <img src={Logout} alt='' width='16px' height='16px' />
          Log out
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <HeaderContainer>
      <div className='breadcrumb-header-text'>{location}</div>
      <div className='right w-100'>
        {userInfo.role !== "superadmin" && (
          <>
            <div className='d-none d-md-inline-block' style={{ float: "left" }}>
              <div
                className='profile-menu-wrapper'
                onClick={() => history.push("/admin/notification")}
              >
                <img
                  src={Notification}
                  alt=''
                  width='24px'
                  height='24px'
                  style={{ cursor: "pointer" }}
                />
                <hr className='custom-hr' />
              </div>
            </div>

            <div style={{ float: "left" }}>
              <Dropdown overlay={menu} trigger={["click"]} className='menu'>
                <div className='profile-menu-wrapper'>
                  <Image
                    src={
                      userInfo.logo
                        ? `${client.io.io.uri}${userInfo.logo}`
                        : UserLogo
                    }
                    className='admin-picture'
                  />
                  <div
                    className='info'
                    style={{
                      minWidth: 100,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      height: "100%",
                      gap: "6px",
                    }}
                  >
                    <p className='a'>Merchant ID</p>
                    <p className='b'>{userInfo.username || ""}</p>
                  </div>
                  <FaAngleDown style={{ marginLeft: "15px" }} />
                </div>
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </HeaderContainer>
  );
};

export default AdminHeader;
