import { ArrowIcon, ErrorBlueIcon } from "assets/images";
import styled from "styled-components";

const QuestionCard = (props) => {
  const {
    questionNumber,
    question,
    descriptions,
    totalQuestion,
    currentActive,
    onClickBackButton,
    onClickNextButton,
    noPaginator,
    questionHeight,
    answerHeight,
    children,
  } = props;

  const renderPaginator = () => {
    const paginator = [];

    Array.from(Array(totalQuestion)).forEach((item, index) => {
      paginator.push(
        <Eclipses key={index} active={index === currentActive - 1} />
      );
    });

    return paginator;
  };
  return (
    <QuestionCardWrapper>
      <QuestionWrapper questionHeight={questionHeight}>
        <QuestionNumber>{questionNumber}</QuestionNumber>
        <QuestionTitle>{question}</QuestionTitle>
        <QuestionDescriptionWrapper>
          <img width={16} src={ErrorBlueIcon} alt='' />
          <DescriptionText>
            <p>Description</p>
            {descriptions}
          </DescriptionText>
        </QuestionDescriptionWrapper>
      </QuestionWrapper>
      <AnswerWrapper answerHeight={answerHeight}>{children}</AnswerWrapper>
      <PaginatorWrapper>
        {noPaginator ? null : renderPaginator()}
        <NavigationButtonWrapper>
          <ActionButton onClick={onClickBackButton}>
            <img
              src={ArrowIcon}
              alt=''
              style={{ transform: "rotate(180deg)" }}
            />
          </ActionButton>
          <ActionButton onClick={onClickNextButton}>
            <img src={ArrowIcon} alt='' />
          </ActionButton>
        </NavigationButtonWrapper>
      </PaginatorWrapper>
    </QuestionCardWrapper>
  );
};

const QuestionCardWrapper = styled.div`
  width: 100%;
  border: 1px soild #ccc;
  border-radius: 10px;
`;

const QuestionWrapper = styled.div`
  min-height: ${(props) =>
    props.questionHeight ? props.questionHeight : "320px"};
`;

const QuestionNumber = styled.h1`
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 45px */
`;
const QuestionTitle = styled.p`
  color: #000;
  font-family: Roboto;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 45px */
`;
const QuestionDescriptionWrapper = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  border-radius: 10px;
  background: #e8f1ff;
  margin-bottom: 35px;
`;

const DescriptionText = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0 0 10px 0;
    color: #4f4f4f;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16px */
  }
`;
const AnswerWrapper = styled.div`
  min-height: ${(props) => (props.answerHeight ? props.answerHeight : "150px")};
  margin-bottom: 50px;
  position: relative;
`;
const PaginatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const Eclipses = styled.div`
  width: ${(props) => (props.active ? "10px" : "6px")};
  height: ${(props) => (props.active ? "10px" : "6px")};
  background-color: ${(props) => (props.active ? "#047857" : "#E7EAEE")};
  border-radius: 50%;
`;

const NavigationButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const ActionButton = styled.button`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid #eaecee;
  background: transparent;
  img {
    filter: brightness(60%);
  }
  &:hover {
    background: #047857;
    img {
      filter: brightness(100%);
    }
  }
`;

export default QuestionCard;
