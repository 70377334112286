import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined, FormOutlined, PauseOutlined, FileTextOutlined } from '@ant-design/icons';
import { ActionMenuContainer } from "../style";
import { colors } from "style";

const statusColors = {
  live: colors.green,
  expired: colors.grey,
  paused: colors.yellow,
  future: colors.purple
}

const statusLabels = {
  live: 'Live',
  expired: 'Expired',
  paused: 'Paused',
  future: 'Future'
}

const menu = (
  <ActionMenuContainer>
    <Menu.Item key="0">
      <FormOutlined /> Edit
    </Menu.Item>
    <Menu.Item key="1">
      <PauseOutlined /> Pause
    </Menu.Item>
    <Menu.Item key="2">
      <FileTextOutlined /> Details
    </Menu.Item>
  </ActionMenuContainer>
);

const columns = {
  stamp: [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Rewards',
      dataIndex: ['view', 'rewards'],
      key: 'rewards'
    },
    {
      title: 'No.of Stamps',
      dataIndex: ['view', 'stamps'],
      key: 'stamps',
      align: 'center'
    },
    {
      title: 'Start Date',
      dataIndex: 'period_start',
      key: 'period_start',
    },
    {
      title: 'End Date',
      dataIndex: 'period_end',
      key: 'period_end',
    },
    {
      title: 'Not Redeemed',
      dataIndex: ['view', 'not_redeemed'],
      key: 'not_redeemd',
      align: 'center'
    },
    {
      title: 'Redeemed',
      dataIndex: ['view', 'redeemed'],
      key: 'redeemd',
      align: 'center'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      // render: status => {
      //   return (
      //     <div style={{
      //       background: statusColors[status],
      //       color: 'white',
      //       textAlign: 'center',
      //       borderRadius: 12,
      //       width: 80,
      //       height: 24}} >
      //       {status}
      //     </div>
      //   )}
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: () => (
        <Dropdown overlay={menu} trigger={['click']}>
          <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
            <EllipsisOutlined style={{fontSize: 20}}/>
          </a>
        </Dropdown>
      ),
    },
  ],
  package: [
    {
      title: 'ID',
      dataIndex: ['view', 'id'],
      key: 'id'
    },
    {
      title: 'Name Packages',
      dataIndex: ['view', 'rewards'],
      key: 'rewards'
    },
    {
      title: 'Price',
      dataIndex: ['view', 'cost'],
      key: 'cost',
      render: (cost) => <span>${cost}</span>
    },
    {
      title: 'Start Date',
      dataIndex: ['view', 'sdate'],
      key: 'sdate',
    },
    {
      title: 'End Date',
      dataIndex: ['view', 'edate'],
      key: 'edate',
    },
    {
      title: 'Package Sold',
      dataIndex: ['view', 'sold'],
      key: 'sold',
      align: 'center'
    },
    {
      title: 'Package Utilized',
      dataIndex: ['view', 'utilized'],
      key: 'utilized',
      align: 'center'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: ['view', 'status'],
      align: 'center',
      render: status => {
        return (
          <div style={{
            background: statusColors[status],
            color: 'white',
            textAlign: 'center',
            borderRadius: 12,
            width: 80,
            height: 24}} >
            {statusLabels[status]}
          </div>
        )}
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: () => (
        <Dropdown overlay={menu} trigger={['click']}>
          <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
            <EllipsisOutlined style={{fontSize: 20}}/>
          </a>
        </Dropdown>
      ),
    },
  ],
  voucher: [
    {
      title: 'ID',
      dataIndex: ['view', 'id'],
      key: 'id'
    },
    {
      title: 'Name of Voucher',
      dataIndex: ['view', 'rewards'],
      key: 'rewards'
    },
    {
      title: 'Start Date',
      dataIndex: ['view', 'sdate'],
      key: 'sdate',
    },
    {
      title: 'End Date',
      dataIndex: ['view', 'edate'],
      key: 'edate',
    },
    {
      title: 'Package Sold',
      dataIndex: ['view', 'sold'],
      key: 'sold',
      align: 'center'
    },
    {
      title: 'Package Utilized',
      dataIndex: ['view', 'utilized'],
      key: 'utilized',
      align: 'center'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: ['view', 'status'],
      align: 'center',
      render: status => {
        return (
          <div style={{
            background: statusColors[status],
            color: 'white',
            textAlign: 'center',
            borderRadius: 12,
            width: 80,
            height: 24}} >
            {statusLabels[status]}
          </div>
        )}
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: () => (
        <Dropdown overlay={menu} trigger={['click']}>
          <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
            <EllipsisOutlined style={{fontSize: 20}}/>
          </a>
        </Dropdown>
      ),
    },
  ],
  referral: [
    {
      title: 'ID',
      dataIndex: ['view', 'id'],
      key: 'id'
    },
    {
      title: 'Name of Promotion',
      dataIndex: ['view', 'rewards'],
      key: 'rewards'
    },
    {
      title: 'Package Sold',
      dataIndex: ['view', 'sold'],
      key: 'sold',
      align: 'center'
    },
    {
      title: 'Package Utilized',
      dataIndex: ['view', 'utilized'],
      key: 'utilized',
      align: 'center'
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: ['view', 'status'],
      align: 'center',
      render: status => {
        return (
          <div style={{
            background: statusColors[status],
            color: 'white',
            textAlign: 'center',
            borderRadius: 12,
            width: 80,
            height: 24}} >
            {statusLabels[status]}
          </div>
        )}
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: () => (
        <Dropdown overlay={menu} trigger={['click']}>
          <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
            <EllipsisOutlined style={{fontSize: 20}}/>
          </a>
        </Dropdown>
      ),
    },
  ]
}

export default columns;
