const axios = require('axios');

const StripeCreateCheckoutSession = (data) => axios.request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}stripe-create-checkout-session`,
    headers: {
      Authorization: `Bearer ${
        data?.accessToken
      }`,
    },
    data,
  })
  .then((response) => response)
  .catch((error) => ({ error: error?.response?.data }));

module.exports = {
  StripeCreateCheckoutSession,
};
