import React, { useState, useContext, useEffect, useRef } from "react";
import { PageContainer } from "./style";
import StyledButton from "components/Elements/StyledButton";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { updateDetails } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import Validation from "data/validation.js";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import CountryList from "country-list-with-dial-code-and-flag";

import client from "feathers.js";
import DetailsSection from "components/DetailsSection/RowContainer";
import { DropdownArrow, Info, TooltipClose } from "assets/svg";

const ProfileSchema = Yup.object().shape({
  address1: Yup.string().required("Required!"),
  city: Yup.string().required("Required!"),
  zip: Yup.string().required("Required!"),
  owner_name: Yup.string().required("Required!"),
  business_name: Yup.string().required("Required!"),
  contact_phone: Yup.string()
    .required("Required!")
    .matches(
      Validation.PHONE_REG_WITHOUT_COUNTRY_CODE,
      "Phone number is not valid"
    ),
});

const MerchantDetails = (props) => {
  const history = useHistory();

  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [country, setCountry] = useState("SG");
  const [phonePrefix, setPhonePrefix] = useState("+65");
  const [currency, setCurrency] = useState("SGD");
  const [email, setEmail] = useState("");
  const [owner_name, setOwner_name] = useState("");
  const [contact_phone, setContact_phone] = useState("");

  const [openTooltip, setOpenTooltip] = useState(false);

  const tooltipIconRef = useRef(null);
  const tooltipContainerRef = useRef(null);

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);

  useEffect(() => {
    if (props.userInfo._id) {
      if (props.userInfo.phonePrefix) {
        setPhonePrefix(props.userInfo.phonePrefix);
      }
      if (props.userInfo.country) {
        setCountry(props.userInfo.country);
      }
      if (props.userInfo.currency) {
        setCurrency(props.userInfo.currency);
      }
      if (props.userInfo.email) {
        setEmail(props.userInfo.email);
      }
      if (props.userInfo.owner_name) {
        setOwner_name(props.userInfo.owner_name);
      }
      if (props.userInfo.contact_phone) {
        setContact_phone(props.userInfo.contact_phone);
      }
    }
  }, [props.userInfo]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipIconRef.current &&
        !tooltipIconRef.current.contains(event.target) &&
        tooltipContainerRef.current &&
        !tooltipContainerRef.current.contains(event.target)
      ) {
        setOpenTooltip(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onUpdateProfile = async (data) => {
    let cloneData = data;
    cloneData.phonePrefix = phonePrefix;
    cloneData.country = country;

    setLoading(true);
    client
      .service("merchants")
      .patch(props.userInfo._id, cloneData)
      .then((res) => {
        props.updateDetails(res);
        showToast("success", "Merchant Details Updated Successfully!");

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <PageContainer>
      <Formik
        validationSchema={ProfileSchema}
        onSubmit={onUpdateProfile}
        initialValues={{
          business_name: props.userInfo.business_name
            ? props.userInfo.business_name
            : "",
          address1: props.userInfo.address1 ? props.userInfo.address1 : "",
          address2: props.userInfo.address2 ? props.userInfo.address2 : "",
          city: props.userInfo.city ? props.userInfo.city : "",
          zip: props.userInfo.zip ? props.userInfo.zip : "",

          owner_name: props.userInfo.owner_name
            ? props.userInfo.owner_name
            : owner_name,
          contact_phone: props.userInfo.contact_phone
            ? props.userInfo.contact_phone
            : contact_phone,
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form onSubmit={handleSubmit} id='form1' className='form-styling'>
            <DetailsSection
              leftHeader='Business Details'
              leftDesc="These are your business's details"
              rightContent={
                <>
                  <div className='input-container'>
                    Merchant ID
                    <Form.Control
                      type='text'
                      name='username'
                      defaultValue={props.userInfo.username}
                      disabled
                      placeholder='Business ID'
                      className='input-disabled'
                    />
                    <button
                      type='button'
                      className='icon-wrapper'
                      onClick={() => setOpenTooltip(!openTooltip)}
                    >
                      <img src={Info} alt='' width='20px' height='20px' />
                    </button>
                    {openTooltip && (
                      <>
                        <div className='tooltip-container'>
                          <button
                            type='button'
                            onClick={() => setOpenTooltip(false)}
                            className='tooltip-button'
                          >
                            <img
                              src={TooltipClose}
                              alt=''
                              width='16px'
                              height='16px'
                            />
                            Your MerchantID is unique, and can't be changed.
                          </button>
                          <div className='tooltip-arrow' />
                        </div>
                      </>
                    )}
                  </div>
                  <div className='input-container'>
                    Business Name
                    <Form.Control
                      type='text'
                      name='business_name'
                      value={values.business_name}
                      onChange={handleChange}
                      isInvalid={!!errors.business_name}
                      placeholder='Business Name'
                      className='input'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.business_name}
                    </Form.Control.Feedback>
                  </div>
                  <div className='right-content'>
                    <div className='input-container'>
                      Address Line 1
                      <Form.Control
                        type='text'
                        name='address1'
                        value={values.address1}
                        onChange={handleChange}
                        isInvalid={!!errors.address1}
                        className='input'
                        placeholder='Address Line 1'
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.address1}
                      </Form.Control.Feedback>
                    </div>
                    <div className='input-container'>
                      Address Line 2
                      <Form.Control
                        type='text'
                        name='address2'
                        value={values.address2}
                        onChange={handleChange}
                        isInvalid={!!errors.address2}
                        className='input'
                        placeholder='Address Line 2'
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.address2}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className='right-content'>
                    <div className='input-container'>
                      Zip Code
                      <Form.Control
                        type='text'
                        name='zip'
                        value={values.zip}
                        onChange={handleChange}
                        isInvalid={!!errors.zip}
                        placeholder='Postal/Zip Code'
                        className='input'
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.zip}
                      </Form.Control.Feedback>
                    </div>
                    <div className='input-container'>
                      City
                      <Form.Control
                        type='text'
                        name='city'
                        value={values.city}
                        onChange={handleChange}
                        isInvalid={!!errors.city}
                        placeholder='City'
                        className='input'
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.city}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className='input-container'>
                    Country
                    <InputGroup>
                      <Form.Control
                        as='select'
                        aria-label='Select Country'
                        onChange={(e) => setCountry(e.target.value)}
                        value={country}
                        className='input'
                        style={{ appearance: "none" }}
                      >
                        {CountryList.sort(function (a, b) {
                          if (a.name === "Malaysia" || a.name === "Singapore") {
                            return -1;
                          } else {
                            return a.name - b.name;
                          }
                        }).map((v, i) => {
                          return (
                            <option key={i} value={v.code}>
                              {v.flag + " " + v.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <div className='icon-wrapper'>
                        <img
                          src={DropdownArrow}
                          alt=''
                          width='20px'
                          height='20px'
                        />
                      </div>
                    </InputGroup>
                  </div>
                </>
              }
            />

            <DetailsSection
              leftHeader='Business Administrator'
              leftDesc="These details here is business administrator's contact details"
              rightContent={
                <>
                  <div className='input-container'>
                    Administrator's Name
                    <Form.Control
                      type='text'
                      name='owner_name'
                      value={values.owner_name}
                      onChange={handleChange}
                      isInvalid={!!errors.owner_name}
                      placeholder='Name person In-Charge'
                      className='input'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.owner_name}
                    </Form.Control.Feedback>
                  </div>

                  <div className='input-container'>
                    Administrator's Email
                    <Form.Control
                      type='text'
                      name='email'
                      value={email}
                      placeholder='Email'
                      disabled
                      className='input-disabled'
                    />
                  </div>

                  <div className='right-content'>
                    <div className='input-container'>
                      Country
                      <InputGroup>
                        <Form.Control
                          as='select'
                          aria-label='Default select example'
                          onChange={(e) => setPhonePrefix(e.target.value)}
                          value={phonePrefix}
                          className='input'
                          style={{ appearance: "none" }}
                        >
                          {CountryList.sort(function (a, b) {
                            if (
                              a.name === "Malaysia" ||
                              a.name === "Singapore"
                            ) {
                              return -1;
                            } else {
                              return a.name - b.name;
                            }
                          }).map((v, i) => {
                            return (
                              <option key={i} value={v.dial_code}>
                                {v.flag + " " + v.dial_code}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <div className='icon-wrapper'>
                          <img
                            src={DropdownArrow}
                            alt=''
                            width='20px'
                            height='20px'
                          />
                        </div>
                      </InputGroup>
                    </div>
                    <div className='input-container'>
                      Phone Number
                      <InputGroup>
                        <Form.Control
                          type='text'
                          name='contact_phone'
                          value={values.contact_phone}
                          onChange={handleChange}
                          isInvalid={!!errors.contact_phone}
                          placeholder='Contact Number'
                          className='input'
                        />
                      </InputGroup>
                      <Form.Control.Feedback
                        type='invalid'
                        className='input-group-feedback'
                      >
                        {errors.contact_phone}
                      </Form.Control.Feedback>
                    </div>
                  </div>

                  <div className='input-container'>
                    Currency
                    <Form.Control
                      type='text'
                      name='currency'
                      value={currency}
                      placeholder='Currency'
                      disabled
                      className='input-disabled'
                    />
                  </div>
                </>
              }
            />
          </Form>
        )}
      </Formik>
      <div className='row-container'>
        <button
          type='button'
          className='cancel-button'
          onClick={() => history.goBack()}
        >
          Cancel
        </button>
        <StyledButton
          form='form1'
          sizeh={48}
          title='Save & Update'
          type='submit'
          className='save-button'
          bgcolor={"#567877"}
        />
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDetails);
