import { breakpoints, colors } from "style";
import styled from "styled-components";
import { MerchantRectangle } from "assets/images"

export const InfoContainer = styled.div`
      // background-image: url('${MerchantRectangle}');
      // background-repeat: no-repeat;
      // background-position: bottom center;
      label {
        margin-bottom: 0;
      }
      justify-content: center;
      position: relative;
      ::before {
        content: "";
        background-color: ${props => props.color1};
        height: 70%;
        width: 100%;
        display: block;
        position: absolute;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      h4 {
        color: ${colors.purple};
        font-size: bold;
        text-align: center;
      }
      .info-wrapper {
        position: relative;
        border: solid #fff 2px;
        border-radius: 15px;
        margin: 12px 12px 8px 12px;
        overflow: hidden;
        background: #fff;
        // display: flex;
        // align-items: center;
        // flex-direction: column;
      }
      .info {
        width: 100%;
        border: none;
        border-radius: 15px;
        .brand-logo {
          // border: solid #fff 2px;
          border-radius: 15px;
          width: 50px;
          height: 50px;
          margin: 12px;
          object-fit: cover;
          border-radius: 50%;
          // background: white;
        }
      }
      .info-details {
        position: relative;
        // border: none;
        // border-radius: 15px;
        // margin: 0px 44px 0px 44px;
        padding: 12px;
        // box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07)!important;
        .app-logo {
          width: 45%;
        }
        .app-logo {
          z-index: 9;
        }
        .social {
          justify-content: center;
          display: flex;
          align-items: center;
          .social-actions {
            position: absolute;
            right: 17px;
            top: -18px;
            div {
              display: inline-block;
              height: 38px;
              width: 38px;
              background: #FFFFFF;
              box-shadow: 0px 4px 4px rgba(213, 213, 213, 0.25);
              margin: auto;
              border: none;
              border-radius: 50%;
              text-align: center;
              position: relative;
              cursor: pointer;
              svg {
                color: #DBDBDB;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                right: 0;
                margin: auto;
              }
              :hover svg {
                color: #3b7779 !important;
              }
            }
            .like {
              margin-right: 8px;
            }
            .active svg {
              color: #3b7779 !important;
            }
          }
          .brand-info {
            h3 {
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 0;
              line-height: 1;
            }
            p {
              margin-bottom: 4px;
              font-size: 12px;
              color: #A4A4A4;
              line-height: 1;
              a {
                color: #A4A4A4;
                text-decoration: underline;
              }
            }
            .ratings {
              // width: 70%;
              // display: flex;
              // justify-content: space-between;
              // align-items: center;
              .text {
                font-weight: 400;
                font-size: 12px;
                line-height: 13px;
                color: #757575;
                margin: 0; 
              }
              .ant-rate {
                font-size: 12px;
              }
              // .fa-star {
              //   color: #FFBA09;
              // }
              .thumbs-wrap {
                margin-top: 8px;
                display: inline-flex;
                align-items: center;
              }
              .fa-thumbs-up, .fa-thumbs-down {
                margin-right: 4px;
                color: #DBDBDB;
              }
            }
            .header {
              display: flex;
              align-items: center;
              margin-bottom: 4px;
            }
            .business-checked {
              display: inline-block;
              background-color: #007bff;
              height: 18px;
              width: 18px;
              border: none;
              border-radius: 50%;
              color: white;
              text-align: center;
              position: relative;
              margin-left: 6px;
              // position: absolute;
              // top: 50%;
              // transform: translate(0, -50%);
              svg {
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
              }
            }
          }
        }
      }
      // .info::before {
      //   content: "";
      //   width: 100%;
      //   height: 100%;
      //   border-top-left-radius: 25px;
      //   border-top-right-radius: 25px;
      //   background-size: contain;
      //   background-position: center;
      //   position: absolute;
      //   background-color: black;
      //   opacity: 0.35;
      // }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;

export const CampaignsMessage = styled.div`
  background: white;
  border: solid red 1px;
  border-radius: 40px;
  border-style: dashed;
  margin: 12px 12px 2px 12px;
    > div {
      background: #FDB256;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #3B3054;
      border: solid white 4px;
      border-radius: 40px;
      padding: 6px 22px;
      span {
        font-weight: 600;
      }
    }
`;