import React, { useState } from "react";
import FailModal from "components/Reward/FailModal";
import SuccessModal1 from "components/Reward/SuccessModal1";
import FailModal1 from "components/Reward/FailModal1";

const DataContext = React.createContext(null);

export { DataContext };

function DataContextContainer(props) {
  const [lang, setLang] = useState("en");
  const [isLoading, setLoading] = useState(false);
  const [authUser, setAuthUser] = useState({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailModal, setShowFailModal] = useState(false);
  const [showFailModal1, setShowFailModal1] = useState(false);
  const [message, setMessage] = useState("");
  const [component, setComponent] = useState("");
  const [location, setLocation] = useState("");
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [toolsAction, setToolsAction] = useState("all");
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  const showToast = (type, msg, footer) => {
    if (footer) {
      setComponent(footer);
    } else {
      setComponent("");
    }
    if (type === "error") {
      toggleFailModal();
      setMessage(msg);
    } else if (type === "success") {
      toggleSuccessModal();
      setMessage(msg);
    } else if (type === "error1") {
      toggleFailModal1();
      setMessage(msg);
    }
  };

  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const toggleFailModal = () => {
    setShowFailModal(!showFailModal);
  };

  const toggleFailModal1 = () => {
    setShowFailModal1(!showFailModal1);
  };

  const controlFailModal = (bool) => {
    setShowFailModal(bool);
  };

  return (
    <>
      <DataContext.Provider
        value={{
          lang,
          setLang,
          isLoading,
          setLoading,
          authUser,
          setAuthUser,
          showToast,
          controlFailModal,
          location,
          setLocation,
          breadcrumb,
          setBreadcrumb,
          toolsAction,
          setToolsAction,
          confirmModalIsOpen,
          setConfirmModalIsOpen,
        }}
      >
        {props.children}
      </DataContext.Provider>

      <FailModal
        message={message}
        showModal={showFailModal}
        toggleModal={toggleFailModal}
        footer={component}
      />

      <FailModal1
        message={message}
        showModal={showFailModal1}
        toggleModal={toggleFailModal1}
        footer={component}
      />

      <SuccessModal1
        message={message}
        showModal={showSuccessModal}
        toggleModal={toggleSuccessModal}
      />
    </>
  );
}
export default DataContextContainer;
