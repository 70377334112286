import React, { useState, useRef, useContext, useEffect } from "react";
import { PageContainer } from "./style";
import StyledButton from "components/Elements/StyledButton";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { updateDetails } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import ModalLanuchEmpty from "components/Extra/ModalLanuchEmpty";
import { Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import CountryList from "country-list-with-dial-code-and-flag";
import Contact from "./Contact/index";

import client from "feathers.js";
import InfoCard from "components/InfoCard/InfoCard";
import DetailsSection from "components/DetailsSection/RowContainer";
import { DropdownArrow, Facebook, Twitter, Instagram } from "assets/svg";

const ProfileSchema = Yup.object().shape({
  // address1: Yup.string().required("Required!"),
  // city: Yup.string().required("Required!"),
  // zip: Yup.string().required("Required!"),
  // owner_name:Yup.string().required("Required!"),
  // business_name:Yup.string().required("Required!"),
  // contact_phone: Yup.string().required("Required!").matches(Validation.PHONE_REG_WITHOUT_COUNTRY_CODE, 'Phone number is not valid'),
});

const MerchantDetails = (props) => {
  const submitRef = useRef(null);
  const history = useHistory();
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [country_visible, setCountry_visible] = useState("SG");
  const [phonePrefix_1, setPhonePrefix_1] = useState("+65");
  const [contact_phone_1, setContact_phone_1] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const modalCallback = () => {
    setLoading(true);
    submitRef.current.click();
    toggleModal();
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Page", props.title]);
  }, []);

  useEffect(() => {
    if (props.userInfo._id) {
      if (props.userInfo.country_visible) {
        setCountry_visible(props.userInfo.country_visible);
      }
      if (props.userInfo.phonePrefix_1) {
        setPhonePrefix_1(props.userInfo.phonePrefix_1);
      }
      if (props.userInfo.contact_phone_1) {
        setContact_phone_1(props.userInfo.contact_phone_1);
      }
    }
  }, [props.userInfo]);

  const onUpdateProfile = async (data) => {
    let cloneData = data;
    cloneData.phonePrefix_1 = phonePrefix_1;
    cloneData.country_visible = country_visible;

    setLoading(true);
    client
      .service("merchants")
      .patch(props.userInfo._id, cloneData)
      .then((res) => {
        props.updateDetails(res);
        showToast("success", "Merchant Details Updated Successfully!");

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <PageContainer>
      <InfoCard
        desc={
          "Including these details on the Campaign page ensures that customers have all the necessary information about your business while participating in your various campaigns."
        }
      />
      <Formik
        validationSchema={ProfileSchema}
        onSubmit={onUpdateProfile}
        initialValues={{
          address1_visible: props.userInfo.address1_visible
            ? props.userInfo.address1_visible
            : "",
          state_visible: props.userInfo.state_visible
            ? props.userInfo.state_visible
            : "",
          city_visible: props.userInfo.city_visible
            ? props.userInfo.city_visible
            : "",
          zip_visible: props.userInfo.zip_visible
            ? props.userInfo.zip_visible
            : "",
          about: props.userInfo.about ? props.userInfo.about : "",

          contact_phone_1: props.userInfo.contact_phone_1
            ? props.userInfo.contact_phone_1
            : contact_phone_1,

          contact_email: props.userInfo.contact_email
            ? props.userInfo.contact_email
            : "",

          facebook: props.userInfo.facebook
            ? props.userInfo.facebook
            : {
                id: "",
                url: "",
              },
          twitter: props.userInfo.twitter
            ? props.userInfo.twitter
            : {
                id: "",
                url: "",
              },
          instagram: props.userInfo.instagram
            ? props.userInfo.instagram
            : {
                id: "",
                url: "",
              },
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form
            onSubmit={handleSubmit}
            id='form1'
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
              gap: "80px",
            }}
          >
            <DetailsSection
              leftHeader='Business Address'
              leftDesc='Provide your business address here. These details will be displayed on your campaign page for customers to reach out directly if needed.'
              rightContent={
                <>
                  <div className='input-container'>
                    Address
                    <Form.Control
                      type='text'
                      name='address1_visible'
                      value={values.address1_visible}
                      onChange={handleChange}
                      placeholder='Eg'
                      className='input'
                    />
                  </div>
                  <div className='right-content'>
                    <div className='input-container'>
                      City
                      <Form.Control
                        type='text'
                        name='city_visible'
                        value={values.city_visible}
                        onChange={handleChange}
                        placeholder='City'
                        className='input'
                      />
                    </div>
                    <div className='input-container'>
                      State/Province
                      <Form.Control
                        type='text'
                        name='state_visible'
                        value={values.state_visible}
                        onChange={handleChange}
                        placeholder='Singapore'
                        className='input'
                      />
                    </div>
                  </div>
                  <div className='right-content'>
                    <div className='input-container'>
                      Country
                      <InputGroup>
                        <Form.Control
                          as='select'
                          onChange={(e) => setCountry_visible(e.target.value)}
                          value={country_visible}
                          style={{
                            height: "48px",
                            padding: "0 10px",
                            appearance: "none",
                          }}
                        >
                          {CountryList.sort(function (a, b) {
                            if (
                              a.name === "Malaysia" ||
                              a.name === "Singapore"
                            ) {
                              return -1;
                            } else {
                              return a.name - b.name;
                            }
                          }).map((v, i) => {
                            return (
                              <option key={i} value={v.code}>
                                {v.flag + " " + v.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <div className='icon-wrapper'>
                          <img
                            src={DropdownArrow}
                            alt=''
                            width='20px'
                            height='20px'
                          />
                        </div>
                      </InputGroup>
                    </div>
                    <div className='input-container'>
                      Zip/ Postal Code
                      <Form.Control
                        type='text'
                        name='zip_visible'
                        value={values.zip_visible}
                        onChange={handleChange}
                        placeholder='Postal/Zip Code'
                        className='input'
                      />
                    </div>
                  </div>
                  <div className='input-container'>
                    About us
                    <Form.Control
                      type='text'
                      as='textarea'
                      rows={3}
                      style={{ height: "auto", minHeight: "129px" }}
                      name='about'
                      value={values.about}
                      onChange={handleChange}
                      placeholder='About Us'
                    />
                  </div>
                </>
              }
            />
            <DetailsSection
              leftHeader='Business Contact Details'
              leftDesc='Provide your main business contact details here. These details will be displayed on your campaign page for customers to reach out directly if needed.'
              rightContent={
                <>
                  <div className='input-container'>
                    Email
                    <Form.Control
                      type='text'
                      name='contact_email'
                      value={values.contact_email}
                      onChange={handleChange}
                      isInvalid={!!errors.contact_email}
                      placeholder='Email'
                      className='input'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.contact_email}
                    </Form.Control.Feedback>
                  </div>
                  <div className='right-content'>
                    <div className='input-container'>
                      Country
                      <InputGroup>
                        <Form.Control
                          as='select'
                          aria-label='Default select example'
                          onChange={(e) => setPhonePrefix_1(e.target.value)}
                          value={phonePrefix_1}
                          style={{
                            height: "48px",
                            padding: "0 10px",
                            appearance: "none",
                          }}
                        >
                          {CountryList.sort(function (a, b) {
                            if (
                              a.name === "Malaysia" ||
                              a.name === "Singapore"
                            ) {
                              return -1;
                            } else {
                              return a.name - b.name;
                            }
                          }).map((v, i) => {
                            return (
                              <option key={i} value={v.dial_code}>
                                {v.flag + " " + v.dial_code}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <div className='icon-wrapper'>
                          <img
                            src={DropdownArrow}
                            alt=''
                            width='20px'
                            height='20px'
                          />
                        </div>
                      </InputGroup>
                    </div>
                    <div className='input-container'>
                      Phone Number
                      <InputGroup>
                        <Form.Control
                          type='text'
                          name='contact_phone_1'
                          value={values.contact_phone_1}
                          onChange={handleChange}
                          isInvalid={!!errors.contact_phone_1}
                          placeholder='Contact Number'
                          className='input'
                        />
                      </InputGroup>
                      <Form.Control.Feedback
                        type='invalid'
                        className='input-group-feedback'
                      >
                        {errors.contact_phone_1}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </>
              }
            />
            <DetailsSection
              leftHeader='Business Social Media'
              leftDesc='Allow customers to conenct with you through social media. These details will be displayed on your campaign page.'
              rightContent={
                <>
                  <div className='input-container'>
                    Facebook URL
                    <div className='url-container'>
                      <InputGroup>
                        <InputGroup.Text className='url'>
                          http://
                        </InputGroup.Text>
                        <Form.Control
                          type='text'
                          name='facebook.url'
                          value={values.facebook.url}
                          onChange={handleChange}
                          placeholder='URL'
                        />
                        <div className='icon-wrapper' style={{ right: "20px" }}>
                          <img
                            src={Facebook}
                            alt=''
                            width='24px'
                            height='24px'
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                  <div className='input-container'>
                    Instagram URL
                    <div className='url-container'>
                      <InputGroup>
                        <InputGroup.Text className='url'>
                          http://
                        </InputGroup.Text>
                        <Form.Control
                          type='text'
                          name='instagram.url'
                          value={values.instagram.url}
                          onChange={handleChange}
                          placeholder='URL'
                        />
                        <div className='icon-wrapper' style={{ right: "20px" }}>
                          <img
                            src={Instagram}
                            alt=''
                            width='24px'
                            height='24px'
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                  <div className='input-container'>
                    Twitter URL
                    <div className='url-container'>
                      <InputGroup>
                        <InputGroup.Text className='url'>
                          http://
                        </InputGroup.Text>
                        <Form.Control
                          type='text'
                          name='twitter.url'
                          value={values.twitter.url}
                          onChange={handleChange}
                          placeholder='URL'
                        />
                        <div className='icon-wrapper' style={{ right: "20px" }}>
                          <img
                            src={Twitter}
                            alt=''
                            width='24px'
                            height='24px'
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                  <button type='submit' className='d-none' ref={submitRef} />
                </>
              }
            />
            <ModalLanuchEmpty
              modalVisible={modalVisible}
              toggleModal={toggleModal}
              modalCallback={modalCallback}
              header={
                <>
                  <span className='title1 text-center'>
                    <br />
                    Business Details Preview
                  </span>
                </>
              }
              body={
                <>
                  <div className='preview-wrapper'>
                    <div className='preview'>
                      <Contact
                        merchant={props.userInfo}
                        address1_visible={values.address1_visible}
                        city_visible={values.city_visible}
                        zip_visible={values.zip_visible}
                        about={values.about}
                        phonePrefix_1={phonePrefix_1}
                        contact_phone_1={values.contact_phone_1}
                        contact_email={values.contact_email}
                        facebook={values.facebook}
                        twitter={values.twitter}
                        instagram={values.instagram}
                      />
                    </div>
                  </div>
                </>
              }
              canEdit={true}
              noFooter={true}
              textAlign={"left"}
            />
          </Form>
        )}
      </Formik>

      <div
        className='row-container'
        style={{ justifyContent: "flex-end", gap: "10px" }}
      >
        <StyledButton
          form='form1'
          sizeh={48}
          title='Preview'
          type='button'
          onClick={toggleModal}
          bgcolor={"#567877"}
          className='preview-button'
        />
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantDetails);
