import React, { useState, useContext, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Radio, Space } from "antd";
import { PreviewContainer } from "../../styleEdit";
import { PageContainer } from "./style";
import { DataContext } from "contexts/DataContextContainer";
import StyledButton from "components/Elements/StyledButton";
import { connect } from "react-redux";
import BroadcastModal from "components/Extra/BroadcastModal";
import TooltipsMRZ from "components/Extra/Tooltips";

import client from "feathers.js";
import axios from "axios";

import "index.css";
import DetailsSection from "components/DetailsSection/RowContainer";

const SendingBroadcast = (props) => {
  const { showToast, setLocation, setBreadcrumb } = useContext(DataContext);
  const [campaignEnum, setCampaignEnum] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [value, setValue] = useState("");
  const [currentSelectionId, setCurrentSelectionId] = useState("");
  const [data, setData] = useState("");
  const [currentIndexSelected, setCurrentIndexSelected] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const onClickHandler = (campaignType, idValue) => {
    setCurrentSelectionId(idValue);

    if (campaignType === "Stamp Card") {
      setCampaignEnum(1);
    } else if (campaignType === "Package") {
      setCampaignEnum(2);
    } else if (campaignType === "Voucher") {
      setCampaignEnum(3);
    } else if (campaignType === "Referral") {
      setCampaignEnum(4);
    }
  };

  const modalCallback = () => {
    client.authenticate().then((auth) => {
      let dataToPatch = {
        merchantId: auth.user._id,
        campaignType: campaignEnum,
        campaignId: currentSelectionId,
      };

      return axios
        .post(`${client.io.io.uri}sendMarketingBroadcast`, dataToPatch, {
          headers: {
            Authorization: auth.accessToken,
          },
        })
        .then((res) => {
          if (res) {
            showToast("success", "Successfully created a marketing broadcast!");
          }
        })
        .catch((err) => {
          if (err.message.includes("411")) {
            showToast("error", "You have reached the limit to send broadcasts");
          } else {
            showToast("error", err.message);
          }
        });
    });
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Marketing Broadcast", props.title]);
  }, []);

  useEffect(() => {
    if (props.userInfo._id) {
      client
        .authenticate()
        .then((auth) => {
          return axios.post(
            `${client.io.io.uri}getNotificationOptions`,
            {
              userId: props.userInfo._id,
            },
            {
              headers: {
                Authorization: auth.accessToken,
              },
            }
          );
        })
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.userInfo._id]);

  return (
    <PageContainer>
      <DetailsSection
        leftHeader='Select Campaign'
        leftDesc='Show customers your best campaign where customers can click on the banner and go directly to the campaign.'
        rightContent={
          <>
            {Object.entries(data).map((item, index) => {
              if (item[1].length > 0) {
                return (
                  <div className='tagline-container'>
                    <div className='tagline-content'>
                      <div
                        className='tagline-header'
                        style={{
                          textDecoration: `${
                            currentIndexSelected === index ? "underline" : ""
                          }`,
                        }}
                      >
                        {item[0]}
                      </div>
                      <Radio.Group
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        className='ml-3'
                        style={{ color: "#3b7779" }}
                      >
                        <Space direction='vertical'>
                          {item[1].length > 0 &&
                            item[1].map((x1, i1) => {
                              return (
                                <Radio
                                  onClick={() =>
                                    onClickHandler(item[0], x1._id)
                                  }
                                  key={i1}
                                  value={item[0] + "||" + x1.title}
                                >
                                  {x1.title}
                                </Radio>
                              );
                            })}
                        </Space>
                      </Radio.Group>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </>
        }
      />

      <div className='row-container'>
        <StyledButton
          title='Preview'
          onClick={toggleModal}
          sizeh={48}
          type='submit'
          className='primary-button'
          bgcolor={"#567877"}
        />
      </div>

      <BroadcastModal
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        campaign='voucher'
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Broadcast Preview
            </span>
          </>
        }
        body={<PreviewContainer></PreviewContainer>}
      />
    </PageContainer>
  );
};

// const StyledSelectionButton = styled.button`
//     width: 180px;
//     height: 45px;
//     border-radius: 10px;
// `

// const StyledTemplateButton = styled.button`
//     width: 180px;
//     height: 240px;
//     background-color: white;
//     padding: 5px;
// `

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

export default connect(mapStateToProps, null)(SendingBroadcast);
