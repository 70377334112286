/* eslint-disable */
import { Menu } from "antd";
import { FormOutlined, DownloadOutlined } from "@ant-design/icons";
import { ActionMenuContainer } from "../style";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";

const columns = (downloadPdf) => [
  {
    title: "Type",
    key: "type",
    render: (x) => {
      return x.creditDebit === "credit" ? (
        <span>Topup</span>
      ) : x.remark === "upgrade" ? (
        <span>Upgrade</span>
      ) : (
        <span>Renew</span>
      );
    },
  },
  {
    title: "Payment",
    key: "payment",
    render: (x) => {
      return x.creditDebit === "credit" ? (
        <span className='text-success'>
          {"+ " +
            getSymbolFromCurrency(x.currency ? x.currency : "usd") +
            " " +
            x.amount}
        </span>
      ) : (
        <span className='text-danger'>
          {"- " +
            getSymbolFromCurrency(x.currency ? x.currency : "usd") +
            " " +
            x.amount}
        </span>
      );
    },
  },
  {
    title: "Desc",
    key: "desc",
    render: (x) => {
      return x.remark === "upgrade" ? (
        <>
          <p className='text-capitalize m-0'>
            {x.fromSubscription + " -> " + x.toSubscription}
          </p>
          <p className='m-0'>
            {moment(x.substart).format("DD/MM/YYYY - HH:mm") +
              " / " +
              moment(x.subEnd).format("DD/MM/YYYY - HH:mm")}
          </p>
        </>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) => (status ? <span>{status}</span> : <span>-</span>),
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (record) => (
      <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    ),
  },
  {
    title: "Invoice",
    key: "invoice",
    render: (record) => (
      <DownloadOutlined
        style={{ cursor: "pointer" }}
        onClick={() => downloadPdf(record)}
      />
    ),
  },
];

export default columns;
