import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { Layout } from "antd";
import client from "feathers.js";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

import SideMenu from "./SideMenu";
import AdminHeader from "./Header";
import AdminInnerHeader from "./InnerHeader";
import { AdminLayoutContainer } from "./style";
import { DataContext } from "contexts/DataContextContainer";
import { logout } from "redux/actions/roleActions";
import AdminRoutes from "routes/AdminRoutes";
import { superadminConfig, adminConfig } from "routes/AdminRoutes/config";
import { MRZLogo } from "assets/svg";
import { SideMenuArrow } from "assets/svg";
import LoadingView from "components/Elements/LoadingView";
import OverlayLock from "components/Extra/OverlayLock.js";

const { Content } = Layout;

var contentStyle = {
  overflow: "auto",
  flex: "1 1 0",
  padding: "0 40px 40px 40px",
  position: "relative",
  width: "clamp(940px, 100%, 100%)",
};

const AdminLayout = (props) => {
  const { isLoading } = useContext(DataContext);
  const [collapsed, setCollapsed] = useState(false);

  const signout = () => {
    client
      .authenticate()
      .then(() => {
        setTimeout(() => {
          client.logout();
        }, 500);
        props.logout();
        props.history.push("/");
      })
      .catch((err) => {
        props.logout();
        props.history.push("/");
      });
  };

  useEffect(() => {
    client
      .authenticate()
      .then((res) => {})
      .catch((err) => {
        if (err.name === "NotAuthenticated") {
          signout();
        }
      });
    /* eslint-disable-next-line */
  }, []);

  return (
    <>
      {false ? (
        <div>
          <LoadingView />
        </div>
      ) : (
        <AdminLayoutContainer>
          {props.role === "superadmin" ? (
            <SideMenu
              userInfo={props.userInfo}
              collapsed={collapsed}
              menuItems={superadminConfig.menus}
              logo={MRZLogo}
              path={props.location.pathname}
            />
          ) : (
            <SideMenu
              userInfo={props.userInfo}
              collapsed={collapsed}
              menuItems={adminConfig.menus}
              logo={MRZLogo}
              path={props.location.pathname}
            />
          )}

          <ToastContainer
            className='toast-container'
            position='top-right'
            autoClose={1500}
            hideProgressBar
            pauseOnFocusLoss={false}
            closeOnClick
            limit={3}
          />
          <Layout style={{ flexDirection: "column", position: "relative" }}>
            <button
              type='button'
              onClick={() => setCollapsed(!collapsed)}
              className='button-collapse'
            >
              <div
                style={{
                  transform: collapsed ? "rotate(180deg)" : "",
                }}
              >
                <img src={SideMenuArrow} alt='' width='11px' height='15px' />
              </div>
            </button>
            <AdminHeader
              userInfo={props.userInfo}
              balance={props.balance}
              earnings={props.earnings}
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              signout={signout}
            />
            <AdminInnerHeader username={props.userInfo.username} />
            <Content
              style={
                props.userInfo.role !== "superadmin" &&
                !window.location.pathname.includes("/topup") &&
                !window.location.pathname.includes("/upgrade")
                  ? // true?
                    moment().diff(props.userInfo.subEnd, "days") >= 15
                    ? // true ?
                      {
                        overflow: "hidden",
                        flex: "1 1 0",
                        padding: "0 40px 40px 40px",
                        position: "relative",
                        width: "clamp(940px, 100%, 100%)",
                      }
                    : contentStyle
                  : contentStyle
              }
            >
              <AdminRoutes />
              {props.userInfo.role !== "superadmin" &&
              !window.location.pathname.includes("/topup") &&
              !window.location.pathname.includes("/upgrade") ? (
                moment().diff(props.userInfo.subEnd, "days") >= 15 ? (
                  <OverlayLock
                    body={
                      <div className='renew-plan-container'>
                        <h4 className='text-black'>Renew your plan now!</h4>
                        <button
                          className='renew-button'
                          onClick={() => props.history.push("/admin/upgrade")}
                        >
                          Renew
                        </button>
                      </div>
                    }
                  />
                ) : null
              ) : null}
            </Content>
          </Layout>
          <LoadingView isLoading={isLoading} />
        </AdminLayoutContainer>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  balance: state.role.balance ? state.role.balance : 0,
  earnings: state.role.earnings ? state.role.earnings : 0,
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  logout: logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminLayout));
