import styled from "styled-components";
import { breakpoints, colors } from "style";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  width: 100%;
  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  right-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 30px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
  }
  .input {
    width: 100%;
    padding: 11.1px 20px;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    background: white;
  }
  .input-box {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #eaecf0;
    background: white;
    padding: 11.1px 20px;
    position: relative;
    border-radius: 8px 0 0 8px;
  }
  .remove-file-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .input-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid #eaecf0;
    background: white;
    border-radius: 10px;
    position: relative;
  }
  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    cursor: pointer;
  }
  .copy-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: #ffffff;
    font-size: 16px;
    font-weight: light;
    background-color: #567877;
    border-radius: 0 20px 20px 0;
    padding: 0 10px;
    min-height: 48px;
  }
  #link::selection {
    background: transparent;
    color: black;
  }
  .url-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0;
    width: 100%;
  }
  .button-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  .icon {
    position: absolute;
    top: 0;
    right: 0;
  }
  .confirm-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    max-width: 160px;
    font-size: 14px;
    color: white;
    background: #567877;
    cursor: pointer;
  }
  .confirm-button-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    max-width: 160px;
    font-size: 14px;
    color: white;
    cursor: not-allowed;
  }
  .url {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }
  .tooltip-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #eaecee;
    width: 100%;
    height: auto;
    max-width: 240px;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    right: -7px;
    top: 90%;
    z-index: 100;
  }
  .tooltip-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    color: #344051;
    line-height: 16px;
  }
  .tooltip-text {
    font-size: 12px;
    color: #637083;
    line-height: 15px;
  }
  .tooltip-arrow {
    position: absolute;
    top: -5px;
    right: 24px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #eaecee;
  }
  .right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .business-bg {
    background: #fafafa;
    padding: 20px;
  }
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .info-header {
    display: flex;
    align-items: center;
    // .title {
    //   width: 170px;
    //   display: flex;
    //   align-items: center;
    //   border-right: 2px solid ${colors.bgGrey};
    //   h3 {
    //     font-weight: bold;
    //     color: ${colors.purple};
    //   }
    // }
    .plan-info {
      display: flex;
      .plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
        span {
          font-weight: bold;
          font-size: 18px;
          &:nth-child(1) {
            color: ${colors.purple};
          }
        }
      }
      .maximum {
        span:nth-child(2) {
          color: ${colors.red};
        }
      }
      .active {
        span:nth-child(2) {
          color: ${colors.blue};
        }
      }
      .balance {
        span:nth-child(2) {
          color: ${colors.yellow};
        }
      }
    }
    .btn-plan {
      margin-left: 24px;
      width: 200px;
      background: #3b7779;
      color: white;
      font-weight: normal;
      font-size: 15px;
      height: 34px;
      border-radius: 17px;
    }
  }
  .status-header {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 20px;
    .status {
      width: 30%;
      height: 100px;
      background: linear-gradient(
        160deg,
        ${colors.red},
        ${colors.red},
        ${colors.red},
        ${colors.yellow}
      );
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span:nth-child(1) {
        color: white;
        font-size: 18px;
      }
      span:nth-child(2) {
        color: white;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
  .page-container {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    p {
      margin-bottom: 0;
    }
    span {
      font-size: 12px;
    }
    padding: 12px0;
    height: 100%;
    background: white;
    border-radius: 8px;
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
