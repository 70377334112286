import React from "react";
import { DatePicker } from "antd";
import { FaClock } from "react-icons/fa";
import moment from "moment";
import { FormGroup, InputGroup } from "react-bootstrap";

const { RangePicker } = DatePicker;

const DateRange = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  searchDate,
}) => {
  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    searchDate(dates[0], dates[1]);
  };

  return (
    <>
      <FormGroup className='mb-0'>
        <InputGroup className='input-group-alternative justify-content-center'>
          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
            }}
            value={[startDate, endDate]}
            onChange={onChangeCampaignPeriod}
          />
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default DateRange;
