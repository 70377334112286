import styled from "styled-components";
import { Increment, Decrement } from "assets/svg";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 80px;
  .timeframe-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #344051;
  }
  .input-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 12px;
    width: 100%;
  }
  .input-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100px;
    font-size: 14px;
    color: black;
    height: 48px;
  }
  .timeframe-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
  }
  .input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }
  .input-with-icon {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }
  .input {
    display: flex;
    align-items: center;
    padding: 10px 8px 10px 12px;
    border: 1px solid #ced2da;
    background: white;
    height: 48px;
    width: 100%;
    max-width: 476;
    border-radius: 8px 0 0 8px;
    color: #637083;
    font-size: 16px;
  }
  .input-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: 1px solid #ced2da;
    border-radius: 0 8px 8px 0;
    padding: 10px 8px;
    color: #6b7280;
    font-size: 16px;
    width: 100%;
    max-width: 68px;
    border-left: none;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #567877;
    border-radius: 10px;
    height: 48px;
    width: 100%;
    max-width: 160px;
    padding: 12px 24px;
    margin: 10px 0 0 0;
    color: white;
    font-size: 14px;
  }
  .inc-dec-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 0px;
    position: absolute;
    top: 0;
    right: 125px;
  }
  .increment-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 16px;
    height: 11px;
  }
  .decrement-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 16px;
    height: 11px;
  }
  .decrement-button:hover,
  .increment-button:hover {
    background: blue;
  }
  .reminder-icon {
    position: absolute;
    top: 12px;
    right: 2%;
    transform: translate(-2%);
  }
`;
