import React, { useContext } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { PageContainer } from "./styleEdit";
import StyledButton from "components/Elements/StyledButton";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";

import client from "feathers.js";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const Edit = (props) => {
  const { setLoading, showToast } = useContext(DataContext);

  const onSubmit = async (value) => {
    setLoading(true);

    client
      .authenticate()
      .then(() => {
        return client.service("users").patch(props.data._id, value);
      })
      .then((res) => {
        // let cloneData = cloneDeep(props.campaignData)
        // let findKey = cloneData.findIndex(e => e._id === props.data._id)
        // if(findKey !== -1){
        //   cloneData[findKey] = res
        // }
        // props.setCampaignData(cloneData)

        setLoading(false);
        showToast("success", "Password edited successfully");
        props.back();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        showToast("error", err.message);
      });
  };

  return (
    <PageContainer>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        initialValues={{
          password: "",
          passwordConfirmation: "",
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <Form
            onSubmit={handleSubmit}
            id='form1'
            className='custom-form-styling'
          >
            <div className='input-container'>
              Password
              <Form.Control
                type='password'
                name='password'
                value={values.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
                placeholder='Password'
                className='input'
              />
              <Form.Control.Feedback type='invalid'>
                {errors.password}
              </Form.Control.Feedback>
            </div>

            <div className='input-container'>
              Repeat Password
              <Form.Control
                type='password'
                name='passwordConfirmation'
                value={values.passwordConfirmation}
                isInvalid={!!errors.passwordConfirmation}
                onChange={handleChange}
                placeholder='Confirm Password'
                className='input'
              />
              <Form.Control.Feedback type='invalid'>
                {errors.passwordConfirmation}
              </Form.Control.Feedback>
            </div>

            <div className='row-container'>
              <button
                type='button'
                className='cancel-button'
                onClick={() => props.back()}
              >
                Cancel
              </button>

              <StyledButton
                sizeh={48}
                title='Confirm'
                type='submit'
                className='save-button'
                bgcolor={"#567877"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
