/* eslint-disable */
import { Menu, Dropdown } from "antd";
import { EllipsisOutlined, FormOutlined } from "@ant-design/icons";
import { ActionMenuContainer } from "../style";
import moment from "moment";

const menu = (record, setAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item
        key='0'
        onClick={() => {
          setAction("edit");
          setDataEdit(record);
        }}
      >
        <FormOutlined /> Edit
      </Menu.Item>
    </ActionMenuContainer>
  );
};

const columns = (setAction, setDataEdit) => [
  {
    title: "Date/Time",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (record) => (
      <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    ),
  },
  {
    title: "Campaign",
    key: "title",
    render: (records) =>
      records.campaignType ? (
        <span>{records.title}</span>
      ) : (
        <span>{records.stampInfo.title}</span>
      ),
  },
  {
    title: "Customer's Email",
    key: "customer_email",
    render: (records) =>
      records.campaignType ? (
        <span>{records.customer_email}</span>
      ) : (
        <span>{records.userInfo ? records.userInfo.email : ""}</span>
      ),
  },
  {
    title: "Actions",
    key: "action",
    render: (records) =>
      records.campaignType ? (
        <span>
          {records.action === "redeem" || records.redeem
            ? "Redeem"
            : "Stamp Given"}
        </span>
      ) : (
        <span>Stamp Card Collected</span>
      ),
  },
  {
    title: "Staff ID",
    key: "staffId",
    render: (records) =>
      records.campaignType ? <span>{records.repName}</span> : <span>-</span>,
  },
  {
    title: "Stamps Left",
    key: "stampLeft",
    render: (records) =>
      records.campaignType ? (
        <span>{records.countLeft ? records.countLeft - 1 : ""}</span>
      ) : (
        <span>{records.stampInfo.count}</span>
      ),
  },
  {
    title: "Stamp Card Expiry",
    key: "stampExpiry",
    render: (records) =>
      records.campaignType ? (
        <span>-</span>
      ) : (
        <span>
          {records.hasOneActivityDate
            ? moment(records.hasOneActivityDate)
                .add(
                  parseInt(records.stampInfo.campaign_validity),
                  records.stampInfo.campaign_validity_unit
                )
                .format("DD/MM/YYYY - HH:mm")
            : moment(records.stampInfo.period_end).format("DD/MM/YYYY - HH:mm")}
        </span>
      ),
  },
  {
    title: "Remarks",
    key: "remarks",
    dataIndex: "remarks",
    render: (record) => (record ? <span>{record}</span> : <span>-</span>),
  },
  // {
  //   title: 'Redeem Date',
  //   dataIndex: 'redeemDate',
  //   key: 'redeemDate',
  //   render: (record) => record?<span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>: <span>-</span>
  // },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   align: 'center',
  //   render: (record) => (
  //     <Dropdown overlay={menu(record, setAction, setDataEdit)} trigger={['click']}>
  //       <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
  //         <EllipsisOutlined style={{fontSize: 20}}/>
  //       </a>
  //     </Dropdown>
  //   ),
  // },
];

export default columns;
