import { breakpoints, colors } from "style";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .tab-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 32px;
    justify-content: center;
    margin: 40px 0 0 0;
  }
  .tab-row-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tab-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 114px;
    height: 46px;
    border: none;
    background: #c0dddc;
    padding: 12px 25px;
    div {
      font-size: 16px;
      font-weight: 550;
      color: #191d23;
      .small {
        margin: 0;
        line-height: 1;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .tab-button-disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 114px;
    height: 46px;
    border: 1px solid #e4e6f1;
    background: transparent;
    padding: 12px 25px;
    div {
      font-size: 16px;
      font-weight: 550;
      color: #6b7280;
      .small {
        margin: 0;
        line-height: 1;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  .tab-text {
    font-size: 16px;
    font-weight: 550;
    color: black;
    height: 24px;
  }
  .plan-container {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
    margin: 60px 0 20px 0;
    justify-items: center;
  }
  .plan-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;
    border: none;
    background: transparent;
    width: 100%;
    max-width: 185px;
    padding: 10px;
  }
  .plan-inner-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
  .plan-inner-content-header {
    color: #18181b;
    font-size: 20px;
    font-weight: bold;
  }
  .plan-inner-content-big-text {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    color: #18181b;
    font-size: 32px;
    font-weight: bold;
  }
  .plan-inner-content-small-text {
    color: #6b7280;
    font-size: 12px;
    padding: 0 0 9px 0;
  }
  .plan-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    border: none;
    background: #567877;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
  }
  .plan-button-disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    border: none;
    background: #cecece;
    color: white;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    cursor: not-allowed;
  }
  .table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .header-section {
    display: flex;
    align-items: center;
    padding: 14px 12px;
    background: #e4e4e7;
    border: none;
    border-radius: 8px;
    font-size: 20px;
    color: #18181b;
    font-weight: 550;
    width: 100%;
  }
  .body-section {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .body-content {
    display: grid;
    padding: 21px 12px 17px 12px;
    border-bottom: 1px solid #d4d4d8;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;

    &:last-child {
      border-bottom: none;
    }
  }
  .body-title-text {
    font-size: 16px;
    color: #18181b;
    width: 100%;
    text-align: left;
  }
  .body-info-text {
    font-size: 16px;
    color: #6b7280;
    width: 100%;
    text-align: center;
  }
  .body-info-text-alt {
    font-size: 16px;
    color: #6b7280;
    width: 100%;
    text-align: center;
  }

  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .page-container {
    flex-direction: column;
    .tabs {
      display: flex;
      text-align: center;
      margin-bottom: 12px;
      background: rgba(255, 255, 255, 0.49);
      box-shadow: 0px 10px 13px rgba(77, 121, 120, 0.1);
      border-radius: 100px;
      padding: 4px;
      .tab {
        border: none;
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px 20px;
        position: relative;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #b3b9c7;
        &.active {
          color: #ffffff;
          background: #3b7779;
          border-radius: 30px;
        }
      }
    }
    span {
      // color: #3B7779;
      // font-weight: 300;
      // font-size: 14px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    td,
    th {
      // border: 1px solid white;
      text-align: left;
      padding: 14px 0;
    }
    th {
      // color: white;
      text-align: center;
      // background-color: rgb(71, 116, 194);
    }
    tr td:not(:first-child) {
      text-align: center;
      border-left: 1px solid #d5d8df;
      color: #3b7779;
      font-weight: 300;
      font-size: 15px;
    }
    // tr:nth-child(even) {
    //   background-color: rgb(205, 213, 233);
    // }
    // tr:nth-child(odd) {
    //   background-color: rgb(232, 235, 245);
    // }
  }
  .title {
    margin-bottom: 24px;
    // font-weight: bold;
    color: ${colors.purple};
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
