import { Input } from 'antd';
import { StepContainer } from "./style";

function CampaignStep2(props) { 
  return (
    <StepContainer>
      <div className='container-center'>
      <p className='text-center mb-0' style={{color: '#3B7779', fontWeight: 500}}>Question</p>
      <h2>How many stamp(s) is required to complete the stamp card?</h2>
      <br/>
        <p className='text-center mb-0' style={{color: '#3B7779', fontWeight: 500}}>Answer</p>
        
        <div className='d-flex justify-content-center align-items-center'>
          <div>
            <Input type='number' min={1} size="large" placeholder="5" value={props.count} onChange={e => props.setCount(e.target.value)}/>
            <span style={{fontWeight: 500}}>{" "}Stamp(s)</span>
          </div>
        </div>

        <div className='box'>
          <h4>Example:</h4>
          <p>You can set any number of stamp(s) required. Example, 1,3,10, etc.</p>
        </div>
      </div>
    </StepContainer>
  );
}

export default CampaignStep2;
