import { CompleteContainer } from "./style";
import StyledButton from "components/Elements/StyledButton";
// import { CheckCircleOutlined } from '@ant-design/icons';
import { RewardSuccess } from "assets/images"

function Complete() {
  return (
    <CompleteContainer>
      <div className='container-center'>
        <div className="details">
          <img width={120} src={RewardSuccess} alt="" />
          <h2 className="pt-2 mb-4">Congratulations</h2>
          <h3 className="mb-4 pb-1">You have completed the signup and your first stamp card campaign is ready to launch.</h3>
          
          <h3 className="mb-1">Please check your email</h3>
          <h3 className="mb-1"><b>(subject: Successful Campaign Creation)</b></h3>
          <h3 className="mb-1">for details to kick start the campaign.</h3>
          <br/>
          <StyledButton
            sizeh={56}
            width={"35%"}
            bgcolor={'#FDB256'}
            className="btn-blue my-4"
            title='Done'
            onClick={() => { window.open('mailto:'); window.location.href = '/'}}
          />
        </div>
      </div>
    </CompleteContainer>
  );
}

export default Complete;
