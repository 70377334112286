import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  max-width: 330px;
  border: 1px solid #eaecf0;
  background: white;
  border-radius: 20px;
  height: 123px;
  padding: 10px 20px;
  box-shadow: 5px 10px 10px rgba(220, 220, 220, 0.2),
    -5px 5px 10px rgba(220, 220, 220, 0.2),
    -5px 5px 10px rgba(220, 220, 220, 0.2), 5px -5px 10px rgba(220, 220, 220, 0);
  .counter-container-header {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #101828;
  }
  .counter-container-count {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 30px;
    color: #567877;
    font-weight: 550;
  }
  .counter-container-activate {
    display: flex;
    align-items: center;
    font-size: 45px;
    color: #567877;
    gap: 5px;
    font-weight: 550;
  }
  .counter-container-total {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #101828;
    font-weight: 550;
    margin: 5px 0 0 0;
  }
`;
