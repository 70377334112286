import React, { useState, useContext, useEffect, useRef } from "react";
import { PageContainer } from "./style";
import { DataContext } from "contexts/DataContextContainer";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateDetails } from "redux/actions/roleActions";
import StyledButton from "components/Elements/StyledButton";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Resizer from "react-image-file-resizer";

import client from "feathers.js";
import axios from "axios";
import InfoCard from "components/InfoCard/InfoCard";
import DetailsSection from "components/DetailsSection/RowContainer";
import { toast } from "react-toastify";
import {
  CopyLink,
  Info,
  RemoveFile,
  UploadFile,
  Lock,
  TooltipClose,
} from "assets/svg";

const SEOSchema = Yup.object().shape({
  seo_title: Yup.string().required("Required!"),
  seo_desc: Yup.string().required("Required!"),
});

const Business = (props) => {
  const history = useHistory();
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [bannerName, setBannerName] = useState("");

  const [bannerImage, setBannerImage] = useState(null);
  const [croppedBannerUrl, setCroppedBannerUrl] = useState(null);

  const [openTooltip, setOpenTooltip] = useState(false);

  const tooltipIconRef = useRef(null);
  const tooltipContainerRef = useRef(null);

  const merchantName = props.userInfo.username;

  const formattedUsername =
    merchantName.charAt(0).toUpperCase() + merchantName.slice(1);

  const onClickCopyHandler = () => {
    const linkUrl = document.getElementById("link");

    linkUrl.select();

    const link = document.execCommand("copy");

    if (link) {
      toast.success("Link Copied!");
    }
  };

  const handleUpload = (file) => {
    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append("NAME", "Fred");
      formData.append("file", file);
      formData.append("toFolder", "seoImages");

      axios({
        method: "post",
        url: `${client.io.io.uri}uploadFileLocalPublic`,
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleDelete = (currentFileName) => {
    axios({
      method: "post",
      url: `${client.io.io.uri}deleteFileLocalPublic`,
      data: {
        fileName: currentFileName,
        toFolder: "seoImages",
      },
      config: { headers: { "Content-Type": "application/json" } },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resizeFile = (file, getSize) => {
    let compressPercent = 50;
    // 100kb
    if (file.size > 300000) {
      compressPercent = 80;
    }

    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        getSize.width / 2,
        getSize.height / 2,
        file.type,
        compressPercent,
        0,
        (uri) => {
          return resolve(uri);
        },
        "file"
      );
    });
  };

  // const dataURIToBlob = (dataURI) => {
  //   const splitDataURI = dataURI.split(",");
  //   const byteString =
  //     splitDataURI[0].indexOf("base64") >= 0
  //       ? atob(splitDataURI[1])
  //       : decodeURI(splitDataURI[1]);
  //   const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  //   const ia = new Uint8Array(byteString.length);
  //   for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  //   return new Blob([ia], { type: mimeString });
  // };

  const handleImgChange = async (e) => {
    e.preventDefault();

    var singleFile = e.target.files[0];

    let newFileName =
      "seo_" + (Date.now() + "_" + props.userInfo._id + "_" + singleFile.name);
    let formData = new FormData();
    formData.append("file", singleFile, newFileName);
    let modified = formData.get("file");
    modified.originFileName = singleFile.name;

    if (croppedBannerUrl) {
      URL.revokeObjectURL(croppedBannerUrl);
    }

    if (singleFile.size > 300000) {
      let getSize = await getImageSize(modified);
      const image = await resizeFile(modified, getSize);

      setBannerName(singleFile.name);
      setBannerImage(image);
      setCroppedBannerUrl(URL.createObjectURL(image));
    } else {
      setBannerName(singleFile.name);
      setBannerImage(modified);
      setCroppedBannerUrl(URL.createObjectURL(modified));
    }
    // setShowModal(true)

    // const reader = new FileReader();
    // reader.onload = () => {
    //   setBannerName(files[0].name)
    //   setBannerImage(reader.result)
    //   setShowModal(true)
    // };
    // reader.readAsDataURL(files[0]);
  };

  // const getCropData = () => {
  //   if (typeof cropper !== "undefined") {
  //     setCroppedBannerUrl(cropper.getCroppedCanvas().toDataURL());
  //   }
  // };

  function getImageSize(file) {
    return new Promise((resolve) => {
      var fr = new FileReader();

      fr.onload = function () {
        var img = new Image();

        img.onload = function () {
          return resolve({
            width: img.width,
            height: img.height,
          });
        };

        img.src = fr.result;
      };

      fr.readAsDataURL(file);
    });
  }

  const handleSubmitWallet = async (value, { resetForm }) => {
    setLoading(true);

    var newFileName = "";

    if (bannerImage) {
      // const dataBlob = dataURIToBlob(croppedBannerUrl);
      // let imgSize = await getImageSize(dataBlob)

      handleDelete(props.userInfo.seo_img);
      newFileName = await handleUpload(bannerImage);
      value.seo_img = newFileName;
    }

    client
      .authenticate()
      .then(() => {
        return client.service("merchants").patch(props.userInfo._id, value);
      })
      .then((res) => {
        props.updateDetails(res);
        setLoading(false);

        URL.revokeObjectURL(croppedBannerUrl);
        setCroppedBannerUrl(null);

        showToast("success", "Merchant Details Updated Successfully!");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        showToast("error", err.message);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipIconRef.current &&
        !tooltipIconRef.current.contains(event.target) &&
        tooltipContainerRef.current &&
        !tooltipContainerRef.current.contains(event.target)
      ) {
        setOpenTooltip(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const removeFileHandler = () => {
    setBannerImage(null);
    setBannerName("");

    document.getElementById("cancel-button").addEventListener("click", (e) => {
      e.stopPropagation();
    });
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Page", props.title]);

    setCroppedBannerUrl(
      `${process.env.REACT_APP_API_URL}/${props.userInfo.seo_img}`
    );
  }, []);

  return (
    <PageContainer>
      <InfoCard desc="By leveraging the Social Sharing feature in MoreRewardz, you can fine-tune your sharing content, control the information that is displayed, and maximize the visibility and effectiveness of your various campaigns when shared on popular social media platforms ( eg Google, Facebook, Twiteer and more!)" />

      <Formik
        initialValues={{
          seo_title: props.userInfo.seo_title
            ? props.userInfo.seo_title
            : `${props.userInfo.business_name} has MoreRewardz`,
          seo_keywords: props.userInfo.seo_keywords
            ? props.userInfo.seo_keywords
            : "",
          seo_desc: props.userInfo.seo_desc
            ? props.userInfo.seo_desc
            : `Check out all the awesome promotions from ${props.userInfo.business_name}!`,
        }}
        enableReinitialize
        validationSchema={SEOSchema}
        onSubmit={handleSubmitWallet}
      >
        {({ values }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "flex-start",
              gap: "80px",
            }}
          >
            <DetailsSection
              leftHeader="Social Sharing details"
              leftDesc={`For your business's security, please upload the required documents.`}
              rightContent={
                <>
                  <div className="input-container">
                    Your campaign URL
                    <div className="url-bar-wrapper">
                      <div className="url">
                        <input
                          type="text"
                          id="link"
                          value={`${process.env.REACT_APP_BASE_URL}${merchantName}`}
                          readOnly
                          className="input-box"
                        />
                        <button
                          type="button"
                          ref={tooltipIconRef}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            height: "15px",
                            position: "absolute",
                            top: "15px",
                            right: "10px",
                            zIndex: "10",
                          }}
                          onClick={() => setOpenTooltip(!openTooltip)}
                        >
                          <img src={Info} alt="" width="15px" height="15px" />
                        </button>
                        {openTooltip && (
                          <div
                            className="tooltip-container"
                            ref={tooltipContainerRef}
                          >
                            <div className="tooltip-header">
                              Campaign URL
                              <button
                                type="button"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  border: "none",
                                  background: "transparent",
                                  cursor: "pointer",
                                  height: "16px",
                                  padding: "0",
                                }}
                                onClick={() => setOpenTooltip(false)}
                              >
                                <img
                                  src={TooltipClose}
                                  alt=""
                                  width="16px"
                                  height="16px"
                                />
                              </button>
                            </div>
                            <div className="tooltip-text">
                              This is your campaign URL where you can
                            </div>
                            <div className="tooltip-arrow" />
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        className="copy-button"
                        onClick={() => onClickCopyHandler()}
                      >
                        <img
                          src={CopyLink}
                          alt=""
                          width="13.75px"
                          height="13.75px"
                        />
                        Copy
                      </button>
                    </div>
                  </div>

                  <div className="input-container">
                    Social Sharing Title
                    <Field
                      type="text"
                      className="input"
                      name="seo_title"
                      placeholder={`Eg <${formattedUsername}> has MoreRewardz`}
                    />
                    <ErrorMessage name="seo_title" />
                  </div>

                  <div className="input-container">
                    Social Sharing Description
                    <Field
                      type="text"
                      as="textarea"
                      className="input"
                      name="seo_desc"
                      placeholder={`Check out all the awesome promotions from <${formattedUsername}> !`}
                      style={{ minHeight: "88px" }}
                    />
                    <ErrorMessage name="seo_desc" className="error-text" />
                  </div>

                  <div className="input-container">
                    Social Sharing Image
                    <div className="input-image-container">
                      <label
                        htmlFor="img-logo"
                        className="label-wrapper"
                        style={{ padding: "25px", minHeight: "223px" }}
                      >
                        {croppedBannerUrl ? (
                          <>
                            <img
                              src={croppedBannerUrl}
                              alt=""
                              width="auto"
                              height="155px"
                            />
                            <div className="left-content-text">
                              <div
                                style={{ color: "#567877", fontWeight: "750" }}
                              >
                                Click to upload
                              </div>
                              &nbsp;or drag and drop
                            </div>
                            <div className="left-content-text">
                              SVG, PNG, JPG ( Recommended 1200x400px )
                            </div>
                          </>
                        ) : (
                          <>
                            <img
                              src={UploadFile}
                              alt=""
                              width="40px"
                              height="40px"
                            />
                            <div className="left-content-text">
                              <div
                                style={{ color: "#567877", fontWeight: "750" }}
                              >
                                Click to upload
                              </div>
                              &nbsp;or drag and drop
                            </div>
                            <div className="left-content-text">
                              SVG, PNG, JPG ( Recommended 1200x400px )
                            </div>
                          </>
                        )}
                        <input
                          id="img-logo"
                          type="file"
                          accept=".svg, .png, .jpg"
                          onChange={(e) => handleImgChange(e)}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          style={{ display: "none" }}
                        />
                      </label>
                      {bannerImage && (
                        <button
                          type="button"
                          id="cancel-button"
                          onClick={() => removeFileHandler()}
                          className="remove-file-button"
                        >
                          <img
                            src={RemoveFile}
                            alt=""
                            width="20px"
                            height="20px"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </>
              }
            />

            <div className="row-container">
              <StyledButton
                title={
                  <div className="button-text">
                    Confirm
                    {props.userInfo.subscription === "copper" && (
                      <img
                        src={Lock}
                        alt=""
                        width="24px"
                        height="24px"
                        className="icon"
                      />
                    )}
                  </div>
                }
                sizeh={44}
                type="submit"
                className={
                  props.userInfo.subscription === "copper"
                    ? "confirm-button-disabled"
                    : "confirm-button"
                }
                bgcolor={"#567877"}
                disabled={
                  props.userInfo.subscription === "copper" ? true : false
                }
              />
            </div>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Business);
