import { useContext, useEffect, useState } from "react";
// import ReactFlagsSelect from 'react-flags-select';
import CountryList from 'country-list-with-dial-code-and-flag'
import { SignupContainer } from "./style";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Select } from 'antd';
import { Formik, Field } from "formik";
import * as Yup from "yup";
// import { Link } from "react-router-dom";
import StyledButton from "components/Elements/StyledButton";
import { DataContext } from "contexts/DataContextContainer";
import Validation from 'data/validation.js'
import { connect } from 'react-redux';
import { loginDetails } from 'redux/actions/roleActions';
import { getMerchant } from 'redux/actions/extraActions';

import client from "feathers.js"

const SignupSchema = Yup.object().shape({
  owner_name: Yup.string().required("Required!"),
  contact_phone: Yup.string().matches(Validation.PHONE_REG_WITHOUT_COUNTRY_CODE, 'Phone number is not valid'),
  email: Yup.string().email().required("Required!"),
  toggle: Yup.bool() // use bool instead of boolean
  .oneOf([true], "You must accept the terms and conditions")
});

function SignUp(props) {
  const { setLoading, showToast } = useContext(DataContext);
  const [ phonePrefix, setPhonePrefix ] = useState('SG');
  const [ countrylists, setCountrylists ] = useState([]);

  useEffect(() => {
    props.loginDetails({})
    props.getMerchant({})
    /* eslint-disable-next-line */
  }, [])

  useEffect(() => {
    setCountrylists(CountryList.sort(function(a, b){
        if(a.name === 'Malaysia' || a.name === 'Singapore'){
          return -1
        }else{
          return a.name - b.name
        }
      }).map((v, i) => {
        v.label = v.flag + " " + v.dial_code
        v.value = v.code
        return v
    }))
  }, [])

  const register = async value => {

    setLoading(true)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const affiliateId = urlParams.get('aff')
    // let findAffiliate = await axios.post(`${client.io.io.uri}findAffiliateId`, {
    //   affiliateId: affiliateId
    // })

    value.affiliateId = affiliateId
    value.phonePrefix = CountryList.find(x => x.code === phonePrefix)?.dial_code || '+65'
    value.role = "merchant"
    
    client.service('merchants').create(value)
    .then((res) => {
      // showToast('success', 'Registered Successfully!')
      setLoading(false)

      setTimeout(() => {
        props.history.push('/verification')
      },
        500
      );
    })
    .catch((err)=>{
      showToast('error', err.message)
      setLoading(false)
    })
  }

  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
  
  return (
    <SignupContainer>
      <div className="row no-gutters home-container mx-auto">
        <div className="col-md-12">
          <div className="signup-form">
            <div className="text-center">
              <h2>Get Started Now!</h2>
              <h4>Experience it all, completely FREE ! No payments or credit cards required</h4>
              <p>Enter your credentials to access your account</p>
            </div>
            <Formik
              validationSchema={SignupSchema}
              onSubmit={register}
              initialValues={{
                owner_name: "",
                contact_phone: "",
                email: "",
                toggle: false,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                isValid,
                errors,
                dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row className="mt-4">
                      <Col md={12}>
                        <Form.Label htmlFor="owner_name">Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="owner_name"
                          id="owner_name"
                          value={values.owner_name}
                          onChange={handleChange}
                          isInvalid={!!errors.owner_name}
                          placeholder="Your Name"
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mt-2">

                      {/*
                        <Form.Control
                          type="text"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          isInvalid={!!errors.phone}
                          placeholder="Mobile Phone"
                        />
                        */}
                        <Col xs={4} sm={4} md={4}>
                          {/* <ReactFlagsSelect
                            className='country'
                            showSelectedLabel={false}
                            showOptionLabel={false}
                            selected={phonePrefix}
                            onSelect={code => { setPhonePrefix(code) }}
                          /> */}
                          <Form.Label htmlFor="country_code">Country</Form.Label>
                          <Select
                            showSearch
                            placeholder="Select a person"
                            optionFilterProp="children"
                            id="country_code"
                            className="w-100"
                            onChange={e => setPhonePrefix(e)}
                            value={phonePrefix}
                            // onSearch={onSearch}
                            filterOption={filterOption}
                            options={countrylists}
                          />
                        </Col>
                        <Col xs={8} sm={8} md={8}>
                        <Form.Label htmlFor="phone_number">Phone Number</Form.Label>
                          {/* <InputGroup> */}
                            {/* <InputGroup.Text>{countryCodes.find(cc => cc.code === phonePrefix)['dial_code']}</InputGroup.Text> */}
                            <Form.Control
                            id="phone_number"
                              type="text"
                              name="contact_phone"
                              value={values.contact_phone}
                              onChange={handleChange}
                              isInvalid={!!errors.contact_phone}
                              placeholder="Phone Number"
                            />
                          {/* </InputGroup> */}
                          <Form.Control.Feedback type="invalid" className='input-group-feedback'>{errors.contact_phone}</Form.Control.Feedback>
                        </Col>
                    </Form.Row>
                    <Form.Row className="mt-2">
                      <Col md={12}>
                      <Form.Label htmlFor="email">Email</Form.Label>
                        <Form.Control
                          id="email"
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          placeholder="Email"
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                    {/* <p className="text-center mb-0 mt-1" style={{fontSize: 13}}>Please ensure that your email is correct to receive our verification email.</p> */}
                    <Form.Row className="mt-3">
                      <Col md={12}>
                        <label>
                          <Field type="checkbox" name="toggle" className="rounded-checkbox"/>
                          <span className="d-inline ml-2 term-label">
                          I accept the {" "}
                          </span>
                          <a href="https://morerewardz.com/terms/" target="_blank" rel="noreferrer" >Term & Condition</a>
                        </label>
                        <Form.Control.Feedback type="invalid">{errors.toggle}</Form.Control.Feedback>
                      </Col>
                    </Form.Row>
                    <div className="d-flex justify-content-center">
                      <StyledButton
                        disabled={!(isValid && dirty)}
                        type="submit"
                        sizeh={56}
                        width={'100%'}
                        bgcolor={'#567877'}
                        radius={12}
                        className="btn-blue mt-3"
                        title="Sign Up"
                        />
                    </div>
                </Form>
              )}
            </Formik>
            <span>Have an account? <a href="https://app.morerewardz.com/" target="_blank" rel="noreferrer">Login</a> </span>
          </div>
        </div>
      </div>
    </SignupContainer>
  );
}


const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  loginDetails: loginDetails,
  getMerchant: getMerchant
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);