import React, { useState, useContext } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { PageContainer } from "./styleEdit";
import StyledButton from "components/Elements/StyledButton";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";
import { UserLogo } from "assets/images";
import { FaCamera } from "react-icons/fa";
import Resizer from "react-image-file-resizer";

import axios from "axios";
import client from "feathers.js";
import { cloneDeep } from "lodash";
import { HidePassword, ShowPassword, UploadFile } from "assets/svg";
import { useHistory } from "react-router-dom";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  // owner_name: Yup.string()
  //   .required('Owner Name is required'),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const Edit = (props) => {
  const history = useHistory();
  const { setLoading, showToast, setToolsAction } = useContext(DataContext);
  const [file, setFile] = useState(null);
  const [previewImg, setPreviewImg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const uploadUserLogo = async (fileData) => {
    return axios
      .post(`${client.io.io.uri}uploadPublicImgAndDelete`, {
        _id: props.userInfo._id,
        fileData: fileData,
        deleteFileName: "",
        toFolder: "userLogos",
        prefix: "user",
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resizeFile = (file, getSize) => {
    let compressPercent = 0;
    if (file.size > 2000) {
      compressPercent = 50;
    }
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        getSize.width,
        getSize.height,
        file.name.split(".").pop(),
        compressPercent,
        0,
        (uri) => {
          return resolve(uri);
        },
        "base64"
      );
    });
  };

  const handleImgChange = (e) => {
    let singleFile = e.target.files[0];
    // let fileType = (singleFile.type === "image/jpeg" || singleFile.type === "image/jpg" || singleFile.type === "image/png" || singleFile.type === "image/gif")

    // if(singleFile.size <= 2000000 && fileType){
    let newFileName =
      Date.now() + "_" + props.userInfo._id + "_" + singleFile.name;
    let formData = new FormData();
    formData.append("file", singleFile, newFileName);

    let modified = formData.get("file");
    modified.originFileName = singleFile.name;

    setFile(modified);
    if (previewImg) {
      URL.revokeObjectURL(previewImg);
    }
    setPreviewImg(URL.createObjectURL(singleFile));

    return (e.target.value = null);
    // }else{
    //   window.alert("File size Limit: 2000000 kb!")
    //   return false
    // }
  };

  function getImageSize(file) {
    return new Promise((resolve) => {
      var fr = new FileReader();

      fr.onload = function () {
        var img = new Image();

        img.onload = function () {
          return resolve({
            width: img.width,
            height: img.height,
          });
        };

        img.src = fr.result;
      };

      fr.readAsDataURL(file);
    });
  }

  const onSubmit = async (value) => {
    setLoading(true);

    var newFileName = "";

    if (file) {
      let imgSize = await getImageSize(file);

      let getSize = {
        width: imgSize.width,
        height: imgSize.height,
      };

      const image = await resizeFile(file, getSize);
      newFileName = await uploadUserLogo(image);
    }

    value.profilePicURL = newFileName;

    client
      .authenticate()
      .then(() => {
        return client.service("users").create(value);
      })
      .then((res) => {
        let cloneData = cloneDeep(props.campaignData);

        props.fetchCampaignInfo();
        props.setCampaignData(cloneData.concat(res));

        setLoading(false);
        showToast("success", "Staff ID is created successfully");
        props.back();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        showToast("error", err.message);
      });
  };

  return (
    <PageContainer>
      <Formik
        // enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        initialValues={{
          password: "",
          passwordConfirmation: "",
          username: "",
          owner_name: "",
          profilePicURL: "",
          merchantId: props.userInfo._id,
          userId: props.userInfo._id,
          merchantName: props.userInfo.username,
          role: "business_rep",
          set_done: "done",
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <Form
            onSubmit={handleSubmit}
            id='form1'
            className='custom-form-styling'
          >
            <label htmlFor='img-logo' className='upload-image'>
              <img
                src={previewImg ? previewImg : UploadFile}
                alt=''
                style={{ objectFit: "cover" }}
              />

              <input
                id='img-logo'
                type='file'
                onChange={(e) => handleImgChange(e)}
                style={{ display: "none", cursor: "pointer" }}
              />
            </label>

            <div className='input-container'>
              Name of user
              <Form.Control
                type='text'
                name='owner_name'
                value={values.owner_name}
                onChange={handleChange}
                isInvalid={!!errors.owner_name}
                placeholder='Name of User'
                className='input'
              />
              <Form.Control.Feedback type='invalid'>
                {errors.owner_name}
              </Form.Control.Feedback>
            </div>

            <div className='input-container'>
              Staff ID
              <Form.Control
                type='text'
                name='username'
                value={values.username}
                onChange={handleChange}
                isInvalid={!!errors.username}
                placeholder='Staff ID'
                className='input'
              />
              <Form.Control.Feedback type='invalid'>
                {errors.username}
              </Form.Control.Feedback>
            </div>

            <div className='input-container'>
              Password
              <Form.Control
                type={showPassword ? "text" : "password"}
                name='password'
                value={values.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
                placeholder='Password'
                className='input'
              />
              <Form.Control.Feedback type='invalid'>
                {errors.password}
              </Form.Control.Feedback>
              <button
                type='button'
                className='icon-wrapper'
                onClick={() => setShowPassword(!showPassword)}
              >
                <img
                  src={showPassword ? ShowPassword : HidePassword}
                  alt=''
                  width='24px'
                  height='24px'
                />
              </button>
            </div>

            <div className='input-container'>
              Confirm Password
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                name='passwordConfirmation'
                value={values.passwordConfirmation}
                isInvalid={!!errors.passwordConfirmation}
                onChange={handleChange}
                placeholder='Confirm Password'
                className='input'
              />
              <Form.Control.Feedback type='invalid'>
                {errors.passwordConfirmation}
              </Form.Control.Feedback>
              <button
                type='button'
                className='icon-wrapper'
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <img
                  src={showConfirmPassword ? ShowPassword : HidePassword}
                  alt=''
                  width='24px'
                  height='24px'
                />
              </button>
            </div>

            <div className='row-container'>
              <button
                type='button'
                className='cancel-button'
                onClick={() => props.back()}
              >
                Cancel
              </button>

              <StyledButton
                form='form1'
                sizeh={48}
                title='Confirm'
                type='submit'
                className='save-button'
                bgcolor={"#567877"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
