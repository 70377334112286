/* eslint-disable */
import { Menu } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { ActionMenuContainer } from "../style";
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'

const menu = (record, setAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item key="0" onClick={() => {
        setAction('edit')
        setDataEdit(record)
        }}>
        <FormOutlined /> Edit
      </Menu.Item>
      {/* <Menu.Item key="1">
        <PauseOutlined /> Pause
      </Menu.Item>
      <Menu.Item key="2">
        <FileTextOutlined /> Details
      </Menu.Item> */}
    </ActionMenuContainer>
  )
}

const columns = (setAction, setDataEdit) => [
    {
      title: 'Paid Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'Original Amount',
      dataIndex: 'oriAmount',
      key: 'oriAmount',
      render: record => record? <span>${record?.toFixed(2)}</span>: <span>-</span>
    },
    {
      title: 'Commision Amount',
      dataIndex: 'comAmount',
      key: 'comAmount',
      render: record => record? <span>${record?.toFixed(2)}</span>: <span>-</span>
    },
    {
      title: 'Commision Percent',
      dataIndex: 'commision',
      key: 'commision',
      render: record => record? <span>{record}%</span>: <span>-</span>
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: status => status? <span>{status}</span>: <span>-</span>
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   align: 'center',
    //   render: (record) => (
    //     <Dropdown overlay={menu(record, setAction, setDataEdit)} trigger={['click']}>
    //       <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
    //         <EllipsisOutlined style={{fontSize: 20}}/>
    //       </a>
    //     </Dropdown>
    //   ),
    // },
  ]

export default columns;
