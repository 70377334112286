import React from "react";
import { Modal } from "antd";
import { FaTimesCircle } from "react-icons/fa";
import { ModalContainer } from "./style";

const ModalConfirmation = (props) => {
  const confirmModal = () => {
    props.toggleModal();
    props.modalCallback(true);
  };

  return (
    <>
      <Modal
        title={false}
        visible={props.modalVisible}
        onCancel={() => {
          props.toggleModal();
        }}
        centered
        closable={true}
        footer={false}
        maskClosable={false}
        closeIcon={<FaTimesCircle className='close-icon' />}
      >
        <ModalContainer style={{ minWidth: "350px" }}>
          <div className='header'>{props.header}</div>

          <div
            className='body w-100'
            style={{ textAlign: props.textAlign ? props.textAlign : "center" }}
          >
            {props.body}
          </div>

          {props.noFooter ? null : (
            <div className='footer'>
              <button
                onClick={() => props.toggleModal()}
                className='check-availability-again'
              >
                Cancel
              </button>
              <button onClick={() => confirmModal()} className='confirm-btn'>
                Confirm
              </button>
            </div>
          )}
        </ModalContainer>
      </Modal>
    </>
  );
};

export default ModalConfirmation;
