import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  .row-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
    margin: 50px 0 0 0;
  }
  .custom-form-styling {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
  }
  .upload-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-height: 142px;
    max-height: 142px;
    min-width: 142px;
    max-width: 142px;
    border: 1px solid #e7f6ef;
    cursor: pointer;
    overflow: hidden;
    margin: 0 0 50px 0;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    font-size: 18px;
    color: #344051;
    position: relative;
    height: 113px;
  }
  .input {
    width: 100%;
    padding: 11.1px 20px;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    background: white;
    height: 48px;
  }
  .input-disabled {
    width: 100%;
    padding: 11.1px 20px;
    border-radius: 8px;
    border: 1px solid #eaecf0;
    height: 48px;
  }
  .cancel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 114px;
    height: 48px;
    border: 1px solid #eaecee;
    background: white;
    color: #6b7280;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
  }
  .save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 160px;
    color: white;
    font-size: 14px;
    border: none;
    height: 48px;
    border-radius: 10px;
    font-weight: 500;
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 38%;
    transform: translate(-38%);
    right: 0px;
  }
  .invalid-feedback {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
`;
