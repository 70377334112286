import { PreviewContainer } from "./style";
import parse from 'html-react-parser';
import moment from "moment";

function Preview(props) {
  return (
    <PreviewContainer>
      <h2>Preview</h2>
      <div className='container-center'>
        <div className="details">
          <div className='title'>
            <h4>{props.title}</h4>
          </div>
          <div className='stamps'>
            <h4>{`Expiry : ${props.campaignValidity} ${props.campaignValidityUnit} from first Stamp`}</h4>
            <div className='cards row no-gutters'>
              {
                [...Array(parseInt(props.count)).keys()].map(item =>
                  <div className='col-4 col-sm-4 col-md-4'>
                    <div className='card' key={item}>
                      <span className='card-no'>{item + 1}</span>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          <div className='terms'>
            <h4>Terms & Condition</h4>
            <ul style={{marginBottom: 0}}>
              <li>
                Campaign period {moment(props.periodStart).format('DD/MM/YYYY')} - {moment(props.periodEnd).format('DD/MM/YYYY')}
              </li>
              <li>
                This card is valid for {props.campaignValidity} {props.campaignValidityUnit.slice(0, -1)}(s) upon first collect
              </li>
              <li>
                Redeem reward within {props.redeemValidity} {props.redeemValidityUnit}(s) upon completing
              </li>
            </ul>
            {
              <div>
                {parse(props.tos)}
              </div>
            }
          </div>
        </div>
      </div>
      {props.children}
    </PreviewContainer>
  );
}

export default Preview;
