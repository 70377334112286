import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function StyledQuill({
  readOnly = false,
  modules = [],
  value = "",
  onChange = () => {},
  style,
}) {
  return (
    <ReactQuill
      readOnly={readOnly}
      modules={modules}
      formats={[
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
      ]}
      theme='snow'
      // value={selectedAnswer.answer_text}
      value={value}
      onChange={onChange}
      style={style}
    />
  );
}

export default StyledQuill;
