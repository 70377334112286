import React from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import { ExceedLimit } from "assets/images";
import { CloseModal } from "assets/svg";

const FailModal = (props) => {
  return (
    <Modal
      centered
      footer={null}
      closeIcon={<></>}
      open={props.showModal}
      maskClosable={true}
      onCancel={() => props.toggleModal()}
      bodyStyle={{
        height: "540px",
        width: "930px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
          fontSize: "30px",
          color: "black",
          fontWeight: "500",
        }}
      >
        <div>OOPS!</div>
        You have exceeded the usage limit.
      </div>

      <img src={ExceedLimit} alt='' />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          fontSize: "18px",
          color: "#567877",
          fontWeight: "bold",
        }}
      >
        Upgrade your plan
        <div style={{ color: "#6b7280", fontWeight: "normal" }}>
          {props.message}
        </div>
      </div>

      <button
        type='button'
        onClick={() => props.toggleModal()}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          border: "none",
          background: "transparent",
          cursor: "pointer",
        }}
      >
        <img src={CloseModal} alt='' width='24px' height='24px' />
      </button>

      <a
        type='button'
        href='/admin/upgrade'
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "295px",
          height: "48px",
          padding: "19px 76px",
          backgroundImage: "linear-gradient(to right, #567877, #89A4A3)",
          color: "white",
          fontSize: "14px",
          borderRadius: "10px",
          textDecoration: "none",
        }}
      >
        Upgrade Now
      </a>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FailModal);
