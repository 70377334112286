import { useState, useContext } from "react";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from 'react-redux';
import { loginDetails, rememberMe } from 'redux/actions/roleActions';
import { getBalance } from 'redux/actions/roleActions';
import { DataContext } from "contexts/DataContextContainer";
// import { Link } from "react-router-dom";
import StyledButton from "components/Elements/StyledButton";
// import MorerewardzLayout from "components/MorerewardzLayout"
import { LoginContainer } from "./style";

import client from "feathers.js"

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required("Required!")
});

function Login(props) {
  const { setLoading, showToast } = useContext(DataContext);
  const [ email, setEmail ] = useState('');
  const [ active, setActive ] = useState(0);
  
  const signin = async (value, { resetForm }) => {
    setLoading(true)

    setEmail(value.email)

    client.service('forgot-passwords').create({
      email: value.email
    })
    .then((res) => {
      resetForm()
      setActive(1)
      showToast('success', 'Email sent, Check your inbox shortly.')
      setLoading(false)
    })
    .catch((err)=>{
      showToast('error', err.message)
      setLoading(false)
    })
  }

  return (
      <LoginContainer>
        {/* <div className=""> */}

        {
          active === 0? (
            <div className="signup-form elementor-element elementor-element-55cf268 e-container--column e-container" data-id="55cf268" data-element_type="container">
              <div className="text-center elementor-element elementor-element-0a881c5 elementor-widget elementor-widget-heading" data-id="0a881c5" data-element_type="widget" data-widget_type="heading.default">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default" style={{marginBottom: '40px'}}>
                    Forgot Password
                  </h2>
                </div>
              </div>
              <div className="text-center elementor-element elementor-element-f46e7bf elementor-widget elementor-widget-text-editor" data-id="f46e7bf" data-element_type="widget" data-widget_type="text-editor.default">
                <div className="elementor-widget-container" style={{marginBottom: '60px'}}>
                  <style dangerouslySetInnerHTML={{__html: "/*! elementor - v3.7.8 - 02-10-2022 */\n.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#818a91;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#818a91;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block}" }} />
                    <h4>
                      Please enter the Morerewardz email that was used to set up your account. 
                      {/* <br/> */}
                      {" "}
                      You will receive an email with instructions on how to reset your password.
                    </h4>
                  </div>
              </div>

              <div className="elementor-element elementor-element-4f80bd6 elementor-button-align-stretch elementor-widget elementor-widget-form" data-id="4f80bd6" data-element_type="widget" id="register_form" data-settings="{&quot;step_next_label&quot;:&quot;Next&quot;,&quot;step_previous_label&quot;:&quot;Previous&quot;,&quot;button_width&quot;:&quot;100&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}" data-widget_type="form.default">
              <Formik
                validationSchema={LoginSchema}
                onSubmit={signin}
                initialValues={{
                  email: ''
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  isValid,
                  errors,
                  dirty,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    
                    <Form.Row className="mt-3">
                      <Col md="12">
                    <div className="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100">
                      {/* <label htmlFor="form-field-name" className="elementor-field-label elementor-screen-only">
                        Merchant ID
                      </label> */}
                      <Form.Control
                        size={1} 
                        type="text" 
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                        className="elementor-field elementor-size-sm  elementor-field-textual" 
                        placeholder="Email" 
                        style={{borderRadius: '8px'}}
                      />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </div>
                    </Col>
                    </Form.Row>

                    <div className="w-100 d-flex justify-content-center">
                      <StyledButton
                        type="submit" 
                        sizeh={56}
                        width={'100%'}
                        bgcolor={'#567877'}
                        className="btn-blue mt-5 submit-button"
                        title="Submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>

            </div>
            {/* <span className="elementor-element elementor-element-5cb92bc elementor-widget elementor-widget-text-editor" data-id="5cb92bc" data-element_type="widget" data-widget_type="text-editor.default">
              <div className="elementor-widget-container">
            <span>Already have an account? <a href="https://app.morerewardz.com/" target="_blank" rel="noreferrer">Login</a> now!</span>
              </div>
            </span> */}
          </div>
          ): (
            <div className="signup-form elementor-element elementor-element-55cf268 e-container--column e-container" data-id="55cf268" data-element_type="container">
              <div className="text-center elementor-element elementor-element-0a881c5 elementor-widget elementor-widget-heading" data-id="0a881c5" data-element_type="widget" data-widget_type="heading.default">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    Check your email
                  </h2>
                </div>
              </div>
              <div className="text-center elementor-element elementor-element-f46e7bf elementor-widget elementor-widget-text-editor" data-id="f46e7bf" data-element_type="widget" data-widget_type="text-editor.default">
                <div className="elementor-widget-container">
                    <h4>
                      A message has been sent to {email} with a link to reset your password.
                    </h4>
                  </div>
              </div>
            <div className="w-100 d-flex justify-content-center">
              <StyledButton
                sizeh={56}
                bgcolor={'#FDB256'}
                width={'45%'}
                className="btn-blue mt-4"
                title='Check Email'
                onClick={() => { window.open('mailto:')}}
              />
            </div>
          </div>
          )
        }

      {/* </div> */}
    </LoginContainer>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  loginDetails: loginDetails,
  rememberMe: rememberMe,
  getBalance: getBalance
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
