import { breakpoints, colors } from "style";
import styled from "styled-components";

export const PreviewContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #3B3054;    
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
    color: #434343;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: #434343;
  }

  .details {
    width: 100%;
    border-radius: 8px;
    background: #FFFFFF;
    opacity: 0.97;
    box-shadow: 0px 22px 18px rgba(183, 183, 183, 0.2);
    .title {
      padding: 10px 16px;
      background: #3b7779;
      opacity: 0.97;
      // box-shadow: 0px 10px 18px rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 0px 0px;
      text-transform: capitalize;
      h4 {
        font-weight: 600;
        font-size: 14px;
        color: #FFFFFF;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .stamps {
      padding: 16px;
      h4 {
        font-weight: 400;
        font-size: 14px;
        color: #3B3054;
        text-align: center;
        margin-bottom: 10px;
      }
      .cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .card {
          display: flex;
          justify-content: center;
          padding: 4px;
          
          background: #F6F8F8;

          height: 50px;
          width: auto;
          margin-right: 8px;
          margin-bottom: 12px;
          .card-no {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background: ${colors.bgGrey};
            border-radius: 18px;
            color: white;
            font-weight: bold;
          }
          // &:last-child {
          //   visibility: hidden;
          // }
        }
      }
    }
    .terms {
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      h4 {
        font-weight: 600;
        font-size: 13px;
        color: #3B3054;
        margin: 0;
      }
      ul {
        padding: 0 24px;
      }
      p, span, ul li {
        line-height: 1.5;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
