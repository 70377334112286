import { colors } from "style";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 80px;
  .page-header {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #191d23;
  }
  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #e4e6f1;
    color: #191d23;
    font-size: 16px;
    background: white;
  }
  .options-disabled {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #e4e6f1;
    color: #6b7280;
    font-size: 16px;
    font-weight: 600;
    background: #eaecee;
  }
  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .disabled-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .stripe-text {
    display: flex;
    align-items: center;
    color: #6461fc;
    font-size: 20px;
    font-weight: 500;
  }
  .stripe-setup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    color: #191d23;
    font-size: 16px;
  }
  .setup-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #feb02d;
    font-weight: 400;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
  }
  .disabled-text {
    font-size: 14px;
    font-weight: normal;
    line-height: 10px;
  }
  .toggle-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 100%;
    max-width: 36px;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .update-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: none;
    background: #567877;
    border-radius: 10px;
    height: 48px;
    width: 100%;
    max-width: 160px;
    padding: 12px 24px;
    margin: 10px 0 0 0;
    color: white;
    font-size: 14px;
    cursor: pointer;
    position: relative;
  }
  .update-button-disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border: none;
    border-radius: 10px;
    height: 48px;
    width: 100%;
    max-width: 160px;
    padding: 12px 24px;
    margin: 10px 0 0 0;
    color: white;
    font-size: 14px;
    position: relative;
    cursor: not-allowed;
  }
  .button-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
  }
  .ant-switch,
  ant-switch-checked {
    height: 25px;
  }
  .ant-switch-checked {
    background: #567877;
  }
  .ant-switch-handle {
    top: 3px;
  }
  .ant-switch-checked .ant-switch-handle {
    top: 3px;
    left: calc(100% - 18px - 3px);
  }
`;
