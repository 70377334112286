import { Input } from 'antd';
import { StepContainer } from "./style";

function CampaignStep1(props) {
  return (
    <StepContainer>
      <p className='text-center mb-0' style={{color: '#3B7779', fontWeight: 500}}>Question</p>
      <div className='container-center'>
      <h2>What is the reward for your customer for completing the stamp card?</h2>
      <br/>
        <p className='text-center mb-0' style={{color: '#3B7779', fontWeight: 500}}>Answer</p>
        <Input size="large" placeholder="Get a free cup of coffee worth $6" value={props.title} onChange={e => props.setTitle(e.target.value)}/>

        <div className='box'>
          <h4>Example:</h4>
          <ol>
            <li>- Get a free cup of beverage worth up to $10</li>
            <li>- Redeem a haircut worth $20</li>
            <li>- A slice of cake worth $10</li>
          </ol>
        </div>
      </div>
    </StepContainer>
  );
}

export default CampaignStep1;
