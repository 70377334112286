import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { CSVDownload } from "react-csv";
import { Spinner } from "react-bootstrap";
import { Table, Pagination } from "antd";
import dayjs from "dayjs";
import client from "feathers.js";
import axios from "axios";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";

import { PageContainer } from "../../style";
import { updateDetails } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import columns from "./columns";
import Edit from "./Edit";
import env from "env";
import ModalConfirmation from "components/Extra/ModalConfirmation";
import ModalPreview from "components/Extra/ModalPreview";
import DataCard from "components/DataCard";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const Voucher = (props) => {
  /* eslint-disable-next-line */
  const {
    setLoading,
    showToast,
    setLocation,
    setBreadcrumb,
    toolsAction,
    setToolsAction,
  } = useContext(DataContext);
  const [action, setAction] = useState("all"); //edit, detail, create
  const [campaignData, setCampaignData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [pagination, setPagination] = useState({});
  const [campaignInfo, setCampaignInfo] = useState({});
  const [confirmAction, setConfirmAction] = useState("activate"); // activate, delete
  const [modalTotalVisible, setModalTotalVisible] = useState(false);
  const [previewAction, setPreviewAction] = useState("collect");
  const [spin, setSpin] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [reRender, setReRender] = useState(false);

  const massagedData = campaignData.map((item) => {
    return {
      ...item,
      status: !(dayjs(item.period_end) < dayjs(new Date()))
        ? item.active
          ? "Active"
          : "Inactive"
        : "Expired",
    };
  });

  const activatedVoucher = massagedData.filter(
    (item) => item.active === true && item.period_end < dayjs()
  ).length;

  const fetchCampaignInfo = () => {
    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}getVouchersInformation`,
          {
            userId: props.userInfo._id,
          },
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        setCampaignInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
        showToast("error", err.message);
      });
  };

  const back = () => {
    setAction("all");
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleTotalModal = () => {
    setModalTotalVisible(!modalTotalVisible);
  };

  const modalCallback = () => {
    // setLoading(true)
    if (confirmAction === "activate") {
      let status = dataEdit.active === "Active" ? false : true;

      function updateStatus() {
        client
          .authenticate()
          .then(() => {
            return client
              .service("vouchers")
              .patch(dataEdit._id, { active: status });
          })
          .then((res) => {
            let cloneData = cloneDeep(campaignData);
            let findKey = cloneData.findIndex((e) => e._id === dataEdit._id);
            if (findKey !== -1) {
              res.totalCollected = cloneData[findKey].totalCollected;
              res.totalRedeemed = cloneData[findKey].totalRedeemed;
              cloneData[findKey] = res;
            }

            fetchCampaignInfo();
            setCampaignData(cloneData);

            setLoading(false);
            showToast("success", "Campaign is Edited successfully");

            // If Deactive
            if (!res.active) {
              let campaignTitle = "Voucher||" + dataEdit.title;

              if (campaignTitle === props.userInfo.notification) {
                client
                  .service("merchants")
                  .patch(props.userInfo._id, {
                    notification: "",
                  })
                  .then((res) => {
                    props.updateDetails(res);
                  });
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            showToast("error", err.message);
          });
      }

      // if deactive
      // if active => deactive no check
      if (status) {
        if (
          activatedVoucher <
          env.subscription[props.userInfo.subscription || "copper"].voucher
        ) {
          updateStatus();
        } else {
          showToast("error1", `to unlock additional limits and features!`);
          setLoading(false);
        }
      } else {
        updateStatus();
      }
    } else {
      client
        .authenticate()
        .then(() => {
          return client.service("vouchers").remove(dataEdit._id);
        })
        .then((res) => {
          let cloneData = cloneDeep(campaignData);
          let findKey = cloneData.findIndex((e) => e._id === dataEdit._id);
          if (findKey !== -1) {
            cloneData.splice(findKey, 1);
          }

          fetchCampaignInfo();
          setCampaignData(cloneData);

          setLoading(false);
          showToast("success", "Campaign is Deleted successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    }
  };

  const getCampaignData = (skip, currentPage, userId) => {
    setLoading(true);
    client
      .service("vouchers")
      .find({
        query: {
          checkVoucherInfo: true,
          $skip: skip,
          $limit: PAGESIZE,
          userId,
        },
      })
      .then((res) => {
        setCampaignData(res.data);
        setPagination(paginationCount(res.total, currentPage));
        setLoading(false);
        setToolsAction("all");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  const createCampaign = () => {
    if (
      activatedVoucher <
      env.subscription[props.userInfo.subscription || "copper"].voucher
    ) {
      setAction("create");
      setDataEdit({});
    } else {
      showToast("error1", `to unlock additional limits and features!`);
    }
  };

  const downloadCSV = async () => {
    setSpin(true);

    if (previewAction === "collect") {
      let tmpData = [];
      let arr = dataEdit.totalCollectedUsers;

      for (let i = 0; i < arr.length; i++) {
        tmpData.push({
          Campaign: dataEdit.title,
          Email: arr[i].email,
          "First Name": arr[i].firstName,
          "Last Name": arr[i].lastName,
          "Phone Prefix": arr[i].phonePrefix,
          "Contact Phone": arr[i].contact_phone,
          Gender: arr[i].gender,
          Birthday: arr[i].birthday
            ? moment(arr[i].birthday).format("DD/MM/YYYY - HH:mm")
            : "",
          "Address 1": arr[i].address1,
          "Address 2": arr[i].address2,
          City: arr[i].city,
          Zip: arr[i].zip,
          Country: arr[i].country,
          "Collected Date": moment(arr[i].createdAt).format(
            "DD/MM/YYYY - HH:mm"
          ),
          "Card Expiry": moment(arr[i].cardExpiry).format("DD/MM/YYYY - HH:mm"),
        });
      }

      setCsvData(tmpData);
      setTimeout(() => {
        setSpin(false);
      }, 2000);
    } else {
      let tmpData = [];
      let arr = dataEdit.totalRedeemedUsers;

      for (let i = 0; i < arr.length; i++) {
        tmpData.push({
          Campaign: dataEdit.title,
          Email: arr[i].email,
          "First Name": arr[i].firstName,
          "Last Name": arr[i].lastName,
          "Phone Prefix": arr[i].phonePrefix,
          "Contact Phone": arr[i].contact_phone,
          Gender: arr[i].gender,
          Birthday: arr[i].birthday
            ? moment(arr[i].birthday).format("DD/MM/YYYY - HH:mm")
            : "",
          "Address 1": arr[i].address1,
          "Address 2": arr[i].address2,
          City: arr[i].city,
          Zip: arr[i].zip,
          Country: arr[i].country,
          "Collected Date": moment(arr[i].createdAt).format(
            "DD/MM/YYYY - HH:mm"
          ),
          "Card Expiry": moment(arr[i].cardExpiry).format("DD/MM/YYYY - HH:mm"),
          "Redeemed Date": arr[i].redeemDate
            ? moment(arr[i].redeemDate).format("DD/MM/YYYY - HH:mm")
            : moment(arr[i].updatedAt).format("DD/MM/YYYY - HH:mm"),
        });
      }

      setCsvData(tmpData);
      setTimeout(() => {
        setSpin(false);
      }, 2000);
    }
  };

  const tablePage = () => {
    return (
      <>
        <div className='row-header'>
          <div className='status-wrapper'>
            <DataCard
              title='Activated Vouchers'
              data={activatedVoucher}
              total={
                env.subscription[props.userInfo.subscription || "copper"]
                  .voucher
              }
            />
          </div>

          <button
            className='primary-button'
            onClick={() => {
              createCampaign();
            }}
          >
            <span>+ Vouchers</span>
          </button>
        </div>

        <div className='row-container'>
          <Table
            rowKey='_id'
            columns={columns(
              setAction,
              setDataEdit,
              toggleModal,
              props.userInfo.currency,
              setConfirmAction,
              setPreviewAction,
              toggleTotalModal
            )}
            dataSource={massagedData}
            pagination={false}
          />
        </div>
        <div className='row-container'>
          <Pagination
            pageSize={PAGESIZE}
            total={pagination.total}
            showSizeChanger={false}
            onChange={(e) => {
              setPagination(paginationCount(pagination.total, e));
              getCampaignData((e - 1) * PAGESIZE, e, props.userInfo._id);
            }}
          />
        </div>
      </>
    );
  };

  const editPage = () => {
    return (
      <Edit
        userInfo={props.userInfo}
        fetchCampaignInfo={fetchCampaignInfo}
        campaignData={campaignData}
        setCampaignData={setCampaignData}
        action={action}
        data={dataEdit}
        back={back}
        firstTime={massagedData.length <= 0}
      />
    );
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Tools", props.title]);
  }, []);

  useEffect(() => {
    if (toolsAction === "create") {
      setAction("create");
    } else if (campaignData && campaignData.length <= 0) {
      setAction("create");
    } else {
      setAction("all");
    }
  }, [campaignData]);

  useEffect(() => {
    getCampaignData(0, 1, props.userInfo._id);
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  useEffect(() => {
    fetchCampaignInfo();
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  return (
    <PageContainer>
      {action === "all" ? <>{!reRender && tablePage()}</> : editPage()}

      <ModalConfirmation
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              {"Confirmation"}
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>
              {confirmAction === "activate"
                ? dataEdit.active === "Active"
                  ? "Proceed to Deactivate this Voucher"
                  : "Proceed to Activate Voucher"
                : "Proceed to Delete Voucher"}
            </span>
          </>
        }
      />

      <ModalPreview
        modalVisible={modalTotalVisible}
        toggleModal={toggleTotalModal}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Total {previewAction === "collect" ? "Collected" : "Redeemed"}
            </span>
            <button
              type='button'
              className='btn-mrz-primary'
              style={{ position: "absolute", right: 10 }}
              disabled={spin}
              onClick={downloadCSV}
            >
              <div className='d-flex align-items-center'>
                {spin ? (
                  <Spinner className='mr-2' style={{ height: 15, width: 15 }}>
                    Loading...
                  </Spinner>
                ) : null}
                Export CSV
              </div>
            </button>
            {spin ? (
              <>
                <CSVDownload data={csvData} target='_blank' />
              </>
            ) : null}
          </>
        }
        body={
          <>
            {!isEmpty(dataEdit) ? (
              <span className='title2'>
                <Table
                  rowKey='_id'
                  columns={
                    previewAction === "collect"
                      ? [
                          {
                            title: (
                              <span>
                                Customer <br />
                                Email
                              </span>
                            ),
                            // dataIndex: 'email',
                            key: "email",
                            render: (record) => {
                              return (
                                <a
                                  href={`/admin/transactions?email=${record.email}&title=${dataEdit.title}&campaign=${dataEdit._id}&tab=2`}
                                >
                                  {record.email}
                                </a>
                              );
                            },
                          },
                          {
                            title: "Phone",
                            key: "phone",
                            render: (record) => (
                              <span>
                                {(record.phonePrefix || "-") +
                                  " " +
                                  (record.contact_phone || "")}
                              </span>
                            ),
                          },
                          {
                            title: "First Name",
                            dataIndex: "firstName",
                            key: "firstName",
                          },
                          {
                            title: "Last Name",
                            dataIndex: "lastName",
                            key: "lastName",
                          },
                          {
                            title: "Collected Date",
                            dataIndex: "createdAt",
                            key: "createdAt",
                            render: (record) => (
                              <span>
                                {moment(record).format("DD/MM/YYYY - HH:mm")}
                              </span>
                            ),
                          },
                          {
                            title: (
                              <span>
                                Card <br />
                                Expiry
                              </span>
                            ),
                            dataIndex: "cardExpiry",
                            key: "cardExpiry",
                            render: (record) => (
                              <span>
                                {moment(record).format("DD/MM/YYYY - HH:mm")}
                              </span>
                            ),
                          },
                        ]
                      : [
                          {
                            title: (
                              <span>
                                Customer <br />
                                Email
                              </span>
                            ),
                            // dataIndex: 'email',
                            key: "email",
                            render: (record) => {
                              return (
                                <a
                                  href={`/admin/transactions?email=${record.email}&title=${dataEdit.title}&campaign=${dataEdit._id}&tab=2`}
                                >
                                  {record.email}
                                </a>
                              );
                            },
                          },
                          {
                            title: "Phone",
                            key: "phone",
                            render: (record) => (
                              <span>
                                {(record.phonePrefix || "-") +
                                  " " +
                                  (record.contact_phone || "")}
                              </span>
                            ),
                          },
                          {
                            title: "First Name",
                            dataIndex: "firstName",
                            key: "firstName",
                          },
                          {
                            title: "Last Name",
                            dataIndex: "lastName",
                            key: "lastName",
                          },
                          {
                            title: "Collected Date",
                            dataIndex: "createdAt",
                            key: "createdAt",
                            render: (record) => (
                              <span>
                                {moment(record).format("DD/MM/YYYY - HH:mm")}
                              </span>
                            ),
                          },
                          {
                            title: (
                              <span>
                                Card <br />
                                Expiry
                              </span>
                            ),
                            dataIndex: "cardExpiry",
                            key: "cardExpiry",
                            render: (record) => (
                              <span>
                                {moment(record).format("DD/MM/YYYY - HH:mm")}
                              </span>
                            ),
                          },
                          {
                            title: "Redeemed Date",
                            // dataIndex: 'createdAt',
                            key: "redeemDate",
                            render: (records) => (
                              <span>
                                {records.redeemDate
                                  ? moment(records.redeemDate).format(
                                      "DD/MM/YYYY - HH:mm"
                                    )
                                  : moment(records.updatedAt).format(
                                      "DD/MM/YYYY - HH:mm"
                                    )}
                              </span>
                            ),
                          },
                        ]
                  }
                  dataSource={
                    previewAction === "collect"
                      ? dataEdit.totalCollectedUsers
                      : dataEdit.totalRedeemedUsers
                  }
                />
              </span>
            ) : null}
          </>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
