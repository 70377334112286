import React, { useState, useEffect, useContext } from 'react';
import { Table, Pagination } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageContainer } from "./styleEdit";
import { DataContext } from "contexts/DataContextContainer";
import columns from './user_columns'

import client from "feathers.js"

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const Edit = props => {
  const { setLoading, showToast } = useContext(DataContext);
  const [campaignData, setCampaignData] = useState([])
  const [ pagination, setPagination ] = useState({})

  useEffect(() => {
    getCampaignData(0, 1, props.data._id)
    /* eslint-disable-next-line */
  }, [props.data._id])

  const getCampaignData = (skip, currentPage, userId) => {
    setLoading(true)
    client.authenticate()
    .then(() => {
      return client.service('users').find({
        query: {
          merchantId: userId,
          role: 'user',
          $skip: skip,
          $limit: PAGESIZE,
        }
      })
    })
    .then((res) => {
      setCampaignData(res.data)
      setPagination(paginationCount(res.total, currentPage))
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      if(err.name === "NotAuthenticated"){
            
      }else{
        showToast('error', err.message)
      }
    })
  }

  return (
    <PageContainer>
      <div className='header'>
        <button className='btn-back' onClick={() => props.back()}><ArrowLeftOutlined /></button>
        <h3>{props.data.email}</h3>
      </div>
      <div className='page-container'>
        <Table rowKey="_id" columns={columns()} dataSource={campaignData} pagination={false} />
        <div className='d-flex justify-content-end w-100 mt-3'>
          <Pagination pageSize={PAGESIZE} total={pagination.total} showSizeChanger={false} onChange={(e) => {
            setPagination(paginationCount(pagination.total, e))
            getCampaignData((e-1)*PAGESIZE, e, props.data._id)
          }} />
        </div>
      </div>
    </PageContainer>
  );
}

export default Edit;
