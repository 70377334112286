import { breakpoints, colors } from "style";
import styled from "styled-components";
import { Home2 } from "assets/images";

export const VerificationContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  img {
    max-width: 100%;
    object-fit: cover;
  }
  .left-image {
    height: 100%;
    background-image: url(${Home2});
    background-size: cover;
    background-position: center;
  }
  .home-container {
    flex: 1;
  }
  .verification-steps{
    width: 320px;
    gap: 25px;
    margin: 70px 0 60px 0;
    display: flex;
    flex-direction: column;

    .steps-item {
      display: flex;
      flex-direction: row;
      gap: 25px;
      height: 55px;

      .steps-icons {
        background-color: rgba(254, 176, 45, 0.30);
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
          margin: 0;
        }
      }

      .steps-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .step-title {
        color:  #191D23;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
        margin: 0;
        text-align: left;
      }

      p {
        color:  #191D23;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.16px;
        margin: 0;
        a{
          color: #567877;
          text-decoration-line: underline;
        }
      }
    }
  }
  .verification-form {
    padding: 30px 30px;
    max-width: 750px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      display: flex;
      justify-content: center;
      margin: auto;
      margin-top: 100px;
      margin-bottom: 1rem;
    }
    h2 {
      margin-top: 75px;
      color: #567877;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 80% */
      letter-spacing: -0.3px;
    }
    h4 {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      margin-bottom: 20px;
    }
    h6 {
      width: 618px;
      color: #6B7280;
      text-align: center;
      font-size: 18px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.18px;
      .stamp{
        color:  #6B7280;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.18px;
      }
      .minutes {
        color: #191D23;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.18px;
      }
    }
    .continue-button{
      margin-top: 100px;
    }
    .link-done {
      width: 100%;
    }
    span {
      margin-top: 40px;
      align-self: center;
      color: ${colors.red};
      a {
        color: ${colors.red};
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
