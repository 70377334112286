import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 80px;
  .page-header {
    display: flex;
    align-items: center;
    color: #191d23;
    font-size: 25px;
    margin: 50px 0 0 0;
  }
  .row-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 50px;

    @media (max-width: 1325px) {
      gap: 20px;
    }
  }
  .card-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 460px;
    background: white;
    height: auto;
    min-height: 340px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 5px 10px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      5px -5px 10px rgba(220, 220, 220, 0);

    @media (max-width: 1325px) {
      max-width: 410px;
      height: 379px;
    }
  }
  .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #35414b;
    width: 100%;
    font-weight: 600;
    background: transparent;
    border: none;
    padding: 0;
  }
  .card-text {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #6b7280;
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .create-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #567877;
    height: 48px;
    width: 100%;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .view-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solud #eaecee;
    background: white;
    height: 48px;
    width: 100%;
    border-radius: 10px;
    color: #6b7280;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 64px;
    min-height: 64px;
    background: #e8fafa;
    border: none;
    border-radius: 50%;
  }
  .ul-styling {
    list-style-position: outside;
  }
`;
