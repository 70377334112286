import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import Resizer from "react-image-file-resizer";
import parse from "html-react-parser";
import { Input, Modal } from "antd";
import moment from "moment";
import { cloneDeep, isEmpty } from "lodash";
import axios from "axios";
import client from "feathers.js";

import StyledQuill from "components/Elements/StyledQuill";
import StyledButton from "components/Elements/StyledButton";
import StyledRadio from "components/Elements/StyledRadio";
import ModalLanuch from "components/Extra/ModalLanuch";
import TooltipsLock from "components/Extra/TooltipsLock";
import GoogleSEOPreview from "components/SEOPreview/GoogleSEOPreview";
import { DataContext } from "contexts/DataContextContainer";
import Reward from "./Reward.js";
import { PreviewContainer } from "../../styleEdit";
import { PageContainer } from "../../style";
import Datepicker from "../../../../../components/Datepicker";
import {
  Decrement,
  Increment,
  RemoveFile,
  Success,
  UploadFile,
} from "assets/svg";
import StepsCounter from "components/StepsCounter/index.js";
import QuestionCard from "components/QuestionCard/index.js";

const durationOptions = [
  {
    value: "days",
    label: "Day(s)",
  },
  {
    value: "weeks",
    label: "Week(s)",
  },
  {
    value: "months",
    label: "Month(s)",
  },
  {
    value: "years",
    label: "Year(s)",
  },
];

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      // { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
  ],
};

const Edit = (props) => {
  const { setLoading, showToast } = useContext(DataContext);
  const [member_title, setMember_title] = useState("");
  const [member_count, setMember_count] = useState(0);
  const [member_validity, setMember_validity] = useState(1);
  const [member_validity_unit, setMember_validity_unit] = useState("months");
  const [new_signup_title, setNew_signup_title] = useState("");
  const [new_signup_count, setNew_signup_count] = useState(0);
  const [new_signup_validity, setNew_signup_validity] = useState(1);
  const [new_signup_validity_unit, setNew_signup_validity_unit] =
    useState("months");
  const [periodStart, setPeriodStart] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);
  const [expiry_validity, setExpiry_validity] = useState(1);
  const [expiry_validity_unit, setExpiry_validity_unit] = useState("months");
  const [applicableTo, setApplicableTo] = useState("new");
  const [tos, setTos] = useState("");
  const [tos1, setTos1] = useState("");
  const [canEdit, setCanEdit] = useState(false);
  const [users, setUsers] = useState([]);
  // const [ option, setOption ] = useState('details')
  const [seo_title, setSeo_title] = useState("");
  const [seo_keywords, setSeo_keywords] = useState("");
  const [seo_desc, setSeo_desc] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [previewAction, setPreviewAction] = useState("referrer");
  const [ownSEOTitle, setOwnSEOTitle] = useState(false);
  const [ownSEODesc, setOwnSEODesc] = useState(false);
  const [bannerName, setBannerName] = useState("");

  const [bannerImage, setBannerImage] = useState(null);
  const [croppedBannerUrl, setCroppedBannerUrl] = useState(null);

  const [currentStep, setCurrentStep] = useState(1);
  const [showSuccessCreate, setShowSuccessCreate] = useState(false);

  const [socialSharingPreviewModal, setSocialSharingPreviewModal] =
    useState(false);

  const handleUpload = (file) => {
    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append("NAME", "Fred");
      formData.append("file", file);
      formData.append("toFolder", "seoImages");

      axios({
        method: "post",
        url: `${client.io.io.uri}uploadFileLocalPublic`,
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
      })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleDelete = (currentFileName) => {
    axios({
      method: "post",
      url: `${client.io.io.uri}deleteFileLocalPublic`,
      data: {
        fileName: currentFileName,
        toFolder: "seoImages",
      },
      config: { headers: { "Content-Type": "application/json" } },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resizeFile = (file, getSize) => {
    let compressPercent = 50;
    // 100kb
    if (file.size > 300000) {
      compressPercent = 80;
    }

    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        getSize.width / 2,
        getSize.height / 2,
        file.type,
        compressPercent,
        0,
        (uri) => {
          return resolve(uri);
        },
        "file"
      );
    });
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const getUsers = () => {
    setLoading(true);
    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}searchByOwnCustomer`,
          {
            merchantId: props.userInfo._id,
          },
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        showToast("error", err.message);
        setLoading(false);
      });
  };

  const launch = async () => {
    if (!periodStart || !periodEnd) {
      showToast("error", "Please fill all necessary information");
    } else {
      setLoading(true);

      var newFileName = "";

      if (bannerImage) {
        handleDelete(props.data.seo_img);
        newFileName = await handleUpload(bannerImage);
        // value.seo_img = newFileName
      }

      if (props.action === "edit") {
        const campaignDetail = {
          // title,
          member_title,
          member_validity,
          member_validity_unit,
          new_signup_title,
          new_signup_validity,
          new_signup_validity_unit,
          period_start: periodStart.toDate(),
          period_end: periodEnd.toDate(),
          expiry_validity,
          expiry_validity_unit,
          applicableTo,
          tos,
          tos1,
          seo_title,
          seo_keywords,
          seo_desc,
          seo_img: newFileName,
        };
        if (!croppedBannerUrl) {
          delete campaignDetail.seo_img;
        }

        if (canEdit) {
          updateCampaignDetails(campaignDetail);
        } else {
          const seoDetail = {
            seo_title,
            seo_keywords,
            seo_desc,
            seo_img: newFileName,
          };
          if (!croppedBannerUrl) {
            delete seoDetail.seo_img;
          }
          updateSEO(seoDetail);
        }
      } else {
        const campaignDetail = {
          // merchant_id: props.currentMerchant._id,
          // campaign_type: 'package',
          userId: props.userInfo._id,
          // title,
          member_title,
          member_validity,
          member_validity_unit,
          new_signup_title,
          new_signup_validity,
          new_signup_validity_unit,
          period_start: periodStart.toDate(),
          period_end: periodEnd.toDate(),
          expiry_validity,
          expiry_validity_unit,
          applicableTo,
          tos,
          tos1,
          seo_title,
          seo_keywords,
          seo_desc,
          seo_img: newFileName,
        };
        if (!croppedBannerUrl) {
          delete campaignDetail.seo_img;
        }

        client
          .authenticate()
          .then(() => {
            return client.service("referrals").create(campaignDetail);
          })
          .then((res) => {
            let cloneData = cloneDeep(props.campaignData);

            URL.revokeObjectURL(croppedBannerUrl);
            setCroppedBannerUrl(null);

            props.fetchCampaignInfo();
            props.setCampaignData(cloneData.concat(res));

            setLoading(false);
            showToast("success", "Campaign is created successfully");
            props.back();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            showToast("error", err.message);
          });
      }
    }
  };

  const modalCallback = () => {
    launch();
  };

  const updateCampaignDetails = (campaignDetail) => {
    client
      .authenticate()
      .then(() => {
        return client.service("referral-shares").find({
          query: {
            referralId: props.data._id,
            merchantId: props.userInfo._id,
            $limit: 1,
          },
        });
      })
      .then((res) => {
        if (res.data.length > 0) {
          setLoading(false);
          setCanEdit(false);
          showToast(
            "error",
            "Customer already shared this Referral! Not allow to edit!"
          );
        } else {
          return client
            .service("referrals")
            .patch(props.data._id, campaignDetail)
            .then((res) => {
              let cloneData = cloneDeep(props.campaignData);
              let findKey = cloneData.findIndex(
                (e) => e._id === props.data._id
              );
              if (findKey !== -1) {
                cloneData[findKey] = res;
              }

              URL.revokeObjectURL(croppedBannerUrl);
              setCroppedBannerUrl(null);

              props.fetchCampaignInfo();
              props.setCampaignData(cloneData);

              setLoading(false);
              showToast("success", "Campaign is created successfully");
              props.back();
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        showToast("error", err.message);
      });
  };

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setPeriodStart(dates[0]);
    setPeriodEnd(dates[1]);
  };

  const handleMemberTitle = (value) => {
    if (value.length <= 50) {
      setMember_title(value);
      setMember_count(value.length);
    }
  };

  const handleNewSignupTitle = (value) => {
    if (value.length <= 50) {
      setNew_signup_title(value);
      setNew_signup_count(value.length);
    }
  };

  const handleApplicable = (value) => {
    setApplicableTo(value);

    if (value === "member" && users.length === 0) {
      getUsers();
    }
  };

  const handleImgChange = async (e) => {
    e.preventDefault();

    var singleFile = e.target.files[0];

    let newFileName =
      "seo_referral" +
      (Date.now() + "_" + props.userInfo._id + "_" + singleFile.name);
    let formData = new FormData();
    formData.append("file", singleFile, newFileName);
    let modified = formData.get("file");
    modified.originFileName = singleFile.name;

    if (croppedBannerUrl) {
      URL.revokeObjectURL(croppedBannerUrl);
    }

    if (singleFile.size > 300000) {
      let getSize = await getImageSize(modified);
      const image = await resizeFile(modified, getSize);

      setBannerName(singleFile.name);
      setBannerImage(image);
      setCroppedBannerUrl(URL.createObjectURL(image));
    } else {
      setBannerName(singleFile.name);
      setBannerImage(modified);
      setCroppedBannerUrl(URL.createObjectURL(modified));
    }
  };

  function getImageSize(file) {
    return new Promise((resolve) => {
      var fr = new FileReader();

      fr.onload = function () {
        var img = new Image();

        img.onload = function () {
          return resolve({
            width: img.width,
            height: img.height,
          });
        };

        img.src = fr.result;
      };

      fr.readAsDataURL(file);
    });
  }

  const updateSEO = async (campaignDetail) => {
    setLoading(true);

    client
      .authenticate()
      .then(() => {
        return client
          .service("referrals")
          .patch(props.data._id, campaignDetail);
      })
      .then((res) => {
        let cloneData = cloneDeep(props.campaignData);
        let findKey = cloneData.findIndex((e) => e._id === props.data._id);
        if (findKey !== -1) {
          cloneData[findKey] = res;
        }
        props.setCampaignData(cloneData);

        setLoading(false);
        showToast("success", "Social Sharing is created successfully");
        props.back();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        showToast("error", err.message);
      });
  };

  const renderIcons = (value, setValue) => {
    const incrementCounter = () => {
      setValue(value + 1);
    };
    const decrementCounter = () => {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    };
    return (
      <div className="inc-dec-wrapper">
        <button
          type="button"
          className="increment-button"
          onClick={() => incrementCounter()}
        >
          <img
            src={Increment}
            alt=""
            width="20px"
            height="20px"
            draggable={false}
          />
        </button>
        <button
          type="button"
          className="decrement-button"
          onClick={() => decrementCounter()}
        >
          <img
            src={Decrement}
            alt=""
            width="20px"
            height="18px"
            draggable={false}
          />
        </button>
      </div>
    );
  };

  const onKeyPressedChecking = (event, value, setValue) => {
    if (event.key === "ArrowUp") {
      setValue(value + 1);
    } else if (event.key === "ArrowDown") {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    }
  };

  const inputChecking = (value, setValue) => {
    const cleanedValue = String(value)
      .replace(/[^0-9]/g, "")
      .replace(/^0+/, "");

    const newValue = cleanedValue || "0";
    const parsedValue = parseInt(newValue);

    setValue(parsedValue);
  };

  const removeFileHandler = (e) => {
    setBannerName("");
    setBannerImage(null);
    setCroppedBannerUrl(null);

    document.getElementById("cancel-button").addEventListener("click", (e) => {
      e.stopPropagation();
    });
  };

  const toggleSocialSharing = () => {
    setSocialSharingPreviewModal(!socialSharingPreviewModal);
  };

  const onBackQuestion = () => {
    if (currentStep === 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onNextQuestion = () => {
    if (currentStep === 1 && periodStart && periodEnd) {
      setCurrentStep(2);
    } else if (currentStep === 2 && applicableTo) {
      setCurrentStep(3);
    } else if (currentStep === 3 && member_title.length > 0) {
      setCurrentStep(4);
    } else if (currentStep === 4 && member_validity > 0) {
      setCurrentStep(5);
    } else if (currentStep === 5) {
      setCurrentStep(6);
    } else if (currentStep === 6 && new_signup_title) {
      setCurrentStep(7);
    } else if (currentStep === 7 && new_signup_validity > 0) {
      setCurrentStep(8);
    } else if (currentStep === 8) {
      setShowSuccessCreate(true);
    }
  };

  const renderQuestionCard = (q, d, c) => {
    return (
      <QuestionCard
        question={q}
        descriptions={<div className="description-text">{d}</div>}
        totalQuestion={6}
        currentActive={currentStep}
        onClickBackButton={onBackQuestion}
        onClickNextButton={onNextQuestion}
        noPaginator
        questionHeight="auto"
        answerHeight="100px"
        children={c}
      />
    );
  };

  const renderFirstTimeEditContent = () => {
    const description = [
      "Set up the referral campaign period",
      "Set up who is applicable for this campaign",
      "Set the rewards for the referrers",
      "Set the validity of the referrer's rewards",
      "Add extra terms & conditions for referrer's rewards",
      "Set up gifts for referees'",
      "Set the validity of the referee's gifts",
      "Add extra terms & conditions for referee's gifts",
    ];

    return (
      <div className="first-time-edit-content">
        <div className="first-time-step-container">
          <div className="first-time-step-wrapper">
            <StepsCounter
              currentStepNumber={currentStep}
              stepDescriptions={description}
              left="49%"
              progressHeight="85%"
            />
          </div>
        </div>

        <div className="first-time-step-details-container">
          {currentStep === 1 &&
            renderQuestionCard(
              "How long will the referral campaign be?",
              "Set the start and end date for this referral campaign.",
              <>
                <Datepicker
                  periodStart={periodStart}
                  periodEnd={periodEnd}
                  onChange={onChangeCampaignPeriod}
                  noLabel
                />
              </>
            )}
          {currentStep === 2 &&
            renderQuestionCard(
              "Who is applicable to use this referral campaign?",
              "Set which customers can have the benefits of using this campaign.",
              <div className="input">
                <StyledRadio
                  options={[
                    {
                      value: "new",
                      label: "New customer",
                    },
                    // {
                    //   value: "member",
                    //   label: "Existing customer",
                    // }
                  ]}
                  value={applicableTo}
                  onChange={(e) => handleApplicable(e.target.value)}
                />
              </div>
            )}
          {currentStep === 3 &&
            renderQuestionCard(
              "What will be the reward for the referrers?",
              "Provide an interesting reward to catch the users' attention.",
              <div className="input-container">
                <Input
                  type="text"
                  size="large"
                  className="first-time-input"
                  value={member_title}
                  onChange={(e) => handleMemberTitle(e.target.value)}
                  placeholder={"$15 Cash Voucher"}
                />
                <p className="answer-input-description">
                  Characters ({member_title.trim().length}/50)
                </p>
              </div>
            )}
          {currentStep === 4 &&
            renderQuestionCard(
              "What is the validity of the referral's rewards?",
              "Provide the period of time the referral's reward stays valid.",
              <div className="input-container">
                <div className="input-container">
                  <Input
                    className="first-time-input"
                    type="text"
                    size="large"
                    value={member_validity}
                    onKeyDown={(e) =>
                      onKeyPressedChecking(
                        e,
                        member_validity,
                        setMember_validity
                      )
                    }
                    onChange={(e) =>
                      inputChecking(e.target.value, setMember_validity)
                    }
                    onBlur={() =>
                      member_validity === 0 && setMember_validity(1)
                    }
                  />
                  {renderIcons(member_validity, setMember_validity)}
                </div>
                <div className="number-preset">
                  <button
                    className={member_validity_unit === "days" ? "active" : ""}
                    onClick={() => setMember_validity_unit("days")}
                  >
                    Day(s)
                  </button>
                  <button
                    className={member_validity_unit === "weeks" ? "active" : ""}
                    onClick={() => setMember_validity_unit("weeks")}
                  >
                    Week(s)
                  </button>
                  <button
                    className={
                      member_validity_unit === "months" ? "active" : ""
                    }
                    onClick={() => setMember_validity_unit("months")}
                  >
                    Month(s)
                  </button>
                  <button
                    className={member_validity_unit === "years" ? "active" : ""}
                    onClick={() => setMember_validity_unit("years")}
                  >
                    Year(s)
                  </button>
                </div>
              </div>
            )}
          {currentStep === 5 &&
            renderQuestionCard(
              <>
                <div>Any other terms & conditions?</div>
              </>,
              <ul className="description-list">
                <li>
                  Campaign Period{" "}
                  {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""}{" "}
                  - {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
                </li>
                <li>
                  This card is valid for {member_validity}{" "}
                  {member_validity_unit.slice(0, -1)}(s) upon first collect
                </li>
              </ul>,
              <StyledQuill
                readOnly={false}
                modules={quillModules}
                value={tos}
                onChange={async (value) => {
                  setTos(value);
                }}
              />
            )}
          {currentStep === 6 &&
            renderQuestionCard(
              "What is the referee's gift?",
              "Provide an interesting reward to catch the user's attention.",
              <div className="input-container">
                <Input
                  type="text"
                  size="large"
                  className="first-time-input"
                  value={new_signup_title}
                  onChange={(e) => handleNewSignupTitle(e.target.value)}
                  placeholder={"$10 Cash Voucher"}
                />
              </div>
            )}
          {currentStep === 7 &&
            renderQuestionCard(
              "What is the validity of the referee's gift?",
              "Provide the period of time the referee's gift stays valid.",
              <div className="input-container">
                <div className="input-container">
                  <Input
                    className="first-time-input"
                    type="text"
                    size="large"
                    value={new_signup_validity}
                    onKeyDown={(e) =>
                      onKeyPressedChecking(
                        e,
                        new_signup_validity,
                        setNew_signup_validity
                      )
                    }
                    onChange={(e) =>
                      inputChecking(e.target.value, setNew_signup_validity)
                    }
                    onBlur={() =>
                      new_signup_validity === 0 && setNew_signup_validity(1)
                    }
                  />
                  {renderIcons(new_signup_validity, setNew_signup_validity)}
                </div>
                <div className="number-preset">
                  <button
                    className={
                      new_signup_validity_unit === "days" ? "active" : ""
                    }
                    onClick={() => setNew_signup_validity_unit("days")}
                  >
                    Day(s)
                  </button>
                  <button
                    className={
                      new_signup_validity_unit === "weeks" ? "active" : ""
                    }
                    onClick={() => setNew_signup_validity_unit("weeks")}
                  >
                    Week(s)
                  </button>
                  <button
                    className={
                      new_signup_validity_unit === "months" ? "active" : ""
                    }
                    onClick={() => setNew_signup_validity_unit("months")}
                  >
                    Month(s)
                  </button>
                  <button
                    className={
                      new_signup_validity_unit === "years" ? "active" : ""
                    }
                    onClick={() => setNew_signup_validity_unit("years")}
                  >
                    Year(s)
                  </button>
                </div>
              </div>
            )}
          {currentStep === 8 &&
            renderQuestionCard(
              <>
                <div>Final step!</div>
                <div>Any other terms & conditions?</div>
              </>,
              <ul className="description-list">
                <li>
                  Campaign Period{" "}
                  {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""}{" "}
                  - {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
                </li>
                <li>
                  This card is valid for {new_signup_validity}{" "}
                  {new_signup_validity_unit.slice(0, -1)}(s) upon first collect
                </li>
              </ul>,
              <StyledQuill
                readOnly={false}
                modules={quillModules}
                value={tos1}
                onChange={async (value) => {
                  setTos1(value);
                }}
              />
            )}
        </div>
      </div>
    );
  };

  const successCreate = () => {
    return (
      <div className="first-time-edit-content">
        <div className="success-container">
          <img src={Success} alt="" width="50px" height="50px" />
          Almost Done!
          <StyledButton
            title="Preview"
            onClick={toggleModal}
            type="submit"
            className="primary-button"
            bgcolor={"#567877"}
          />
        </div>
      </div>
    );
  };

  const renderEditContent = () => {
    return (
      <>
        <div className="edit-content">
          <div className="input-container">
            Referral campaign period
            <Datepicker
              periodStart={periodStart}
              periodEnd={periodEnd}
              onChange={onChangeCampaignPeriod}
              noLabel
            />
          </div>

          <div className="input-container">
            Referral campaign applicability
            <div className="input">
              <StyledRadio
                options={[
                  {
                    value: "new",
                    label: "New customer",
                  },
                  // {
                  //   value: "member",
                  //   label: "Existing customer",
                  // }
                ]}
                value={applicableTo}
                onChange={(e) => handleApplicable(e.target.value)}
              />
            </div>
          </div>

          <div className="input-container">
            <div>
              <b>Referrer's Reward</b> (Whenever referee redeems their gift)
            </div>
            <Input
              type="text"
              size="large"
              className="input"
              value={member_title}
              onChange={(e) => handleMemberTitle(e.target.value)}
              placeholder={"$15 Cash Voucher"}
            />
          </div>

          <div className="input-container">
            <div className="input-container">
              Referral reward validity
              <Input
                className="input"
                type="text"
                size="large"
                value={member_validity}
                onKeyDown={(e) =>
                  onKeyPressedChecking(e, member_validity, setMember_validity)
                }
                onChange={(e) =>
                  inputChecking(e.target.value, setMember_validity)
                }
                onBlur={() => member_validity === 0 && setMember_validity(1)}
              />
              {renderIcons(member_validity, setMember_validity)}
            </div>
            <StyledRadio
              options={durationOptions}
              value={member_validity_unit}
              onChange={(e) => setMember_validity_unit(e.target.value)}
              className="col-md-6"
            />
          </div>

          <div className="input-container">
            Additional Terms & Condition
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <li>
                Campaign Period{" "}
                {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""} -{" "}
                {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
              </li>
              <li>
                This card is valid for {member_validity}{" "}
                {member_validity_unit.slice(0, -1)}(s) upon first collect
              </li>
            </ul>
            <StyledQuill
              readOnly={false}
              modules={quillModules}
              value={tos}
              onChange={async (value) => {
                setTos(value);
              }}
            />
          </div>

          <div className="button-wrapper-container">
            <StyledButton
              title="Preview"
              onClick={() => {
                toggleModal();
                setPreviewAction("referrer");
              }}
              type="submit"
              className="primary-button"
              bgcolor={"#567877"}
            />
          </div>

          <div className="input-container">
            <b>Referee's Gift</b>
            <Input
              type="text"
              size="large"
              className="input"
              value={new_signup_title}
              onChange={(e) => handleNewSignupTitle(e.target.value)}
              placeholder={"$10 Cash Voucher"}
            />
          </div>

          <div className="input-container">
            <div className="input-container">
              Referee reward validity
              <Input
                className="input"
                type="text"
                size="large"
                value={new_signup_validity}
                onKeyDown={(e) =>
                  onKeyPressedChecking(
                    e,
                    new_signup_validity,
                    setNew_signup_validity
                  )
                }
                onChange={(e) =>
                  inputChecking(e.target.value, setNew_signup_validity)
                }
                onBlur={() =>
                  new_signup_validity === 0 && setNew_signup_validity(1)
                }
              />
              {renderIcons(new_signup_validity, setNew_signup_validity)}
            </div>
            <StyledRadio
              options={durationOptions}
              value={new_signup_validity_unit}
              onChange={(e) => setNew_signup_validity_unit(e.target.value)}
            />
          </div>

          <div className="input-container">
            Additional Terms & Condition
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <li>
                Campaign Period{" "}
                {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""} -{" "}
                {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
              </li>
              <li>
                This card is valid for {new_signup_validity}{" "}
                {new_signup_validity_unit.slice(0, -1)}(s) upon first collect
              </li>
            </ul>
            <StyledQuill
              readOnly={false}
              modules={quillModules}
              value={tos1}
              onChange={async (value) => {
                setTos1(value);
              }}
            />
          </div>

          <div className="button-wrapper-container">
            <StyledButton
              title="Preview"
              onClick={() => {
                toggleModal();
                setPreviewAction("referee");
              }}
              type="submit"
              className="primary-button"
              bgcolor={"#567877"}
            />
          </div>

          <div className="input-container">
            Social Sharing Title
            <Input
              type="text"
              className="input"
              name="seo_title"
              placeholder="Social Title"
              value={seo_title}
              disabled={props.userInfo.subscription === "copper" ? true : false}
              onChange={(e) => {
                setOwnSEOTitle(true);
                setSeo_title(e.target.value);
              }}
            />
            {props.userInfo.subscription === "copper" ? <TooltipsLock /> : null}
          </div>

          <div className="input-container">
            Social Sharing Description
            <textarea
              type="text"
              className="input"
              name="seo_desc"
              placeholder="Social Desciption"
              style={{
                cursor: `${
                  props.userInfo.subscription === "copper"
                    ? "not-allowed"
                    : "pointer"
                }
          `,
              }}
              value={seo_desc}
              disabled={props.userInfo.subscription === "copper" ? true : false}
              onChange={(e) => {
                setOwnSEODesc(true);
                setSeo_desc(e.target.value);
              }}
            />
            {props.userInfo.subscription === "copper" ? <TooltipsLock /> : null}
          </div>

          <div className="input-container">
            Social Sharing image
            <div className="input-image-container">
              <label
                htmlFor="img-logo"
                className="label-wrapper"
                style={{
                  padding: "25px",
                  minHeight: "155px",
                  cursor: `${
                    props.userInfo.subscription === "copper"
                      ? "not-allowed"
                      : "pointer"
                  }
          `,
                }}
              >
                {croppedBannerUrl ? (
                  <>
                    <img
                      src={croppedBannerUrl}
                      alt=""
                      width="auto"
                      height="155px"
                    />
                    <div className="left-content-text">
                      <div style={{ color: "#567877", fontWeight: "750" }}>
                        Click to upload
                      </div>
                      &nbsp;or drag and drop
                    </div>
                    <div className="left-content-text">PNG, JPEG</div>
                  </>
                ) : (
                  <>
                    <img src={UploadFile} alt="" width="40px" height="40px" />
                    <div className="left-content-text">
                      <div style={{ color: "#567877", fontWeight: "750" }}>
                        Click to upload
                      </div>
                      &nbsp;or drag and drop
                    </div>
                    <div className="left-content-text">PNG, JPEG</div>
                  </>
                )}
              </label>
              <input
                id="img-logo"
                type="file"
                accept=".png, .jpeg"
                disabled={
                  props.userInfo.subscription === "copper" ? true : false
                }
                onClick={(event) => {
                  event.target.value = null;
                }}
                onChange={(e) => handleImgChange(e)}
                style={{
                  display: "none",
                }}
              />
              {bannerImage && (
                <button
                  type="button"
                  id="cancel-button"
                  onClick={(e) => removeFileHandler(e, "logo")}
                  className="remove-file-button"
                >
                  <img src={RemoveFile} alt="" width="20px" height="20px" />
                </button>
              )}
            </div>
            {props.userInfo.subscription === "copper" ? <TooltipsLock /> : null}
          </div>

          <div className="button-wrapper-container">
            <button
              type="button"
              onClick={() => setSocialSharingPreviewModal(true)}
              className="primary-button"
            >
              Preview
            </button>
          </div>

          <div
            className="button-wrapper-container"
            style={{ margin: "100px 0 0 0" }}
          >
            <button
              type="button"
              onClick={() => props.back()}
              className="cancel-button"
            >
              Back
            </button>
            <StyledButton
              title={"Launch"}
              onClick={launch}
              type="submit"
              className="primary-button"
              bgcolor={"#567877"}
            />
          </div>
        </div>
      </>
    );
  };

  const unNecessaryCommentedLines = () => {
    return {
      /* <div
        className={classNames({
          "referral-overlay": !canEdit,
        })}
      >
        <div className='page-content mb-3'>
          <Space
            className='detail'
            style={{ background: "transparent" }}
            size={32}
            direction='vertical'
          >
            {applicableTo === "member" ? (
              <Select
                mode='multiple'
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder='Please select'
                // defaultValue={['a10', 'c12']}
                onChange={handleChange}
              >
                {users.length > 0 &&
                  users.map((x, i) => {
                    return (
                      <Option key={i} value={x._id}>
                        {x.email} ({x.lastName + " " + x.firstName})
                      </Option>
                    );
                  })}
              </Select>
            ) : null}

            <Space className='section' size={8} direction='vertical'>
                <TooltipsMRZ 
                  title="Tracking cookie will be valid for"
                  tips={(
                    <>
                      Set the time frame of tracking cookie to be in-force. If you set 1 week, the tracking cookie will expire after 1 week and will not be tracked.
                      <br/>
                      Note: When the referred-in friend clicks on the referral link and visits your website or app, a tracking cookie is embedded in their browser that then allows you to track the referral, as long as they continue using the same browser on the same device, or don't clear cookies.
                    </>
                  )}
                />
                <div className='stamp-duration row no-gutters' style={{justifyContent: 'flex-start'}}>
                  <Input className='col-md-2 mr-2' type='number' min={1} size="large" value={expiry_validity} onChange={e => setExpiry_validity(e.target.value)}/>
                  <StyledRadio
                    options={durationOptions}
                    value={expiry_validity_unit}
                    onChange={e => setExpiry_validity_unit(e.target.value)}
                    className='col-md-6'
                  />
                </div>
              </Space>
          </Space>
        </div>
      </div> */
    };
  };

  useEffect(() => {
    if (props.data._id) {
      client
        .authenticate()
        .then(() => {
          return client.service("referral-shares").find({
            query: {
              referralId: props.data._id,
              merchantId: props.userInfo._id,
              $limit: 1,
            },
          });
        })
        .then((res) => {
          if (res.data.length > 0) {
            showToast(
              "error",
              "Customer already shared this Referral! Not allow to edit!"
            );
          } else {
            setCanEdit(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    } else {
      setCanEdit(true);
    }
    /* eslint-disable-next-line */
  }, [props.data._id, props.userInfo._id]);

  useEffect(() => {
    if (!isEmpty(props.data)) {
      // setTitle(props.data.title)
      setMember_title(props.data.member_title);
      setMember_count(props.data.new_signup_title.length);
      setMember_validity(props.data.member_validity);
      setMember_validity_unit(props.data.member_validity_unit);
      setNew_signup_title(props.data.new_signup_title);
      setNew_signup_count(props.data.new_signup_title.length);
      setNew_signup_validity(props.data.new_signup_validity);
      setNew_signup_validity_unit(props.data.new_signup_validity_unit);
      setPeriodStart(moment(new Date(props.data.period_start), "DD/MM/YYYY"));
      setPeriodEnd(moment(new Date(props.data.period_end), "DD/MM/YYYY"));
      setExpiry_validity(props.data.expiry_validity);
      setExpiry_validity_unit(props.data.expiry_validity_unit);
      setApplicableTo(props.data.applicableTo);
      setTos(props.data.tos);
      setTos1(props.data.tos1);
      setCroppedBannerUrl(
        `${process.env.REACT_APP_API_URL}/${props.data.seo_img}`
      );

      setSeo_keywords(props.data.seo_keywords);

      if (props.data.seo_title) {
        setOwnSEOTitle(true);
        setSeo_title(props.data.seo_title);
      }
      if (props.data.seo_desc) {
        setOwnSEODesc(true);
        setSeo_desc(props.data.seo_desc);
      }
    }
  }, [
    props.data._id,
    props.data,
    props.userInfo.business_name,
    props.userInfo.subscription,
  ]);

  useEffect(() => {
    if (props.action === "create") {
      if (props.userInfo.subscription === "copper") {
        setSeo_title(`Get MoreREWARDZ from ${props.userInfo.business_name}`);
      } else {
        if (!ownSEOTitle)
          setSeo_title(`Get MoreREWARDZ from ${props.userInfo.business_name}`);
      }
    }
    /* eslint-disable-next-line */
  }, [
    new_signup_title,
    props.userInfo.business_name,
    props.userInfo.subscription,
  ]);

  useEffect(() => {
    if (props.action === "create") {
      if (props.userInfo.subscription === "copper") {
        setSeo_desc(
          `Redeem ${new_signup_title} from ${props.userInfo.business_name}. Click on the link to redeem now!`
        );
      } else {
        if (!ownSEODesc)
          setSeo_desc(
            `Redeem ${new_signup_title} from ${props.userInfo.business_name}. Click on the link to redeem now!`
          );
      }
    }
    /* eslint-disable-next-line */
  }, [
    new_signup_title,
    props.userInfo.business_name,
    props.userInfo.subscription,
  ]);

  return (
    <PageContainer>
      {props.firstTime
        ? showSuccessCreate
          ? successCreate()
          : renderFirstTimeEditContent()
        : renderEditContent()}

      <Modal
        centered
        footer={null}
        closeIcon={<></>}
        open={socialSharingPreviewModal}
        maskClosable={true}
        onCancel={() => toggleSocialSharing()}
        bodyStyle={{
          height: "auto",
          width: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          padding: "0",
        }}
      >
        <GoogleSEOPreview
          username={props.userInfo.username}
          title={seo_title}
          description={seo_desc}
          image={
            croppedBannerUrl ||
            (props.data.seo_img
              ? client.io.io.uri + props.data.seo_img
              : client.io.io.uri + props.userInfo.logo)
          }
        />
        {croppedBannerUrl || client.io.io.uri + props.data.seo_img ? (
          <>
            <br />
            <br />
          </>
        ) : null}
      </Modal>

      <ModalLanuch
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        campaign="referral"
        header={
          <>
            <span className="title1 text-center">
              <br />
              Campaign Preview
            </span>
          </>
        }
        body={
          <PreviewContainer>
            {previewAction === "referrer" ? (
              <div className="preview-wrapper">
                <div className="preview">
                  {/* <span>Preview</span> */}
                  <div className="details">
                    <div className="title">
                      <h4 style={{ fontSize: 15, wordBreak: "unset" }}>
                        Invite your friend{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            color: "black",
                          }}
                        >
                          {"{"}
                          {new_signup_title ? new_signup_title : "-"}
                          {"}"}
                        </span>{" "}
                        and you will be rewarded with{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            color: "black",
                          }}
                        >
                          {"{"}
                          {member_title ? member_title : "-"}
                          {"}"}
                        </span>{" "}
                        when they redeem the gift.
                      </h4>
                    </div>
                    <div className="stamps">
                      {/* <h4>{`Expiry : ${member_validity_unit} ${member_validity_unit} when first collected`}</h4>
                          <h4>{`Expiry : ${new_signup_validity} ${new_signup_validity_unit} when first collected`}</h4> */}
                    </div>
                    <div className="terms">
                      <h4>Terms & Condition</h4>
                      <ul style={{ marginBottom: 0 }}>
                        <li>
                          Campaign Period{" "}
                          {periodStart
                            ? moment(periodStart).format("DD/MM/YYYY")
                            : ""}{" "}
                          -{" "}
                          {periodEnd
                            ? moment(periodEnd).format("DD/MM/YYYY")
                            : ""}
                        </li>
                        <li>
                          This card is valid for {member_validity}{" "}
                          {member_validity_unit.slice(0, -1)}(s) upon first
                          collect
                        </li>
                      </ul>
                      {<div>{parse(tos)}</div>}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="preview-wrapper">
                {/* <div className='preview'>
                    <span>Preview</span>
                  </div> */}
                <div style={{ transform: "scale(.9)" }}>
                  <Reward
                    periodStart={periodStart}
                    periodEnd={periodEnd}
                    new_signup_title={new_signup_title}
                    new_signup_validity={new_signup_validity}
                    new_signup_validity_unit={new_signup_validity_unit}
                    applicableTo={applicableTo}
                    tos={tos1}
                    merchant={props.userInfo}
                  />
                </div>
              </div>
            )}
          </PreviewContainer>
        }
        isReward={previewAction === "referrer" ? false : true}
        canEdit={canEdit}
        noLaunch={true}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
