import { 
  Menu, 
  Dropdown 
} from 'antd';
import { 
  EllipsisOutlined, 
  FormOutlined 
} from '@ant-design/icons';
import { ActionMenuContainer } from "../../Admin/style";
import moment from 'moment';
import { Tag } from 'antd';

const menu = (record, setAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item key="0" onClick={() => {
        setAction('edit')
        setDataEdit(record)
        }}>
        <FormOutlined /> Edit
      </Menu.Item>
      {/* <Menu.Item key="1">
        <PauseOutlined /> Pause
      </Menu.Item>
      <Menu.Item key="2">
        <FileTextOutlined /> Details
      </Menu.Item> */}
    </ActionMenuContainer>
  )
}

const columns = (setAction, setDataEdit) => [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (record, records, index) => 
      <span>{index+1}</span>
    },
    {
      title: 'Merchant ID',
      dataIndex: 'merchantName',
      key: 'merchantName'
    },
    {
      title: 'Url',
      key: 'url',
      render: (record) => 
      <a href={window.location.origin + "/signup?aff=" + record.shortId} target="_blank" rel="noreferrer">{window.location.origin + "/signup?aff=" + record.shortId}</a>
    },
    {
      title: 'No.of registered',
      key: 'noOfRegistered',
      render: (record) => 
        <span>{record.merchants.length}</span>
    },
    {
      title: 'Pending Amount',
      dataIndex: 'amountToPay',
      key: 'amountToPay',
      render: (record) => 
        record? <span>${Number(record).toFixed(2)}</span> : 0
    },
    {
      title: 'Commisions',
      key: 'amountToPay',
      render: (records) => {
        return (new Date() > new Date(moment(records.merchantInfo.createdAt).add(1, 'years'))?
        // second year
        (records.amountToPay? <span>${((10 / 100) * Number(records.amountToPay)).toFixed(2)}</span> :0)
        :
        // first year
        (records.amountToPay? <span>${((20 / 100) * Number(records.amountToPay)).toFixed(2)}</span> :0)
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (record, records) => {
        if(record){
          if(record.toLowerCase() === 'false'){
            return (
              <>
              <Tag color="#2db7f5" className='text-capitalize'>Pending</Tag>
              {records.resubmitDate? (
                <Tag color="#f50" className='text-capitalize'>Resubmit</Tag>
              ): null}
              </>
            )
          }else if(record.toLowerCase() === 'reject'){
            return (
              <>
              <Tag color="#f50" className='text-capitalize'>{record}</Tag>
              {records.resubmitDate? (
                <Tag color="#f50" className='text-capitalize'>Resubmit</Tag>
              ): null}
              </>
            )
          }else{
            return (
              <>
              <Tag color="#87d068" className='text-capitalize'>{record}</Tag>
              {records.resubmitDate? (
                <Tag color="#f50" className='text-capitalize'>Resubmit</Tag>
              ): null}
              </>
            )
          }
        }else{
          return null
        }
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => 
        <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (record) => (
        <Dropdown overlay={menu(record, setAction, setDataEdit)} trigger={['click']}>
          <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
            <EllipsisOutlined style={{fontSize: 20}}/>
          </a>
        </Dropdown>
      ),
    },
  ]

export default columns;
