import React from "react";
import { Modal } from "antd";
import {
    FaTimesCircle
  } from "react-icons/fa";
import { ModalContainer } from "./style"
import 'index.css'

const RecipientModal = (props) => {

  return ( 
    <>
        <Modal
            title={false}
            visible={props.modalVisible}
            onCancel={() => {
            props.toggleModal()
            }}
            centered
            closable={true}
            footer={false}
            maskClosable={false}
            closeIcon={<FaTimesCircle className='close-icon'/>}
        >
            <ModalContainer>
                <div className="header">
                    {props.header}
                </div>

                <div className="body w-100" style={{textAlign: props.textAlign?props.textAlign:'center'}}>
                    {props.body}
                </div>
            </ModalContainer>
        </Modal>
    </>
  );
}
 
export default RecipientModal;