import React from "react";
import { StyledButtonContainer } from "./style";
import { LockIcon } from "assets/images";

function StyledButton({
  form,
  type,
  className = "",
  title = "",
  sizeh,
  width = "100%",
  onClick = () => {},
  disabled,
  outlined,
  bgcolor,
  radius,
  textTransform,
  weight,
  isLock,
}) {
  return (
    <>
      <StyledButtonContainer
        type={type}
        form={form}
        className={className}
        sizeh={sizeh}
        width={width}
        onClick={onClick}
        disabled={disabled}
        outlined={outlined}
        bgcolor={bgcolor}
        radius={radius}
        textTransform={textTransform}
        weight={weight}
      >
        {title}
        {isLock ? <img src={LockIcon} alt='' /> : null}
      </StyledButtonContainer>
    </>
  );
}

export default StyledButton;
