import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { CSVDownload } from "react-csv";
import { Table, Pagination, AutoComplete, Spin } from "antd";
import dayjs from "dayjs";
import client from "feathers.js";

import { DataContext } from "contexts/DataContextContainer";
import { PageContainer } from "../style";
import Datepicker from "../../../../components/Datepicker";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const AuditLogs = (props) => {
  const history = useHistory();
  const { showToast, setLocation, setBreadcrumb } = useContext(DataContext);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});

  const [searchStaffId, setSearchStaffId] = useState("");
  const [searchCustomerEmail, setSearchCustomerEmail] = useState("");
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMerchantId, setCurrentMerchantId] = useState("");
  const [downloadQuery, setDownloadQuery] = useState("");

  const [spin, setSpin] = useState(false);
  const [reRender, setRerender] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const columns = () => [
    {
      title: "Staff ID",
      dataIndex: "repName",
      key: "repName",
    },
    {
      title: "Customer Email",
      dataIndex: "customer_email",
      key: "customer_email",
    },
    {
      title: "Campaign Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Campaign Type",
      dataIndex: "campaignType",
      key: "campaignType",
    },
    {
      title: "Time of Scan",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

  const filterData = () => {
    let filterQuery;
    setCurrentPage(1);

    if (
      searchStaffId !== "" &&
      searchStaffId !== undefined &&
      (searchCustomerEmail === "" || searchCustomerEmail === undefined) &&
      !endDate
    ) {
      filterQuery = {
        repName: `${searchStaffId}`,
      };
      setDownloadQuery(filterQuery);
    } else if (
      searchCustomerEmail !== "" &&
      searchCustomerEmail !== undefined &&
      (searchStaffId === "" || searchCustomerEmail === undefined) &&
      !endDate
    ) {
      filterQuery = {
        customer_email: `${searchCustomerEmail}`,
      };
      setDownloadQuery(filterQuery);
    } else if (
      startDate &&
      endDate &&
      (searchCustomerEmail === "" || searchCustomerEmail === undefined) &&
      (searchStaffId === "" || searchCustomerEmail === undefined)
    ) {
      filterQuery = {
        $skip: (currentPage - 1) * PAGESIZE,
        $and: [
          {
            createdAt: {
              $gte: new Date(
                new Date(startDate).setHours(0, 0, 0, 0)
              ).getTime(),
            },
          },
          {
            createdAt: {
              $lte: new Date(
                new Date(endDate).setHours(23, 59, 59, 999)
              ).getTime(),
            },
          },
        ],
      };
      setDownloadQuery(filterQuery);
    } else if (
      searchStaffId !== "" &&
      searchStaffId !== undefined &&
      searchCustomerEmail !== "" &&
      searchCustomerEmail !== undefined &&
      !endDate
    ) {
      filterQuery = {
        repName: `${searchStaffId}`,
        customer_email: `${searchCustomerEmail}`,
      };
      setDownloadQuery(filterQuery);
    } else if (
      searchStaffId !== "" &&
      searchStaffId !== undefined &&
      (searchCustomerEmail === "" || searchCustomerEmail === undefined) &&
      startDate &&
      endDate
    ) {
      filterQuery = {
        repName: `${searchStaffId}`,
        $and: [
          {
            createdAt: {
              $gte: new Date(
                new Date(startDate).setHours(0, 0, 0, 0)
              ).getTime(),
            },
          },
          {
            createdAt: {
              $lte: new Date(
                new Date(endDate).setHours(23, 59, 59, 999)
              ).getTime(),
            },
          },
        ],
      };
      setDownloadQuery(filterQuery);
    } else if (
      (searchStaffId === "" || searchStaffId === undefined) &&
      searchCustomerEmail !== "" &&
      searchCustomerEmail !== undefined &&
      startDate &&
      endDate
    ) {
      filterQuery = {
        customer_email: `${searchCustomerEmail}`,
        $and: [
          {
            createdAt: {
              $gte: new Date(
                new Date(startDate).setHours(0, 0, 0, 0)
              ).getTime(),
            },
          },
          {
            createdAt: {
              $lte: new Date(
                new Date(endDate).setHours(23, 59, 59, 999)
              ).getTime(),
            },
          },
        ],
      };
      setDownloadQuery(filterQuery);
    } else if (
      searchStaffId !== "" &&
      searchStaffId !== undefined &&
      searchCustomerEmail !== "" &&
      searchCustomerEmail !== undefined &&
      startDate &&
      endDate
    ) {
      filterQuery = {
        repName: `${searchStaffId}`,
        customer_email: `${searchCustomerEmail}`,
        $and: [
          {
            createdAt: {
              $gte: new Date(
                new Date(startDate).setHours(0, 0, 0, 0)
              ).getTime(),
            },
          },
          {
            createdAt: {
              $lte: new Date(
                new Date(endDate).setHours(23, 59, 59, 999)
              ).getTime(),
            },
          },
        ],
      };
      setDownloadQuery(filterQuery);
    } else {
      filterQuery = {
        $and: [
          {
            createdAt: {
              $gte: new Date(new Date().setMonth(new Date().getMonth() - 2)),
            },
          },
          {
            createdAt: {
              $lte: new Date(),
            },
          },
        ],
      };
      setDownloadQuery(filterQuery);
    }
  };

  const searchDate = (startDate, endDate) => {
    if (startDate && endDate) {
    }
  };

  const downloadCSV = async () => {
    let newQuery = {};
    let tmpData = [];
    let numOfLoop = [];

    if (props.userInfo.subscription === "copper") {
      showToast("error", "Only Bronze and above allow to download CSV!");
      return false;
    } else {
      const stackData = (value) => {
        if (downloadQuery === "") {
          newQuery = {
            status: "authorized",
            merchantId: `${currentMerchantId}`,
            $skip: value * pagination.per_page,
            $and: [
              {
                createdAt: {
                  $gte: new Date(
                    new Date().setMonth(new Date().getMonth() - 2)
                  ),
                },
              },
              {
                createdAt: {
                  $lte: new Date(),
                },
              },
            ],
          };
        } else {
          newQuery = {
            ...downloadQuery,
            $skip: value * pagination.per_page,
            status: "authorized",
            merchantId: `${currentMerchantId}`,
          };
        }

        return new Promise((resolve) => {
          client
            .service("activities")
            .find({
              query: newQuery,
            })
            .then((res) => {
              let d = [];

              for (let i = 0; i < res.data.length; i++) {
                d.push({
                  "Staff Id": res.data[i].repName,
                  Email: res.data[i].customer_email,
                  "Campaign Name": res.data[i].title,
                  "Campaign Type": res.data[i].campaignType,
                  "Time of Scan": dayjs(res.data[i].updatedAt).format(
                    "DD-MM-YYYY HH:mmA"
                  ),
                  Remarks: res.data[i].remarks,
                });
              }

              tmpData = tmpData.concat(d);
              resolve(res.data);
            });
        });
      };

      for (let i = 0; i < pagination.last_page; i++) {
        numOfLoop.push(i);
      }

      let process = await numOfLoop.map(async (value, index) => {
        let data = await stackData(index);

        return data;
      });

      let result = await Promise.all(process);

      if (result) {
        setCsvData(tmpData);
        setSpin(true);
        setTimeout(() => {
          setSpin(false);
        }, 1500);
      }
    }
  };

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    searchDate(dates[0], dates[1]);
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);

  useEffect(() => {
    let defaultQuery;

    client.authenticate().then((auth) => {
      defaultQuery = {
        status: "authorized",
        merchantId: auth.user._id,
        $limit: PAGESIZE,
        $skip: (pagination.current_page - 1) * PAGESIZE,
        $and: [
          {
            createdAt: {
              $gte: new Date(new Date().setMonth(new Date().getMonth() - 2)),
            },
          },
          {
            createdAt: {
              $lte: new Date(),
            },
          },
        ],
      };
      setTimeout(() => {
        setRerender(true);

        if (downloadQuery === "") {
          client
            .service("activities")
            .find({
              query: defaultQuery,
            })
            .then((res) => {
              const massagedData = res.data.map((item) => {
                return {
                  ...item,
                  campaignType:
                    item.campaignType.charAt(0).toUpperCase() +
                    item.campaignType.slice(1),
                  updatedAt: dayjs(item.updatedAt).format("DD-MM-YYYY HH:mmA"),
                };
              });
              setData(massagedData);
              setPagination(paginationCount(res.total, currentPage));
              setCurrentMerchantId(auth.user._id);
              setRerender(false);
            });
        } else {
          client
            .service("activities")
            .find({
              query: {
                ...downloadQuery,
                status: "authorized",
                merchantId: `${currentMerchantId}`,
                $limit: PAGESIZE,
                $skip: (currentPage - 1) * PAGESIZE,
              },
            })
            .then((res) => {
              const massagedData = res.data.map((item) => {
                return {
                  ...item,
                  campaignType:
                    item.campaignType.charAt(0).toUpperCase() +
                    item.campaignType.slice(1),
                  updatedAt: dayjs(item.updatedAt).format("DD-MM-YYYY HH:mmA"),
                };
              });
              setData(massagedData);
              setPagination(paginationCount(res.total, currentPage));
              setCurrentMerchantId(auth.user._id);
              setRerender(false);
            });
        }
      }, 500);
    });
  }, [currentPage, downloadQuery]);

  return (
    <PageContainer>
      <div className='row-header'>
        <div className='status-wrapper'>
          <AutoComplete
            className='search-bar'
            onChange={(e) => setSearchStaffId(e)}
            value={searchStaffId}
            onClear={(e) => {
              setSearchStaffId("");
            }}
            placeholder='Search By Staff ID'
            allowClear
          />
          <AutoComplete
            className='search-bar'
            onChange={(e) => setSearchCustomerEmail(e)}
            value={searchCustomerEmail}
            onClear={(e) => {
              setSearchCustomerEmail("");
            }}
            placeholder='Search By Customer Email'
            allowClear
          />
          <Datepicker
            periodStart={startDate}
            periodEnd={endDate}
            onChange={onChangeCampaignPeriod}
            noLabel
            dateDisable={true}
          />
        </div>
        <div className='button-wrapper'>
          <button
            type='button'
            className='filter-button'
            onClick={() => [setRerender(true), filterData()]}
          >
            {reRender ? <Spin /> : "Filter Data"}
          </button>
          <button
            type='button'
            className='primary-button'
            disabled={props.userInfo.subscription === "copper" ? true : false}
            onClick={() => downloadCSV()}
          >
            {spin ? <Spin /> : "Download CSV"}
          </button>
          {spin ? (
            <>
              <CSVDownload data={csvData} target='_blank' />
            </>
          ) : null}
        </div>
      </div>

      <div className='row-container'>
        <Table
          rowKey='_id'
          columns={columns()}
          dataSource={data}
          pagination={false}
        />
      </div>

      <div className='row-container'>
        <Pagination
          pageSize={PAGESIZE}
          total={pagination.total}
          current={currentPage}
          showSizeChanger={false}
          onChange={(e) => {
            setCurrentPage(e);
            setPagination(paginationCount(pagination.total, e));
          }}
        />
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

export default connect(mapStateToProps, null)(AuditLogs);
