import { breakpoints, colors } from "style";
import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 24px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .page-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .detail {
      // max-width: 500px;
      width: 100%;
      display: flex;
      flex-direction: column;
      background: white;
      padding: 30px;
      border-radius: 16px;
      margin-right: 40px;
      .section {
        width: 100%;
        .title {
          font-size: 16px;
          font-weight: bold;
          color: ${colors.purple};
        }
        .stamp-duration {
          justify-content: space-between;
          align-items: center;
          input {
            padding-left: 8px;
          }
        }
        .ant-picker {
          width: 100%;
          height: 40px;
        }
        .ant-radio-wrapper {
          span: nth-child(2) {
            font-weight: normal;
          }
        }
        .ql-container {
          min-height: 200px;
        }
      }
    }
    .preview-wrapper {
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      .preview {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;

        span {
          color: ${colors.purple};
          font-size: 24px;
          font-weight: bold;
        }
        h4 {
          margin-top: 16px;
          color: black !important;
        }
        .details {
          width: 100%;
          border-radius: 8px;
          .title {
            padding: 16px 10px;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            background-color: ${colors.red};
            background-image: linear-gradient(to right, ${colors.red} , ${colors.yellow});
            h4 {
              color: white !important;
              margin-top: 0;
              margin-bottom: 0;
            }
          }
          .stamps {
            padding: 16px;
            h4 {
              color: ${colors.purple} !important;
              text-align: center;
              margin-bottom: 20px;
            }
            .cards {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .card {
                display: flex;
                justify-content: center;
                padding: 4px;
                background: ${colors.lightGrey};
                height: 36px;
                width: 32%;
                margin-bottom: 12px;
                .card-no {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 20px;
                  height: 20px;
                  background: ${colors.bgGrey};
                  border-radius: 10px;
                  color: white;
                  font-weight: bold;
                  font-size: 14px;
                }
                &:last-child {
                  visibility: hidden;
                }
              }
            }
          }
          .terms {
            padding: 0 16px;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            h4 {
              margin-top: 0;
            }
            span, p {
              margin-bottom: 4px;
            }
          }

          -webkit-box-shadow: 2px 3px 10px 2px rgba(0,0,0,0.2);
          box-shadow: 2px 3px 10px 2px rgba(0,0,0,0.2);
        }
      }
      .actions {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
