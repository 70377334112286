import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { CSVDownload } from "react-csv";
import { Table, Pagination, Spin } from "antd";
import { AutoComplete } from "antd";
import client from "feathers.js";

import { PageContainer } from "./style";
import getCampaignData from "./getCampaignData";
import loadCsvData from "./loadCsvData";
import Edit from "./Edit";

import { DataContext } from "contexts/DataContextContainer";
import { Search, Download } from "assets/svg";

import classNames from "classnames";

import Datepicker from "../../../../components/Datepicker";
import columns_stamps from "./columns_stamps";
import columns_packages from "./columns_packages";
import columns_vouchers from "./columns_vouchers";
import columns_points from "./columns_points";
import columns_transactions from "./columns_transactions";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

var typingTimer = 0;
var typingTimer1 = 0;

const CustomerManagement = (props) => {
  const { isLoading, setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [action, setAction] = useState("all"); //edit, detail, create
  const [campaignData, setCampaignData] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [pagination, setPagination] = useState({});
  const [startDate, setStartDate] = useState(false);
  const [spin, setSpin] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [searchEmail, setSearchEmail] = useState("");
  const [searchCampaign, setSearchCampaign] = useState("");
  const [options, setOptions] = useState([]);
  const [options1, setOptions1] = useState([]);
  const [campaignId, setCampaignId] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [voucherType, setVoucherType] = useState("voucher");

  var getTab = Number(new URLSearchParams(props.location.search).get("tab"));
  var getEmail = new URLSearchParams(props.location.search).get("email");
  var getTitle = new URLSearchParams(props.location.search).get("title");
  var getCampaignId = new URLSearchParams(props.location.search).get(
    "campaign"
  );

  const fetchCampaign = async (key) => {
    setActiveTab(key);
    getCampaignData(
      0,
      1,
      props.userInfo._id,
      key,
      false,
      false,
      showToast, // 7
      setLoading, // 8
      setPagination, // 9
      setCampaignData, // 10
      paginationCount, // 11
      customerId,
      campaignId,
      voucherType
    );
    setStartDate(false);
    setEndDate(false);
  };

  const back = () => {
    setAction("all");
  };

  const searchDate = (startDate, endDate) => {
    if (startDate && endDate) {
      getCampaignData(
        0,
        1,
        props.userInfo._id,
        activeTab,
        startDate,
        endDate,
        showToast, // 7
        setLoading, // 8
        setPagination, // 9
        setCampaignData, // 10
        paginationCount, // 11
        customerId,
        campaignId,
        voucherType
      );
    }
  };

  const renderColumns = () => {
    if (activeTab === 0) {
      return columns_stamps(setAction, setDataEdit);
    } else if (activeTab === 1) {
      return columns_packages(setAction, setDataEdit);
    } else if (activeTab === 2) {
      return columns_vouchers(setAction, setDataEdit);
    } else if (activeTab === 3) {
      return columns_points(setAction, setDataEdit);
    } else if (activeTab === 4) {
      return columns_transactions(setAction, setDataEdit);
    }
  };

  const downloadCSV = async () => {
    if (props.userInfo.subscription === "copper") {
      showToast("error", "Only Bronze and above allow to download CSV!");
      return false;
    }

    loadCsvData(
      activeTab,
      props.userInfo._id,
      setCsvData,
      setSpin,
      setLoading,
      pagination,
      startDate,
      endDate,
      showToast,
      customerId,
      campaignId,
      voucherType
    );
  };

  const filterData = (id) => {
    getCampaignData(
      0,
      1,
      props.userInfo._id,
      activeTab,
      startDate,
      endDate,
      showToast, // 7
      setLoading, // 8
      setPagination, // 9
      setCampaignData, // 10
      paginationCount, // 11
      customerId ? customerId : id,
      campaignId,
      voucherType
    );
  };

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    searchDate(dates[0], dates[1]);
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);

  useEffect(() => {
    let tab = 0;
    if (getTab === 0 || getTab === 1) {
      tab = getTab;
      setActiveTab(tab);
    } else if (getTab === 2 || getTab === 3 || getTab === 4) {
      tab = 2;
      setActiveTab(tab);
    }
    getCampaignData(
      0,
      1,
      props.userInfo._id,
      tab,
      false,
      false,
      showToast, // 7
      setLoading, // 8
      setPagination, // 9
      setCampaignData, // 10
      paginationCount, // 11
      customerId,
      campaignId,
      voucherType
    );
    /* eslint-disable-next-line */
  }, [props.userInfo._id, getTab]);

  useEffect(() => {
    if (getEmail) {
      setSearchEmail(getEmail);
    }
  }, [getEmail]);

  useEffect(() => {
    if (getCampaignId) {
      setCampaignId(getCampaignId);
    }
    if (getTitle) {
      setSearchCampaign(getTitle);
    }
  }, [getCampaignId, getTitle]);

  useEffect(() => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      if (searchEmail) {
        client
          .authenticate()
          .then((auth) => {
            return client.service("users").find({
              query: {
                merchantId: props.userInfo.userInfo,
                role: "user",
                set_done: "done",
                orRegex: { email: searchEmail },
                // $sort: {
                //   createdAt: -1
                // }
              },
            });
          })
          .then((res) => {
            if (res.data.length > 0) {
              setCustomerId(res.data[0]._id);
              setOptions(
                res.data.map((x) => {
                  return {
                    _id: x._id,
                    value: x.email,
                  };
                })
              );

              if (!firstLoad) {
                filterData(res.data[0]._id);
                setFirstLoad(true);
              }
            } else {
              setCustomerId("");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setCustomerId("");
      }
    }, 500);

    /* eslint-disable-next-line */
  }, [props.userInfo.userInfo, searchEmail]);

  useEffect(() => {
    clearTimeout(typingTimer1);
    typingTimer1 = setTimeout(() => {
      if (searchCampaign) {
        client
          .authenticate()
          .then(async (auth) => {
            if (activeTab === 0) {
              return client.service("stamps").find({
                query: {
                  merchantId: props.userInfo.userInfo,
                  orRegex: { title: searchCampaign },
                  $select: ["title"],
                },
              });
            } else if (activeTab === 1) {
              return client.service("packages").find({
                query: {
                  merchantId: props.userInfo.userInfo,
                  orRegex: { title: searchCampaign },
                  $select: ["title"],
                },
              });
            } else if (activeTab === 2 || activeTab === 3 || activeTab === 4) {
              let r1 = await client.service("vouchers").find({
                query: {
                  merchantId: props.userInfo.userInfo,
                  orRegex: { title: searchCampaign },
                  $select: ["title"],
                },
              });
              let r2 = await client.service("points").find({
                query: {
                  merchantId: props.userInfo.userInfo,
                  orRegex: { title: searchCampaign },
                  $select: ["title"],
                },
              });
              let r3 = await client.service("referrals").find({
                query: {
                  merchantId: props.userInfo.userInfo,
                  orRegex: { title: searchCampaign },
                  $select: ["title", "member_title"],
                },
              });

              return {
                data: r1.data
                  .map((x) => {
                    x.voucherType = "voucher";
                    return x;
                  })
                  .concat(
                    r2.data.map((x) => {
                      x.voucherType = "point";
                      return x;
                    })
                  )
                  .concat(
                    r3.data.map((x) => {
                      x.voucherType = "referral";
                      x.title = x.member_title;
                      return x;
                    })
                  ),
              };
            }
          })
          .then((res) => {
            if (res.data.length > 0) {
              setCampaignId(res.data[0]._id);
              setVoucherType(res.data[0].voucherType);
              setOptions1(
                res.data.map((x) => {
                  return {
                    _id: x._id,
                    value: x.title,
                    voucherType: x.voucherType,
                  };
                })
              );
            } else {
              setCampaignId("");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setCampaignId("");
      }
    }, 500);

    /* eslint-disable-next-line */
  }, [props.userInfo.userInfo, searchCampaign]);

  return (
    <PageContainer>
      {action === "all" ? (
        <>
          <div className='tabs-container'>
            <button
              type='button'
              className={classNames("tabs", {
                active: activeTab === 0,
              })}
              onClick={() => fetchCampaign(0)}
            >
              Stamps
            </button>
            <button
              className={classNames("tabs", {
                active: activeTab === 1,
              })}
              onClick={() => fetchCampaign(1)}
            >
              Package
            </button>
            <button
              className={classNames("tabs", {
                active: activeTab === 2,
              })}
              onClick={() => fetchCampaign(2)}
            >
              Voucher
            </button>
            <button
              className={classNames("tabs", {
                active: activeTab === 3,
              })}
              onClick={() => fetchCampaign(3)}
            >
              Points
            </button>
          </div>

          <div className='filter-container'>
            <div className='filter-left-content'>
              <AutoComplete
                className='search-bar'
                options={options1}
                onChange={(e) => setSearchCampaign(e)}
                onSelect={(e, o) => {
                  setCampaignId(o._id);
                  setVoucherType(o.voucherType);
                }}
                value={searchCampaign}
                onClear={(e) => {
                  setCampaignId("");
                  setSearchCampaign("");
                }}
                placeholder={
                  <div className='placeholder'>
                    <div className='placeholder-text'>Search outlet...</div>
                    <img src={Search} alt='' width='16px' height='16px' />
                  </div>
                }
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                allowClear
              />
              <Datepicker
                periodStart={startDate}
                periodEnd={endDate}
                onChange={onChangeCampaignPeriod}
                noLabel
                dateDisable={true}
              />
            </div>
            <div className='filter-right-content'>
              <button
                type='button'
                className='filter-button'
                onClick={() => filterData()}
              >
                <div className='d-flex align-items-center'>Filter</div>
              </button>
              <button
                type='button'
                className='primary-button'
                disabled={
                  props.userInfo.subscription === "copper" ? true : spin
                }
                onClick={downloadCSV}
              >
                {spin ? (
                  <Spin />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <img src={Download} alt='' width='16px' height='16px' />
                    Download CSV
                  </div>
                )}
              </button>
              {spin ? (
                <>
                  <CSVDownload data={csvData} target='_blank' />
                </>
              ) : null}
            </div>
          </div>

          <div className='table'>
            {!isLoading ? (
              <>
                <Table
                  rowKey='_id'
                  columns={renderColumns()}
                  dataSource={campaignData}
                  pagination={false}
                />
                <Pagination
                  pageSize={PAGESIZE}
                  current={pagination.current_page}
                  total={pagination.total}
                  showSizeChanger={false}
                  onChange={(e) => {
                    setPagination(paginationCount(pagination.total, e));
                    getCampaignData(
                      (e - 1) * PAGESIZE,
                      e,
                      props.userInfo._id,
                      activeTab,
                      startDate,
                      endDate,
                      showToast, // 7
                      setLoading, // 8
                      setPagination, // 9
                      setCampaignData, // 10
                      paginationCount, // 11
                      customerId,
                      campaignId,
                      voucherType
                    );
                  }}
                />
              </>
            ) : null}
          </div>
        </>
      ) : (
        <Edit
          userInfo={props.userInfo}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          action={action}
          data={dataEdit}
          back={back}
        />
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);
