import { Menu, Dropdown } from "antd";
import {
  EllipsisOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ActionMenuContainer } from "../../style";
import moment from "moment";

const menu = (
  record,
  setAction,
  setDataEdit,
  toggleModal,
  setConfirmAction
) => {
  return (
    <ActionMenuContainer>
      <Menu.Item
        key='0'
        onClick={() => {
          setAction("edit");
          setDataEdit(record);
        }}
      >
        <FormOutlined /> Edit
      </Menu.Item>
      <Menu.Item
        key='1'
        onClick={() => {
          toggleModal();
          setConfirmAction("activate");
          setDataEdit(record);
        }}
      >
        {record.status === "Active" && (
          <>
            <PauseOutlined /> Deactivate
          </>
        )}
        {record.status === "Inactive" && (
          <>
            <PlayCircleOutlined /> Activate
          </>
        )}
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => {
          toggleModal();
          setConfirmAction("delete");
          setDataEdit(record);
        }}
      >
        <DeleteOutlined /> Delete
      </Menu.Item>
    </ActionMenuContainer>
  );
};

const columns = (
  setAction,
  setDataEdit,
  toggleModal,
  setConfirmAction,
  setPreviewAction,
  toggleTotalModal
) => [
  {
    title: "Campaign",
    key: "title",
    render: (record) => {
      return (
        <a
          href='#edit'
          onClick={() => {
            setAction("edit");
            setDataEdit(record);
          }}
        >
          {record.title}
        </a>
      );
    },
  },
  {
    title: "No.of Stamps",
    dataIndex: "count",
    key: "count",
  },
  {
    title: "Total Collected",
    // dataIndex: 'totalCollected',
    key: "totalCollected",
    render: (record) => {
      return (
        <a
          href='#edit'
          onClick={() => {
            setPreviewAction("collect");
            setDataEdit(record);
            toggleTotalModal();
          }}
        >
          {record.totalCollected}
        </a>
      );
    },
  },
  {
    title: "Total Completed",
    // dataIndex: 'totalCompleted',
    key: "totalCompleted",
    render: (record) => {
      return (
        <a
          href='#edit'
          onClick={() => {
            setPreviewAction("complete");
            setDataEdit(record);
            toggleTotalModal();
          }}
        >
          {record.totalCompleted}
        </a>
      );
    },
  },
  {
    title: "Total Redeemed",
    // dataIndex: 'totalRedeemed',
    key: "totalRedeemed",
    render: (record) => {
      return (
        <a
          href='#edit'
          onClick={() => {
            setPreviewAction("redeem");
            setDataEdit(record);
            toggleTotalModal();
          }}
        >
          {record.totalRedeemed}
        </a>
      );
    },
  },
  {
    title: "Start Date",
    dataIndex: "period_start",
    key: "period_start",
    render: (record) => (
      <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    ),
  },
  {
    title: "End Date",
    dataIndex: "period_end",
    key: "period_end",
    render: (record) => (
      <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    ),
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (record) => (
      <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    ),
  },
  // {
  //   title: 'Redeemed',
  //   key: 'status',
  //   dataIndex: 'status',
  //   render: status => status? <span>{status}</span>: <span>-</span>
  // },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) => (
      <span
        className={status === "Active" ? "status-active" : "status-inactive"}
      >
        {status}
      </span>
    ),
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    render: (record) => (
      <Dropdown
        overlay={menu(
          record,
          setAction,
          setDataEdit,
          toggleModal,
          setConfirmAction
        )}
        trigger={["click"]}
      >
        <a
          href='/#'
          className='ant-dropdown-link'
          onClick={(e) => e.preventDefault()}
          style={{ color: "gray" }}
        >
          <EllipsisOutlined style={{ fontSize: 20 }} />
        </a>
      </Dropdown>
    ),
  },
];

export default columns;
