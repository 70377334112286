import styled from "styled-components";
import { breakpoints, colors } from "style";

export const TopbarContainer = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  background: #3b7779;
  height: 90px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  position: relative;
  .img {
    width: 200px;
    // margin-left: 60px;
    // position: absolute;
    // margin: auto;
    // left: 0;
    // right: 0;
  }
  .btn-back {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
  }
  .navbar {
    height: 90px;
    border-bottom: solid 1px #000;
    .topbar-wrapper {
      .navbar-brand {
        margin-left: 20px;
        img {
          width: 220px;
        }
      }
      .navbar-toggler {
        border-color: transparent;
        color: ${colors.black} !important;
      }
    }
    .navbar-collapse {
      .navbar-nav {
        .link-nav {
          color: ${colors.white};
          font-size: 14px;
          line-height: 23px;
          margin-right: 26px;
          text-transform: uppercase;
          align-self: center;
          &:hover {
            color: ${colors.red};
            text-decoration: none;
          }
        }
        .btn-campaign {
          color: ${colors.black};
          padding: 4px 12px;
          background: ${colors.orange};
          &:hover {
            color: ${colors.white};
          }
        }
        .btn-login {
          color: ${colors.black};
          padding: 2px 10px;
          background: ${colors.grey};
          &:hover {
            color: ${colors.white};
          }
        }
        .dropdown {
          .dropdown-toggle {
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .topbar-bottom {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      color: ${colors.black};
      font-size: 18px;
      margin: 0 16px;
    }
  }
  @media (max-width: 767px) {
    z-index: 1000;
    .navbar {
      height: 52px;
      padding: 0;
      .topbar-wrapper {
        min-height: 50px;
        display: flex;
        align-items: center;
        width: 100%;
        .navbar-brand {
          flex: 1;
          text-align: center;
          margin-top: 0;
          margin-left: 0;
          img {
            height: 29px;
          }
        }
      }

      .navbar-collapse {
        background: white;
        .navbar-nav {
          padding: 15px;
          a {
            margin-left: 0px;
            align-self: flex-start;
            margin-bottom: 5px;
          }
        }
      }
    }
    .topbar-bottom {
      height: 40px;
    }
  }
  @media (max-width: ${breakpoints.xs}px) {
    .topbar-bottom {
      a {
        font-size: 15px;
      }
    }
  }
`;
