import React, { useRef, useState, useContext, useEffect } from "react";
import { PageContainer } from "./style";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";
import StyledButton from "components/Elements/StyledButton";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Alert,
  InputGroup,
  Form as BootstrapForm,
} from "react-bootstrap";
import CountryList from "country-list-with-dial-code-and-flag";
import { InboxOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Validation from "data/validation.js";
import parse from "html-react-parser";

import client from "feathers.js";
import axios from "axios";
import moment from "moment";
import InfoCard from "components/InfoCard/InfoCard";
import DetailsSection from "components/DetailsSection/RowContainer";
import { DropdownArrow, Lock, MessageCheck, RemoveFile } from "assets/svg";

const { Dragger } = Upload;

const WalletSchema = Yup.object().shape({
  name: Yup.string().required("Required!"),
  contact_email: Yup.string().email().required("Required!"),
  contact_phone: Yup.string()
    .required("Required!")
    .matches(
      Validation.PHONE_REG_WITHOUT_COUNTRY_CODE,
      "Phone number is not valid"
    ),
});

const Business = (props) => {
  // const { title } = props
  const history = useHistory();
  const file1El = useRef();
  const file2El = useRef();
  const file3El = useRef();
  const [phonePrefix, setPhonePrefix] = useState("+65");
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [submitFormData, setSubmitFormData] = useState({});
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [tos, setTos] = useState("");

  useEffect(() => {
    // Submit Form
    getRequestFormData();
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  const getRequestFormData = () => {
    setLoading(true);
    client
      .authenticate()
      .then(() => {
        return client.service("requests").find({
          query: {
            userId: props.userInfo._id,
            $limit: 1,
          },
        });
      })
      .then((res) => {
        // Is Edit
        if (res.data.length > 0) {
          setTos(res.data[0].tos);
          setPhonePrefix(res.data[0].phonePrefix);
          setSubmitFormData(res.data[0]);
          setLoading(false);
        } else {
          // Is Create
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
        // No stop loading
        // setLoading(false)
      });
  };

  const handleImgChange = (e, setFile) => {
    let singleFile = e.target.files[0];
    let newFileName =
      Date.now() + "_" + props.userInfo._id + "_" + singleFile.name;
    let formData = new FormData();
    formData.append("file", singleFile, newFileName);

    let modified = formData.get("file");
    modified.originFileName = singleFile.name;

    setFile(modified);

    return (e.target.value = null);
  };

  const fileUpload = async (file) => {
    const formData = new FormData();
    formData.append("NAME", "Fred");
    formData.append("file", file);

    return await axios({
      method: "post",
      url: `${client.io.io.uri}uploadFileLocal`,
      data: formData,
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleDeleteFile = (fileName) => {
  //   axios({
  //     method: 'post',
  //     url: `${client.io.io.uri}deleteFileLocal`,
  //     data: {fileName,},
  //     config: { headers: {'Content-Type': 'application/json' }}
  //   })
  //   .then((res) => {
  //     return (res.data)
  //   })
  //   .catch((err) => {
  //     console.log(err)
  //   })
  // }

  const onChange = (info) => {
    // const { status } = info.file;

    let result = info.fileList.map((x) => {
      let singleFile = x.originFileObj;

      let newFileName =
        Date.now() + "_" + props.userInfo._id + "_" + singleFile.name;
      let formData = new FormData();
      formData.append("file", singleFile, newFileName);
      let modified = formData.get("file");
      modified.originFileName = singleFile.name;
      return modified;
    });

    setFileList(result);

    // if (status !== 'uploading') {
    //   console.log(info.file, info.fileList);
    // }

    // if (status === 'done') {
    //   message.success(`${info.file.name} file uploaded successfully.`);
    // } else if (status === 'error') {
    //   message.error(`${info.file.name} file upload failed.`);
    // }
  };

  const onDrop = (e) => {
    console.log("Dropped files", e.dataTransfer.files);
  };

  const handleSubmitWallet = async (value, { resetForm }) => {
    value.phonePrefix = phonePrefix;

    if (
      (file1 ||
        (submitFormData.files ? submitFormData.files[0].file : false)) &&
      (file2 ||
        (submitFormData.files ? submitFormData.files[1].file : false)) &&
      (file3 || (submitFormData.files ? submitFormData.files[2].file : false))
    ) {
      let files = [
        {
          name: "Business Registration License",
          originFileName: "",
          file: "",
          fileData: file1,
        },
        {
          name: "Ultilities Bills",
          originFileName: "",
          file: "",
          fileData: file2,
        },
        {
          name: "Business owner Passport or ID card",
          originFileName: "",
          file: "",
          fileData: file3,
        },
      ];

      let result = await Promise.all(
        files.map(async (x, i) => {
          if (x.fileData) {
            let file = await fileUpload(x.fileData);
            x.originFileName = x.fileData.originFileName;
            x.file = file;
            delete x.fileData;
            return x;
          } else {
            if (submitFormData.files[i]) {
              delete x.fileData;
              x.originFileName = submitFormData.files[i].originFileName;
              x.file = submitFormData.files[i].file;
              return x;
            } else {
              return x;
            }
          }
        })
      );

      // upload fileList
      let moreFiles = await Promise.all(
        fileList.map(async (x, i) => {
          let file = await fileUpload(x);
          return {
            fileName: x.name,
            originFileName: file,
          };
        })
      );

      if (result && moreFiles) {
        value.files = result;
        value.moreFiles = submitFormData.moreFiles
          ? moreFiles.concat(submitFormData.moreFiles)
          : moreFiles;
        value.submitDate = moment(new Date()).format();
        value.merchantName = props.username;
        value.status = "false";

        setLoading(true);
        if (submitFormData._id) {
          value.resubmitDate = moment(new Date()).format();

          client
            .authenticate()
            .then(() => {
              return client
                .service("requests")
                .patch(submitFormData._id, value);
            })
            .then((res) => {
              // resetForm()
              setFile1(null);
              setFile2(null);
              setFile3(null);
              setFileList([]);
              setLoading(false);
              setSubmitFormData(res);
              showToast("success", "Request Re-Submit Successful!");
            })
            .catch((err) => {
              console.log(err);
              showToast("error", err.message);
              setLoading(false);
            });
        } else {
          // Create Request
          client
            .authenticate()
            .then(() => {
              return client.service("requests").create(value);
            })
            .then((res) => {
              setSubmitFormData(res);
              resetForm();
              setFile1(null);
              setFile2(null);
              setFile3(null);
              setFileList([]);
              setLoading(false);
              showToast("success", "Request Submit Successful!");
            })
            .catch((err) => {
              console.log(err);
              showToast("error", err.message);
              setLoading(false);
            });
        }
      }
    } else {
      showToast("error", "Please Upload necessary files!");
    }
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Page", props.title]);
  }, []);

  return (
    <PageContainer style={{ position: "relative" }}>
      <div className='message-container'>
        <InfoCard desc='The business verification process in MoreRewardz ensures a secure and reliable environment for both merchants and customers. By verifying your business, you gain credibility and demonstrate your commitment to providing a trustworthy loyalty program.' />

        <div className='checkmark-container'>
          {props.userInfo.business_verified ? (
            <img src={MessageCheck} alt='' width='24px' height='24px' />
          ) : null}
          Blue checkmark displayed against merchant indicated that MoreRewardz
          had taken steps to ensure that the account was actually owned by the
          person or organization whom it claimed to represent.
        </div>

        <div className='application-wrapper'>
          {/* props.userInfo.business_verified */}
          {submitFormData.status === "" || submitFormData.status === "false" ? (
            <Alert variant='primary'>
              <Alert.Heading>
                Your application to verify your business is pending.
              </Alert.Heading>
              <p>
                <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  TAKE NOTE:
                </span>{" "}
                We may take up to 7 days (or more) to review your application.
              </p>
            </Alert>
          ) : null}
          {submitFormData.status === "approved" ? (
            <Alert variant='success'>
              <Alert.Heading>
                Your application for a business verification has approved!
              </Alert.Heading>
              {/* {tos && parse(tos)} */}
            </Alert>
          ) : null}
          {submitFormData.status === "reject" ? (
            <Alert variant='danger'>
              <Alert.Heading>
                Your application to have your business verified has been
                rejected!
              </Alert.Heading>
              {tos && parse(tos)}
              <br />
              {submitFormData.submitDate ? (
                <>
                  {/* <p>Submit Date at {moment(submitFormData.submitDate).format('DD/MM/YYYY') }</p>
                        <p>Please Resubmit the from at {moment(submitFormData.submitDate).add(1, 'weeks').format('DD/MM/YYYY') }</p> */}
                </>
              ) : null}
            </Alert>
          ) : null}
        </div>
      </div>

      <DetailsSection
        leftHeader='Business Verification Documents'
        leftDesc={`For your business's security, please upload the required documents.`}
        rightContent={
          <>
            <div className='input-container'>
              Business Registration Licence:
              <input
                type='file'
                ref={file1El}
                onChange={(e) => handleImgChange(e, setFile1)}
                hidden
              />
              <div className='input'>
                <button
                  className='file-upload-button'
                  onClick={() => file1El.current.click()}
                >
                  Upload File
                </button>
                <div className={file1 ? "file-text" : "placeholder-text"}>
                  {file1
                    ? file1.originFileName
                    : submitFormData.files
                    ? submitFormData.files[0].originFileName
                    : "No file choosen"}
                </div>
                {file1 && (
                  <button
                    type='button'
                    className='remove-file-button'
                    onClick={() => setFile1(null)}
                  >
                    <img src={RemoveFile} alt='' width='20px' height='20px' />
                  </button>
                )}
              </div>
            </div>

            <div className='input-container'>
              Utilities Bills:
              <input
                type='file'
                ref={file2El}
                onChange={(e) => handleImgChange(e, setFile2)}
                hidden
              />
              <div className='input'>
                <button
                  className='file-upload-button'
                  onClick={() => file2El.current.click()}
                >
                  Upload File
                </button>
                <div className={file2 ? "file-text" : "placeholder-text"}>
                  {file2
                    ? file2.originFileName
                    : submitFormData.files
                    ? submitFormData.files[1].originFileName
                    : "No file choosen"}
                </div>
                {file2 && (
                  <button
                    type='button'
                    className='remove-file-button'
                    onClick={() => setFile2(null)}
                  >
                    <img src={RemoveFile} alt='' width='20px' height='20px' />
                  </button>
                )}
              </div>
            </div>

            <div className='input-container'>
              Business owner Passport of ID Card
              <input
                type='file'
                ref={file3El}
                onChange={(e) => handleImgChange(e, setFile3)}
                hidden
              />
              <div className='input'>
                <button
                  className='file-upload-button'
                  onClick={() => file3El.current.click()}
                >
                  Upload File
                </button>
                <div className={file3 ? "file-text" : "placeholder-text"}>
                  {file3
                    ? file3.originFileName
                    : submitFormData.files
                    ? submitFormData.files[2].originFileName
                    : "No file choosen"}
                </div>
                {file3 && (
                  <button
                    type='button'
                    className='remove-file-button'
                    onClick={() => setFile3(null)}
                  >
                    <img src={RemoveFile} alt='' width='20px' height='20px' />
                  </button>
                )}
              </div>
            </div>
          </>
        }
      />

      <DetailsSection
        leftHeader='Business Details'
        leftDesc='Provide your business details here. These details will be displayed on your campaign page for customers to reach out directly if needed.'
        rightContent={
          <Formik
            initialValues={{
              name: submitFormData.name ? submitFormData.name : "",
              contact_phone: submitFormData.contact_phone
                ? submitFormData.contact_phone
                : "",
              contact_email: submitFormData.contact_email
                ? submitFormData.contact_email
                : "",
            }}
            enableReinitialize
            validationSchema={WalletSchema}
            onSubmit={handleSubmitWallet}
          >
            {({ values }) => (
              <Form className='form-styling'>
                <div className='input-container'>
                  Name
                  <Field
                    type='text'
                    className='input'
                    name='name'
                    placeholder='Name'
                  />
                  <div className='error-message'>
                    <ErrorMessage name='name' />
                  </div>
                </div>

                <div className='input-container'>
                  Phone Number
                  <div className='right-content'>
                    <InputGroup style={{ width: "300px" }}>
                      <BootstrapForm.Control
                        as='select'
                        aria-label='Default select example'
                        onChange={(e) => setPhonePrefix(e.target.value)}
                        value={phonePrefix}
                        className='input'
                        style={{
                          appearance: "none",
                          cursor: "pointer",
                        }}
                      >
                        {CountryList.sort(function (a, b) {
                          if (a.name === "Malaysia" || a.name === "Singapore") {
                            return -1;
                          } else {
                            return a.name - b.name;
                          }
                        }).map((v, i) => {
                          return (
                            <option key={i} value={v.dial_code}>
                              {v.flag + " " + v.dial_code}
                            </option>
                          );
                        })}
                      </BootstrapForm.Control>
                      <div className='icon-wrapper'>
                        <img
                          src={DropdownArrow}
                          alt=''
                          width='20px'
                          height='20px'
                        />
                      </div>
                    </InputGroup>
                    <div className='input-container'>
                      <Field
                        type='text'
                        className='input'
                        name='contact_phone'
                        placeholder='Contact Number'
                      />
                      <div className='error-message'>
                        <ErrorMessage name='contact_phone' />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='input-container'>
                  Email
                  <Field
                    type='text'
                    className='input'
                    name='contact_email'
                    placeholder='Email Address'
                  />
                  <div className='error-message'>
                    <ErrorMessage name='contact_email' />
                  </div>
                </div>

                <div className='row-container'>
                  <StyledButton
                    title={
                      <>
                        <div className='button-text'>
                          Confirm
                          {props.userInfo.subscription === "copper" && (
                            <div className='button-icon-wrapper'>
                              <img
                                src={Lock}
                                alt=''
                                width='24px'
                                height='24px'
                              />
                            </div>
                          )}
                        </div>
                      </>
                    }
                    sizeh={44}
                    type='submit'
                    className={
                      props.userInfo.subscription === "copper"
                        ? "confirm-button-disabled"
                        : "confirm-button"
                    }
                    radius='7'
                    bgcolor={"#567877"}
                    disabled={
                      props.userInfo.subscription === "copper" ? true : false
                    }
                  />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Business);
