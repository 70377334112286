import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import client from "feathers.js";
import axios from "axios";
import { cloneDeep } from "lodash";
import { Table, Pagination } from "antd";

import columns from "./columns";
import Edit from "./Edit";
import NewForm from "./New";
import EditPassword from "./Password";
import { PageContainer } from "../style";
import { DataContext } from "contexts/DataContextContainer";
import ModalConfirmation from "components/Extra/ModalConfirmation";
import DataCard from "components/DataCard";
import env from "env";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const MerchantReps = (props) => {
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [action, setAction] = useState("all"); //edit, detail, create
  const [campaignData, setCampaignData] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [campaignInfo, setCampaignInfo] = useState({});
  const [pagination, setPagination] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const fetchCampaignInfo = () => {
    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}getStaffInformation`,
          {
            userId: props.userInfo._id,
          },
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        setCampaignInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
        showToast("error", err.message);
      });
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const modalCallback = () => {
    setLoading(true);

    let status = dataEdit.status === "baned" ? "" : "baned";

    function updateStatus() {
      client
        .authenticate()
        .then(() => {
          return client
            .service("users")
            .patch(dataEdit._id, { status: status });
        })
        .then((res) => {
          let cloneData = cloneDeep(campaignData);
          let findKey = cloneData.findIndex((e) => e._id === dataEdit._id);
          if (findKey !== -1) {
            cloneData[findKey] = res;
          }

          fetchCampaignInfo();
          setCampaignData(cloneData);

          setLoading(false);
          showToast("success", "Staff status edited successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    }

    // if deactive
    // if active => deactive no check
    if (status !== "baned") {
      if (
        campaignInfo.numActivate <
        env.subscription[props.userInfo.subscription || "copper"].staff
      ) {
        updateStatus();
      } else {
        showToast("error1", `to unlock additional limits and features!`);
        setLoading(false);
      }
    } else {
      updateStatus();
    }
  };

  const back = () => {
    setAction("all");
  };

  const getCampaignData = (skip, currentPage, userId) => {
    setLoading(true);
    client
      .authenticate()
      .then((auth) => {
        // return axios.post(`${client.io.io.uri}searchByOwnCustomer`, {
        //   merchantId: userId
        // },
        // {
        //   headers: {
        //     'Authorization': auth.accessToken
        //   }
        // }
        // )
        return client.service("users").find({
          query: {
            $skip: skip,
            $limit: PAGESIZE,
            userId,
            role: "business_rep",
          },
        });
      })
      .then((res) => {
        setCampaignData(res.data);
        setPagination(paginationCount(res.total, currentPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  const createCampaign = () => {
    if (
      campaignInfo.numActivate <
      env.subscription[props.userInfo.subscription || "copper"].staff
    ) {
      setAction("create");
      setDataEdit({});
    } else {
      showToast("error1", "to unlock additional limits and features!");
    }
  };

  const RenderForm = () => {
    if (action === "edit") {
      return (
        <Edit
          userInfo={props.userInfo}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          action={action}
          setDataEdit={setDataEdit}
          data={dataEdit}
          back={back}
        />
      );
    } else if (action === "editPassword") {
      return (
        <EditPassword
          userInfo={props.userInfo}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          action={action}
          data={dataEdit}
          back={back}
        />
      );
    } else {
      return (
        <NewForm
          userInfo={props.userInfo}
          fetchCampaignInfo={fetchCampaignInfo}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          action={action}
          data={dataEdit}
          back={back}
        />
      );
    }
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Tools", props.title]);
  }, []);

  useEffect(() => {
    getCampaignData(0, 1, props.userInfo._id);
    fetchCampaignInfo();
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  return (
    <PageContainer>
      {action === "all" ? (
        <>
          <div className='row-header'>
            <div className='status-wrapper'>
              <DataCard
                title='Activated Staff'
                data={campaignInfo.numActivate}
                total={
                  env.subscription[props.userInfo.subscription || "copper"]
                    .staff
                }
              />
              <DataCard
                title='Deactivated Staff'
                data={campaignInfo.numDeactivate}
              />
            </div>

            <button
              className='primary-button'
              onClick={() => {
                createCampaign();
              }}
            >
              + User
            </button>
          </div>

          <div className='row-container'>
            <Table
              rowKey='_id'
              columns={columns(setAction, setDataEdit, toggleModal)}
              dataSource={campaignData}
              pagination={false}
            />
          </div>

          <div className='row-container'>
            <Pagination
              pageSize={PAGESIZE}
              total={pagination.total}
              showSizeChanger={false}
              onChange={(e) => {
                setPagination(paginationCount(pagination.total, e));
                getCampaignData((e - 1) * PAGESIZE, e, props.userInfo._id);
              }}
            />
          </div>
        </>
      ) : (
        <RenderForm />
      )}

      <ModalConfirmation
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              {dataEdit.status === "baned"
                ? "Activate Staff"
                : "Deactivate Staff"}
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>
              {dataEdit.status === "baned"
                ? "Proceed to activate this Staff?"
                : "Procced to deactivate this Staff?"}
            </span>
          </>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantReps);
