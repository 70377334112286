import { breakpoints, colors } from "style";
import styled from "styled-components";
import { Home4 } from "assets/images";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 80px;
  .row-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .right-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 16px;
    position: relative;
  }
  .logo-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    max-width: 64px;
  }
  .input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid #eaecf0;
    background: white;
    border-radius: 10px;
  }
  .label-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    cursor: pointer;
  }
  .tagline-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    padding: 20px;
    background: white;
  }
  .tagline-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
  }
  .tagline-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #212134;
  }
  .preview-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 160px;
    border: none;
    padding: 20px;
    border-radius: 10px;
    font-size: 14px;
  }
  .remove-file-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .ant-radio.ant-radio-checked {
    border-color: #567877 !important;

    .ant-radio-inner {
      border-color: #567877 !important;
    }

    .ant-radio-inner:after {
      background: #567877 !important;
    }
  }
  .title {
    margin-bottom: 24px;
    // font-weight: bold;
    color: ${colors.purple};
  }
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .tabs {
    display: flex;
    text-align: center;
    margin-bottom: 12px;
    background: rgba(255, 255, 255, 0.49);
    box-shadow: 0px 10px 13px rgba(77, 121, 120, 0.1);
    border-radius: 100px;
    padding: 4px;
    .tab {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px 20px;
      position: relative;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #b3b9c7;
      &.active {
        color: #ffffff;
        background: #3b7779;
        border-radius: 30px;
      }
    }
  }
  .page-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: 100%;
    .home-container {
      flex: 1;
    }
    img {
      max-width: 100%;
      object-fit: cover;
    }
    .left-image {
      height: 100%;
      background-image: url(${Home4});
      background-size: cover;
      background-position: center;
    }
    .brand-container {
      flex: 1;
    }
    .brand-form {
      width: 100%;
      // max-width: 500px;
      // padding: 40px;
      // display: flex;
      // flex-direction: column;
      background: white;
      border-radius: 16px;
      h2 {
        color: ${colors.purple};
      }
      h4 {
        color: ${colors.purple};
        font-size: bold;
      }

      .actions {
        display: flex;
        justify-content: space-between;
      }
    }
    .highlight-wrapper {
      // display: flex;
      // justify-content: space-evenly;
      width: 100%;
    }
    .logo-upload {
      .logo-upload-btn {
        // background: white;
        background: ${(props) =>
          props.logo ? `url(${props.logo})` : `url(${props.croppedLogoUrl})`};
        background-size: cover;
        // padding: 50px;
        width: 45px;
        height: 45px;
        // margin: 12px;
        position: absolute;
        top: 10px;
        left: 10px;
        border: solid 1px;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        &:hover {
          /* background: ${colors.grey}; */
          -webkit-box-shadow: 3px 2px 9px -3px #707070;
          box-shadow: 3px 2px 9px -3px #707070;
        }
        img {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 45%;
          transform: translate(0, -50%);
          object-fit: contain;
        }
      }
    }
    .banner-upload {
      // margin-top: 24px;
      .banner-upload-btn {
        display: flex;
        justify-content: center;
        width: 100%;
        // background: ${(props) =>
          props.banner
            ? `url(${props.banner})`
            : `url(${props.croppedBannerUrl})`};
        background-size: cover;
        padding: 40px;
        flex-direction: column;
        align-items: center;
        border-radius: 6px;
        cursor: pointer;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        &:hover {
          /* background: ${colors.grey}; */
          -webkit-box-shadow: 3px 2px 9px -3px #707070;
          box-shadow: 3px 2px 9px -3px #707070;
        }
        img {
          width: 40px;
          object-fit: contain;
        }
        h4 {
          margin-top: 16px;
          font-weight: 500;
          // color: ${colors.dark7};
        }
      }
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
