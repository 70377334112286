import React, { useState, useContext } from 'react';
import StyledQuill from "components/Elements/StyledQuill";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import { PageContainer } from "./styleEdit";
import StyledButton from "components/Elements/StyledButton";
import { DataContext } from "contexts/DataContextContainer";
// import moment from 'moment';
import { cloneDeep } from 'lodash';

import client from "feathers.js"

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

const Edit = props => {
  const { setLoading, showToast } = useContext(DataContext);
  const [tos, setTos] = useState(props.data.tos)

  const handleApprove = async (status) => {
    setLoading(true)

    var sts = 'reject'

    if(status === 'approved'){
      sts = 'approved'
    }

    client.service('affiliates').patch(props.data._id, {
      tos,
      status: sts
    })
    .then(() => {
      let cloneData = cloneDeep(props.campaignData)
      let findKey = cloneData.findIndex(e => e._id === props.data._id)
      if(findKey !== -1){
        cloneData[findKey].status = sts
        cloneData[findKey].tos = tos
      }
      props.setCampaignData(cloneData)
      
      setLoading(false)
      showToast('success', `Request ${sts} successfully`)
      props.back()
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      showToast('error', err.message)
    })
  }

  return (
    <PageContainer>
      <div className='header'>
        <button className='btn-back' onClick={() => props.back()}><ArrowLeftOutlined /></button>
        <h3>Affiliate Review</h3>
      </div>
      <div className='page-content'>
        <Space className='detail' size={32} direction='vertical'>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>Name</span>
            <Input 
              type='text' 
              size="large" 
              value={props.data.name} 
              disabled
            />
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>Email</span>
            <Input 
              type='text' 
              size="large" 
              value={props.data.email} 
              disabled
            />
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>Message to Merchant</span>
            <StyledQuill
              readOnly={false}
              modules={quillModules}
              value={tos}
              onChange={async (value) => {
                setTos(value)
              }}
            />
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <div className='actions d-flex' style={{justifyContent: 'space-evenly'}}>
              <StyledButton
                width={'30%'}
                sizeh={50}
                className="btn-blue mt-5"
                title='Back'
                onClick={() => props.back()}
              />
              <StyledButton
                width={'30%'}
                sizeh={50}
                className="btn-blue mt-5"
                style={{backgroundColor: 'var(--danger) !important'}}
                title='Reject'
                onClick={() => handleApprove('reject')}
              />
              <StyledButton
                width={'30%'}
                sizeh={50}
                className="btn-blue mt-5"
                title='Approve'
                onClick={() => handleApprove('approved')}
              />
            </div>
          </Space>
        </Space>
      </div>
    </PageContainer>
  );
}

export default Edit;
