/* eslint-disable */
import React, { useRef, useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import CountryList from "country-list-with-dial-code-and-flag";
import currencyToSymbolMap from "currency-symbol-map/map";
import { cloneDeep } from "lodash";
import { TimePicker } from "antd";
import axios from "axios";
import client from "feathers.js";
import moment from "moment";

import { ProfileContainer, VerificationContainer } from "./style";
import { logout } from "redux/actions/roleActions";
import { getLogoBanner } from "redux/actions/extraActions";
import { updateDetails } from "redux/actions/roleActions";
import StyledButton from "components/Elements/StyledButton";
import StepsCounter from "components/StepsCounter";
import { DataContext } from "contexts/DataContextContainer";
import classNames from "classnames";
import {
  BusinessCard,
  ErrorIcon,
  VerifiedEmail,
  ViewPassword,
  warningIcon,
} from "assets/images";

var CryptoJS = require("crypto-js");

const format = "HH:mm";

const ProfileSchema = Yup.object().shape({
  password: Yup.string()
    .required("Please enter your password")
    .min(8, "password must contain 8 or more characters"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required!"),
  business_name: Yup.string().required("Required!"),
  // address1: Yup.string().required("Required!"),
  // city: Yup.string().required("Required!"),
  // zip: Yup.string().required("Required!"),
  owner_name: Yup.string().required("Required!"),
  // contact_phone: Yup.string().required("Required!").matches(Validation.PHONE_REG_WITHOUT_COUNTRY_CODE, 'Phone number is not valid'),

  store_storeName: Yup.string().when("physicalStore", {
    is: (e) => e === true || e === "true",
    then: Yup.string().required("Required!"),
  }),
  store_address1: Yup.string().when("physicalStore", {
    is: (e) => e === true || e === "true",
    then: Yup.string().required("Required!"),
  }),
  // store_address2: Yup.string().when('physicalStore', {
  //   is: (e) => e === true || e === 'true',
  //   then: Yup.string().required("Required!"),
  // }),
  store_city: Yup.string().when("physicalStore", {
    is: (e) => e === true || e === "true",
    then: Yup.string().required("Required!"),
  }),
  store_zip: Yup.string().when("physicalStore", {
    is: (e) => e === true || e === "true",
    then: Yup.string().required("Required!"),
  }),
  store_outletContactNumber: Yup.string().when("physicalStore", {
    is: (e) => e === true || e === "true",
    then: Yup.string().required("Required!"),
  }),
  store_outletEmail: Yup.string().when("physicalStore", {
    is: (e) => e === true || e === "true",
    then: Yup.string().required("Required!"),
  }),
});

const Profile = (props) => {
  const domainRef = useRef(null);
  const history = useHistory();
  const { setLoading, showToast } = useContext(DataContext);
  const [store_country, setStore_country] = useState("SG");
  const [store_phonePrefix, setStore_PhonePrefix] = useState("+65");
  const [phonePrefix, setPhonePrefix] = useState("+65");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [username, setUsername] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [email, setEmail] = useState("");
  const [owner_name, setOwner_name] = useState("");
  const [contact_phone, setContact_phone] = useState("");
  const [outletData, setOutletData] = useState({});
  const [workingHours, setWorkingHours] = useState({
    saturday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    sunday: [],
  });
  const [title_count, setTitle_count] = useState(0);
  const [valid, setValid] = useState(-1);
  const [confirm, setConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [stepStage, setStepStage] = useState(1);
  const [questionNumber, setQuestionNumber] = useState(1);

  useEffect(() => {
    // setUsername('')
    // props.signout()
    client
      .authenticate()
      .then((a) => {
        client.logout();
        props.logout();
        setUsername("");
        setTitle_count(0);
      })
      .catch((err) => {
        props.logout();
      });

    props.getLogoBanner({
      logo: "",
      banner: "",
    });

    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    const userId = new URLSearchParams(props.location.search).get("code");
    if (userId) {
      checkStatus(userId, setLoading);

      if (props.userInfo.phonePrefix) {
        setPhonePrefix(props.userInfo.phonePrefix);
      }
      if (props.userInfo.country) {
        setCountry(props.userInfo.country);
      }
      if (props.userInfo.currency) {
        setCurrency(props.userInfo.currency);
      }
      if (props.userInfo.username) {
        setUsername(props.userInfo.username);
        setTitle_count(props.userInfo.username.length);
      }

      // client.service('outlets').find({
      //   query: {
      //     userId,
      //     $limit: 1
      //   }
      // })
      // .then(res => {
      //   if(res.data.length > 0){
      //     setStore_country(res.data[0].country)
      //     setStore_PhonePrefix(res.data[0].outletPhonePrefix)
      //     setWorkingHours(res.data[0].workingHours)
      //     setOutletData(res.data[0])
      //   }
      // }).catch(err => {
      //   console.log(err)
      // })
    } else {
      window.location.href = "/";
    }
    /* eslint-disable-next-line */
  }, [
    props.location.search,
    props.userInfo.country,
    props.userInfo.phonePrefix,
    props.userInfo.username,
  ]);

  const checkStatus = (userId, setLoading) => {
    client
      .service("merchants")
      .get(userId)
      .then((res) => {
        if (res.set_done !== "sign") {
          window.location.href = "/";
        }
        setEmail(res.email);
        setOwner_name(res.owner_name);
        setContact_phone(res.contact_phone);
        setPhonePrefix(res.phonePrefix);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        showToast("error", err.message);
        setLoading(false);
        window.location.href = "/";
      });
  };

  const checkAvailability = async (fromBtn) => {
    if (username.length < 3) {
      showToast("error", "Merchant ID should be more than 3 characters");
      return false;
    } else {
      setLoading(true);

      return axios
        .get(`${client.io.io.uri}verifyUsername`, {
          params: {
            username: username,
          },
        })
        .then((res) => {
          if (res.data) {
            setLoading(false);
            setValid(0);
            return false;
          } else {
            setLoading(false);
            setValid(1);
            return true;
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setValid(0);
          return false;
        });
    }
  };

  const onChangeDomain = (name) => {
    if (name.length <= 50) {
      const value = name.toLowerCase();
      /* eslint-disable-next-line */
      const regex = /^[0-9a-zA-Z\_]+$/;
      if (value.match(regex) || value === "") {
        setUsername(value);
        setTitle_count(value.length);
      }
    }
  };

  const onUpdateProfile = async (data) => {
    let cloneData = data;
    cloneData.phonePrefix = phonePrefix;
    cloneData.country = country;
    cloneData.currency = currency;
    // cloneData.set_done = "sign"

    if (!currency || !country) {
      showToast("error", "Please fill all necessary information");
      return false;
    } else {
      if (await checkAvailability()) {
        const userId = new URLSearchParams(props.location.search).get("code");

        // if(cloneData.physicalStore === true || cloneData.physicalStore === 'true'){
        //   // data inserted before

        //   let canvasMap = ""
        //   let captureCanvas = await captureImage()
        //   canvasMap = await uploadCanvasMap(captureCanvas)

        //   let outlet = {
        //     storeName: cloneData.store_storeName?cloneData.store_storeName: "",
        //     address1: cloneData.store_address1?cloneData.store_address1: "",
        //     address2: cloneData.store_address2?cloneData.store_address2: "",
        //     city: cloneData.store_city?cloneData.store_city: "",
        //     zip: cloneData.store_zip?cloneData.store_zip: "",
        //     country: store_country,
        //     outletPhonePrefix: store_phonePrefix,
        //     workingHours: workingHours,
        //     outletContactNumber: cloneData.store_outletContactNumber?cloneData.store_outletContactNumber: "",
        //     outletEmail: cloneData.store_outletEmail?cloneData.store_outletEmail: "",
        //     canvasMap: canvasMap,
        //     latitude: cloneData.latitude?cloneData.latitude: "",
        //     longitude: cloneData.longitude?cloneData.longitude: "",
        //     google_map_url: cloneData.google_map_url?cloneData.google_map_url: "",
        //     formatted_address: cloneData.formatted_address?cloneData.formatted_address: "",
        //   }

        //   if(outletData._id){
        //     // patch
        //     client.service('outlets').patch(outletData._id, outlet)
        //     .catch(err => {
        //       console.log(err)
        //     })
        //   }else{
        //     outlet.userId = userId
        //     // If not yet, insert
        //     client.service('outlets').create(outlet)
        //     .then((res) => {
        //       setStore_country(res.country)
        //       setStore_PhonePrefix(res.outletPhonePrefix)
        //       setWorkingHours(res.workingHours)
        //       setOutletData(res)
        //     })
        //     .catch(err => {
        //       console.log(err)
        //     })
        //   }
        // }

        client
          .service("merchants")
          .patch(userId, cloneData)
          .then((res) => {
            // Caution
            res.username = username;
            res.outletData = outletData;

            // Encrypt
            var ciphertext = CryptoJS.AES.encrypt(
              cloneData.password,
              "my password"
            ).toString();

            res.ciphertext = ciphertext;

            props.updateDetails(res);

            setLoading(false);
            history.push("/brand");
          })
          // .then(() => {
          //   axios.post(`${client.io.io.uri}merchant_creation_success`, {
          //     email: props.userInfo.email,
          //     owner_name: props.userInfo.owner_name,
          //     username: props.userInfo.username,
          //     password: cloneData.password,
          //   })

          //   setLoading(false)
          //   history.push('/brand');
          // })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
  };

  const addWorkingHour = (value) => {
    let cloneWorking = cloneDeep(workingHours);
    if (cloneWorking[value].length > 0) {
      cloneWorking[value] = [];
      setWorkingHours(cloneWorking);
    } else {
      cloneWorking[value].push({
        startTime: moment(new Date()).format(format),
        endTime: moment(new Date()).format(format),
      });
      setWorkingHours(cloneWorking);
    }
  };

  const handleHours = (time, timeString, x, timeAt) => {
    let cloneWorking = cloneDeep(workingHours);
    cloneWorking[x[0]][0][timeAt] = timeString;
    setWorkingHours(cloneWorking);
  };

  const renderIconComp = () => {
    if (valid === -1) {
      return (
        <p
          className='m-0'
          style={{ fontSize: 14, color: "rgb(117, 117, 117)" }}
        >
          ({title_count}/50)
        </p>
      );
    } else if (valid === 0) {
      return (
        <span>
          <i className='fa fa-times' style={{ color: "rgb(204, 75, 65)" }} />
        </span>
      );
    } else {
      return (
        <span>
          <i className='fa fa-check' style={{ color: "#27AE60" }} />
        </span>
      );
    }
  };

  const renderButtonComp = () => {
    if (valid === -1) {
      return (
        <>
          <div className='w-100 d-flex flex-column justify-content-center mb-2'>
            <button
              type='button'
              className='btn check-avilability'
              onClick={() => checkAvailability(true)}
            >
              Check availability
            </button>
          </div>
        </>
      );
    } else if (valid === 0) {
      return (
        <>
          <p className='mb-0 mt-1 error-message'>
            Opps! your choice of merchant name has been taken!
          </p>
          <div className='w-100 d-flex flex-column justify-content-center mb-2'>
            <button
              type='button'
              className='btn check-avilability'
              onClick={() => {
                setUsername("");
                setTitle_count(0);
                setConfirm(false);
                setValid(-1);
              }}
            >
              Check Again
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <p className='mb-0 mt-1 success-message'>
            Success! Your choice of merchant name is available
          </p>
          <div className='w-100 d-flex justify-content-center mb-2'>
            <button
              type='button'
              className='btn check-avilability-again mr-1'
              disabled={confirm ? true : false}
              onClick={() => {
                setUsername("");
                setTitle_count(0);
                setConfirm(false);
                setValid(-1);
                setStepStage(1);
              }}
            >
              Check again
            </button>
            <button
              type='button'
              className='btn confirm-btn ml-1'
              onClick={() => [setConfirm(true), setStepStage(2)]}
              disabled={confirm ? true : false}
            >
              <a href='#details'>Confirm</a>
            </button>
          </div>
          {confirm ? null : (
            <div className='warning-message'>
              <img src={warningIcon} alt='' />
              <p>NOTE: Once confirmed, your Merchant name cannot be changed!</p>
            </div>
          )}
        </>
      );
    }
  };

  const stepsList = [
    "Create your Business Profile",
    "Enter your details",
    "Create your first stamp campaign",
  ];

  const renderSteps = () => {
    if (activeTab === 0) {
      return null;
    }
    return (
      <div className='steps-group'>
        <StepsCounter
          stepDescriptions={stepsList}
          currentStepNumber={stepStage}
        />
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className='home-container'>
        <div className='signup-container'>
          <div className='signup-form'>
            <img src={BusinessCard} alt='' />
            <h2>Create your Business Profile</h2>
            <div className='business-name'>
              <div className='mb-2'>
                <h6 className='form-item-title'>Create a Merchant Name</h6>
                <p className='form-item-desc mb-1'>
                  (only alphanumeric without special characters and space)
                </p>
                {/* <h6 className='bdomain'></h6> */}
                <div className='w-100' style={{ position: "relative" }}>
                  <InputGroup>
                    <Form.Control
                      ref={domainRef}
                      type='text'
                      className='form-control bname'
                      placeholder='Your merchant name'
                      onChange={(e) => onChangeDomain(e.target.value.trim())}
                      value={username}
                      disabled={valid === -1 ? false : true}
                      style={{
                        // textTransform: 'lowercase',
                        flex: 1,
                      }}
                    />
                    <InputGroup.Text className='justify-content-center'>
                      {renderIconComp()}
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              {renderButtonComp()}
            </div>

            {confirm ? (
              <>
                <div className='mb-5' id='details' />
                <p className='mb-1 link-group-title'>
                  This will be your unique campaign URL.
                </p>
                <div className='link-group'>
                  <div className='link-title'>https://</div>
                  <div className='link-name'>
                    go.morerewardz.com/<b>{username}</b>
                  </div>
                </div>
                <p className='mt-1 link-group-desc'>
                  Check it out on the top right hand corner of your dashboard
                  later!
                </p>
                <Formik
                  enableReinitialize
                  validationSchema={ProfileSchema}
                  validateOnChange={false}
                  validateOnBlur={false}
                  onSubmit={onUpdateProfile}
                  initialValues={{
                    password: "",
                    passwordConfirmation: "",
                    business_name: props.userInfo.business_name
                      ? props.userInfo.business_name
                      : "",
                    address1: props.userInfo.address1
                      ? props.userInfo.address1
                      : "",
                    address2: props.userInfo.address2
                      ? props.userInfo.address2
                      : "",
                    city: props.userInfo.city ? props.userInfo.city : "",
                    zip: props.userInfo.zip ? props.userInfo.zip : "",
                    // country: props.userInfo.country? props.userInfo.country: "",
                    owner_name: props.userInfo.owner_name
                      ? props.userInfo.owner_name
                      : owner_name,
                    contact_phone: props.userInfo.contact_phone
                      ? props.userInfo.contact_phone
                      : contact_phone,
                    physicalStore: props.userInfo.physicalStore
                      ? props.userInfo.physicalStore.toString()
                      : "false",
                    store_storeName: outletData.storeName
                      ? outletData.storeName
                      : "",
                    store_address1: outletData.address1
                      ? outletData.address1
                      : "",
                    store_address2: outletData.address2
                      ? outletData.address2
                      : "",
                    store_city: outletData.city ? outletData.city : "",
                    store_zip: outletData.zip ? outletData.zip : "",
                    // store_country: outletData.country?outletData.country: "",
                    store_outletContactNumber: outletData.outletContactNumber
                      ? outletData.outletContactNumber
                      : "",
                    store_outletEmail: outletData.outletEmail
                      ? outletData.outletEmail
                      : "",
                    latitude: outletData.latitude ? outletData.latitude : "",
                    longitude: outletData.longitude ? outletData.longitude : "",
                    google_map_url: outletData.google_map_url
                      ? outletData.google_map_url
                      : "",
                    formatted_address: outletData.formatted_address
                      ? outletData.formatted_address
                      : "",
                    facebook: props.userInfo.facebook
                      ? props.userInfo.facebook
                      : {
                          id: "",
                          url: "",
                        },
                    twitter: props.userInfo.twitter
                      ? props.userInfo.twitter
                      : {
                          id: "",
                          url: "",
                        },
                    instagram: props.userInfo.instagram
                      ? props.userInfo.instagram
                      : {
                          id: "",
                          url: "",
                        },
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                  }) => (
                    <Form onSubmit={handleSubmit} id='form1'>
                      <div className='company-details'>
                        <h6 className='form-item-title'>
                          Company Name / Brand
                        </h6>
                        <div className='mb-4'>
                          <Form.Control
                            type='text'
                            name='business_name'
                            value={values.business_name}
                            onChange={handleChange}
                            isInvalid={!!errors.business_name}
                            placeholder='Company Name / Brand'
                            style={{ backgroundImage: "none" }}
                          />
                          <Form.Control.Feedback
                            type='invalid'
                            style={{ textAlign: "start" }}
                          >
                            {errors.business_name}
                          </Form.Control.Feedback>
                        </div>
                        <div className='mb-2'>
                          <Form.Group>
                            <h6 className='form-item-title'>Password</h6>
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              name='password'
                              value={values.password}
                              onChange={handleChange}
                              isInvalid={!!errors.password}
                              placeholder='Password'
                              className='form-password'
                              style={{ backgroundImage: "none" }}
                            />
                            <div className='view-password'>
                              <img
                                src={ViewPassword}
                                alt=''
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            </div>
                            <Form.Control.Feedback
                              type='invalid'
                              style={{ textAlign: "start" }}
                            >
                              {errors.password}
                            </Form.Control.Feedback>
                            {!errors.password && (
                              <p className='mt-1 form-item-desc'>
                                Enter a secure password
                              </p>
                            )}
                          </Form.Group>
                        </div>
                        <div className='mb-2'>
                          <Form.Group>
                            <h6 className='form-item-title'>
                              Confirm Password
                            </h6>
                            <Form.Control
                              type={showConfirmPassword ? "text" : "password"}
                              name='passwordConfirmation'
                              value={values.passwordConfirmation}
                              isInvalid={!!errors.passwordConfirmation}
                              onChange={handleChange}
                              placeholder='Confirm Password'
                              className='form-password'
                              style={{ backgroundImage: "none" }}
                            />
                            <div className='view-password'>
                              <img
                                src={ViewPassword}
                                alt=''
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                              />
                            </div>
                            <Form.Control.Feedback
                              type='invalid'
                              style={{ textAlign: "start" }}
                            >
                              {errors.passwordConfirmation}
                            </Form.Control.Feedback>
                            {!errors.passwordConfirmation && (
                              <p className='mt-1 form-item-desc'>
                                Make sure to check your spelling!
                              </p>
                            )}
                          </Form.Group>
                        </div>
                        {/* <div className='mb-2'>
                              <Form.Control
                                type="text"
                                name="address1"
                                value={values.address1}
                                onChange={handleChange}
                                isInvalid={!!errors.address1}
                                placeholder="Address Line 1"
                              />
                              <Form.Control.Feedback type="invalid">{errors.address1}</Form.Control.Feedback>
                            </div> */}
                        {/* <div className='mb-2'>
                              <Form.Control
                                type="text"
                                name="address2"
                                value={values.address2}
                                onChange={handleChange}
                                isInvalid={!!errors.address2}
                                placeholder="Address Line 2"
                              />
                              <Form.Control.Feedback type="invalid">{errors.address2}</Form.Control.Feedback>
                            </div> */}
                        {/* <div className='mb-2'>
                              <Form.Control
                                type="text"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                isInvalid={!!errors.city}
                                placeholder="City"
                              />
                              <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                            </div> */}
                        {/* <div className='mb-2'>
                              <Form.Control
                                type="text"
                                name="zip"
                                value={values.zip}
                                onChange={handleChange}
                                isInvalid={!!errors.zip}
                                placeholder="Postal/Zip Code"
                              />
                              <Form.Control.Feedback type="invalid">{errors.zip}</Form.Control.Feedback>
                            </div> */}
                        <div className='mb-4'>
                          {/* <ReactFlagsSelect
                                placeholder='Country'
                                className='country'
                                selected={country}
                                onSelect={code => setCountry(code)}
                              /> */}
                          <h6 className='form-item-title'>Country</h6>
                          <Form.Control
                            as='select'
                            aria-label='Select Country'
                            onChange={(e) => setCountry(e.target.value)}
                            value={country}
                          >
                            {CountryList
                              // .sort(function(a, b){
                              //   if(a.name === 'Malaysia' || a.name === 'Singapore'){
                              //     return -1
                              //   }else{
                              //     return a.name - b.name
                              //   }
                              // })
                              .map((v, i) => {
                                if (i === 0) {
                                  return <option key={-1} value=''></option>;
                                }
                                return (
                                  <option key={i} value={v.code}>
                                    {v.flag + " " + v.name}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </div>
                        <div className='mb-4'>
                          <h6 className='form-item-title'>Currency</h6>
                          <Form.Control
                            as='select'
                            aria-label='Default select example'
                            onChange={(e) => setCurrency(e.target.value)}
                            value={currency}
                          >
                            {Object.entries(currencyToSymbolMap)
                              // .sort(function(a, b){
                              //   if(a[0] === 'MYR' || a[0] === 'SGD'){
                              //     return -1
                              //   }else{
                              //     return a[0] - b[0]
                              //   }
                              // })
                              .map((v, i) => {
                                if (i === 0) {
                                  return <option key={-1} value=''></option>;
                                }
                                return (
                                  <option key={i} value={v[0]}>
                                    {v[0]} ({v[1]})
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </div>
                      </div>

                      {/* <div className='mb-3' style={{
                          background: '#FFFFFF',
                          opacity: 0.97,
                          boxShadow: '0px 8px 18px rgba(133, 133, 133, 0.1)',
                          borderRadius: 15,
                          padding: '10px 16px',
                        }}>
                          <h6 className="form-item-title">Contact Details</h6>
                            <div className='mb-1'>
                              {values.owner_name}
                            </div>
                            <div className='mb-1'>
                              {email}
                            </div>
                            <div className='mb-1'>
                              <Row className='no-gutters'>
                                <Col md="12">
                                  {phonePrefix}
                                  {values.contact_phone}
                                </Col>
                              </Row>
                            </div>

                          <div className='w-100 box'>
                            Contact information can only be edit in Merchant Dashboard
                          </div>
                        </div> */}

                      {/* <br/> */}
                      {/* <Row>
                          <Col md="6">
                            <h6 className="form-item-title">Do you have a Physical Store?</h6>
                            <div className='mb-2'>
                            
                              <div role="group" aria-labelledby="my-radio-group">
                                <label className='mr-4'>
                                  <Field type="radio" name="physicalStore" value={"true"} className="mr-1" />
                                  Yes
                                </label>
                                <label>
                                  <Field type="radio" name="physicalStore" value={"false"} className="mr-1" />
                                  No
                                </label>
                              </div>
                            </div>
                          </Col>
                        </Row> */}
                      {values.physicalStore === true ||
                      values.physicalStore === "true" ? (
                        <>
                          <div>
                            <div className='mb-2'>
                              <Form.Control
                                type='text'
                                name='store_storeName'
                                value={values.store_storeName}
                                onChange={handleChange}
                                isInvalid={!!errors.store_storeName}
                                placeholder='Name of Store'
                              />
                              <Form.Control.Feedback type='invalid'>
                                {errors.store_storeName}
                              </Form.Control.Feedback>
                            </div>
                            <div className='mb-2'>
                              <Form.Control
                                type='text'
                                name='store_address1'
                                value={values.store_address1}
                                onChange={handleChange}
                                isInvalid={!!errors.store_address1}
                                placeholder='Address Line 1'
                              />
                              <Form.Control.Feedback type='invalid'>
                                {errors.store_address1}
                              </Form.Control.Feedback>
                            </div>
                            <div className='mb-2'>
                              <Form.Control
                                type='text'
                                name='store_address2'
                                value={values.store_address2}
                                onChange={handleChange}
                                isInvalid={!!errors.store_address2}
                                placeholder='Address Line 2'
                              />
                              <Form.Control.Feedback type='invalid'>
                                {errors.store_address2}
                              </Form.Control.Feedback>
                            </div>
                            <div className='mb-2'>
                              <Form.Control
                                type='text'
                                name='store_city'
                                value={values.store_city}
                                onChange={handleChange}
                                isInvalid={!!errors.store_city}
                                placeholder='City'
                              />
                              <Form.Control.Feedback type='invalid'>
                                {errors.store_city}
                              </Form.Control.Feedback>
                            </div>
                            <div className='mb-2'>
                              <Form.Control
                                type='text'
                                name='store_zip'
                                value={values.store_zip}
                                onChange={handleChange}
                                isInvalid={!!errors.store_zip}
                                placeholder='Postal/Zip Code'
                              />
                              <Form.Control.Feedback type='invalid'>
                                {errors.store_zip}
                              </Form.Control.Feedback>
                            </div>
                            <div className='mb-2'>
                              <Form.Control
                                as='select'
                                aria-label='Select Country'
                                onChange={(e) =>
                                  setStore_country(e.target.value)
                                }
                                value={store_country}
                              >
                                {CountryList.sort(function (a, b) {
                                  if (
                                    a.name === "Malaysia" ||
                                    a.name === "Singapore"
                                  ) {
                                    return -1;
                                  } else {
                                    return a.name - b.name;
                                  }
                                }).map((v, i) => {
                                  return (
                                    <option key={i} value={v.code}>
                                      {v.flag + " " + v.name}
                                    </option>
                                  );
                                })}
                              </Form.Control>
                            </div>

                            <div className='mb-2'>
                              <Row className='no-gutters'>
                                <Col md='4' className='pr-2'>
                                  <Form.Control
                                    as='select'
                                    aria-label='Default select example'
                                    onChange={(e) =>
                                      setStore_PhonePrefix(e.target.value)
                                    }
                                    value={store_phonePrefix}
                                  >
                                    {CountryList.sort(function (a, b) {
                                      if (
                                        a.name === "Malaysia" ||
                                        a.name === "Singapore"
                                      ) {
                                        return -1;
                                      } else {
                                        return a.name - b.name;
                                      }
                                    }).map((v, i) => {
                                      return (
                                        <option key={i} value={v.dial_code}>
                                          {v.flag + " " + v.dial_code}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                </Col>
                                <Col md='8'>
                                  <Form.Control
                                    type='text'
                                    name='store_outletContactNumber'
                                    value={values.store_outletContactNumber}
                                    onChange={handleChange}
                                    isInvalid={
                                      !!errors.store_outletContactNumber
                                    }
                                    placeholder='Outlet Contact Number'
                                  />
                                  <Form.Control.Feedback type='invalid'>
                                    {errors.store_outletContactNumber}
                                  </Form.Control.Feedback>
                                </Col>
                              </Row>
                            </div>
                            <div className='mb-2'>
                              <Form.Control
                                type='text'
                                name='store_outletEmail'
                                value={values.store_outletEmail}
                                onChange={handleChange}
                                isInvalid={!!errors.store_outletEmail}
                                placeholder='Outlet Email'
                              />
                              <Form.Control.Feedback type='invalid'>
                                {errors.store_outletEmail}
                              </Form.Control.Feedback>
                            </div>
                            <div className='mb-2 mt-4'>
                              <h6 className='form-item-title'>Working Hours</h6>
                              {Object.keys(workingHours).map((x, i) => {
                                return (
                                  <div key={i} className='d-inline-block'>
                                    <button
                                      type='button'
                                      style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: "50%",
                                      }}
                                      className={classNames("mr-2", {
                                        "text-white bg-primary":
                                          workingHours[x].length > 0,
                                      })}
                                      onClick={() => addWorkingHour(x)}
                                    >
                                      <span className='text-capitalize'>
                                        {x[0]}
                                      </span>
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                            <div className='mb-2'>
                              {Object.entries(workingHours).map((x, i) => {
                                if (x[1].length > 0) {
                                  return (
                                    <Row key={i}>
                                      <Col md='3'>
                                        <span className='text-capitalize'>
                                          {x[0]}
                                        </span>
                                      </Col>
                                      <Col md='4'>
                                        <TimePicker
                                          value={moment(
                                            x[1][0].startTime,
                                            format
                                          )}
                                          onChange={(time, timeString) =>
                                            handleHours(
                                              time,
                                              timeString,
                                              x,
                                              "startTime"
                                            )
                                          }
                                          format={format}
                                        />
                                      </Col>
                                      <Col md='1'>to</Col>
                                      <Col md='4'>
                                        <TimePicker
                                          value={moment(
                                            x[1][0].endTime,
                                            format
                                          )}
                                          onChange={(time, timeString) =>
                                            handleHours(
                                              time,
                                              timeString,
                                              x,
                                              "endTime"
                                            )
                                          }
                                          format={format}
                                        />
                                      </Col>
                                    </Row>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </div>
                          </div>
                        </>
                      ) : null}

                      {/* <h6 className="form-item-title section-social">Business Social Media</h6> */}

                      {/* <div className='mb-2'>
                            <Row className='no-gutters'>
                              <Col xs="8" sm="8" md="8">
                                <InputGroup>
                                  <InputGroup.Text className='facebook'><i className="fab fa-facebook"></i></InputGroup.Text>
                                  <Form.Control
                                    type="text"
                                    name="facebook.url"
                                    value={values.facebook.url}
                                    onChange={handleChange}
                                    placeholder="Facebook Url"
                                  />
                                </InputGroup>
                              </Col>
                              <Col xs="4" sm="4" md="4" className='pl-0'>
                                <Form.Control
                                  type="text"
                                  name="facebook.id"
                                  value={values.facebook.id}
                                  onChange={handleChange}
                                  placeholder="Facebook ID"
                                />
                              </Col>
                            </Row>
                          </div> */}

                      {/* <div className='mb-2'>
                            <Row className='no-gutters'>
                              <Col xs="8" sm="8" md="8">
                                <InputGroup>
                                  <InputGroup.Text className='twitter'><i className="fab fa-twitter"></i></InputGroup.Text>
                                  <Form.Control
                                    type="text"
                                    name="twitter.url"
                                    value={values.twitter.url}
                                    onChange={handleChange}
                                    placeholder="Twitter Url"
                                  />
                                </InputGroup>
                              </Col>
                              <Col xs="4" sm="4" md="4" className='pl-0'>
                                <Form.Control
                                  type="text"
                                  name="twitter.id"
                                  value={values.twitter.id}
                                  onChange={handleChange}
                                  placeholder="Twitter ID"
                                />
                              </Col>
                            </Row>
                          </div> */}

                      {/* <div className='mb-2'>
                              <Row className='no-gutters'>
                                <Col xs="8" sm="8" md="8">
                                  <InputGroup>
                                    <InputGroup.Text className='instagram'><i className="fab fa-instagram"></i></InputGroup.Text>
                                    <Form.Control
                                      type="text"
                                      name="instagram.url"
                                      value={values.instagram.url}
                                      onChange={handleChange}
                                      placeholder="Instagram Url"
                                    />
                                  </InputGroup>
                                </Col>
                                <Col xs="4" sm="4" md="4" className='pl-0'>
                                  <Form.Control
                                    type="text"
                                    name="instagram.id"
                                    value={values.instagram.id}
                                    onChange={handleChange}
                                    placeholder="Instagram ID"
                                  />
                                </Col>
                              </Row>
                            </div> */}
                    </Form>
                  )}
                </Formik>
                <div className='w-100 d-flex justify-content-center'>
                  <StyledButton
                    form='form1'
                    type='submit'
                    sizeh={56}
                    width={"100%"}
                    bgcolor={"#567877"}
                    radius={8}
                    className='mt-3 mb-3 check-avilability'
                    title='Continue'
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ProfileContainer>
      {renderSteps()}
      <div className='setup-container'>
        {activeTab === 0 ? (
          // ? verified message layout
          <VerificationContainer>
            <div className='row no-gutters home-container'>
              <div className='col-md-12'>
                <div className='verification-form mx-auto'>
                  <img width={130} src={VerifiedEmail} alt='' />
                  <h2>Congratulations! Your email has been verified</h2>
                  <h6 style={{ fontStyle: "normal" }}>
                    To finalize your onboarding with us, we kindly ask you to
                    complete your first{" "}
                    <span className='stamp'>stamp campaign</span> with us. This
                    campaign is designed to help you familiarize yourself with
                    our platform. Don't worry, it's a breeze - it takes{" "}
                    <span className='minutes'>4 minutes</span> only!
                  </h6>
                  <StyledButton
                    sizeh={56}
                    width={"60%"}
                    radius={12}
                    bgcolor={"#567877"}
                    className='btn-blue continue-button'
                    title='Okay, let’s continue!'
                    onClick={() => setActiveTab(1)}
                  />
                </div>
                <div className='alert-message mx-auto'>
                  <img src={ErrorIcon} alt='' />
                  <p>
                    If you cancel now (or at any point before completion), all
                    details that you have previously entered will be deleted
                    immediately and your account will not be set up. In the
                    future, if you wish to set it up again, you can re-register
                    again!
                  </p>
                </div>
              </div>
            </div>
          </VerificationContainer>
        ) : (
          renderForm()
        )}
      </div>
    </ProfileContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  logout: logout,
  updateDetails: updateDetails,
  getLogoBanner: getLogoBanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

// export default connect(state => state, Actions)(Profile);
