import { CheckIcon } from "assets/images";
import styled from "styled-components";

const StepsCounter = (props) => {
  const { currentStepNumber, stepDescriptions, left, progressHeight } = props;

  return (
    <StepsCounterWrapper>
      <StepsLink adjustLeft={left} adjustProgressHeight={progressHeight} />
      {stepDescriptions &&
        stepDescriptions.map((item, index) => {
          return (
            <StepsItem
              active={index + 1 === currentStepNumber}
              done={index + 1 < currentStepNumber}
            >
              {index + 1 < currentStepNumber ? (
                <img width={40} src={CheckIcon} alt='' />
              ) : (
                <StepsNumber active={index + 1 === currentStepNumber}>
                  {index + 1}
                </StepsNumber>
              )}
              <p>{item}</p>
            </StepsItem>
          );
        })}
    </StepsCounterWrapper>
  );
};

const StepsCounterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  position: relative;
  gap: 20px;
`;

const StepsLink = styled.div`
  display: flex;
  position: absolute;
  width: 80%;
  height: ${(props) =>
    props.adjustProgressHeight ? props.adjustProgressHeight : "80%"};
  border-left: 1px solid #eaecee;
  left: ${(props) => (props.adjustLeft ? props.adjustLeft : "50%")};
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const StepsItem = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 283px;
  height: 62px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  z-index: 2;
  background: ${(props) => (props.active ? "#E6F1F1" : "transparent")};
  p {
    margin: 0;
    color: ${(props) => (props.active || props.done ? "#191D23" : "#6B7280")};
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: ${(props) => (props.active || props.done ? "500" : "400")};
    line-height: 18px; /* 128.571% */
    max-width: 190px;
  }
`;

const StepsNumber = styled.div`
  display: flex;
  width: 43px;
  height: 42px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 35px;
  border: 1px solid ${(props) => (props.active ? "#567877" : "#EAECEE")};
  background: #fff;
  z-index: 2;
`;

export default StepsCounter;
