import React from "react";
import { Modal } from "antd";
import {
    FaTimesCircle
  } from "react-icons/fa";
import StyledButton from "components/Elements/StyledButton";
import { ModalContainer } from "./style"
import { 
  IphonePreviewStamp,
  IphonePreviewPackage,
  IphonePreviewVoucher,
  IphonePreviewReferral,
  IphonePreviewPoint,
 } from "assets/images"

const BroadcastModal = (props) => {

  const confirmModal = () => {
    props.toggleModal()
    props.modalCallback(true)
  }

  const RenderPreviewImg = ({campaignName}) => {
    if(campaignName === "package"){
      return <img src={IphonePreviewPackage} className="w-100" alt="" />
    }else if(campaignName === "voucher"){
      return <img src={IphonePreviewVoucher} className="w-100" alt="" />
    }else if(campaignName === "referral"){
      return <img src={IphonePreviewReferral} className="w-100" alt="" />
    }else if(campaignName === "point"){
      return <img src={IphonePreviewPoint} className="w-100" alt="" />
    }else{
      return <img src={IphonePreviewStamp} className="w-100" alt="" />  
    }
  }

  return ( 
    <>
    <Modal
        title={false}
        visible={props.modalVisible}
        onCancel={() => {
          props.toggleModal()
        }}
        centered
        closable={true}
        footer={false}
        className="modal-preview-content"
        closeIcon={<FaTimesCircle className='close-icon'/>}
      >
        <ModalContainer>
          
          <div className="header">
            {props.header}
          </div>

          <div className="body w-100" style={{position: 'relative'}}>
            {/* <RenderPreviewImg campaignName={props.campaign} /> */}
            {/* <img src='https://templatelab.com/wp-content/uploads/2019/03/Cover-Page-Template-1-TemplateLab-e1553052868262.jpg' alt="test" style={{width: '370px', height: '580px'}}/> */}
            {/* <div className={classNames('campaign-preview', {
              'isReward': props.isReward
            })}>
              {props.body}
            </div> */}
          </div>
          
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: props.noLaunch?'center':'space-between'
          }}
          >
            <StyledButton
              width={'45%'}
              title='Cancel'
              onClick={() => props.toggleModal()}
              sizeh={44}
              className="btn-blue mt-5"
              radius="7"
              bgcolor={"#FDB256"}
              textTransform="uppercase"
              weight={700}
            />
            {
              props.noLaunch?
              null
              :
              <StyledButton
                width={'45%'}
                title="Launch"
                onClick={confirmModal}
                sizeh={44}
                type="submit"
                className="btn-blue mt-5"
                radius="7"
                bgcolor={"#3b7779"}
                textTransform="uppercase"
                weight={700}
              />
            }
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
}
 
export default BroadcastModal;