/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { PageContainer } from "./style";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Divider, Steps } from "antd";
import { connect } from "react-redux";
import { getOutletModal } from "redux/actions/extraActions";
import { DataContext } from "contexts/DataContextContainer";
import { Row, Col, Card, Button } from "react-bootstrap";
import ModalSharePreview from "components/Extra/ModalSharePreview";
import ReactShare from "./ReactShare";
import getSymbolFromCurrency from "currency-symbol-map";
import Charts from "./Charts";
import Charts1 from "./Charts1";
import ModalOutlet from "components/Extra/ModalOutlet";
import env from "env";

import axios from "axios";
import client from "feathers.js";
import classNames from "classnames";
import InfoCard from "components/InfoCard/InfoCard";

import {
  Checked,
  Bag,
  Phone,
  Location,
  Scan,
  QRCode,
  Wallet,
  People,
} from "assets/svg";

import { AppStore, GoogleStore } from "assets/images";

const Dashboard = (props) => {
  const history = useHistory();

  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [data, setData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [actions, setActions] = useState("outlet");
  const [mrzscanData, setMrzScanData] = useState("");

  const [selected, setSelected] = useState(1);
  const [businessPage, setBusinessPage] = useState(1);

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);

  useEffect(() => {
    if (props.userInfo.role === "superadmin") {
      fetchSuperadminData();
    } else {
      fetchData();
    }

    fetchMRZScanData();
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  // useEffect(() => {
  //   // if(!props.userInfo.contact_phone_1){
  //   //   setActions('contact')
  //   //   setModalVisible(true)
  //   // }else{

  //     if(props.outletOpen || props.outletOpen === undefined){
  //       client.authenticate()
  //       .then((auth) => {
  //         return client.service('outlets').find({
  //           query: {
  //             userId: props.userInfo._id,
  //             $limit: 1
  //           }
  //         })
  //       })
  //       .then(res => {
  //         if(res.data.length > 0){
  //           setModalVisible(false)
  //         }else{
  //           setModalVisible(true)
  //         }
  //       })
  //       .catch((err)=>{
  //         console.log(err)
  //       })
  //     }

  //   // }
  // }, [props.userInfo._id])

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const modalCallback = () => {
    if (actions === "contact") {
      props.history.push("/admin/merchant/merchantDetails");
    } else {
      props.history.push("/admin/merchant/outlet");
    }
  };

  const fetchData = () => {
    setLoading(true);
    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}getDashboardData`,
          {
            userId: props.userInfo._id,
          },
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        console.log(res);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        showToast("error", err.message);
      });
  };

  const fetchSuperadminData = () => {
    setLoading(true);
    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}getSuperadminDashboardData`,
          {},
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        showToast("error", err.message);
      });
  };

  const fetchMRZScanData = () => {
    client
      .authenticate()
      .then(() => {
        return client.service("users").find({
          query: {
            role: "business_rep",
            merchantName: props.userInfo.username,
          },
        });
      })
      .then((res) => {
        setMrzScanData(res.data[0].lastLogin);
      });
  };

  const renderSteps = (order, validator, title, onClick) => {
    const isSelected = Number(order) === selected;
    return (
      <button
        className={classNames("step", validator)}
        style={{
          background: isSelected ? "#E6F1F1" : "transparent",
        }}
        onClick={onClick}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            justifyContent: "center",
            background: (validator.checked ? '' : 'white'),
            fontWeight: "250",
            border: "1px solid #567877",
          }}
        >
          {validator.checked ? (
            <img src={Checked} alt='' width='13px' height='11px' />
          ) : (
              Number(order)
          )}
        </div>
        <div
          style={{
            color: isSelected ? "#567877" : "#6B7280",
            fontSize: "14px",
            fontWeight: "light",
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const renderStepsDetails = (icon, title, desc, renderOnClickHtml) => {
    return (
      <div className='step-details-container'>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            width: "100%",
          }}
        >
          <img src={icon} alt='' width='40px' height='40px' />
          <div className='step-details-header'>{title}</div>
          <div className='step-details-text'>{desc}</div>
        </div>
        <div className='button-wrapper'>{renderOnClickHtml}</div>
      </div>
    );
  };

  return (
    <PageContainer>
      <InfoCard
        desc={`Welcome to our dashboard! While completing the following steps are not mandatory, we highly recommend it for a seamless and personalized experience. Let's get started!`}
      />

      <div className='content-container'>
        <div className='guide-text'>Your start guide:</div>
        <div className='page-container'>
          {props.userInfo.role === "superadmin" ? (
            <>
              <Row>
                <Col md='12'>
                  <Row className='mb-3'>
                    <Col md='8'></Col>
                    <Col md='4'>
                      <div className='d-flex justify-content-end'></div>
                    </Col>
                  </Row>
                  <Card className='shadow-sm box-1'>
                    <Card.Body>
                      <Row>
                        <Col md='3'>
                          <label>Total Merchants</label>
                          <div>
                            <h2>{data.numMerchants || 0}</h2>
                          </div>
                        </Col>
                        <Col md='3'>
                          <label>Leads Merchants</label>
                          <div>
                            <h2>{data.numRemovedMerchants || 0}</h2>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <br />
              <br />
              <br />
            </>
          ) : (
            <>
              <div className='start-guide-container'>
                <div className='mrz-steps step-container'>
                  <div className='step-wrapper'>
                    {renderSteps(
                      "1",
                      {
                        checked:
                          props.userInfo.contact_email ||
                          props.userInfo.contact_phone_1,
                      },
                      "Add business details",
                      () => [setSelected(1), setBusinessPage(1)]
                    )}
                    {renderSteps(
                      "2",
                      { checked: data.outlets },
                      "Add business location",
                      () => setSelected(2)
                    )}
                    {renderSteps(
                      "3",
                      // { checked: mrzscanData === undefined ? false : true },
                      { checked: data.staffLogin },
                      "Set up MRZ Scan",
                      () => setSelected(3)
                    )}
                    {renderSteps(
                      "4",
                      { checked: props.userInfo.doneQR },
                      "Download QR code / Poster",
                      () => setSelected(4)
                    )}
                    {renderSteps(
                      "5",
                      { checked: data.numUsers },
                      "Register your first customer",
                      () => setSelected(5)
                    )}
                    {renderSteps(
                      "6",
                      { checked: data.walletSetup },
                      "Set up payment",
                      () => setSelected(6)
                    )}
                  </div>
                </div>
                {selected === 1 &&
                  businessPage === 1 &&
                  renderStepsDetails(
                    Bag,
                    "Add business details",
                    "Provide the necessary information about your business. They'll be automatically added to your campaign URL.",
                    <>
                      <button
                        type='button'
                        className='button'
                        onClick={() =>
                          history.push("/admin/merchant/merchantDetails")
                        }
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "white",
                          }}
                        >
                          Add details
                        </div>
                      </button>
                      <button
                        type='button'
                        className='button-next'
                        onClick={() => setBusinessPage(2)}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#567877",
                          }}
                        >
                          Next step
                        </div>
                      </button>
                    </>
                  )}
                {selected === 1 &&
                  businessPage === 2 &&
                  renderStepsDetails(
                    Phone,
                    "Add business details",
                    "Provide the necessary information about your business",
                    <>
                      <button
                        type='button'
                        className='button'
                        onClick={() =>
                          history.push("/admin/merchant/merchantDetails")
                        }
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "white",
                          }}
                        >
                          Add details
                        </div>
                      </button>
                      <button
                        type='button'
                        className='button-next'
                        onClick={() => setSelected(2)}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#567877",
                          }}
                        >
                          Next step
                        </div>
                      </button>
                    </>
                  )}
                {selected === 2 &&
                  renderStepsDetails(
                    Location,
                    "Add outlet details",
                    "Provide specific information about your different outlets for customers to easily locate and engage with your physical stores if required.",
                    <>
                      <button
                        type='button'
                        className='button'
                        onClick={() => history.push("/admin/merchant/outlet")}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "white",
                          }}
                        >
                          Add details
                        </div>
                      </button>
                      <button
                        type='button'
                        className='button-next'
                        onClick={() => setSelected(3)}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#567877",
                          }}
                        >
                          Next step
                        </div>
                      </button>
                    </>
                  )}
                {selected === 3 &&
                  renderStepsDetails(
                    Scan,
                    "Login to MRZscan App",
                    "Download MRZScan App And Login With The Credentials Provided For You In The Successful Campaign Creation Email.",
                    <>
                      <button
                        type='button'
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "default",
                        }}
                      >
                        <img
                          src={GoogleStore}
                          alt=''
                          width='100px'
                          height='30px'
                        />
                      </button>
                      <button
                        type='button'
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "default",
                        }}
                      >
                        <img
                          src={AppStore}
                          alt=''
                          width='100px'
                          height='30px'
                        />
                      </button>
                      <button
                        type='button'
                        className='button-next'
                        onClick={() => setSelected(4)}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#567877",
                          }}
                        >
                          Next step
                        </div>
                      </button>
                    </>
                  )}
                {selected === 4 &&
                  renderStepsDetails(
                    QRCode,
                    "Download QR code / Poster",
                    "Print Out These Useful Materials And Paste Around Your Shop So That Your Customer Can Scan To Access Your Campaign Page.",
                    <>
                      <button
                        type='button'
                        className='button'
                        onClick={() => history.push("/admin/merchant/qr")}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "white",
                          }}
                        >
                          Download now
                        </div>
                      </button>
                      <button
                        type='button'
                        className='button-next'
                        onClick={() => setSelected(5)}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#567877",
                          }}
                        >
                          Next step
                        </div>
                      </button>
                    </>
                  )}
                {selected === 5 &&
                  renderStepsDetails(
                    People,
                    "Get your first customer",
                    "Start Sharing Your Campaign Page URL To Your Customer To Join Your Campaign",
                    <>
                      <button
                        type='button'
                        className='button'
                        onClick={(e) => {
                          e.preventDefault();
                          toggleModal();
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "white",
                          }}
                        >
                          Continue
                        </div>
                      </button>
                      <button
                        type='button'
                        className='button-next'
                        onClick={() => setSelected(6)}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#567877",
                          }}
                        >
                          Next step
                        </div>
                      </button>
                    </>
                  )}
                {selected === 6 &&
                  renderStepsDetails(
                    Wallet,
                    "Set up payment modes",
                    "This Step Is Necessary If You Allow Customer To Pay With Credit Card.",
                    <button
                      type='button'
                      className='button'
                      onClick={() => history.push("/admin/tools/modeofpayment")}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "white",
                        }}
                      >
                        Set up now
                      </div>
                    </button>
                  )}
              </div>

              <div className='cards-container'>
                <div className='cards'>
                  <div className='cards-header'>Total Customers</div>
                  <div className='cards-text'>
                    <h2>{data.numUsers || 0}</h2>
                    <p>
                      {" "}
                      /{" "}
                      {
                        env.subscription[
                          props.userInfo.subscription || "copper"
                        ].customers
                      }
                    </p>
                  </div>
                </div>
                <div className='cards'>
                  <div className='cards-header'>Referral Shares</div>
                  <div className='cards-text'>
                    <h2>{data.numShared || 0}</h2>
                  </div>
                </div>
                <div className='cards'>
                  <div className='cards-header'>New signups</div>
                  <div className='cards-text'>
                    <h2>{data.newSignup || 0}</h2>
                  </div>
                </div>
                <div className='cards'>
                  <div className='cards-header'>Interactions</div>
                  <div className='cards-text'>
                    <h2>{data.interactions || 0}</h2>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <ModalSharePreview
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        header={
          <>
            <br />
            Share via
          </>
        }
        body={
          <>
            <ReactShare
              merchant={props.userInfo}
              shareUrl={
                process.env.REACT_APP_BASE_URL + props.userInfo.username
              }
              // shareTitle={
              //   ``
              // }
            />
          </>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
  outletOpen: state.extra.outletOpen,
});

const mapDispatchToProps = {
  getOutletModal: getOutletModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
