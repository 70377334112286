import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import client from "feathers";
import dayjs from "dayjs";
import { Table, Pagination, Button, Spin } from "antd";

import env from "env";
import "index.css";
import { PageContainer } from "./style";
import RecipientModal from "components/Extra/RecipientModal";
import DataCard from "components/DataCard";
import { DataContext } from "contexts/DataContextContainer";
import Datepicker from "../../../../../components/Datepicker";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const enumToStringCoverter = (value) => {
  switch (value) {
    case 1:
      return "Stamp Card";
    case 2:
      return "Package";
    case 3:
      return "Voucher";
    case 4:
      return "Referral";
    default:
      return "";
  }
};

const sentStatusConverter = (value) => {
  switch (value) {
    case true:
      return "Sent";
    case false:
      return "Unsuccessful";
    default:
      return "";
  }
};

const readStatusConverter = (value) => {
  switch (value) {
    case true:
      return "Read";
    case false:
      return "Unread";
    default:
      return "";
  }
};

const BroadcastHistory = (props) => {
  const history = useHistory();
  const { setLocation, setBreadcrumb } = useContext(DataContext);
  const [data, setData] = useState([]);
  const [recipientData, setRecipientData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [modalPagination, setModalPagination] = useState({});
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [spin, setSpin] = useState(false);
  const [recipientListModalIsOpen, setRecipientListModalIsOpen] =
    useState(false);
  const [currentMerchantId, setCurrentMerchantId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentModalPage, setCurrentModalPage] = useState(1);
  const [reRender, setRerender] = useState(false);
  const [totalBroadcast, setTotalBroadcast] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [filterQuery, setFilterQuery] = useState("");
  const [broadcastId, setBroadcastId] = useState("");

  let arrayOfTotalSuccess = [];
  let arrayOfTotalFailed = [];
  let tableMassagedData = [];

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Marketing Broadcast", props.title]);
  }, []);

  useEffect(() => {
    setRecipientData([]);
    setOpenModal(false);
  }, [recipientListModalIsOpen === false]);

  useEffect(() => {
    let defaultQuery;

    client.authenticate().then((auth) => {
      setCurrentMerchantId(auth.user._id);

      defaultQuery = {
        $and: [
          {
            createdAt: {
              $gte: dayjs().startOf("month"),
            },
          },
          {
            createdAt: {
              $lte: dayjs().endOf("month"),
            },
          },
        ],
      };

      if (filterQuery === "") {
        client
          .service("marketing-broadcasts")
          .find({
            query: {
              ...defaultQuery,
              merchantId: auth.user._id,
              $limit: PAGESIZE,
              $skip: (pagination.current_page - 1) * PAGESIZE,
            },
          })
          .then((res) => {
            for (let k = 0; k < res.data.length; k++) {
              let successfulCounter = 0;
              let failedCounter = 0;

              for (let i = 0; i < res.data[k].recipientInfo.length; i++) {
                if (res.data[k].recipientInfo[i].sentStatus === true) {
                  successfulCounter += 1;
                } else {
                  failedCounter += 1;
                }
              }

              arrayOfTotalSuccess.push(successfulCounter);
              arrayOfTotalFailed.push(failedCounter);
            }

            for (let k = 0; k < res.data.length; k++) {
              tableMassagedData.push({
                ...res.data[k],
                successfulRecipients: arrayOfTotalSuccess[k],
                failedRecipients: arrayOfTotalFailed[k],
                totalRecipients: res.data[k].recipientInfo.length,
                updatedAt: dayjs(res.data[k].updatedAt).format(
                  "DD-MM-YYYY HH:mm A"
                ),
                campaignType: enumToStringCoverter(res.data[k].campaignType),
              });
            }

            setPagination(paginationCount(res.total, currentPage));
            if (totalBroadcast === "") {
              setTotalBroadcast(res.total || 0);
            }
            setRerender(true);
          })
          .then(() => {
            setData(tableMassagedData);
            setRerender(false);
            setSpin(false);
          });
      } else {
        client
          .service("marketing-broadcasts")
          .find({
            query: {
              ...filterQuery,
              merchantId: auth.user._id,
              $limit: PAGESIZE,
              $skip: (pagination.current_page - 1) * PAGESIZE,
            },
          })
          .then((res) => {
            for (let k = 0; k < res.data.length; k++) {
              let successfulCounter = 0;
              let failedCounter = 0;

              for (let i = 0; i < res.data[k].recipientInfo.length; i++) {
                if (res.data[k].recipientInfo[i].sentStatus === true) {
                  successfulCounter += 1;
                } else {
                  failedCounter += 1;
                }
              }

              arrayOfTotalSuccess.push(successfulCounter);
              arrayOfTotalFailed.push(failedCounter);
            }

            for (let k = 0; k < res.data.length; k++) {
              tableMassagedData.push({
                ...res.data[k],
                successfulRecipients: arrayOfTotalSuccess[k],
                failedRecipients: arrayOfTotalFailed[k],
                totalRecipients: res.data[k].recipientInfo.length,
                updatedAt: dayjs(res.data[k].updatedAt).format(
                  "DD-MM-YYYY HH:mm A"
                ),
                campaignType: enumToStringCoverter(res.data[k].campaignType),
              });
            }

            setPagination(paginationCount(res.total, currentPage));
            if (totalBroadcast === "") {
              setTotalBroadcast(res.total || 0);
            }
            setRerender(true);
          })
          .then(() => {
            setData(tableMassagedData);
            setRerender(false);
            setSpin(false);
          });
      }
    });
  }, [currentPage, filterQuery]);

  useEffect(() => {
    recipientListModal(broadcastId);
  }, [openModal === true]);

  const columns = () => {
    return [
      {
        title: "Date Sent",
        dataIndex: "updatedAt",
        key: "updatedAt",
      },
      {
        title: "Campaign Type",
        dataIndex: "campaignType",
        key: "campaignType",
      },
      {
        title: "Successful Recipients",
        dataIndex: "successfulRecipients",
        key: "successfulRecipients",
      },
      {
        title: "Failed Recipients",
        dataIndex: "failedRecipients",
        key: "failedRecipients",
      },
      {
        title: "Total Recipients",
        dataIndex: "totalRecipients",
        key: "totalRecipients",
      },
      {
        title: "Action",
        dataIndex: "_id",
        key: "action",
        align: "center",
        render: (record) => (
          <Button
            onClick={() => [setBroadcastId(record), setOpenModal(true)]}
            trigger={["click"]}
          >
            View
          </Button>
        ),
      },
    ];
  };

  const recipientColumns = () => {
    return [
      {
        title: "Recipients ID",
        dataIndex: "_id",
        key: "recipients + _id",
      },
      {
        title: "Recipients Email",
        dataIndex: "recipientEmail",
        key: "recipientEmail",
      },
      {
        title: "Sent Status",
        dataIndex: "sentStatus",
        key: "sentStatus",
      },
      {
        title: "Read Status",
        dataIndex: "readStatus",
        key: "readStatus",
      },
    ];
  };

  const toggleRecipientModal = () => {
    setRecipientListModalIsOpen(!recipientListModalIsOpen);
  };

  const searchDate = (startDate, endDate) => {
    if (startDate && endDate) {
    }
  };

  const recipientListModal = (value) => {
    setCurrentModalPage(1);

    let modalMassagedData = [];

    client
      .authenticate()
      .then((auth) => {
        client
          .service("marketing-broadcasts")
          .find({
            query: {
              _id: value,
            },
          })
          .then((res) => {
            for (let k = 0; k < res.data.length; k++) {
              for (let i = 0; i < res.data[k].recipientInfo.length; i++) {
                modalMassagedData.push({
                  _id: res.data[k].recipientInfo[i]._id,
                  recipientEmail: res.data[k].recipientInfo[i].recipientEmail,
                  sentStatus: sentStatusConverter(
                    res.data[k].recipientInfo[i].sentStatus
                  ),
                  readStatus: readStatusConverter(
                    res.data[k].recipientInfo[i].readStatus
                  ),
                });
              }
            }

            setModalPagination(
              paginationCount(res.data.length, currentModalPage)
            );
          })
          .then(() => {
            setRecipientData(modalMassagedData);
          });
      })
      .then(() => {
        if (openModal === true) {
          setRecipientListModalIsOpen(true);
        }
      });
  };

  const filterData = () => {
    let newQuery = {};

    if (startDate && endDate) {
      newQuery = {
        merchantId: `${currentMerchantId}`,
        $and: [
          {
            updatedAt: {
              $gte: new Date(
                new Date(startDate).setHours(0, 0, 0, 0)
              ).getTime(),
            },
          },
          {
            updatedAt: {
              $lte: new Date(
                new Date(endDate).setHours(23, 59, 59, 999)
              ).getTime(),
            },
          },
        ],
      };

      setCurrentPage(1);
      setFilterQuery(newQuery);

      if (newQuery) {
        setSpin(true);
      }
    }
  };

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    searchDate(dates[0], dates[1]);
  };

  return (
    <PageContainer>
      <div className='row-header'>
        <div className='status-wrapper'>
          <DataCard
            title='Broadcasts Sent'
            data={totalBroadcast}
            total={
              env.subscription[props.userInfo.subscription || "copper"].voucher
            }
          />
        </div>
      </div>

      <div className='row-header'>
        <Datepicker
          periodStart={startDate}
          periodEnd={endDate}
          onChange={onChangeCampaignPeriod}
          noLabel
          dateDisable={true}
        />

        <button
          type='button'
          className='primary-button'
          onClick={() => filterData()}
        >
          {spin ? <Spin /> : "Filter"}
        </button>
      </div>

      <div className='row-container'>
        <Table
          rowKey='_id'
          columns={columns()}
          dataSource={data}
          pagination={false}
        />
      </div>

      <div className='row-container'>
        <Pagination
          pageSize={PAGESIZE}
          total={pagination.total}
          showSizeChanger={false}
          current={currentPage}
          onChange={(e) => {
            setPagination(paginationCount(pagination.total, e));
            setCurrentPage(e);
          }}
        />
      </div>

      <RecipientModal
        modalVisible={recipientListModalIsOpen}
        toggleModal={toggleRecipientModal}
        header={
          <>
            <br />
          </>
        }
        body={
          <Table
            rowKey='_id'
            columns={recipientColumns()}
            dataSource={recipientData}
            pagination={false}
          />
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

export default connect(mapStateToProps, null)(BroadcastHistory);
