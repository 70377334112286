import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { CSVDownload } from "react-csv";
import { useHistory } from "react-router-dom";
import client from "feathers.js";
import moment from "moment";
import { Table, Pagination, Spin } from "antd";

import columns from "./columns";
import Edit from "./Edit";
import { PageContainer } from "../style";
import { DataContext } from "contexts/DataContextContainer";
import Datepicker from "../../../../components/Datepicker";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const CustomerManagement = (props) => {
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [action, setAction] = useState("all"); //edit, detail, create
  const [campaignData, setCampaignData] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [pagination, setPagination] = useState({});
  const [startDate, setStartDate] = useState(false);
  const [spin, setSpin] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const back = () => {
    setAction("all");
  };

  const searchDate = (startDate, endDate) => {
    if (startDate && endDate) {
      getCampaignData(0, 1, props.userInfo._id, startDate, endDate);
    }
  };

  const getCampaignData = (skip, currentPage, userId, startDate, endDate) => {
    setLoading(true);

    let query = {
      $skip: skip,
      merchantId: userId,
      role: "user",
      $limit: PAGESIZE,
      $sort: {
        createdAt: -1,
      },
    };

    if (startDate && endDate) {
      query = {
        $and: [
          {
            createdAt: {
              $gte: new Date(
                moment(new Date(startDate)).format("YYYY-MM-DD hh:mm")
              ).getTime(),
            },
          },
          {
            createdAt: {
              $lte: new Date(
                new Date(endDate).setHours(23, 59, 59, 999)
              ).getTime(),
            },
          },
        ],
        $skip: skip,
        $limit: PAGESIZE,
        merchantId: userId,
        role: "user",
        $sort: {
          createdAt: -1,
        },
      };
    }

    if (props.userInfo.role === "superadmin") {
      delete query.merchantId;
    }

    client
      .authenticate()
      .then((auth) => {
        // return axios.post(`${client.io.io.uri}searchByOwnCustomer`, {
        //   merchantId: userId
        // },
        // {
        //   headers: {
        //     'Authorization': auth.accessToken
        //   }
        // }
        // )
        return client.service("users").find({
          query,
        });
      })
      .then((res) => {
        setPagination(paginationCount(res.total, currentPage));
        setCampaignData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  const downloadCSV = async () => {
    if (props.userInfo.subscription === "copper") {
      showToast("error", "Only Bronze and above allow to download CSV!");
      return false;
    }

    let tmpData = [];
    setLoading(false);

    const stackData = (index) => {
      var query = {
        $skip: index * pagination.per_page,
        merchantId: props.userInfo._id,
        role: "user",
        $sort: {
          createdAt: -1,
        },
      };

      if (startDate && endDate) {
        query = {
          $and: [
            {
              createdAt: {
                $gte: new Date(startDate).getTime(),
              },
            },
            {
              createdAt: {
                $lte: new Date(
                  new Date(endDate).setHours(23, 59, 59, 999)
                ).getTime(),
              },
            },
          ],
          $skip: index * pagination.per_page,
          merchantId: props.userInfo._id,
          role: "user",
          $sort: {
            createdAt: -1,
          },
        };
      }

      return new Promise((resolve) => {
        client
          .authenticate()
          .then(() => {
            return client.service("users").find({
              query: query,
            });
          })
          .then((res) => {
            let d = [];

            for (let i = 0; i < res.data.length; i++) {
              d.push({
                Email: res.data[i].email,
                "First Name": res.data[i].firstName,
                "Last Name": res.data[i].lastName,
                "Phone Prefix": res.data[i].phonePrefix,
                "Contact Phone": res.data[i].contact_phone,
                Gender: res.data[i].gender,
                Birthday: res.data[i].birthday
                  ? moment(res.data[i].birthday).format("DD/MM/YYYY - HH:mm")
                  : "",
                "Address 1": res.data[i].address1,
                "Address 2": res.data[i].address2,
                City: res.data[i].city,
                Zip: res.data[i].zip,
                Country: res.data[i].country,
                "Is Referral": res.data[i].isReferral,
                "Created Date": moment(res.data[i].createdAt).format(
                  "DD/MM/YYYY - HH:mm"
                ),
              });
            }

            tmpData = tmpData.concat(d);
            resolve(res.data);
          })
          .catch((err) => {
            if (err.name === "NotAuthenticated") {
              showToast("error", "Please Sign-in to continue!");
            } else {
              showToast("error", err.message);
            }
          });
      });
    };

    let numOfLoop = [];
    for (let i = 0; i < pagination.last_page; i++) {
      numOfLoop.push(i);
    }

    let process = await numOfLoop.map(async (value, index) => {
      let data = await stackData(index);
      return data;
    });

    let result = await Promise.all(process);

    if (result) {
      setCsvData(tmpData);
      setSpin(true);
      setTimeout(() => {
        setSpin(false);
      }, 2000);
    }
  };

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
    searchDate(dates[0], dates[1]);
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);

  useEffect(() => {
    getCampaignData(0, 1, props.userInfo._id);
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  return (
    <PageContainer>
      {action === "all" ? (
        <>
          <div className='row-header'>
            <div>
              <h4>Search by Date</h4>
              <Datepicker
                periodStart={startDate}
                periodEnd={endDate}
                onChange={onChangeCampaignPeriod}
                noLabel
                dateDisable={true}
              />
            </div>

            <button
              type='button'
              className='primary-button'
              disabled={props.userInfo.subscription === "copper" ? true : spin}
              onClick={downloadCSV}
            >
              {spin ? <Spin /> : "Download CSV"}
            </button>

            {spin && <CSVDownload data={csvData} target='_blank' />}
          </div>

          <div className='row-container'>
            <Table
              rowKey='_id'
              columns={columns(setAction, setDataEdit, props.userInfo)}
              dataSource={campaignData}
              pagination={false}
            />
          </div>
          <div className='row-container'>
            <Pagination
              pageSize={PAGESIZE}
              total={pagination.total}
              showSizeChanger={false}
              onChange={(e) => {
                setPagination(paginationCount(pagination.total, e));
                getCampaignData(
                  (e - 1) * PAGESIZE,
                  e,
                  props.userInfo._id,
                  startDate,
                  endDate
                );
              }}
            />
          </div>
        </>
      ) : (
        <Edit
          userInfo={props.userInfo}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          action={action}
          data={dataEdit}
          back={back}
        />
      )}
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);
