import { breakpoints, colors } from "style";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: clamp(852px, 100%, 100%);
  gap: 65px;
  .row-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }
  .form-styling {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 80px;
  }
  .add-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 194px;
    padding: 13.5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    background: #567877;
    border-radius: 10px;
  }
  .outlet-name-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-decoration: none;
    color: #2f80ed;
    font-size: 14px;
  }
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-pagination {
    margin: 100px 0 0 0;
  }
  .ant-pagination-prev,
  .ant-pagination-item {
    margin-right: 2px;
  }
  .ant-pagination-prev,
  .ant-pagination-item,
  .ant-pagination-next {
    background: #f1f2f6;
    color: #8b909a;
    border: none;
    border-radius: 4px;
  }
  .ant-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-pagination-item-active {
    background: #c0dddc;
    color: white;
    border: none;
  }
  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #f1f2f6;
    color: #8b909a;
  }
  .ant-table-thead > tr > th {
    background: #f2f2f2;
  }
  .page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .title {
    margin-bottom: 24px;
    font-weight: bold;
    color: ${colors.purple};
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
