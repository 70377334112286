/* eslint-disable */
import moment from 'moment';

const columns = () => [
    {
      title: 'Description',
      dataIndex: 'merchantDescription',
      key: 'merchantDescription',
      render: (record) => record? <span>{record}</span>: "-"
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
  ]

export default columns;
