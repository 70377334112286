import { breakpoints, colors } from "style";
import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .title {
    margin-bottom: 24px;
    font-weight: bold;
    color: ${colors.purple};
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
