// import { breakpoints, colors } from "style";
import styled from "styled-components";

export const PageContainer = styled.div`
  h4 {
    font-weight: 400;
  }
  p {
    margin-bottom: 0;
  }
  .dollar-sign{
    height: 50px;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid  #E4E6F1;
    background: #F9FAFB;
  }
  .money-input{
    width: 150px;
    height: 50px;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid  #E4E6F1;
    padding: 12px;
  }
  .point-sign{
    height: 50px;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid  #E4E6F1;
    border-left: none;
  }
  .point-input{
    width: 150px;
    height: 50px;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid  #E4E6F1;
    border-right: none;
    padding: 12px;
  }
`;
