import React, { useState, useContext, useEffect } from 'react';
import { PageContainer } from "./style";
import { connect } from 'react-redux'
import { DataContext } from "contexts/DataContextContainer";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { 
  Row, 
  Col,
  Button,
  InputGroup,
  Alert,
  Form
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import parse from 'html-react-parser';

import client from "feathers.js"
import axios from 'axios';
import moment from 'moment';

const AffiliateSchema = Yup.object().shape({
  name: Yup.string().required("Required!"),
  email: Yup.string().required("Required!"),
});

const Affiliate = props => {
  // const { title } = props
  
  const history = useHistory();
  const { setLoading, showToast, setLocation, setBreadcrumb } = useContext(DataContext);
  const [ submitFormData, setSubmitFormData ] = useState({})
  const [ tos, setTos ] = useState('')
  const [ data, setData ] = useState({})

  useEffect(() => {
    setLocation(props.title)
    setBreadcrumb([props.title])
}, [])

  useEffect(() => {
    // Submit Form
    getAffiliateData()
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  const getAffiliateData = () => {
    setLoading(true)
    client.authenticate()
    .then(() => {
      return client.service('affiliates').find({
        query: {
          userId: props.userInfo._id,
          $limit: 1
        }
      })
    })
    .then((res) => {
      if(res.data.length > 0){
        setTos(res.data[0].tos)
        setSubmitFormData(res.data[0])
        affiliateInfo(res.data[0])
      }
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      showToast('error', err.message)
    })
  }

  const affiliateInfo = (data) => {
    client.authenticate()
    .then((auth) => {
      return axios.post(`${client.io.io.uri}getAffiliateInfo`, {
        userId: props.userInfo._id,
        shortId: data.shortId
      },
      {
        headers: {
          'Authorization': auth.accessToken
        }
      }
      )
    })
    .then(res => {
      setData(res.data)
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      showToast('error', err.message)
    })
  }

  const onSubmit = (value, { resetForm }) => {
    if(submitFormData._id){
    
      value.resubmitDate = moment(new Date()).format()

      client.authenticate()
      .then(() => {
        return client.service('affiliates').patch(submitFormData._id, value)
      })
      .then((res) => {
        // resetForm()
        setLoading(false)
        setSubmitFormData(res)
        showToast('success', "Request Re-Submit Successful!")
      })
      .catch((err)=>{
        console.log(err)
        showToast('error', err.message)
        setLoading(false)
      })

    }else{
      // Create Request

      value.merchantName = props.username

      client.authenticate()
      .then(() => {
        return client.service('affiliates').create(value)
      })
      .then((res) => {
        setSubmitFormData(res)
        resetForm()
        setLoading(false)
        showToast('success', "Request Submit Successful!")
      })
      .catch((err)=>{
        console.log(err)
        showToast('error', err.message)
        setLoading(false)
      })
    }
  }

  return (
    <PageContainer>
      <div className='header'>
        <button className='btn-back' onClick={() => history.goBack()}><ArrowLeftOutlined /></button>
        <div className='breadcrumbs'>
          <div className='child'>Affiliate Program</div>
        </div>
      </div>
      {/* <div className='info-header'>
        <div className='title mb-2'>
          <h3>{title}</h3>
        </div>
      </div> */}
      <div className='page-container'>
        <Row className='text-center'>
          <Col md="12">
            <div className='status-header'>
              <div className='status'>
                <label>Applied</label>
                <div>
                  <p className='font-black'>{data.numApplied || 0}</p>
                </div>
              </div>
              <div className='status'>
                <label>Pending</label>
                <div>
                  <p className='font-black'>{data.numPending || 0}</p>
                </div>
              </div>
              <div className='status'>
                <label>Registered</label>
                <div>
                  <p className='font-black'>{data.numRegistered || 0}</p>
                </div>
              </div>
            </div>
            <div className='wallet-bg'>
              <label>Earn extra cash incentives by converting social media audience into Morerewardz Merchant</label>
              {
                submitFormData.status === 'approved'? (
                  <Row className='justify-content-center'>
                    <Col md="6">
                      <div className='mb-2 text-left'>
                        <label>Merchant Name</label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="Url"
                            value={submitFormData.merchantName}
                            placeholder="Merchant Name"
                            disabled
                          />
                        </InputGroup>
                      </div>
                      <div className='mb-2 text-left'>
                        <label>Affiliate Url</label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="Url"
                            value={window.location.origin + "/signup?aff=" + submitFormData.shortId}
                            placeholder="Affiliate URL"
                            disabled
                          />
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                ): (
                  <>
                  {
                    submitFormData.status === '' || submitFormData.status === 'false'?
                    <Alert variant="primary">
                      <Alert.Heading>You Form is Pending!</Alert.Heading>
                      <p>
                        We will review your request.<br/>
                        Please stay tuned, Once approved you can start setup your affiliate!
                      </p>
                    </Alert>
                    : null
                  }
                  { 
                    submitFormData.status === 'approved'?
                    <Alert variant="success">
                      <Alert.Heading>You Form is Approved!</Alert.Heading>
                      {tos && parse(tos)}
                    </Alert>
                    : null
                  }
                  {
                    submitFormData.status === 'reject'?
                    <Alert variant="danger">
                      <Alert.Heading>Oh snap! You Form is Rejected!</Alert.Heading>
                      {tos && parse(tos)}
                      <br/>
                      {
                        submitFormData.submitDate?
                        <>
                          {/* <p>Submit Date at {moment(submitFormData.submitDate).format('DD/MM/YYYY') }</p>
                          <p>Please Resubmit the from at {moment(submitFormData.submitDate).add(1, 'weeks').format('DD/MM/YYYY') }</p> */}
                        </>
                        :null
                      }
                    </Alert>
                    : null
                  }
                  <Formik
                    validationSchema={AffiliateSchema}
                    onSubmit={onSubmit}
                    initialValues={{
                      name: "",
                      email: "",
                    }}
                  >
                  {({ 
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                  }) => (
                    <Form onSubmit={handleSubmit} id='form1'>
                      <Row className='mb-2 justify-content-center'>
                        <Col md="8">
                          <div className='mb-2'>
                            <Form.Control
                              type="text"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              isInvalid={!!errors.name}
                              placeholder="Name"
                            />
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                          </div>
                          <div className='mb-2'>
                            <Form.Control
                              type="text"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              isInvalid={!!errors.email}
                              placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                          </div>

                          <Button
                            className='mr-2'
                            color="secondary"
                            type='submit'
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    )}
                  </Formik>
                  </>
                )
              }
            </div>
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
}

const mapStateToProps = state => ({
  auth: state.role.auth,
  userId: state.role.details.user?state.role.details.user._id:'',
  username: state.role.details.user?state.role.details.user.username:'',
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Affiliate);
