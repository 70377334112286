import React from "react";
import { RewardContainer } from "./style";
import { connect } from "react-redux";
import { Modal } from "antd";
import { FaTimesCircle } from "react-icons/fa";
import { Success } from "assets/svg";

const SuccessModal = (props) => {
  return (
    <Modal
      title={false}
      visible={props.showModal}
      onCancel={() => {
        props.toggleModal();
      }}
      centered
      closable={true}
      footer={false}
      // maskClosable={false}
      // getContainer={() => document.getElementById("reward-container")}
      width='90%'
      className='login-antd-modal'
      closeIcon={<FaTimesCircle className='close-icon' />}
    >
      <RewardContainer>
        <div className='reward-container'>
          <div className='fail'>
            <img src={Success} alt='' width='48px' height='48px' />
            <h5 style={{ fontWeight: "600" }}>{props.message}</h5>
          </div>
        </div>
      </RewardContainer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);
