import { breakpoints, colors } from "style";
import { createGlobalStyle } from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    height: 100%;
    font-family: "Roboto", Sans-serif;
  }
  .App {
    height: 100vh;
  }
  .cursor {
    cursor: pointer;
  }
  h1 {
    font-size: 60px;
    // font-family: 'Lato-Bold';
    // font-weight: bold;
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 32px;
    }
  }
  h2 {
    font-size: 34px;
    // font-family: 'Lato-Black';
    // font-weight: bold;
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 32px;
    }
  }
  h3 {
    font-size: 28px;
    // font-family: 'Lato-Bold';
    // font-weight: bold;
  }
  h4 {
    font-size: 18px;
    line-height: 28px;
    // font-family: 'Lato-Regular';
    // font-weight: bold;
  }
  h5 {
    font-size: 14px;
    // font-family: 'Lato-Regular';
    // font-weight: bold;
  }
  h6 {
    // font-family: 'Lato-Regular';
    // font-weight: bold;
  }
  a {
    /* text-decoration: underline; */
  }
  .container {
    @media (min-width: 1484px) {
      min-width: 1484px;
    }
  }
  button, .button {
    font-size: 18px;
    font-weight: 700;
    border: none;
    transition: all .45s;
  }
  .btn-redLight {
    height: 25px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    background-color: #E01414;
    padding: 0 16px;
    line-height: 21px;
    &:hover {
      background-color: #F15C5C;
    }
    &:disabled {
      background-color: #F15C5C;
      cursor: not-allowed;
    }
  }
  .btn-red {
    height: 25px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    background-color: #DF1919;
    padding: 0 16px;
    line-height: 21px;
    &:hover {
      background-color: #E08218;
    }
    &:disabled {
      background-color: #E08218;
      cursor: not-allowed;
    }
  }
  .btn-darkgreen {
    height: 25px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    background-color: #0B4141;
    padding: 0 16px;
    line-height: 21px;
    &:hover {
      background-color: #0F6468;
    }
    &:disabled {
      background-color: #0F6468;
      cursor: not-allowed;
    }
  }
  .btn-orange {
    height: 25px;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    background-color: #CE4E05;
    padding: 0 16px;
    line-height: 21px;
    &:hover {
      background-color: #E08218;
    }
    &:disabled {
      background-color: #E08218;
      cursor: not-allowed;
    }
  }
  .bg-gray {
    background: rgba(0, 0, 0, 0.13);
  }
  ::-webkit-input-placeholder {
     font-size: 1rem !important;
     color: ${colors.textGrey} !important;
  }

  :-moz-placeholder {
    font-size: 1rem !important;
    color: ${colors.textGrey} !important
  }
  ::-moz-placeholder {
    font-size: 1rem !important;
    color: ${colors.textGrey} !important
  }

  .modal-edit-image {
    .ant-modal-body {
      align-items: center;
      display: flex;
      justify-content: center;
      background: ${colors.lightGrey};
      padding: 0;
      .ReactCrop__image {
        max-height: 600px;
      }
    }
  }
`;

export default GlobalStyle;
