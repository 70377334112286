import { Menu } from "antd";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 80px;
  margin: 50px 0 0 0;
  .row-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
  }
  .status-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
  }
  .primary-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 194px;
    padding: 13.5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    background: #567877;
    height: 48px;
    border-radius: 10px;
  }
  .filter-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 144px;
    padding: 13.5px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    background: #567877;
    height: 48px;
    border-radius: 10px;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
    max-width: 350px;
  }
  .search-bar {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 240px;
    max-width: 240px;
    background: white;
    border: 1px solid #eaecee;
    border-radius: 10px;
    height: 48px;
  }
  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .tagline-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    padding: 20px;
    background: white;
  }
  .tagline-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
  }
  .tagline-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #212134;
  }
  .form-group,
  .input-group {
    display: flex;
    align-items: center;
    width: auto;
    background: white;
    height: 48px;
  }
  .input-group-text,
  .ant-picker-range {
    height: 100%;
  }
  .status-active {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    background: #ecfdf5;
    color: #064e3b;
    font-size: 14px;
    height: 20px;
    width: 60px;
    font-weight: 450;
  }
  .status-inactive {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    background: #fef2f2;
    color: #b85d5d;
    font-size: 14px;
    height: 20px;
    width: 60px;
    font-weight: 450;
  }
  .custom-modal-header {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: black;
  }
  .custom-modal-text {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 18px;
    color: #567877;
  }
  .custom-modal-text-alt {
    color: #6b7280;
  }
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table-thead > tr > th {
    background: #e7eaee;
  }
  .ant-table-cell::before {
    display: none;
  }
  .ant-pagination {
    display: flex;
    align-items: center;
    width: auto;
    gap: 16px;
    margin: 50px 0 0 0;
  }
  .ant-pagination-prev {
    margin-right: 50px;
  }
  .ant-pagination-next {
    margin-left: 50px;
  }
  .ant-pagination-prev,
  .ant-pagination-item,
  .ant-pagination-next {
    background: white;
    color: #344051;
    border: 1px solid #f2f4f7;
    border-radius: 8px;
  }
  .ant-pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-pagination-item-active {
    background: #f2f4f7;
    color: #344051;
    border: 1px solid #344051;
  }
  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #8b909a;
    border-radius: 8px;
  }
  .ant-spin-dot-item {
    background: white;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: 42px;
    border-radius: 10px;
  }
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 42px;
  }
`;

export const ActionMenuContainer = styled(Menu)`
  border-radius: 4px;
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    span {
      margin-right: 12px;
    }
  }
`;
