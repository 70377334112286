import React from "react";
import { RowContainer } from "./style";

const DetailsSection = (props) => {
  const { leftHeader, leftDesc, leftContent, rightContent, align } = props;

  return (
    <RowContainer>
      <div className='left-content-container'>
        <div className='left-content-header'>{leftHeader}</div>
        <div className='left-content-text'>{leftDesc}</div>
        {leftContent}
      </div>
      <div className='right-content-container' style={{ alignItems: align }}>
        {rightContent}
      </div>
    </RowContainer>
  );
};

export default DetailsSection;
