import styled from "styled-components";
import { breakpoints, colors } from "style";

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 50px 0 0 0;
    width: 100%;
  }
  .guide-text {
    font-size: 30px;
    color: #191d23;
  }
  .page-container {
    width: 100%;
    height: 100%;
    background: #fafbfc;
    border-radius: 8px;
    p {
      margin-bottom: 0;
    }
    span {
      font-size: 12px;
    }
  }
  .start-guide-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-width: 825px;
    height: auto;
    min-height: 458px;
    background-color: #fafbfc;
    margin: 10px 0 0 0;
    border-radius: 20px;
    padding: 50px 60px;
    border: 1px solid #eaecee;
    box-shadow: 5px 10px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      5px -5px 10px rgba(220, 220, 220, 0);
  }
  .step-container {
    width: 100%;
    max-width: 350px;
  }
  .step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 335px;
    border-right: 1px solid #eaecee;
  }
  .step-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    height: 100%;
    min-height: 356px;
    padding: 30px 0 30px 70px;
  }
  .step-details-header {
    font-size: 25px;
    font-weight: 650;
  }
  .step-details-text {
    font-size: 18px;
    line-height: 25px;
    color: #6b7280;
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
  }
  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #567877;
    padding: 10px;
    width: 100%;
    max-width: 160px;
    border-radius: 10px;
  }
  .button-next {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 10px;
    width: 100%;
    max-width: 160px;
    border-radius: 10px;
    border: 1px solid #567877;
  }
  .cards-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin: 50px 0 0 0;
  }
  .cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    border: 1px solid #eaecf0;
    background: white;
    border-radius: 20px;
    width: 100%;
    height: 118px;
    padding: 20px;
    box-shadow: 5px 10px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2),
      5px -5px 10px rgba(220, 220, 220, 0);
  }
  .cards-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #6b7280;
  }
  .cards-text {
    display: flex;
    align-items: center;
    font-size: 30px;
    color: #101828;
    font-weight: 600;
  }

  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    // margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .box-1 {
    label {
      font-weight: 700;
      color: #3b7779;
    }
    h2 {
      display: inline-block;
    }
    p {
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      color: #3b7779;
    }
  }
  .info-header {
    display: flex;
    align-items: center;
    // .title {
    //   width: 170px;
    //   display: flex;
    //   align-items: center;
    //   border-right: 2px solid ${colors.bgGrey};
    //   h3 {
    //     font-weight: bold;
    //     color: ${colors.purple};
    //   }
    // }
    .plan-info {
      display: flex;
      .plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
        span {
          font-weight: bold;
          font-size: 18px;
          &:nth-child(1) {
            color: ${colors.purple};
          }
        }
      }
      .maximum {
        span:nth-child(2) {
          color: ${colors.red};
        }
      }
      .active {
        span:nth-child(2) {
          color: ${colors.blue};
        }
      }
      .balance {
        span:nth-child(2) {
          color: ${colors.yellow};
        }
      }
    }
    .btn-plan {
      margin-left: 24px;
      width: 200px;
      background: #3b7779;
      color: white;
      font-weight: normal;
      font-size: 15px;
      height: 34px;
      border-radius: 17px;
    }
  }
  .status-header {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 20px;
    .status {
      width: 30%;
      height: 100px;
      background: linear-gradient(
        160deg,
        ${colors.red},
        ${colors.red},
        ${colors.red},
        ${colors.yellow}
      );
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span:nth-child(1) {
        color: white;
        font-size: 18px;
      }
      span:nth-child(2) {
        color: white;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
