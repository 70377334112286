import React, { useState } from "react";
import { InfoCardContainer } from "./style";

import { InfoCardArrow, InfoCardError } from "assets/svg";

const InfoCard = ({ desc }) => {
  const [open, setOpen] = useState(true);
  const [mainHeight, setMainHeight] = useState("auto");

  const onClickDescHandler = () => {
    setOpen(!open);
  };

  const arrowToggleStyle = {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    transform: open ? "" : "rotate(180deg)",
    position: "absolute",
    top: "20px",
    right: "18px",
  };

  return (
    <InfoCardContainer>
      <div className='error-wrapper'>
        <img src={InfoCardError} alt='' width='16px' height='16px' />
      </div>
      <div className='desc-wrapper'>
        <div className='desc-header'>Description</div>
        {open && <div className='desc-text'>{desc}</div>}
      </div>
      <button
        type='button'
        onClick={() => onClickDescHandler()}
        style={arrowToggleStyle}
      >
        <img src={InfoCardArrow} alt='' width='20px' height='20px' />
      </button>
    </InfoCardContainer>
  );
};

export default InfoCard;
