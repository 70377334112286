import { Layout } from "antd";
import styled from "styled-components";

export const AdminLayoutContainer = styled(Layout)`
  height: 100%;
  background: white;
  position: relative;
  .ant-layout-content {
    background: #fafbfc;
  }
  .toast-container {
    top: 100px;
    width: unset;
  }
  .renew-plan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    height: auto;
    border: 1px solid #eaecee;
    background: white;
    border-radius: 10px;
  }
  .renew-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 120px;
    height: 40px;
    color: white;
    background: #567877;
    border-radius: 8px;
    border: none;
  }
  .button-collapse {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    height: 77px;
    background: #eaecee;
    border: none;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    position: absolute;
    top: 45%;
    left: 5px;
    transform: translate(-45%);
    z-index: 99;
  }
  .layer-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 767px) {
  }
`;
