import { Menu, Dropdown } from "antd";
import {
  EllipsisOutlined,
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ActionMenuContainer } from "../style";
import { Row, Col } from "react-bootstrap";
import client from "feathers.js";
import { Badge } from "react-bootstrap";

const menu = (
  record,
  setAction,
  setDataEdit,
  toggleModal,
  setConfirmAction
) => {
  return (
    <ActionMenuContainer>
      <Menu.Item
        key='0'
        onClick={() => {
          setAction("edit");
          setDataEdit(record);
        }}
      >
        <FormOutlined /> Edit
      </Menu.Item>
      <Menu.Item
        key='1'
        onClick={() => {
          toggleModal();
          setConfirmAction("default");
          setDataEdit(record);
        }}
      >
        <FormOutlined /> Make as default
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => {
          toggleModal();
          setConfirmAction("delete");
          setDataEdit(record);
        }}
      >
        <DeleteOutlined /> Delete
      </Menu.Item>
    </ActionMenuContainer>
  );
};

const columns = (setAction, setDataEdit, toggleModal, setConfirmAction) => [
  {
    title: "Outlet",
    key: "outlet",
    align: "center",
    width: "103px",
    render: (records) => {
      return (
        <img
          src={`${client.io.io.uri}${records.canvasMap}`}
          alt=''
          width='63px'
          height='63px'
        />
      );
    },
  },
  {
    title: "Outlet Name",
    key: "outletName",
    align: "left",
    width: "209px",
    render: (records) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            textDecoration: "underline",
            color: "#2f80ed",
            fontSize: "14px",
          }}
        >
          {records.storeName}
        </div>
      );
    },
  },
  {
    title: "Contact Details",
    key: "contactDetails",
    align: "left",
    width: "233px",
    render: (records) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            fontSize: "14px",
          }}
        >
          <div style={{ textDecoration: "underline" }}>
            {records.outletEmail}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {records.outletPhonePrefix} {records.outletContactNumber}
          </div>
        </div>
      );
    },
  },
  {
    title: "Address",
    key: "address",
    align: "left",
    width: "209px",
    render: (records) => {
      return <div>{records.formatted_address}</div>;
    },
  },
  {
    title: "Operating Hours",
    key: "operatingHours",
    align: "left",
    width: "209px",
    render: (records) => {
      return (
        <>
          {Object.entries(records.workingHours).map((x, i) => {
            const day = x[0].charAt(0).toUpperCase() + x[0].slice(1);

            if (x[1].length > 0) {
              return (
                <span
                  key={i}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {day}
                  <div
                    style={{
                      fontStyle: "italic",
                      color: "#6B7280",
                    }}
                  >
                    {x[1][0].startTime} - {x[1][0].endTime}
                  </div>
                  <br />
                </span>
              );
            } else {
              return null;
            }
          })}
        </>
      );
    },
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    width: "105px",
    render: (record) => (
      <Dropdown
        overlay={menu(
          record,
          setAction,
          setDataEdit,
          toggleModal,
          setConfirmAction
        )}
        trigger={["click"]}
      >
        <a
          href='/#'
          className='ant-dropdown-link'
          onClick={(e) => e.preventDefault()}
          style={{ color: "gray" }}
        >
          <EllipsisOutlined style={{ fontSize: 20 }} />
        </a>
      </Dropdown>
    ),
  },
];

export default columns;
