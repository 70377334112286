const toolData = {
  stamp: [
    {
      key: '1',
      id: '3325',
      rewards: 'Free coffee of the day',
      stamps: 5,
      sdate: '19/08/2021',
      edate: '19/08/2021',
      not_redeemed: 22,
      redeemed: 3,
      status: 'active'
    },
    {
      key: '2',
      id: '3325',
      rewards: 'Free Pasta',
      stamps: 5,
      sdate: '19/08/2021',
      edate: '19/08/2021',
      not_redeemed: 22,
      redeemed: 3,
      status: 'active'
    },
    {
      key: '3',
      id: '3325',
      rewards: 'Free 1 Cup of Coffee',
      stamps: 5,
      sdate: '19/08/2021',
      edate: '19/08/2021',
      not_redeemed: 22,
      redeemed: 3,
      status: 'expired'
    }
  ],
  package: [
    {
      key: '1',
      id: '3325',
      rewards: '10 Coffee for $50',
      price: 35,
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'active'
    },
    {
      key: '2',
      id: '3325',
      rewards: '10 Set lunch for $99',
      price: 70,
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'paused'
    },
    {
      key: '3',
      id: '3325',
      rewards: '10 Coffee for $50',
      price: 35,
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'active'
    }
  ],
  voucher: [
    {
      key: '1',
      id: '3325',
      rewards: '10 Coffee for $50',
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'active'
    },
    {
      key: '2',
      id: '3325',
      rewards: '10 Set lunch for $99',
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'active'
    },
    {
      key: '3',
      id: '3325',
      rewards: '10 Coffee for $50',
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'active'
    }
  ],
  referral: [
    {
      key: '1',
      id: '3325',
      rewards: '10 Coffee for $50',
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'active'
    },
    {
      key: '2',
      id: '3325',
      rewards: '10 Set lunch for $99',
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'active'
    },
    {
      key: '3',
      id: '3325',
      rewards: '10 Coffee for $50',
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'active'
    },
    {
      key: '4',
      id: '3325',
      rewards: '10 Coffee for $50',
      sdate: '19/08/2021',
      edate: '19/08/2021',
      sold: 22,
      utilized: 3,
      status: 'active'
    }
  ]
}

const toolStatusTitle = {
  stamp: [
    'Live Stamp Card',
    'Active Stamp Card',
    'Completed Stamp Card'
  ],
  package: [
    'Live Package',
    'Package Sold',
    'Package Utilized'
  ],
  voucher: [
    'Live Voucher',
    'Voucher Claimed',
    'Voucher Utilized'
  ],
  referral: [
    'Live Promotion',
    'Promotion Sign Up',
    'Promotion Utilized'
  ],
  points: [
    'Submission',
    'Promotion Sign Up',
    'Promotion Utilized'
  ],
  wallet: [
    'Wallet Holder',
    'Total Wallet Credit',
    'Total Wallet Debit'
  ]
}

export {
  toolData,
  toolStatusTitle
}
