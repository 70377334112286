import { Menu, Dropdown } from "antd";
import {
  EllipsisOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ActionMenuContainer } from "../../style";
import moment from "moment";

const menu = (
  record,
  setAction,
  setDataEdit,
  toggleModal,
  setConfirmAction
) => {
  return (
    <ActionMenuContainer>
      <Menu.Item
        key='0'
        onClick={() => {
          setAction("edit");
          setDataEdit(record);
        }}
      >
        <FormOutlined /> Edit
      </Menu.Item>
      <Menu.Item
        key='1'
        onClick={() => {
          toggleModal();
          setConfirmAction("activate");
          setDataEdit(record);
        }}
      >
        {record.status === "Active" && (
          <>
            <PauseOutlined /> Deactivate
          </>
        )}
        {record.status === "Inactive" && (
          <>
            <PlayCircleOutlined /> Activate
          </>
        )}
      </Menu.Item>
      <Menu.Item
        key='2'
        onClick={() => {
          toggleModal();
          setConfirmAction("delete");
          setDataEdit(record);
        }}
      >
        <DeleteOutlined /> Delete
      </Menu.Item>
    </ActionMenuContainer>
  );
};

const columns = (setAction, setDataEdit, toggleModal, setConfirmAction) => [
  {
    title: "Member Reward",
    key: "member_title",
    render: (record) => {
      return (
        <a
          href='#edit'
          onClick={() => {
            setAction("edit");
            setDataEdit(record);
          }}
        >
          {record.member_title}
        </a>
      );
    },
  },
  {
    title: "New SignUp Reward",
    key: "new_signup_title",
    render: (record) => {
      return (
        <a
          href='#edit'
          onClick={() => {
            setAction("edit");
            setDataEdit(record);
          }}
        >
          {record.new_signup_title}
        </a>
      );
    },
  },
  {
    title: "Validity of member",
    dataIndex: "member_validity",
    key: "member_validity",
    render: (record, records) => (
      <span className=''>
        {records.member_validity +
          " " +
          records.member_validity_unit.slice(0, -1)}
        (s)
      </span>
    ),
  },
  {
    title: "validity of new SignUp",
    dataIndex: "new_signup_validity",
    key: "new_signup_validity",
    render: (record, records) => (
      <span className=''>
        {records.new_signup_validity +
          " " +
          records.new_signup_validity_unit.slice(0, -1)}
        (s)
      </span>
    ),
  },
  {
    title: "Created Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (record) => (
      <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    ),
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (status) => (
      <span
        className={status === "Active" ? "status-active" : "status-inactive"}
      >
        {status}
      </span>
    ),
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    render: (record) => (
      <Dropdown
        overlay={menu(
          record,
          setAction,
          setDataEdit,
          toggleModal,
          setConfirmAction
        )}
        trigger={["click"]}
      >
        <a
          href='/#'
          className='ant-dropdown-link'
          onClick={(e) => e.preventDefault()}
          style={{ color: "gray" }}
        >
          <EllipsisOutlined style={{ fontSize: 20 }} />
        </a>
      </Dropdown>
    ),
  },
];

export default columns;
