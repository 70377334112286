import React from "react";
import { InfoContainer, CampaignsMessage } from "./style";
import { BrandPreviewBottom } from "assets/images"
import { isEmpty } from "lodash";
import { Rate } from 'antd';

const AppPreview = (props) => {
  var color1 = "#3b7779"
  var color2 = "#FDB256"

  let croppedBannerUrl = props.croppedBannerUrl
  let croppedLogoUrl = props.croppedLogoUrl
  let merchant = props.userInfo
  let height = 120
  // let width = props.width? props.width: 400
  
  return (
    <div style={{
      width: 388
    }}>
    <div 
      style={{
        position: 'relative',
        flex: 1,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: width,
        // boxShadow: '0px 8px 18px rgba(133, 133, 133, 0.1)',
      }}>
      <InfoContainer id="merchant-info" color1={color1} color2={color2}>
        <div className='info-wrapper shadow-sm'>
          <div 
            className='info' 
            style={{ 
              backgroundImage: `url(${croppedBannerUrl})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center', 
              backgroundRepeat: 'no-repeat',
              height: height,
              width: 360
            }}
          >
            {/* {props.croppedBannerEle} */}

            {props.croppedLogoEle}
            <img src={`${croppedLogoUrl}`} className='brand-logo' alt='brand-logo'/>
          </div>
          <div className='info-details'>
            {/* <img src={LogoWhite} className='app-logo' alt='logo-white'/> */}
            <div className='social'>
              <div className='w-100'>
              <div className='social-actions'>
                <div 
                  className={'like'}
                ><i className="fas fa-heart"/></div>
                <div 
                  className='share'
                ><i className="fas fa-share-alt"/></div>
              </div>
                <div className='brand-info'>
                    <div className="header">
                      <h3 className="text-capitalize">{merchant.business_name}</h3>
                      {
                        merchant.business_verified? (
                          <div className='business-checked'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                            </svg>
                          </div>
                        ): null
                      }
                    </div>
                    {
                    merchant.physicalStore &&
                      !isEmpty(merchant.outletData)? (
                        <>
                          <p>
                            <i className="fas fa-map-marker-alt"/> 
                            {" "}
                            {merchant.outletData.address1}
                            {" "}
                            <a href="/#">(see more...)</a>
                          </p>
                        </>
                      ): null
                    }
                  <div className='ratings d-flex'>
                    <div style={{width: '80%'}}>
                      <Rate disabled allowHalf defaultValue={0} />
                      <p className="text d-inline-block ml-1">
                      {" "}
                      Rating: 0 · 0 review(s)
                      </p>
                    </div>
                    <div style={{width: '20%'}}>
                      <div className='d-flex justify-content-end'>
                        <div className="thumbs-wrap mr-3">
                          <i className="fas fa-thumbs-up"/>
                          <span className="text">
                            {" "}{0}
                          </span>
                        </div>

                        <div className="thumbs-wrap">
                          <i className="fas fa-thumbs-down"/>
                          <span className="text">
                          {" "}{0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CampaignsMessage>
          {props.notification?
            (
              <div>
                {props.notification.split("||")[1]}
              </div>
            )
            :
            (
              <div>
                Welcome to <span>{props.userInfo.business_name}</span>
              </div>
            )
          }
        </CampaignsMessage>
      </InfoContainer>
      <img src={BrandPreviewBottom} className="w-100" alt="" />
    </div>
    </div>
  );
}
 
export default AppPreview;