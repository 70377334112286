import React, { useState, useContext, useEffect } from "react";
import { Table, Pagination } from "antd";
import { PageContainer } from "../style";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";
import columns from "./columns";

import client from "feathers.js";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const PointsSubmissions = (props) => {
  const { setLoading, showToast, setBreadcrumb, setLocation } =
    useContext(DataContext);
  const [campaignData, setCampaignData] = useState([]);
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);

  useEffect(() => {
    getCampaignData(0, 1, props.userInfo._id);
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  const getCampaignData = (skip, currentPage, userId) => {
    setLoading(true);
    client
      .authenticate()
      .then(() => {
        return client.service("notifications").find({
          query: {
            $skip: skip,
            $limit: PAGESIZE,
            merchantId: userId,
            $sort: { createdAt: -1 },
          },
        });
      })
      .then((res) => {
        setCampaignData(res.data);
        setPagination(paginationCount(res.total, currentPage));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  return (
    <PageContainer>
      <div className='row-container'>
        <Table
          rowKey='_id'
          columns={columns()}
          dataSource={campaignData}
          pagination={false}
        />
      </div>
      <div className='row-container'>
        <Pagination
          pageSize={PAGESIZE}
          total={pagination.total}
          showSizeChanger={false}
          onChange={(e) => {
            setPagination(paginationCount(pagination.total, e));
            getCampaignData((e - 1) * PAGESIZE, e, props.userInfo._id);
          }}
        />
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PointsSubmissions);
