import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { ActionMenuContainer } from "../../Admin/style";
import { Tag } from 'antd';
import moment from 'moment';

const menu = (record, setAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item key="0" onClick={() => {
        setAction('edit')
        setDataEdit(record)
        }}>
        <FormOutlined /> Edit
      </Menu.Item>
      {/* <Menu.Item key="1">
        <PauseOutlined /> Pause
      </Menu.Item>
      <Menu.Item key="2">
        <FileTextOutlined /> Details
      </Menu.Item> */}
    </ActionMenuContainer>
  )
}

const columns = (setAction, setDataEdit) => [
    {
      title: 'No.',
      dataIndex: 'no',
      render: (record, records, index) => 
      <span>{index+1}</span>
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'contact_email',
      key: 'contact_email',
    },
    {
      title: 'Phone',
      dataIndex: 'contact_phone',
      render: (record, records) => 
      <span>{records.phonePrefix + " " + records.contact_phone}</span>
    },
    {
      title: 'Merchant ID',
      dataIndex: 'merchantName',
      key: 'merchantName',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (record, records) => {
        if(record){
          if(record.toLowerCase() === 'false'){
            return (
              <>
              <Tag color="#2db7f5" className='text-capitalize'>Pending</Tag>
              {records.resubmitDate? (
                <Tag color="#f50" className='text-capitalize'>Resubmit</Tag>
              ): null}
              </>
            )
          }else if(record.toLowerCase() === 'reject'){
            return (
              <>
              <Tag color="#f50" className='text-capitalize'>{record}</Tag>
              {records.resubmitDate? (
                <Tag color="#f50" className='text-capitalize'>Resubmit</Tag>
              ): null}
              </>
            )
          }else{
            return (
              <>
              <Tag color="#87d068" className='text-capitalize'>{record}</Tag>
              {records.resubmitDate? (
                <Tag color="#f50" className='text-capitalize'>Resubmit</Tag>
              ): null}
              </>
            )
          }
        }else{
          return null
        }
      }
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => 
        <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (record) => (
        <Dropdown overlay={menu(record, setAction, setDataEdit)} trigger={['click']}>
          <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
            <EllipsisOutlined style={{fontSize: 20}}/>
          </a>
        </Dropdown>
      ),
    },
  ]

export default columns;
