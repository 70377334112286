import { Layout } from "antd";
import styled from "styled-components";
import { colors } from "style";

const { Sider } = Layout;

export const SideMenuContainer = styled(Sider)`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #ffffff;
  box-shadow: 5px 0 7px rgb(0, 0, 0, 0.1);
  z-index: 100;
  padding: 28px 24px;

  &::-webkit-scrollbar {
    display: none;
  }
  .mrz-parents-menu {
    & > .ant-menu {
      background: transparent;
      padding: 0 15px;
      border-left: 1px solid #d0d5dd;
      margin: 0 0 10px 12px;

      & > .ant-menu-item {
        margin-bottom: 8px !important;
      }
    }
  }
  .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom
  }
  .side-menu-text {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    font-size: 16px;
    align-items: center;
    font-weight: 625;
    color: #a8a6a6;
  }
  .custom-top-hr {
    border: 1px solid #d0d5dd;
    width: 100%;
  }
  .custom-bottom-hr {
    border: 1px solid #d0d5dd;
    width: 100%;
  }
  .upgrade-now-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 50px 20px;
    width: 100%;
  }
  .upgrade-now {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 20px 0;
    box-shadow: 5px 10px 10px rgba(220, 220, 220, 0.2),
      -5px 5px 10px rgba(220, 220, 220, 0.2), -5px 5px 10px rgba(220, 220, 220, 0.2),
      5px -5px 10px rgba(220, 220, 220, 0);
  }
  .upgrade-now-header {
    font-size: 20px;
    font-weight: 500;
  }
  .upgrade-now-text {
    font-size: 11px;
    text-align: center;
    color: #6b7280;
    max-width: 135px;
    line-height: 10px;
  }
  .upgrade-now-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right, #567877, #89A4A3);
    padding: 10px;
    width: 145px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 14px;
    font-weight: light;
    margin-top: 10px;
  }
  .upgrade-now-plan {
    font-size: 11px;
    color: #4f5b67;
  }
  .custom-selected {
    background-color: #f7f8f8;
    * > span {
      color: #567877;
      font-weight: bold;
    }
  }
  .ant-menu {
    background: transparent;
    border-left: 1px solid #d0d5dd;
    margin: 0 0 10px 12px;
  }
  .ant-menu-item::after {
    display: none !important;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    align-items: flex-start;
    border-radius: 11px;
}
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    .business-checked {
      display: inline-block;
      background-color: #007bff;
      height: 24px;
      width: 24px;
      border: none;
      border-radius: 50%;
      color: white;
      text-align: center;

      margin-left: 6px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      svg {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
    .menu-icon {
      color: ${colors.red};
      font-weight: bold;
    }
    .ant-menu {
      .ant-menu-item-selected {
        background-color: #f7f8f8;
        * > span {
          color: #567877;
          font-weight: bold;
        }
      }
      .ant-menu-item-selected:active {
        background: none;
      }
      .ant-menu-item,
      .ant-menu-submenu {
        border-radius: 11px;
        padding-left: 13px !important;
        margin-bottom: 28px;
        &:last-child {
          margin-bottom: 0;
        }
        .ant-menu-submenu-title {
          padding-left: 0 !important;
        }
        a {
          color: #ffffff;
        }
        svg {
          color: #ffffff;
        }
      }
      .ant-menu-submenu-active {
        color: #ffffff;
      }
      .ant-menu-submenu-selected {
      }
      .ant-menu-submenu-title:hover {
        color: #ffffff;
      }
      .ant-menu-submenu-arrow {
        color: #a8a6a6;
      }
      .ant-menu-title-content {
        color: #ffffff;
      }
    }
  }
`;
