import React, { useContext, useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Table, Menu, Dropdown } from 'antd';
import { EllipsisOutlined, FileTextOutlined } from '@ant-design/icons';
import { ActionMenuContainer } from "../../Admin/style";
// import { Modal } from "antd";
import { 
  Row, 
  Col
} from "react-bootstrap";
// import getSymbolFromCurrency from 'currency-symbol-map'
import { Input, Space } from 'antd';
import { PageContainer } from "./styleEdit";
import StyledButton from "components/Elements/StyledButton";
import { DataContext } from "contexts/DataContextContainer";
import { cloneDeep } from 'lodash';
import moment from 'moment';

import Notification from "components/Extra/Notifications.js"

import client from "feathers.js"

const Edit = props => {
  const { setLoading, showToast } = useContext(DataContext);
  const [ data, setData ] = useState([])
  /* eslint-disable-next-line */
  const [isModalVisible, setIsModalVisible] = useState(false);
  /* eslint-disable-next-line */
  const [ isOpen, setIsOpen ] = useState(false)
  /* eslint-disable-next-line */
  const [ recordState, setRecordState ] = useState({})
  // const [ amount, setAmount ] = useState(0)

  /* eslint-disable-next-line */
  const showModal = () => {
    setIsModalVisible(true);
  };

  /* eslint-disable-next-line */
  const handleOk = () => {
    setIsModalVisible(false);
  };

  /* eslint-disable-next-line */
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    // setMessage(message)
  }
  
  useEffect(() => {
    client.authenticate()
    .then(() => {
      return client.service('transactions').find({
        query: {
          userId: props.data._id,
          type: "balance",
          creditDebit: "debit"
        }
      })
    })
    .then((res) => {
      console.log(res)
      setData(res.data)
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      showToast('error', err.message)
      setLoading(false)
    })
  /* eslint-disable-next-line */
  }, [props.data._id])

  function calcComAmount(amount){
    if(new Date() > new Date(moment(props.parentData.merchantInfo.createdAt).add(1, 'years'))){
      return (((10 / 100) * amount))
    }else{
      return  (((20 / 100) * amount))
    }
  }

  const selectedInvoice = (record) => {
    setRecordState(record)

    let comAmount = 0
    let commision = 0
    if(new Date() > new Date(moment(props.parentData.merchantInfo.createdAt).add(1, 'years'))){
      comAmount = (((10 / 100) * record.amount))
      commision = 10
    }else{
      comAmount = (((20 / 100) * record.amount))
      commision = 20
    }

    // payCommision(record)
    markasIssued(record, record.amount, comAmount, commision)
  }

  // const payCommision = (record) => {
  //   showModal()
  // }

  const markasIssued = (record, oriAmount, comAmount, commision) => {
    setLoading(true)

    client.authenticate()
    .then(() => {
      return client.service('earnings').find({
        query: {
          transactionId: record._id,
          limit: 1,
        }
      })
    })
    .then((res) => {
      if(res.data.length > 0){
        return client.service('transactions').patch(record._id, {
          paid: true
        })
      }else{
        client.service('earnings').create({
          transactionId : record._id,

          merchantId: props.parentData.merchantInfo._id,
          merchantName : props.parentData.merchantInfo.username,
          
          oriAmount,
          comAmount,
          commision,
          currency : props.parentData.merchantInfo.currency,
          
          // remark: "",
          // status: "",
      
          userId: props.parentData.merchantInfo._id,
        })
        return client.service('transactions').patch(record._id, {
          paid: true
        })
      }
    })
    .then((res) => {
      let cloneData = cloneDeep(data)
      let findKey = cloneData.findIndex(e => e._id === record._id)
      if(findKey !== -1){
        cloneData[findKey] = res
      }
      setData(cloneData)
      
      setLoading(false)
      showToast('success', `paid successfully`)
      setTimeout(() => {
        window.location.reload(false);
      }, 500);
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      showToast('error', err.message)
    })
  }

  // const paymentMadeComplete = () => {
  //   setLoading(true)

  //   // let paymentId = paymentIntent.id
  //   // let paymentMethodTypes = paymentIntent.payment_method_types
    
  //   client.authenticate()
  //   .then(() => {
  //     return client.service('transactions').create({
  //       type: 'balance',
  //       creditDebit: 'credit',
  //       paymentType: 'wallet',
  //       userId: props.parentData.merchantInfo._id,
  //       merchantId: props.parentData.merchantInfo._id,
  //       paid: true,
  //       amount: amount,
  //       currency: 'usd',
  //       desc: `You have Topup Balance ${getSymbolFromCurrency('usd')} ${amount}`
  //     })
  //   })
  //   .then(res => {
  //     setLoading(false)
  //     handleOk()

  //     notificationOpen(true)
  //     markasIssued(recordState)

  //   })
  //   .catch(err =>{
  //     console.log(err)
  //     setLoading(false)
  //     showToast('error', err.message)
  //   })
  // }

  return (
    <PageContainer>
      <div className='header'>
        <button className='btn-back' onClick={() => props.back()}><ArrowLeftOutlined /></button>
        <h3>Affiliate Approval</h3>
      </div>
      <div className='page-content'>
        <Space className='detail' size={32} direction='vertical'>
          <Row>
            <Col md="12">
              <Space className='section' size={8} direction='vertical'>
                <span className='title'>Url</span>
                <Input 
                  type="text" 
                  size="large" 
                  value={props.parentData.shortId && window.location.origin + "/signup?aff=" + props.parentData.shortId} 
                  disabled
                />
              </Space>
            </Col>
            <Col md="6">
              <Space className='section' size={8} direction='vertical'>
                <span className='title'>From</span>
                <Input 
                  type="text" 
                  size="large" 
                  value={props.parentData.merchantInfo.username} 
                  disabled
                />
              </Space>
            </Col>
          </Row>
          <Row>
            <Col md="6">

              <Space className='section' size={8} direction='vertical'>
                <span className='title'>Merchant ID</span>
                <Input 
                  type="text" 
                  size="large" 
                  value={props.data.username} 
                  disabled
                />
              </Space>

              <Space className='section' size={8} direction='vertical'>
                <span className='title'>Contact Number</span>
                <Row className='no-gutters'>
                  <Col md="4" className='pr-2'>
                    <Input 
                      type='text' 
                      size="large" 
                      value={props.data.phonePrefix} 
                      disabled
                    />
                  </Col>
                  <Col md="8">
                    <Input 
                      type='text' 
                      size="large" 
                      value={props.data.contact_phone} 
                      disabled
                    />
                  </Col>
                </Row>
              </Space>

              <Space className='section' size={8} direction='vertical'>
                <span className='title'>Email</span>
                <Input 
                  type='text' 
                  size="large" 
                  value={props.data.email} 
                  disabled
                />
              </Space>
            </Col>
          </Row>

          <Space className='section' size={8} direction='vertical'>
            <Table 
              rowKey="_id" 
              columns={
                [
                  {
                    title: 'remark',
                    dataIndex: 'remark',
                    key: 'remark'
                  },
                  {
                    title: 'Duration',
                    dataIndex: 'renewDuration',
                    key: 'renewDuration'
                  },
                  {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (record) => ('$ ' + Number(record).toFixed(2))
                  },
                  {
                    title: 'Commision',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (record) => ('$ ' + Number(calcComAmount(record).toFixed(2)))
                  },
                  {
                    title: 'Paid',
                    dataIndex: 'paid',
                    key: 'paid',
                    render: (record) => {
                      return record? "paid": "Pending"
                    }
                  },
                  {
                    title: 'Action',
                    key: 'action',
                    align: 'center',
                    render: (record) => 
                      <Dropdown overlay={
                        <ActionMenuContainer>
                          {/* <Menu.Item key="0" onClick={() => {
                            selectedInvoice(record)
                            }}>
                            <FormOutlined /> Pay commision for {props.parentData.merchantInfo.username}
                          </Menu.Item> */}
                          <Menu.Item key="1" onClick={() => {
                            selectedInvoice(record)
                          }}>
                            <FileTextOutlined /> Mark as paid
                          </Menu.Item>
                      </ActionMenuContainer>
                      }>
                        <EllipsisOutlined style={{fontSize: 20}}/>
                      </Dropdown>
                    ,
                  },
                ]
              }
              dataSource={data}
            />
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <div className='actions d-flex' style={{justifyContent: 'space-evenly'}}>
              <StyledButton
                width={'30%'}
                sizeh={50}
                className="btn-blue mt-5"
                title='Back'
                onClick={() => props.back()}
              />
            </div>
          </Space>
        </Space>
      </div>
      {/* <Modal
        title='Pay Commision'
        centered
        visible={isModalVisible}
        onClick={showModal}
        onOk={paymentMadeComplete}
        onCancel={handleCancel}
      >
        <div>
          <h4 className='text-center' style={{fontWeight: 500}}>
            This payment is make for {props.parentData.merchantInfo.username}'s Commision for {amount} MR Wallet
          </h4>
          <h5 className='text-center' style={{fontWeight: 500}}>
            This Merchant ({props.parentData.merchantInfo.username}) will receive {amount} MR Wallet
          </h5>
        </div>
      </Modal> */}
      <Notification 
        isOpen={isOpen}
        handleOpen={notificationOpen}
        title="Upgrade Subscription"
        content="Subscription Upgrade Successfully!"
      />
    </PageContainer>
  );
}

export default Edit;
