import { colors } from "style";
import styled from "styled-components";

export const ContactContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  // box-shadow:inset 0 0 0 2000px rgb(41 41 41 / 62%);
  background-color: ${props => props.color1};
  .contact-container {
    margin-top: 10px;
    // padding: 20px;
    flex: 1;
    height: 100%;
    width: 100%;
    background: ${colors.white};
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    .title-nav {
      display: flex;
      align-items: center;
      position: relative;
      background-color: ${props => props.color1};
      padding: 25px 20px;
      .btn-back {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        position: absolute;
        z-index: 2;
      }
      .btn-back svg {
        font-size: 24px;
        margin-right: 6px;
      }
      .btn-back span {
        font-weight: 500;
        font-size: 12px;
        color: white;
        // line-height: 14px;
      }
      .title {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        img {
          width: 35px;
          margin-right: 16px;
        }
        span {
          font-weight: 700;
          font-size: 20px;
          color: #FFFFFF;          
        }
      }
    }
    .contact-page-body {
      // flex-wrap: wrap;
      // flex: 1;
      // display: flex;
      margin-top: 30px;
      padding: 0px 18px;
      height: 82%;
      overflow-y: scroll;
      > div {
        width: 100%;
        text-align: center;
        margin: auto;
        margin-bottom: .7rem;
      }
      .logo {
        .profile-icon {
          background: #F9F9F9;
          border: 8px solid #FFFFFF;
          box-shadow: 0px 12px 19px rgba(220, 220, 220, 0.25);
          border-radius: 50%;
          height: 124px;
          width: 124px;
          display: inline-block;
        }
        @media (max-width: 375px) {
          .profile-icon {
            height: 100px;
            width: 100px;
          }
        }
      }
      .card-wrapper {
        background: #FFFFFF;
        box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.35);
        border-radius: 10px;
        padding: 10px;
        h1 {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #263238;
          // text-transform: capitalize;
          margin: 0;
        }
        .address {
          font-size: 16px;
          h1 {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #3b7779;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #979797;
            margin: 0;
          }
        }
        .about {
          h4 {
            font-size: 16px; 
            font-weight: 500;
            margin: 0;
            line-height: 1;
          }
          span, p {
            color: #979797;
            font-size: 14px;
          }
        }
        .contact {
          font-size: 16px;
          h1 {
            font-size: 18px;
            font-weight: bold;
            .fa-envelope {
              margin-right: 4px;
            }
          }
          p {
            text-decoration: underline;
            margin: 0;
            .fa-phone {
              margin-right: 4px;
            }
          }
        }
      }
      .social {
        a {
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #3B3054;
          // text-transform: capitalize;
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          .fa-facebook {
            font-size: 24px;
            margin-right: 4px;
            color: #3785cc;
          }
          .fa-instagram {
            font-size: 24px;
            margin-right: 4px;
            color: #f04621;
          }
          .fa-twitter {
            font-size: 24px;
            margin-right: 4px;
            color: #00acee;
          }
        }
      }
    }
  }
`;
