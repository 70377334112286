import { Layout } from "antd";
import styled from "styled-components";
const { Header } = Layout;

export const InnerHeaderContainer = styled(Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 40px;
  background: #fafbfc;
  width: clamp(940px, 100%, 100%);
  height: 84px;
  position: relative;
  .url-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .input-container {
    display: inline-block;
    position: relative;

    & > button {
      position: absolute;
      top: 12px;
      right: 10px;
      z-index: 10;
    }
  }
  .input-box {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 40px;
    width: 345px;
    border: 1px solid #ced2da;
    padding: 0 35px 0 10px;
    position: relative;
    border-radius: 8px 0 0 8px;
    line-height: 40px;
  }
  .copy-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: #ffffff;
    font-size: 16px;
    font-weight: light;
    background-color: #567877;
    border-radius: 0 20px 20px 0;
    padding: 0 10px;
    max-height: 40px;
  }
  #link::selection {
    background: transparent;
    color: black;
  }
  .breadcrumb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    width: 100%;
  }
  .breadcrumb-item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: auto;
    min-width: 80px;
    max-height: 26px;
    padding: 6px 16px;
    background-color: #f5f5f5;
    border: none;
    border-radius: 20px;
    color: #bdbdbd;

    &:last-child {
      background-color: #eeeeee;
      color: #424242;
    }
  }
  .breadcrumb-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0 0 0;
    font-size: 10px;
    font-weight: 600;
  }
  .tooltip-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #eaecee;
    width: 100%;
    height: auto;
    max-width: 240px;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    bottom: -60px;
    right: 110px;
    z-index: 100;
  }
  .tooltip-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    color: #344051;
    line-height: 16px;
  }
  .tooltip-text {
    font-size: 12px;
    color: #637083;
    line-height: 15px;
  }
  .tooltip-arrow {
    position: absolute;
    top: -5px;
    right: 24px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #eaecee;
  }
  .right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;
