import { breakpoints, colors } from "style";
import styled from "styled-components";

export const CompleteContainer = styled.div`
  width: 100%;
  padding: 0px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  h2 {
    font-weight: 500 !important;
    font-size: 24px;
    line-height: 28px;
    color: #3B3054;
  }
  h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #979797;
    text-align: center;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    .check-mark {
      width: 100px;
      height: 100px;
      font-size: 100px;
      color: ${colors.green};
      margin: 30px auto;
    }

    h3 {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
