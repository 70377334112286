import React, { useState, useContext, useEffect } from "react";
import { Table } from "antd";
import { useHistory } from "react-router-dom";
import { PageContainer } from "./style";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";
import columns from "./columns";
import Edit from "./Edit";
import env from "env";
import ModalConfirmation from "components/Extra/ModalConfirmation";

import client from "feathers.js";
import axios from "axios";
import InfoCard from "components/InfoCard/InfoCard";
import DataCard from "components/DataCard";

const CustomerManagement = (props) => {
  const history = useHistory();
  const { setLoading, showToast, setLocation, setBreadcrumb } =
    useContext(DataContext);
  const [action, setAction] = useState("all"); //edit, detail, create
  const [campaignData, setCampaignData] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [confirmAction, setConfirmAction] = useState("default");
  const [modalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const modalCallback = () => {
    if (confirmAction === "default") {
      client
        .authenticate()
        .then(() => {
          return client
            .service("outlets")
            .patch(dataEdit._id, { dateToFirst: new Date() });
        })
        .then((res) => {
          getCampaignData(props.userInfo._id);

          setLoading(false);
          showToast("success", "Outlet default is updated successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    } else {
      client
        .authenticate()
        .then(() => {
          return client.service("outlets").remove(dataEdit._id);
        })
        .then((res) => {
          handleDelete(res.canvasMap);
          getCampaignData(props.userInfo._id);

          setLoading(false);
          showToast("success", "Outlet default is updated successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    }
  };

  const getCampaignData = (userId) => {
    setLoading(true);
    client
      .authenticate()
      .then((auth) => {
        return client.service("outlets").find({
          query: {
            userId,
            $sort: {
              // createdAt: 1,
              dateToFirst: -1,
            },
          },
        });
      })
      .then((res) => {
        // let max = ''
        // let key = -1
        if (res.data.length > 0) {
          // res.data.forEach(function (v, k) {
          //   if (max < v.dateToFirst) {
          //     max = + v.dateToFirst
          //     key = k;
          //   }
          //   return v
          // });

          // if(key !== -1){
          //   res.data[key].default = true
          // }else{
          res.data[0].default = true;
          // }
        }

        setCampaignData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  const handleDelete = (currentFileName) => {
    axios({
      method: "post",
      url: `${client.io.io.uri}deleteFileLocalPublic`,
      data: {
        fileName: currentFileName,
        toFolder: "userMaps",
      },
      config: { headers: { "Content-Type": "application/json" } },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createCampaign = () => {
    if (
      campaignData.length <
      env.subscription[props.userInfo.subscription || "copper"].outlet
    ) {
      setAction("create");
      setDataEdit({});
    } else {
      showToast("error1", `to unlock additional limits and features!`);
    }
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Page", props.title]);
  }, []);

  useEffect(() => {
    getCampaignData(props.userInfo._id);
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  const back = () => {
    setAction("all");
  };

  return (
    <PageContainer>
      {action === "all" ? (
        <>
          <InfoCard
            desc={
              "Outlet Location Details section allows you to provide specific information about your different outlets. This information is crucial for customers to easily locate and engage with your physical stores. All details will be shown on your campaign appearance page. Learn more"
            }
          />
          <div className='row-container'>
            <DataCard
              title='Available Outlets'
              data={campaignData.length}
              total={
                env.subscription[props.userInfo.subscription || "copper"].outlet
              }
            />
            <button
              className='add-button'
              onClick={() => {
                createCampaign();
              }}
            >
              + Outlet
            </button>
          </div>

          <div className='row-container'>
            <Table
              rowKey='_id'
              columns={columns(
                setAction,
                setDataEdit,
                toggleModal,
                setConfirmAction
              )}
              dataSource={campaignData}
              className='table-overwrite'
            />
          </div>
        </>
      ) : (
        <Edit
          userInfo={props.userInfo}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          action={action}
          data={dataEdit}
          back={back}
        />
      )}

      <ModalConfirmation
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              {"Confirmation"}
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>
              {confirmAction === "default"
                ? "Proceed to make this Outlet as default?"
                : "Proceed to Delete Outlet?"}
            </span>
          </>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);
