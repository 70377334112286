/* eslint-disable */
import { Menu, Dropdown } from "antd";
import { EllipsisOutlined, FormOutlined } from "@ant-design/icons";
import { ActionMenuContainer } from "../style";
import moment from "moment";

const menu = (record, setAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item
        key='0'
        onClick={() => {
          setAction("edit");
          setDataEdit(record);
        }}
      >
        <FormOutlined /> Edit
      </Menu.Item>
    </ActionMenuContainer>
  );
};

const columns = (setAction, setDataEdit) => [
  {
    title: "Date/Time",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (record) => (
      <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    ),
  },
  {
    title: "Campaign",
    key: "title",
    render: (records) =>
      records.campaignType ? (
        <span>{records.title}</span>
      ) : (
        <span>{records.packageInfo.title}</span>
      ),
  },
  {
    title: "Customer's Email",
    key: "customer_email",
    render: (records) =>
      records.campaignType ? (
        <span>{records.customer_email}</span>
      ) : (
        <span>{records.userInfo.email}</span>
      ),
  },
  {
    title: "Actions",
    key: "action",
    render: (records) =>
      records.campaignType ? (
        <span>
          {records.action === "ultilised" ? "Ultilised" : "Stamp Given"}
        </span>
      ) : (
        <span>Package Purchased</span>
      ),
  },
  {
    title: "Payment Type",
    key: "paymentType",
    render: (records) => {
      if (records.campaignType) {
        return <span>-</span>;
      } else {
        if (records.paymentType === "cash") {
          return <span>Cash Payment</span>;
        }
        if (records.paymentType === "card") {
          return <span>Card Payment</span>;
        } else {
          // wallet
          return <span>Wallet Payment</span>;
        }
      }
    },
  },
  {
    title: "Staff ID",
    key: "staffId",
    render: (records) =>
      records.repName ? <span>{records.repName}</span> : <span>-</span>,
  },
  {
    title: "Package Balance",
    key: "packageBalance",
    render: (records) =>
      records.campaignType ? (
        <span>{records.countLeft - 1}</span>
      ) : (
        <span>{records.packageInfo.count}</span>
      ),
  },
  {
    title: "Package Expiry",
    key: "stampExpiry",
    render: (records) =>
      records.campaignType ? (
        <span>-</span>
      ) : (
        <span>
          {moment(records.createdAt)
            .add(
              parseInt(records.packageInfo.campaign_validity),
              records.packageInfo.campaign_validity_unit
            )
            .format("DD/MM/YYYY - HH:mm")}
        </span>
      ),
  },
  // {
  //   title: 'Num of unAuth usage',
  //   key: 'numOfUnAuthPunch',
  //   render: (records) => <span>{records.unAuthActivities.length}</span>
  // },
  // {
  //   title: 'Status',
  //   key: 'status',
  //   render: (records) => records.ultilised? <span>Ultilised</span>: <span>-</span>
  // },
  {
    title: "Remarks",
    key: "remarks",
    dataIndex: "remarks",
    render: (record) => (record ? <span>{record}</span> : <span>-</span>),
  },
  // {
  //   title: 'Ultilised Date',
  //   // dataIndex: 'redeemDate',
  //   key: 'redeemDate',
  //   render: (record) => record.redeemActivities.length > 0?<span>{moment(record.redeemActivities[0].createdAt).format("DD/MM/YYYY - HH:mm")}</span>: <span>-</span>
  // },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   align: 'center',
  //   render: (record) => (
  //     <Dropdown overlay={menu(record, setAction, setDataEdit)} trigger={['click']}>
  //       <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
  //         <EllipsisOutlined style={{fontSize: 20}}/>
  //       </a>
  //     </Dropdown>
  //   ),
  // },
];

export default columns;
