import React, { useState, useContext, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Input, Space, DatePicker} from 'antd';
import StyledQuill from "components/Elements/StyledQuill";
import { PageContainer } from "../styleEdit";
import StyledButton from "components/Elements/StyledButton";
import StyledRadio from "components/Elements/StyledRadio";
import { connect } from 'react-redux'
import { DataContext } from "contexts/DataContextContainer";
import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';
import parse from 'html-react-parser';

import client from "feathers.js"

const { RangePicker } = DatePicker;

const durationOptions = [
  {
    value: "days",
    label: "Day",
  },
  {
    value: "weeks",
    label: "Week",
  },
  {
    value: "months",
    label: "Month",
  },
  {
    value: "years",
    label: "Year",
  }
]

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

const Edit = props => {
  const { setLoading, showToast } = useContext(DataContext);
  const [title, setTitle] = useState('')
  const [count, setCount] = useState(1)
  const [periodStart, setPeriodStart] = useState(null)
  const [periodEnd, setPeriodEnd] = useState(null)
  const [campaignValidity, setCampaignValidity] = useState(1)
  const [campaignValidityUnit, setCampaignValidityUnit] = useState('months')
  const [redeemValidity, setRedeemValidity] = useState(1)
  const [redeemValidityUnit, setRedeemValidityUnit] = useState('months')
  const [tos, setTos] = useState('')
  const [ canEdit, setCanEdit ] = useState(false)

  useEffect(() =>  {
    if(props.data._id){
      client.service('activities').find({
        query: {
          campaignType: "stamp",
          campaignId: props.data._id,
          $limit: 1
        }  
      })
      .then((res) => {
        if(res.data.length > 0){
          // cannot edit -> user is using this stamp
          showToast('error', 'Customer is using this Stamp Card! Not allow to edit!')
        }else{
          setCanEdit(true)
        }
      })
      .catch((err)=>{
        console.log(err)
        setLoading(false)
        showToast('error', err.message)
      })
    }else{
      setCanEdit(true)
    }
    /* eslint-disable-next-line */
  }, [props.data._id, props.userInfo._id])

  useEffect(() => {
    if(!isEmpty(props.data)){
      setTitle(props.data.title)
      setCount(props.data.count)
      setPeriodStart(moment(new Date(props.data.period_start),'DD/MM/YYYY'))
      setPeriodEnd(moment(new Date(props.data.period_end),'DD/MM/YYYY'))
      setCampaignValidity(props.data.campaign_validity)
      setCampaignValidityUnit(props.data.campaign_validity_unit)
      setRedeemValidity(props.data.redeem_validity)
      setRedeemValidityUnit(props.data.redeem_validity_unit)
      setTos(props.data.tos)
    }
  }, [props.data._id, props.data]);

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setPeriodStart(dates[0])
    setPeriodEnd(dates[1])
  }

  const launch = async () => {
    if(canEdit){
      if (title.trim() === '' || !periodStart || !periodEnd) {
        showToast('error', 'Please fill all necessary information')
      } else {
        setLoading(true)
        if(props.action === 'edit'){
          const campaignDetail = {
            title,
            count,
            period_start: periodStart.toDate(),
            period_end: periodEnd.toDate(),
            campaign_validity: campaignValidity,
            campaign_validity_unit: campaignValidityUnit,
            redeem_validity: redeemValidity,
            redeem_validity_unit: redeemValidityUnit,
            tos
          }
    
          client.authenticate()
          .then(() => {
           return client.service('stamps').patch(props.data._id, campaignDetail)
          })
          .then((res) => {
            let cloneData = cloneDeep(props.campaignData)
            let findKey = cloneData.findIndex(e => e._id === props.data._id)
            if(findKey !== -1){
              cloneData[findKey] = res
            }
            props.setCampaignData(cloneData)
  
            setLoading(false)
            showToast('success', 'Campaign is created successfully')
            props.back()
          })
          .catch((err)=>{
            console.log(err)
            setLoading(false)
            showToast('error', err.message)
          })
        }else{
          const campaignDetail = {
            // merchant_id: props.currentMerchant._id,
            // campaign_type: 'stamp',
            userId: props.userInfo._id,
            title,
            count,
            period_start: periodStart.toDate(),
            period_end: periodEnd.toDate(),
            campaign_validity: campaignValidity,
            campaign_validity_unit: campaignValidityUnit,
            redeem_validity: redeemValidity,
            redeem_validity_unit: redeemValidityUnit,
            tos
          }
    
          client.authenticate()
          .then(() => {
            return client.service('stamps').create(campaignDetail)
          })
          .then((res) => {
            let cloneData = cloneDeep(props.campaignData)
            props.setCampaignData(cloneData.concat(res))
  
            setLoading(false)
            showToast('success', 'Campaign is created successfully')
            props.back()
          })
          .catch((err)=>{
            console.log(err)
            setLoading(false)
            showToast('error', err.message ? err.message : err)
          })
        }
      }
    }else{
      showToast('error', 'Customer is using this Stamp Card! Not allow to edit!')
    }
  }

  return (
    <PageContainer>
      <div className='header'>
        <button className='btn-back' onClick={() => props.back()}><ArrowLeftOutlined /></button>
        <div>
          <h3>
            Stamp Card Setup
          </h3>
          <p className="text-capitalize">
            ({props.action})
          </p>
        </div>
      </div>
      <div className='page-content'>
        <Space className='detail' size={32} direction='vertical'>
          <Space className='section' size={8} direction='vertical'>
            <span className='title'>What is the reward for completing this stamp card?</span>
            <Input size="large" placeholder="Get a free cup of coffee worth $6" value={title} onChange={e => setTitle(e.target.value)}/>
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>How many stamp is required to earn reward?</span>
            <Input type='number' min={1} size="large" value={count} onChange={e => setCount(e.target.value)}/>
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>What is the stamp Card Campaign period?</span>

              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }} 
                value={[periodStart, periodEnd]}
                onChange={onChangeCampaignPeriod}
              />

          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>What is the validity of the stamp card upon first stamp collected?</span>
            <div className='stamp-duration row no-gutters'>
              <Input className='col-md-3' type='number' min={1} size="large" value={campaignValidity} onChange={e => setCampaignValidity(e.target.value)}/>
              <StyledRadio
                options={durationOptions}
                value={campaignValidityUnit}
                onChange={e => setCampaignValidityUnit(e.target.value)}
                className='col-md-6'
              />
            </div>
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>What is the validity of the reward redemption?</span>
            <div className='stamp-duration row no-gutters'>
              <Input className='col-md-3' type='number' min={1} size="large" value={redeemValidity} onChange={e => setRedeemValidity(e.target.value)}/>
              <StyledRadio
                options={durationOptions}
                value={redeemValidityUnit}
                onChange={e => setRedeemValidityUnit(e.target.value)}
                className='col-md-6'
              />
            </div>
          </Space>

          <Space className='section' size={8} direction='vertical'>
            <span className='title'>What is the Terms & Conditions?</span>
            <StyledQuill
              readOnly={false}
              modules={quillModules}
              value={tos}
              onChange={async (value) => {
                setTos(value)
              }}
            />
          </Space>
        </Space>
        <div className='preview-wrapper'>
          <div className='preview'>
            <span>Preview</span>
            <div className="details">
              <div className='title'>
                <h4>{title ? title : '--'}</h4>
              </div>
              <div className='stamps'>
                <h4>{`Expiry : ${campaignValidity} ${campaignValidityUnit} from first Stamp`}</h4>
                <div className='cards'>
                  {
                    [...Array(parseInt(count ? count : 1) + 1).keys()].map(item =>
                      <div className='card' key={item}>
                        <span className='card-no'>{item + 1}</span>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className='terms'>
                <h4>Terms & Condition</h4>
                {
                  <div>
                    {parse(tos)}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className='actions'>
              <StyledButton
                width={'45%'}
                sizeh={50}
                className="btn-blue mt-5"
                title='Back'
                outlined="true"
                onClick={() => props.back()}
              />
            <StyledButton
              width={'45%'}
              sizeh={50}
              className="btn-blue mt-5 text-capitalize"
              title={props.action}
              disabled={!canEdit}
              onClick={launch}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

const mapStateToProps = state => ({
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
