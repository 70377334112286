import React from 'react';
import { StepContainer } from "./style";
import StyledQuill from "components/Elements/StyledQuill";
import moment from 'moment';

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      // { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
  ],
};

function CampaignStep6(props) {
  return (
    <StepContainer>
      <div className='container-center'>
      <p className='text-center mb-0' style={{color: '#3B7779', fontWeight: 500}}>Question</p>
      <h2>What are the terms & conditions for this stamp card campaign?</h2>
      <br/>
      <p className='text-center mb-2' style={{color: '#3B7779', fontWeight: 500}}>Answer</p>

      <div style={{textAlign: 'left', paddingLeft: 8}}>
        <p className='mb-0' style={{color: '#3B7779'}}>(Auto included Terms & Conditions)</p>
        <p className='mb-0'>· Campaign Period {moment(props.periodStart).format('DD/MM/YYYY')} - {moment(props.periodEnd).format('DD/MM/YYYY')}</p>
        <p className='mb-0'>· This card is valid for {props.campaignValidity} {props.campaignValidityUnit.slice(0, -1)}(s) upon first collect</p>
      </div>

      <br/>

      <div className='terms'>
        <label className='mb-1' style={{fontWeight: 500}}>Additional Terms & Conditions</label>
          <StyledQuill
            readOnly={false}
            modules={quillModules}
            value={props.tos}
            onChange={async (value) => {
              // console.log(value);
              props.setTos(value)
            }}
          />

          <div className='box'>
            <h4>Example:</h4>
            <ul>
              <li>Management reserve the rights to substitue the reward of equal value</li>
              <li>Management decision is final in the event of any discrepancy</li>
              <li>Get 1 stamp with every beverage purchased</li>
            </ul>
          </div>
        </div>
      </div>
    </StepContainer>
  );
}

export default CampaignStep6;
