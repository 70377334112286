import { breakpoints, colors } from "style";
import styled from "styled-components";

export const PreviewContainer = styled.div`
  .preview-wrapper {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    .preview {
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      // flex: 1;
      align-items: center;

      span {
        color: ${colors.purple};
        font-size: 24px;
        font-weight: bold;
      }
      h4 {
        margin-top: 16px;
        color: black !important;
      }
      .details {
        width: 100%;
        background: #FFFFFF;
        box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px;
        border-radius: 10px;
        .title {
          padding: 8px 10px;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #263238;
            margin: 0;
            margin-bottom: 0;
            word-break: break-all;
            white-space: break-spaces;
          }
          .tag {
            display: inline-block;
            background: rgb(241, 241, 208);
            color: rgb(59, 48, 84);
            width: 94px;
            padding: 0;
            border-radius: 100px;
            text-align: center;
            font-weight: 600;
            font-size: 10px;
            margin-bottom: 3px;
          }
        }
        .stamps {
          padding: 0 8px;
          h4 {
            color: rgb(59, 48, 84);
            font-size: 9px;
            line-height: 1;
            font-weight: 400;
            text-align: center;
            margin-top: 0px;
            margin-bottom: 8px;
          }
          .cards {
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            .card {
              display: flex;
              justify-content: center;
              padding: 4px;
              background: ${colors.lightGrey};
              height: 36px;
              width: 32%;
              margin-bottom: 4px;
              margin-right: 3px;
              .card-no {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                background: ${colors.bgGrey};
                border-radius: 10px;
                color: white;
                font-weight: bold;
                font-size: 14px;
              }
              &:last-child {
                visibility: hidden;
                display: none;
                margin-right: 0;
              }
            }
          }
        }
        .terms {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin: 0px 0 10px 8px;
          ul {
            padding-left: 24px;
            margin-bottom: 0;
          }
          h4 {
            font-size: 12px;
            font-weight: 600;
            margin: 4px 0;
            margin-left: 8px;
          }
          ul {
            padding-left: 24px;
            font-weight: 400;
            font-size: 11px;
            line-height: 1.3;
            color: #757575;
          }
          p {
            margin-bottom: 0px !important;  
            font-weight: 400;
            font-size: 11px;
            line-height: 1.3;
            color: #757575;
          }
        }
      }
    }
  }
}`
export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  .preview-wrapper {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    .preview {
      width: 100%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      // flex: 1;
      align-items: center;

      span {
        color: ${colors.purple};
        font-size: 24px;
        font-weight: bold;
      }
      h4 {
        margin-top: 16px;
        color: black !important;
      }
      .details {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.25);
        border-radius: 10px;
        .title {
          padding: 16px;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #263238;
            margin: 0;
            margin-bottom: 0;
            word-break: break-all;
            white-space: break-spaces;
          }
          .tag {
            background-color: #F5F5F6;
            color: var(--mr-color-4);
            display: inline-block;
            border-radius: 5px;
            padding: 1px 8px;
            font-weight: 500;
            font-size: 14px;
          }
        }
        .stamps {
          padding: 0 8px;
          h4 {
            color: rgb(59, 48, 84);
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            margin-top: 0px;
            margin-bottom: 8px;
          }
          .cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .card {
              display: flex;
              justify-content: center;
              padding: 4px;
              background: ${colors.lightGrey};
              height: 36px;
              width: 32%;
              margin-bottom: 8px;
              .card-no {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                background: ${colors.bgGrey};
                border-radius: 10px;
                color: white;
                font-weight: bold;
                font-size: 14px;
              }
              &:last-child {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .tabs {
    display: flex;
    text-align: center;
    margin-bottom: 12px;
    background: rgba(255, 255, 255, 0.49);
    box-shadow: 0px 10px 13px rgba(77, 121, 120, 0.1);
    border-radius: 100px;
    padding: 4px;
    .tab {
      border: none;
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px 20px;
      position: relative;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #B3B9C7;
      &.active {
        color: #FFFFFF;
        background: #3b7779;
        border-radius: 30px;
      }
    }
  }
  .page-content {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: 100%;
    .detail {
      max-width: 500px;
      width: 100%;
      display: flex;
      flex-direction: column;
      background: white;
      // padding: 30px;
      border-radius: 16px;
      margin-right: 40px;
      .section {
        width: 100%;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: ${colors.purple};
        }
        .stamp-duration {
          // justify-content: space-between;
          align-items: center;
          input {
            padding-left: 8px;
            margin-right: 6px;
          }
        }
        .ant-picker {
          width: 100%;
          height: 40px;
        }
        .ant-radio-wrapper {
          span: nth-child(2) {
            font-weight: normal;
          }
        }
        .quill {
          background: white;
        }
        .ql-container {
          min-height: 200px;
        }
        .prefix-textbox {
          display: flex;
          align-items: baseline;
          span {
            font-size: 14px;
            width: 40%;
          }
        }
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
    }
    .picture {
      position: relative;
      img {
        background: #F9F9F9;
        border: 8px solid #FFFFFF;
        box-shadow: 0px 12px 19px rgba(220, 220, 220, 0.35);
        width: 144px;
        height: 144px;
        object-fit: cover;
        border-radius: 50%;
        // margin-top: -70px;
      }
      .add-picture {
        width: 46px;
        height: 46px;
        background: #FDB256;
        border: 5px solid #FFFFFF;
        border-radius: 50%;
        position: absolute;
        top: 60%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 9;
        svg {
          position: absolute;
          color: white;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
          right: 0;
          margin: auto;
          font-size: 24px;
        }
      }
    }
    .terms {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0px 0 10px 8px;
      ul {
        padding-left: 24px;
      }
      ul {
        font-size: 13px;
        width: 370px;
      }
    }
  }
  .point-campaign{
    display: flex;
    flex-direction: column;
    width: 530px;
  }
  .description-text{
    color:  #6B7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      color: #F64C4C;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
  }
  .description-list{
    color:  #6B7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding-left: 10px;
  }
  .answer-input{
    all: unset;
    width: 520px;
    border: unset;
    border-bottom: 1px solid #000;
    padding: 5px;
    margin-bottom: 15px;
    input::placeholder {
    color: #B6B9CE;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
    letter-spacing: 0.25px;
    }
  }
  .answer-input-description {
    color: #6B7280;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .number-preset{
    display: flex;
    flex-direction: row;
    gap: 10px;

    button {
      all: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #E7EAEE;
      width: 78px;
      padding: 10px;
      font-size: 14px;

      &:hover{
        background: #E7EAEE;
      }
    }
    .active{
      border: 1px solid #047857;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
