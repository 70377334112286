import React, { useState, useContext, useEffect } from 'react';
import { Table, Pagination } from 'antd';
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PageContainer } from "../style";
import DateRange from "components/Extra/DateRange.js"
import { connect } from 'react-redux'
// import { 
//   Spinner
// } from "react-bootstrap";
import { DataContext } from "contexts/DataContextContainer";
import columns from './columns'
import Edit from './Edit';
import moment from 'moment';
// import getSymbolFromCurrency from 'currency-symbol-map'
// import { CSVDownload } from "react-csv";

// import { jsPDF } from "jspdf";
// import Handlebars from "handlebars";
// import raw from './invoice_renew.txt';

// import axios from 'axios';
import client from "feathers.js"

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const CustomerManagement = props => {
  // const { title } = props

  const history = useHistory();
  const { setLoading, showToast } = useContext(DataContext);
  const [ action, setAction ] = useState('all' ) //edit, detail, create
  const [campaignData, setCampaignData] = useState([])
  const [ dataEdit, setDataEdit ] = useState({})
  const [ pagination, setPagination ] = useState({})
  /* eslint-disable-next-line */
  const [ spin, setSpin ] = useState(false)
  const [ startDate, setStartDate ] = useState(false)
  const [ endDate, setEndDate ] = useState(false)
  /* eslint-disable-next-line */
  const [ csvData, setCsvData ] = useState([])

  useEffect(() => {
    getCampaignData(0, 1, props.userInfo._id)
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  const back = () => {
    setAction('all')
  }

  const searchDate = (startDate, endDate) => {
    if(startDate && endDate){
      getCampaignData(0, 1, props.userInfo._id, startDate, endDate)
    }
  }

  const getCampaignData = (skip, currentPage, userId, startDate, endDate) => {
    setLoading(true)

    let query = {
      $skip: skip,
      $limit: PAGESIZE,
      userId,
      $sort: {
        createdAt: -1
      }
    }

    if(startDate && endDate){
      query = {
        $and: [
          { 
            createdAt: {
              $gte: new Date(moment(new Date(startDate)).format('YYYY-MM-DD hh:mm')).getTime()
            },
          },
          { 
            createdAt: {
              $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
            },
          }
        ],
        $skip: skip,
        $limit: PAGESIZE,
        userId,
        $sort: {
          createdAt: -1
        }
      }
    }

    client.authenticate()
      .then((auth) => {
        return client.service('earnings').find({
          query,
        })
      })
    .then((res) => {
      setCampaignData(res.data)
      setPagination(paginationCount(res.total, currentPage))
      setLoading(false)
    })
    .catch((err)=>{
      console.log(err)
      setLoading(false)
      if(err.name === "NotAuthenticated"){
            
      }else{
        showToast('error', err.message)
      }
    })
  }


  // const downloadCSV = async() => {
  //   if(props.userInfo.subscription === 'copper'){
  //     showToast('error', 'Only Bronze and above allow to download CSV!')
  //     return false
  //   }

  //   let tmpData = []
  //   setLoading(false)

  //   const stackData = (index) => {
  //     var query = {
  //       $skip: index*pagination.per_page,
  //       userId: props.userInfo._id,
  //       $sort: {
  //         createdAt: -1
  //       }
  //     }
  
  //     if(startDate && endDate){
  //       query = {
  //         $and: [
  //           { 
  //             createdAt: {
  //               $gte: new Date(startDate).getTime()
  //             },
  //           },
  //           { 
  //             createdAt: {
  //               $lte: (new Date(new Date(endDate).setHours(23,59,59,999))).getTime()
  //             },
  //           }
  //         ],
  //         $skip: index*pagination.per_page,
  //         userId: props.userInfo._id,
  //         $sort: {
  //           createdAt: -1
  //         }
  //       }
  //     }

  //     return new Promise(resolve => {
  //       client.authenticate()
  //       .then(()=>{
  //         return client.service('transactions').find({
  //           query: query
  //         })
  //       })
  //       .then((res) => {
  //         let d = []
           
  //         for(let i = 0; i < res.data.length; i++){
  //           d.push({
  //             Type: res.data[i].creditDebit === 'credit'? "Topup": res.data[i].remark === 'upgrade'?"Upgrade": "Renew",
  //             Payment: res.data[i].creditDebit === 'credit'? '+' + getSymbolFromCurrency(res.data[i].currency?res.data[i].currency:'usd') + " " + res.data[i].amount: '-' + getSymbolFromCurrency(res.data[i].currency?res.data[i].currency:'usd') + " " + res.data[i].amount,
  //             Desc: res.data[i].remark === 'upgrade'? res.data[i].fromSubscription + " -> " + res.data[i].toSubscription + "(" + moment(res.data[i].substart).format("DD/MM/YYYY - HH:mm") + " / " + moment(res.data[i].subEnd).format("DD/MM/YYYY - HH:mm")+")": '-',
  //             Status: res.data[i].status,
  //             "Created Date": moment(res.data[i].createdAt).format("DD/MM/YYYY - HH:mm"),
  //           })
  //         }
          
  //         tmpData = tmpData.concat(d)
  //         resolve(res.data)
  //       })
  //       .catch((err)=>{
  //         if(err.name === "NotAuthenticated"){
  //           showToast('error', "Please Sign-in to continue!")
  //         }else{
  //           showToast('error', err.message)
  //         }
  //       })
  //     })
  //   }
    
  //   let numOfLoop = []
  //   for(let i = 0; i < pagination.last_page; i++){
  //     numOfLoop.push(i)
  //   }

  //   let process = await numOfLoop.map(async(value, index) => {
  //     let data = await stackData(index);
  //     return data
  //   })

  //   let result = await Promise.all(process)

  //   if(result){
  //     setCsvData(tmpData)
  //     setSpin(true)
  //     setTimeout(() => {
  //       setSpin(false)
  //     }, 2000);
  //   }
  // }

  return (
    <PageContainer>
    {
      action === 'all' ?
      <>
        <div className='header'>
          <button className='btn-back' onClick={() => history.goBack()}><ArrowLeftOutlined /></button>
          <div className='breadcrumbs'>
            <div className='child'>Earnings</div>
          </div>
        </div>
        <div className='info-header'>
          <div>
            <h4>
              Search by Date
            </h4>
            <DateRange
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate} 
              searchDate={searchDate}
            />
          </div>
{/* 
          <div className='text-center'>
            <button 
              type="button" 
              className="btn-mrz-primary"
              disabled={props.userInfo.subscription === 'copper'? true: spin}
              onClick={downloadCSV}
            >
              <div className="d-flex align-items-center">
              {
              spin? (
                <Spinner className="mr-2" style={{height: 15, width: 15}}>
                  Loading...
                </Spinner>
                ): null
              }
                Download CSV
              </div>
            </button>
            <div>
              <p className="mb-0">
                {
                  (startDate && endDate)? 
                  moment(startDate).format("DD/MM/YYYY - HH:mm") + ' - ' + moment(endDate).format("DD/MM/YYYY - HH:mm") :
                  "All Records"
                }
              </p>
            </div>
            {
              spin? (
                <>
                  <CSVDownload data={csvData} target="_blank" />
                </>
              ): null
            }
          </div> */}

          {/* <div className='title'>
            <h3>{title}</h3>
          </div>
          <div className='plan-info'>
            <div className='plan maximum'>
              <span>maximum</span>
              <span>3</span>
            </div>
            <div className='plan active mx-4'>
              <span>Active Campaign</span>
              <span>3</span>
            </div>
            <div className='plan balance'>
              <span>Balance</span>
              <span>3</span>
            </div>
          </div>
          <button className='btn-plan' onClick={() => props.history.push('/admin/upgrade')}>Upgrade Now</button> */}
        </div>
        {/* <div className='status-header'>
          <div className='status'>
            <span>Live Stamp</span>
            <span>5 / {campaignData.length}</span>
          </div>
          <div className='status'>
            <span>Activated Stamp</span>
            <span>5 / 10</span>
          </div>
          <div className='status'>
            <span>Completed Stamp</span>
            <span>5 / 10</span>
          </div>
        </div> */}
        <div className='page-container'>
          {
            <>
              <Table rowKey="_id" columns={columns(setAction, setDataEdit)} dataSource={campaignData} pagination={false} />
              <div className='d-flex justify-content-end w-100 mt-3'>
                <Pagination pageSize={PAGESIZE} total={pagination.total} showSizeChanger={false} onChange={(e) => {
                  setPagination(paginationCount(pagination.total, e))
                  getCampaignData((e-1)*PAGESIZE, e, props.userInfo._id, startDate, endDate)
                }} />
              </div>
              {/* <button className='btn-add' onClick={() => {
                setAction('create')
                setDataEdit({})
                }}>
                <span>+</span>
              </button> */}
            </>
          }
        </div>
      </>
      :
      <Edit 
        userInfo={props.userInfo}
        campaignData={campaignData}
        setCampaignData={setCampaignData}
        action={action} 
        data={dataEdit} 
        back={back}
      />
    }
    </PageContainer>
  );
}

const mapStateToProps = state => ({
  auth: state.role.auth,
  userId: state.role.details.user?state.role.details.user._id:'',
  username: state.role.details.user?state.role.details.user.username:'',
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);
