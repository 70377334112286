import React from "react";
import ReactDOM from "react-dom";
import 'antd/dist/antd.css';
import "cropperjs/dist/cropper.css";
import 'react-image-crop/dist/ReactCrop.css'
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import axios from 'axios'
import { Provider } from 'react-redux'
import { store, persistor } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react';
import 'svg2pdf.js'

// axios.defaults.baseURL = process.env.REACT_APP_URL
// axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common['Accept'] = 'application/json'

ReactDOM.render(
    <Provider store={ store } >
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
  document.getElementById('root')
);

reportWebVitals();
