import { Route, Switch } from "react-router-dom";
import { superadminConfig, adminConfig } from "./config";
import { connect } from "react-redux";

import AdminDevelopment from "containers/merchant/Admin/Development";

// Merchant
import Dashboard from "containers/merchant/Admin/Dashboard";
import CustomerManagement from "containers/merchant/Admin/Customers";
import Invoices from "containers/merchant/Admin/Invoices";
import Transactions from "containers/merchant/Admin/Transactions";
import Account from "containers/merchant/Admin/Account";
import Affiliate from "containers/merchant/Admin/Affiliate";
import SEO from "containers/merchant/Admin/SEO";
import MerchantRep from "containers/merchant/Admin/MerchantRep";
import QRCode from "containers/merchant/Admin/QRCode";
import MerchantDetails from "containers/merchant/Admin/MerchantDetails";
import BusinessProfile from "containers/merchant/Admin/BusinessProfile";
import MerchantNotifications from "containers/merchant/Admin/MerchantNotifications";
import MerchantBrand from "containers/merchant/Admin/MerchantBrand";
import Tools from "containers/merchant/Admin/Tools";
import Outlet from "containers/merchant/Admin/Outlet";
import Business from "containers/merchant/Admin/Tools/Business";
import UpgradePlan from "containers/merchant/Admin/UpgradePlan";
import Topup from "containers/merchant/Admin/Topup";
import ScanLogs from "containers/merchant/Admin/ScanLogs";
import Earnings from "containers/merchant/Admin/Earnings";

import All from "containers/merchant/Admin/Tools/All";
import Stamp from "containers/merchant/Admin/Tools/Stamp";
import Package from "containers/merchant/Admin/Tools/Package";
import Wallet from "containers/merchant/Admin/Tools/Wallet";
import Voucher from "containers/merchant/Admin/Tools/Voucher";
import Referral from "containers/merchant/Admin/Tools/Referral";
import Points from "containers/merchant/Admin/Tools/Points";
import PointsSubmissions from "containers/merchant/Admin/Tools/PointsManagement";
import ModeOfPayment from "containers/merchant/Admin/Tools/ModeOfPayment";
import GASetup from "containers/merchant/Admin/Tools/GASetup";
import CampaignExpiry from "containers/merchant/Admin/Tools/CampaignExpiry";

import BroadcastHistory from "containers/merchant/Admin/Marketing/BroadcastHistory";
import SendBroadcast from "containers/merchant/Admin/Marketing/SendBroadcast";

// Super Admin
import BusinessRequest from "containers/merchant/Superadmin/BusinessRequest";
import AffiliateSubmissions from "containers/merchant/Superadmin/AffiliateSubmissions";
import ViewMerchants from "containers/merchant/Superadmin/ViewMerchants";
import ViewRemovedMerchants from "containers/merchant/Superadmin/ViewRemovedMerchants";
import HalfwayMerchants from "containers/merchant/Superadmin/HalfwayMerchants";
import EarningsManagement from "containers/merchant/Superadmin/EarningsManagement";

const AllComponents = {
  BusinessRequest,
  AffiliateSubmissions,
  ViewMerchants,
  ViewRemovedMerchants,
  HalfwayMerchants,
  EarningsManagement,

  PointsSubmissions,
  UpgradePlan,
  AdminDevelopment,
  MerchantRep,
  QRCode,

  Dashboard,
  Account,
  Affiliate,
  SEO,
  MerchantDetails,
  BusinessProfile,
  MerchantNotifications,
  MerchantBrand,
  Invoices,
  Transactions,
  Outlet,
  All,
  Stamp,
  Package,
  Wallet,
  Voucher,
  Referral,
  Points,
  ModeOfPayment,
  GASetup,
  CampaignExpiry,
  Business,
  Tools,
  Topup,
  SendBroadcast,
  ScanLogs,
  Earnings,

  BroadcastHistory,

  CustomerManagement,
};

const AdminRoutes = (props) => {
  return (
    <Switch>
      {props.role === "superadmin"
        ? Object.keys(superadminConfig).map((key) =>
            superadminConfig[key].map((r) => {
              const route = (r) => {
                const Component = AllComponents[r.component];
                return (
                  <Route
                    key={r.route || r.key}
                    exact
                    path={r.route || r.key}
                    render={(props) => (
                      <Component {...props} title={r.title} query={r.query} />
                    )}
                  />
                );
              };
              return r.component ? route(r) : r.subs.map((r) => route(r));
            })
          )
        : Object.keys(adminConfig).map((key) =>
            adminConfig[key].map((r) => {
              const route = (r) => {
                const Component = AllComponents[r.component];
                return (
                  <Route
                    key={r.route || r.key}
                    exact
                    path={r.route || r.key}
                    render={(props) => (
                      <Component {...props} title={r.title} query={r.query} />
                    )}
                  />
                );
              };
              return r.component ? route(r) : r.subs.map((r) => route(r));
            })
          )}
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  role: state.role.details.user ? state.role.details.user.role : "",
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoutes);
