import { breakpoints } from "style";
import styled from "styled-components";
import { MerchantSetup1 } from "assets/images";

export const CampaignSetupContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  .box {
    margin-top: 10px;
    background: rgb(242, 242, 242);
    border-radius: .25rem;
    padding: 10px 16px 10px 16px;
    margin-bottom: 1rem;
  }
  .steps-group{
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 300px;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    justify-content: space-between;
  }
  .stamp-campaign{
    display: flex;
    flex-direction: column;
    width: 530px;
  }
  .description-text{
    color:  #6B7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .description-list{
    color:  #6B7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding-left: 10px;
  }

  .create-campaign {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: #3B7779;
    border-radius: 24px;
    border: solid 1px #3B7779;
    padding: 8px 10px;
    width: fit-content;
    p {
      font-weight:  500;
      font-size: 11px;
      margin: 0;
    }
  }
  img {
    max-width: 100%;
    object-fit: cover;
  }
  .answer-input{
    all: unset;
    width: 520px;
    border: unset;
    border-bottom: 1px solid #000;
    padding: 5px;
    margin-bottom: 15px;
    input::placeholder {
    color: #B6B9CE;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
    letter-spacing: 0.25px;
    }
  }
  .answer-input-description {
    color: #6B7280;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .feedback-input{
    all: unset;
    width: 520px;
    border: unset;
    border: 1px solid #CED2DA;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  .ql-toolbar{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .ql-container{
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .date-picker{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: column;
      position: relative;
      p {
        all:unset;
        margin: 5px;
        color: #344051;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
      img {
        position: absolute;
        bottom: 15%;
        right: 5%;
      }
    }
  }
  .answer-date-picker{
    all: unset;
    width: 230px;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #E4E6F1;
    background:  #FFF;
    color: #637083;
    height: 25px;
  }
  .check-avilability-again {
        margin-top: 40px;
        display: flex;
        width: 45%;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid  #567877;
        background: #FFF;
        color: #567877;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        &:focus {
          box-shadow: unset;
        }
      }
  .confirm-btn {
    margin-top: 40px;
    display: flex;
    width: 50%;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    background: #567877;
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &:focus {
      box-shadow: unset;
    }
    a{
      width: 100%;
      height: 100%;
      color: #FFFFFF;
      background: transparent;
      text-decoration: none;
    }
  }
  .number-preset{
    display: flex;
    flex-direction: row;
    gap: 10px;

    button {
      all: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid #E7EAEE;
      width: 78px;
      padding: 10px;
      font-size: 14px;

      &:hover{
        background: #E7EAEE;
      }
    }
    .active{
      border: 1px solid #047857;
    }
  }
  .left-image {
    height: 100%;
    background-image: url(${MerchantSetup1});
    background-size: cover;
    background-position: center;
  }
  .home-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    padding: 0px 20px;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    h2 {
      margin-bottom: 1rem;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #3B3054;    
    }
    .step-label {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #3b7779;
    }
    .step-content {
      display: flex;
      align-items: center;
      justify-content: center;
      // min-height: 400px;
      // margin-top: 60px;
    }
    .ant-steps {
      justify-content: center;
      display: flex;
      flex-direction: row;
      .ant-steps-item {
        padding: 0;
        flex: unset;
        .ant-steps-item-container {
          .ant-steps-item-tail, .ant-steps-item-tail {
            display: none;
          }
          .ant-steps-item-icon {
            width: 21px;
            height: 3px;
            background: #D9D9D9;
            border-radius: 18px;
            border: none;
            .ant-steps-icon {
              display: none;
            }
          }
        }
      }
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background: #3b7779 !important;
      }
    }
    .actions {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
