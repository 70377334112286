import { useState } from 'react';
import { TopupContainer } from "./preview-style";
import classnames from "classnames";
import getSymbolFromCurrency from 'currency-symbol-map'
import { InputGroup } from "react-bootstrap";
import { isEmpty } from 'lodash';

const Topup = props => {
  var color1 = "#3b7779"
  var color2 = "#FDB256"

  const [ active, setActive ] = useState(0)
//   const [ walletSetting, setWalletSetting ] = useState({
//     customAmount: true,
//     modePayment: ["overCounter", "creditCard"],
//     topupAmounts: [{amount: 10}, {amount: 30}, {amount: 50}, {amount: 100}] 
//   })

  const walletSetting = props.walletSetting
  const modePayment = props.modePayment

  return (
    <div style={{
      width: 388
    }}>
    <TopupContainer color1={color1} color2={color2}>
      <div className='topup-container' id="topup-container">
        <div className='title-nav'>
          <span className='btn-back'>
            <i className="fas fa-angle-left"></i> 
            {" "}
            <span>Back</span>
          </span>
          <div className='title'>
            <span>Top up</span>
          </div>
        </div>

        <div className='topup-page-body'>
          <div className='balance-top-up-wrap'>
            <div className='balance-wrap'>
              <p>Current Balance</p>
              <span className='balance'>{getSymbolFromCurrency(props.merchant.currency) + props.balance.toFixed(2)}</span>
            </div>
          </div>
          <div className='topup-amount'>
            {
              walletSetting.topupAmounts.filter(e => e.amount === 0).length >= walletSetting.topupAmounts.length? (
                null
              ): 
              <span>Select Top Up amount</span>
            }
              <div className='buttons'>
                {
                  walletSetting.topupAmounts.filter(e => e.amount !== 0).map((x, i) => {
                    return (
                      <button 
                        key={i}
                        className={classnames({
                          "active": active === x.amount
                        })}
                        onClick={() => setActive(x.amount)}
                        type="button"
                      >
                        {getSymbolFromCurrency(props.merchant.currency)}{x.amount}
                      </button>
                    )
                  })
                }
            </div>
          </div>
          <div className='enter-amount'>
            <div className='w-100 first'>
              {
                walletSetting.customAmount === 'true'?
                <p>Enter amount to topup</p>
                :null
              }
              <p className='small-text'>
                {
                  !isEmpty(walletSetting.customAmountDesc)? (
                    <>
                    {
                      walletSetting.customAmountDesc.minAmount? (
                        <>
                          Min {getSymbolFromCurrency(props.merchant.currency) + "" + walletSetting.customAmountDesc.minAmount}
                          {
                            walletSetting.customAmountDesc.multipleAmount? ", ": null
                          }
                        </>
                      ): null
                    }
                    {
                      walletSetting.customAmountDesc.multipleAmount? (
                        <>
                          In multiple {getSymbolFromCurrency(props.merchant.currency) + "" + (walletSetting.customAmountDesc.multipleAmount)}
                        </>
                      ): null
                    }
                    </>
                  ): null
                }
              </p>
            </div>
            <div className="amount-wrap row no-gutters"> 
              <div className='col-md-12'>
                <InputGroup className='justify-content-center'>
                  <InputGroup.Text style={{
                    background: 'transparent',
                    border: 'none',
                    fontWeight: 'bold',
                    fontSize: 28,
                    marginBottom: -24
                  }}>{getSymbolFromCurrency(props.merchant.currency)}</InputGroup.Text>
                </InputGroup>
              </div>
              <div className='col-md-12'>
                <input 
                  className='amount' 
                  type="number" 
                  step=".01" 
                  value={active} 
                  onWheel={ event => event.currentTarget.blur() }
                  disabled={!walletSetting.customAmount}
                />
              </div>
              {/* <div className='col-md-6 text-right'>
                <p>{getSymbolFromCurrency(props.merchant.currency)}</p>
              </div>
              <div className='col-md-6'>
                
              </div> */}
            </div>
          </div>
          <div className='pay-with-wallet'>
            {
              modePayment.cash &&
                <button type="button">Top up over the Counter</button>
            }

            {
              modePayment.credit &&
              (modePayment.stripePublishableKey && modePayment.stripeSecretKey)?
                <button type="button">Top up with Credit Card</button>
              : null
            }
            <span onClick={() => props.history.push('/' + props.merchant.username + '/transactions')}>View Previous Transactions</span>
          </div>
        </div>
      </div>
    </TopupContainer>
    </div>
  );
}

export default Topup;
