import { breakpoints, colors } from "style";
import styled from "styled-components";
import { Home3 } from "assets/images";

export const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .continue-setup {
    width: 100%;
    max-width: 390px;
    margin: auto;
    padding: 25px 12px;
    text-align: center;
    img {
      display: flex;
      justify-content: center;
      margin: auto;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    h4 {
      width: 100%;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #3b3054;
    }
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
    }
    .box {
      background: rgb(242, 242, 242);
      padding: 10px;
      margin-bottom: 1rem;
    }
    .button-wrapper {
      text-align: center;
      button {
        margin: auto;
        background: #fdb256;
        width: 217px;
        height: 56px;
        border-radius: 0.25rem;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
  img {
    max-width: 100%;
    object-fit: cover;
  }
  .left-image {
    height: 100%;
    background-image: url(${Home3});
    background-size: cover;
    background-position: center;
  }
  .home-container {
    width: 100%;
    margin-top: 10%;
    margin-bottom: 20%;
    padding: 25px 12px;
    text-align: center;
  }
  .steps-group {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 300px;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    justify-content: space-between;
    .steps {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 283px;
      height: 62px;
      padding: 10px;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 10px;
      z-index: 2;
      p {
        margin: 0;
        color: #6b7280;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
      }
    }
    .steps-active {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 283px;
      height: 62px;
      padding: 10px;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: #e6f1f1;
      z-index: 2;
      p {
        margin: 0;
        color: #191d23;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 128.571% */
      }
    }
    .steps-done {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 283px;
      height: 62px;
      padding: 10px;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 10px;
      z-index: 2;
      p {
        margin: 0;
        color: #191d23;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 128.571% */
      }
    }
    .steps-number {
      display: flex;
      width: 43px;
      height: 42px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 35px;
      border: 1px solid #eaecee;
      background: #fff;
      z-index: 2;
    }
    .steps-number-done {
      display: flex;
      width: 43px;
      height: 42px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 35px;
      border: 1px solid #eaecee;
      background: #fff;
      z-index: 2;
    }
    .steps-number-active {
      display: flex;
      width: 43px;
      height: 42px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 35px;
      border: 1px solid #567877;
      background: #fff;
      z-index: 2;
    }
    .steps-link {
      display: flex;
      position: absolute;
      width: 80%;
      height: 80%;
      border-left: 1px solid #eaecee;
      margin: 20px 0 0 30px;
      z-index: 1;
    }
  }
  .stamp-campaign {
    display: flex;
    flex-direction: column;
    width: 530px;
  }
  .link-group-title {
    text-align: left;
    color: #567877;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
  .link-group-desc {
    text-align: left;
    color: #637083;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  .link-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    .link-title {
      padding: 10px 8px 10px 12px;
      align-items: center;
      gap: 8px;
      border-radius: 8px 0px 0px 8px;
      border: 1px solid #ced2da;
      background: #f2f4f7;
    }
    .link-name {
      padding: 10px 8px 10px 12px;
      align-items: center;
      text-align: left;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 0px 8px 8px 0px;
      border: 1px solid #ced2da;
      background: #f9fafb;
    }
  }
  .company-details {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    .form-item-title {
      text-align: left;
      color: #344051;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .form-item-desc {
      color: #637083;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    .form-control {
      height: 48px;
      padding: 8px 8px 8px 12px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      text-align: left;
      &:focus {
        outline: 0px !important;
        border-color: #ced2da;
        box-shadow: none !important;
      }

      .is-invalid {
        background-image: none;
      }
    }
    .view-password {
      position: absolute;
      top: 40px;
      right: 20px;
      background-color: #fff;
    }
  }
  .signup-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .description-text {
    color: #6b7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .description-list {
    color: #6b7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding-left: 10px;
  }
  .form-password {
    border-radius: 8px;
    position: relative;
  }
  .form-group {
    margin-bottom: 0.5rem;
    position: relative;
  }
  .signup-form {
    .business-checked {
      display: inline-block;
      background-color: #007bff;
      height: 24px;
      width: 24px;
      border: none;
      border-radius: 50%;
      color: white;
      text-align: center;

      // margin-left: 6px;
      // top: 50%;
      // transform: translate(0, -50%);
      position: absolute;
      right: 0;
      top: -6px;
      svg {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
    max-width: 450px;
    padding: 0 20px;
    /* display: flex;
    flex-direction: column; */
    h2 {
      margin-top: 80px;
      color: #191d23;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 60% */
    }
    h4 {
      color: ${colors.grey};
    }
    .business-name {
      width: 100%;
      .domain {
        display: flex;
        // justify-content: space-between;
        // flex: 1;
        h6 {
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          color: #263238;
        }
        .bdomain {
          margin-right: 8px;
          margin-top: 18px;
        }
        .bname {
          // margin-right: 40px;
          // max-width: 500px;
        }
        .bname-err {
          border-color: ${colors.red};
        }
      }
      .check-avilability {
        margin-top: 40px;
        display: flex;
        width: 100%;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        background: #567877;
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        &:focus {
          box-shadow: unset;
        }
      }

      .check-avilability-again {
        margin-top: 40px;
        display: flex;
        width: 45%;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid #567877;
        background: #fff;
        color: #567877;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        &:focus {
          box-shadow: unset;
        }
      }
      .confirm-btn {
        margin-top: 40px;
        display: flex;
        width: 50%;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        background: #567877;
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        &:focus {
          box-shadow: unset;
        }
        a {
          width: 100%;
          height: 100%;
          color: #ffffff;
          background: transparent;
          text-decoration: none;
        }
      }
      .warning-message {
        display: flex;
        width: 100%;
        padding: 12px;
        align-items: center;
        gap: 12px;
        border-radius: 10px;
        border: 1px solid #ffd480;
        background: #fff9eb;
        margin-top: 25px;
        p {
          text-align: left;
          color: #344051;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          margin: 0;
        }
      }
      .success-message {
        color: #27ae60;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
      .error-message {
        color: rgb(204, 75, 65);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
      .box {
        background: rgb(242, 242, 242);
        border-radius: 0.25rem;
        padding: 10px 16px;
        margin-bottom: 1rem;

        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
      }
      .form-control {
        width: 171px;
        height: 48px;
        opacity: 0.97;
        width: 100%;
        &:focus,
        &:active {
          outline: 0px !important;
          -webkit-appearance: none;
          box-shadow: none !important;
        }
      }
      .input-group {
        height: 48px;
        opacity: 0.97;
        border: 1px solid #ced4da;
        border-radius: 8px;
        .input-group-text {
          width: 15%;
          background: transparent;
        }
        .form-control {
          width: 85%;
          background: transparent;
        }
      }
      // input:disabled {
      //   color: black;
      // }
      .form-item-title {
        margin-top: 40px;
        color: #344051;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 100% */
        text-align: left;
      }
      .form-item-desc {
        color: #567877;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        text-align: left;
      }
      .input-group {
        // border: 1px solid ${colors.textGrey};
        // border-radius: .25rem;
        // padding-right: 2px;
        .input-group-text,
        .form-control {
          border: none;
          background-color: transparent;
        }

        .facebook {
          color: #3d88ce;
          font-size: 24px;
        }
        .twitter {
          color: #6dcef1;
          font-size: 24px;
        }
        .instagram {
          color: #f04c28;
          font-size: 24px;
        }
      }
      .country button {
        font-weight: normal;
      }
      .input-group-feedback {
        display: block;
      }
      .section-social {
        margin-top: 16px;
      }
      .business-social {
        margin-top: 40px;
        .btn {
          width: 100%;
          height: 40px;
          color: ${colors.white};
          font-size: 14px;
          margin-bottom: 18px;
          border-radius: 6px;
        }
        .btn-facebook {
          background: #3785cc;
        }
        .btn-twitter {
          background: #68cdf1;
        }
        .btn-google {
          background: #f04621;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;

export const VerificationContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  img {
    max-width: 100%;
    object-fit: cover;
  }
  .verification-form {
    padding: 30px 30px;
    max-width: 750px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      display: flex;
      justify-content: center;
      margin: auto;
      margin-bottom: 1rem;
    }
    h2 {
      margin-top: 75px;
      color: #567877;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 80% */
      letter-spacing: -0.3px;

      @media (max-width: 1440px) {
        margin-top: 50px;
      }
    }
    h4 {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      margin-bottom: 20px;
    }
    h6 {
      width: 618px;
      color: #6b7280;
      text-align: center;
      font-size: 18px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.18px;
      margin-top: 50px;

      @media (max-width: 1440px) {
        margin-top: 25px;
      }

      .stamp {
        color: #6b7280;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.18px;
      }
      .minutes {
        color: #191d23;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.18px;
      }
    }
    .continue-button {
      margin-top: 100px;

      @media (max-width: 1440px) {
        margin-top: 40px;
      }
    }

    span {
      margin-top: 40px;
      align-self: center;
      color: ${colors.red};
      a {
        color: ${colors.red};
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }

  .alert-message {
    display: flex;
    width: 632px;
    padding: 12px;
    align-items: flex-start;
    gap: 12px;
    border-radius: 10px;
    border: 1px solid #f98686;
    background: #fee7e7;
    margin-top: 100px;

    @media (max-width: 1440px) {
      margin-top: 20px;
    }

    p {
      text-align: left;
      color: #eb5757;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      margin: 0;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
