import React, { useState, useContext, useEffect } from "react";
import { Input, DatePicker } from "antd";
import StyledQuill from "components/Elements/StyledQuill";
import { PreviewContainer } from "../../styleEdit";
import { PageContainer } from "../../style";
import StyledButton from "components/Elements/StyledButton";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";
import moment from "moment";
import { cloneDeep, isEmpty } from "lodash";
import parse from "html-react-parser";
import ModalLanuch from "components/Extra/ModalLanuch";
import Datepicker from "../../../../../components/Datepicker";

import client from "feathers.js";
import { DropdownArrow, Success } from "assets/svg";
import QuestionCard from "components/QuestionCard";
import StepsCounter from "components/StepsCounter";

const { RangePicker } = DatePicker;

// const durationOptions = [
//   {
//     value: "days",
//     label: "Day(s)",
//   },
//   {
//     value: "weeks",
//     label: "Week(s)",
//   },
//   {
//     value: "months",
//     label: "Month(s)",
//   },
//   {
//     value: "years",
//     label: "Year(s)",
//   }
// ]

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      // { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
  ],
};

const Edit = (props) => {
  const { setLoading, showToast } = useContext(DataContext);
  const [title, setTitle] = useState("");
  const [count, setCount] = useState(1);
  const [cost, setCost] = useState(1);
  const [periodStart, setPeriodStart] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);
  const [valid_start, setValidStart] = useState(null);
  const [valid_end, setValidEnd] = useState(null);
  const [redeemValidity, setRedeemValidity] = useState(1);
  const [redeemValidityUnit, setRedeemValidityUnit] = useState("months");
  const [useNow, setUseNow] = useState(true);
  const [use_validity, setUse_validity] = useState(1);
  const [use_validity_unit, setUse_validity_unit] = useState("days");
  const [canEdit, setCanEdit] = useState(false);
  const [tos, setTos] = useState("");
  const [title_count, setTitle_count] = useState(0);
  const [dates, setDates] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [atrCriteria, setAtrCriteria] = useState(null);
  const [currentCriteria, setCurrentCriteria] = useState("");
  const [isAtr, setIsAtr] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [showSuccessCreate, setShowSuccessCreate] = useState(false);

  const description = [
    "Give your voucher a name",
    "Set voucher collection availabillity period",
    "Set voucher validity",
    "Set voucher atr criteria",
    "Add extra Terms & Conditions",
  ];

  const atrConverter = (value) => {
    switch (value) {
      case 1:
        return "Birthday and Gender";
      case 2:
        return "Phone Number";
      case 3:
        return "Leaving Review";
      case 4:
        return "Topup Wallet";
      default:
        return "-";
    }
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const changeFunc = (value) => {
    if (value === "0") {
      setCurrentCriteria("None");
      setIsAtr(false);
      setAtrCriteria(null);
    }
    if (value === "1") {
      setCurrentCriteria("Birthday and Gender");
      setIsAtr(true);
      setAtrCriteria(value);
    }
    if (value === "2") {
      setCurrentCriteria("Phone Number");
      setIsAtr(true);
      setAtrCriteria(value);
    }
    if (value === "3") {
      setCurrentCriteria("Leaving Review");
      setIsAtr(true);
      setAtrCriteria(value);
    }
    if (value === "4") {
      if (props.userInfo.business_verified) {
        setCurrentCriteria("Top-up Wallet");
        setIsAtr(true);
        setAtrCriteria(value);
      } else {
        showToast("error", "Please setup your wallet before proceeding.");
      }
    }
  };

  const tncForAtr = (value) => {
    switch (value) {
      case 1:
        return "You need to fill in your Birthday and Gender in your profile.";
      case 2:
        return "You need to fill in your Phone Number in your profile.";
      case 3:
        return "You need to leave a review.";
      case 4:
        return "You will need to perform a top up to your wallet";
      default:
        return "";
    }
  };

  const handleTitle = (value) => {
    if (value.length <= 50) {
      setTitle(value);
      setTitle_count(value.length);
    }
  };

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setPeriodStart(dates[0]);
    setPeriodEnd(dates[1]);
    onOpenChange();
  };

  const onChangeValidPeriod = (dates, dateStrings) => {
    setValidStart(dates[0]);
    setValidEnd(dates[1]);
  };

  const modalCallback = () => {
    if (canEdit) {
      if (title.trim() === "" || !periodStart || !periodEnd) {
        showToast("error", "Please fill all necessary information");
      } else {
        setLoading(true);
        if (props.action === "edit") {
          const campaignDetail = {
            _id: props.data._id,
            userId: props.userInfo._id,
            title,
            count,
            period_start: periodStart.toDate(),
            period_end: periodEnd.toDate(),
            valid_start: valid_start.toDate(),
            valid_end: valid_end.toDate(),
            redeem_validity: redeemValidity,
            redeem_validity_unit: redeemValidityUnit,
            cost,
            atrType: atrCriteria,
            isAtr: isAtr,
            useNow,
            use_validity,
            use_validity_unit,
            tos,
          };

          client
            .authenticate()
            .then(() => {
              return client.service("voucher-pouches").find({
                query: {
                  voucherId: props.data._id,
                  merchantId: props.userInfo._id,
                  $limit: 1,
                },
              });
            })
            .then((res) => {
              if (res.data.length > 0) {
                setLoading(false);
                setCanEdit(false);
                showToast(
                  "error",
                  "Customer already pouch this Voucher! Not allow to edit!"
                );
              } else {
                return client
                  .service("vouchers")
                  .patch(props.data._id, campaignDetail)
                  .then((res) => {
                    let cloneData = cloneDeep(props.campaignData);
                    let findKey = cloneData.findIndex(
                      (e) => e._id === props.data._id
                    );
                    if (findKey !== -1) {
                      res.totalCollected = cloneData[findKey].totalCollected;
                      res.totalRedeemed = cloneData[findKey].totalRedeemed;
                      cloneData[findKey] = res;
                    }

                    setLoading(false);
                    props.fetchCampaignInfo();
                    props.setCampaignData(cloneData);

                    setLoading(false);
                    showToast("success", "Campaign is created successfully");
                    props.back();
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              showToast("error", err.message);
            });
        } else {
          const campaignDetail = {
            // merchant_id: props.currentMerchant._id,
            // campaign_type: 'package',
            userId: props.userInfo._id,
            title,
            count,
            period_start: periodStart.toDate(),
            period_end: periodEnd.toDate(),
            valid_start: valid_start.toDate(),
            valid_end: valid_end.toDate(),
            redeem_validity: redeemValidity,
            redeem_validity_unit: redeemValidityUnit,
            cost,
            atrType: atrCriteria,
            isAtr: isAtr,
            useNow,
            use_validity,
            use_validity_unit,
            tos,
          };

          client
            .authenticate()
            .then(() => {
              return client.service("vouchers").create(campaignDetail);
            })
            .then((res) => {
              let cloneData = cloneDeep(props.campaignData);
              res.totalCollected = 0;
              res.totalRedeemed = 0;

              props.fetchCampaignInfo();
              props.setCampaignData(cloneData.concat(res));

              setLoading(false);
              showToast("success", "Campaign is created successfully");
              props.back();
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              showToast("error", err.message);
            });
        }
      }
    } else {
      showToast(
        "error",
        "Customer already pouch this Voucher! Not allow to edit!"
      );
    }
  };

  const onOpenChange = (open) => {
    // if (open) {
    setDates([null, null]);
    setValidStart(null);
    setValidEnd(null);
    // } else {
    //   setDates(null);
    // }
  };

  const onBackQuestion = () => {
    if (currentStep === 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onNextQuestion = () => {
    if (currentStep === 1 && title.length > 0) {
      setCurrentStep(2);
    } else if (currentStep === 2 && periodStart && periodEnd) {
      setCurrentStep(3);
    } else if (currentStep === 3 && valid_start && valid_end) {
      setCurrentStep(4);
    } else if (currentStep === 4 && atrCriteria !== undefined) {
      setCurrentStep(5);
    } else if (currentStep === 5) {
      setShowSuccessCreate(true);
    }
  };

  const renderQuestionCard = (q, d, c) => {
    return (
      <QuestionCard
        question={q}
        descriptions={<div className='description-text'>{d}</div>}
        totalQuestion={5}
        currentActive={currentStep}
        onClickBackButton={onBackQuestion}
        onClickNextButton={onNextQuestion}
        noPaginator
        questionHeight='auto'
        answerHeight='100px'
        children={c}
      />
    );
  };

  const renderFirstTimeEditContent = () => {
    return (
      <div className='first-time-edit-content'>
        <div className='first-time-step-container'>
          <div className='first-time-step-wrapper'>
            <StepsCounter
              currentStepNumber={currentStep}
              stepDescriptions={description}
              left='48%'
            />
          </div>
        </div>

        <div className='first-time-step-details-container'>
          {currentStep === 1 &&
            renderQuestionCard(
              "What will be the reward for this voucher?",
              "Provide an interesting promotion title which will capture customer's attention.",
              <div className='input-container'>
                <Input
                  size='large'
                  placeholder='Get a free cup of coffee worth $6'
                  className='first-time-input'
                  value={title}
                  onChange={(e) => handleTitle(e.target.value)}
                />
                <p className='answer-input-description'>
                  Characters ({title.trim().length}/50)
                </p>
              </div>
            )}
          {currentStep === 2 &&
            renderQuestionCard(
              "How long will this voucher be available for collection?",
              "Set the start and end date for this voucher collection period.",
              <>
                <Datepicker
                  periodStart={periodStart}
                  periodEnd={periodEnd}
                  onChange={onChangeCampaignPeriod}
                  noLabel
                />
              </>
            )}
          {currentStep === 3 &&
            renderQuestionCard(
              "How long will this voucher be valid for use?",
              "Specify the start and end date for this voucher validity.",
              <>
                <Datepicker
                  periodStart={valid_start}
                  periodEnd={valid_end}
                  onChange={onChangeValidPeriod}
                  noLabel
                  isDisable={periodStart && periodEnd ? false : true}
                />
              </>
            )}
          {currentStep === 4 &&
            renderQuestionCard(
              "What is the atr criteria for this voucher?",
              "Provide the atr criteria if there is any to specify the type of voucher.",
              <div className='input-container'>
                <div className='input-with-icon'>
                  <select
                    name='criteria'
                    id='atrCriteria'
                    className='input'
                    value={atrCriteria}
                    onChange={(e) => changeFunc(e.target.value)}
                    style={{ appearance: "none", position: "relative" }}
                  >
                    <option value='0'>None</option>
                    <option value='1'>Birthday and Gender</option>
                    <option value='2'>Phone Number</option>
                    <option value='3'>Leaving Review</option>
                    {props.userInfo.subscription !== "copper" && (
                      <option value='4'>Top-up Wallet</option>
                    )}
                  </select>
                  <img
                    src={DropdownArrow}
                    alt=''
                    width='24px'
                    height='24px'
                    className='dropdown-icon'
                    draggable={false}
                  />
                </div>
              </div>
            )}
          {currentStep === 5 &&
            renderQuestionCard(
              <>
                <div>Final step!</div>
                <div>Any other terms & conditions?</div>
              </>,
              <ul className='description-list'>
                <li>
                  Campaign Period{" "}
                  {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""}{" "}
                  - {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
                </li>
                <li>
                  This voucher is valid from{" "}
                  {valid_start ? moment(valid_start).format("DD/MM/YYYY") : ""}{" "}
                  - {valid_end ? moment(valid_end).format("DD/MM/YYYY") : ""}
                </li>
                <li>This voucher is applicable for one (1) time usage only</li>
                {atrCriteria !== undefined && atrCriteria !== null && (
                  <li>{tncForAtr(Number(atrCriteria))}</li>
                )}
              </ul>,
              <StyledQuill
                readOnly={false}
                modules={quillModules}
                value={tos}
                onChange={async (value) => {
                  setTos(value);
                }}
              />
            )}
        </div>
      </div>
    );
  };

  const successCreate = () => {
    return (
      <div className='first-time-edit-content'>
        <div className='success-container'>
          <img src={Success} alt='' width='50px' height='50px' />
          Almost Done!
          <StyledButton
            title='Preview'
            onClick={toggleModal}
            type='submit'
            className='primary-button'
            bgcolor={"#567877"}
          />
        </div>
      </div>
    );
  };

  const renderEditContent = () => {
    return (
      <>
        <div className='edit-content'>
          <div className='input-container'>
            Voucher Reward
            <Input
              size='large'
              placeholder='Get a free cup of coffee worth $6'
              className='input'
              value={title}
              onChange={(e) => handleTitle(e.target.value)}
            />
          </div>

          <div className='input-container'>
            Voucher Collection Availability
            <Datepicker
              periodStart={periodStart}
              periodEnd={periodEnd}
              onChange={onChangeCampaignPeriod}
              noLabel
            />
          </div>

          <div className='input-container'>
            Voucher Vadility
            <Datepicker
              periodStart={valid_start}
              periodEnd={valid_end}
              onChange={onChangeValidPeriod}
              noLabel
              isDisable={periodStart && periodEnd ? false : true}
            />
          </div>

          <div className='input-container'>
            ATR Criteria
            <div className='input-with-icon'>
              <select
                name='criteria'
                id='atrCriteria'
                className='input'
                value={atrCriteria}
                onChange={(e) => changeFunc(e.target.value)}
                style={{ appearance: "none", position: "relative" }}
              >
                <option value='0'>None</option>
                <option value='1'>Birthday and Gender</option>
                <option value='2'>Phone Number</option>
                <option value='3'>Leaving Review</option>
                {props.userInfo.subscription !== "copper" && (
                  <option value='4'>Top-up Wallet</option>
                )}
              </select>
              <img
                src={DropdownArrow}
                alt=''
                width='24px'
                height='24px'
                className='dropdown-icon'
                draggable={false}
              />
            </div>
          </div>

          <div className='input-container'>
            Additional Terms & Conditions
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <li>
                Campaign Period{" "}
                {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""} -{" "}
                {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
              </li>
              <li>
                This voucher is valid from{" "}
                {valid_start ? moment(valid_start).format("DD/MM/YYYY") : ""} -{" "}
                {valid_end ? moment(valid_end).format("DD/MM/YYYY") : ""}
              </li>
              <li>This voucher is applicable for one (1) time usage only</li>
              {atrCriteria !== undefined && atrCriteria !== null && (
                <li>{tncForAtr(Number(atrCriteria))}</li>
              )}
            </ul>
            <StyledQuill
              readOnly={false}
              modules={quillModules}
              value={tos}
              onChange={async (value) => {
                setTos(value);
              }}
            />
          </div>

          <div className='button-wrapper-container'>
            <button
              type='button'
              onClick={() => props.back()}
              className='cancel-button'
            >
              Back
            </button>
            <StyledButton
              title='Preview'
              onClick={toggleModal}
              type='submit'
              className='primary-button'
              bgcolor={"#567877"}
            />
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (props.data._id) {
      client
        .authenticate()
        .then(() => {
          return client.service("voucher-pouches").find({
            query: {
              voucherId: props.data._id,
              merchantId: props.userInfo._id,
              $limit: 1,
            },
          });
        })
        .then((res) => {
          console.log("the data: ", res);
          if (res.data.length > 0) {
            showToast(
              "error",
              "Customer already pouch this Voucher! Not allow to edit!"
            );
          } else {
            setCanEdit(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    } else {
      setCanEdit(true);
    }
    /* eslint-disable-next-line */
  }, [props.data._id, props.userInfo._id]);

  useEffect(() => {
    if (!isEmpty(props.data)) {
      setTitle(props.data.title);
      setTitle_count(props.data.title.length);
      setCount(props.data.count);
      setPeriodStart(moment(new Date(props.data.period_start), "DD/MM/YYYY"));
      setPeriodEnd(moment(new Date(props.data.period_end), "DD/MM/YYYY"));
      setValidStart(moment(new Date(props.data.valid_start), "DD/MM/YYYY"));
      setValidEnd(moment(new Date(props.data.valid_end), "DD/MM/YYYY"));
      setRedeemValidity(props.data.redeem_validity);
      setRedeemValidityUnit(props.data.redeem_validity_unit);
      setCost(props.data.cost);
      setUseNow(props.data.useNow);
      setUse_validity(props.data.use_validity);
      setUse_validity_unit(props.data.use_validity_unit);
      setTos(props.data.tos);
      setCurrentCriteria(atrConverter(props.data.atrType));
      setAtrCriteria(props.data.atrType);
      setIsAtr(props.data.isAtr);
    }
  }, [props.data._id, props.data]);

  console.log("is it first time: ", props.firstTime);

  return (
    <PageContainer>
      {props.firstTime
        ? showSuccessCreate
          ? successCreate()
          : renderFirstTimeEditContent()
        : renderEditContent()}

      <ModalLanuch
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        campaign='voucher'
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Campaign Preview
            </span>
          </>
        }
        body={
          <PreviewContainer>
            <div className='preview-wrapper'>
              <div className='preview'>
                <div className='details'>
                  <div className='title'>
                    <h4>{title ? title : "--"}</h4>
                  </div>
                  <div className='terms'>
                    <h4>Terms & Condition</h4>
                    <ul style={{ marginBottom: 0 }}>
                      <li>
                        Campaign Period{" "}
                        {periodStart
                          ? moment(periodStart).format("DD/MM/YYYY")
                          : ""}{" "}
                        -{" "}
                        {periodEnd
                          ? moment(periodEnd).format("DD/MM/YYYY")
                          : ""}
                      </li>
                      <li>
                        This voucher is valid from{" "}
                        {valid_start
                          ? moment(valid_start).format("DD/MM/YYYY")
                          : ""}{" "}
                        -{" "}
                        {valid_end
                          ? moment(valid_end).format("DD/MM/YYYY")
                          : ""}
                      </li>
                      <li>
                        This voucher is applicable for one (1) time usage only
                      </li>
                      {atrCriteria !== undefined && atrCriteria !== null && (
                        <li>{tncForAtr(Number(atrCriteria))}</li>
                      )}
                    </ul>
                    {<div>{parse(tos)}</div>}
                  </div>
                </div>
              </div>
            </div>
          </PreviewContainer>
        }
        canEdit={canEdit}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
