import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import { CSVDownload } from "react-csv";
import client from "feathers.js";
import axios from "axios";
import { cloneDeep, isEmpty, set } from "lodash";
import moment from "moment";
import { Table, Pagination } from "antd";
import classNames from "classnames";

import env from "env";
import columns from "./columns";
import Edit from "./Edit";
import { PageContainer } from "../../style";
import MerchantPoints from "../../MerchantPoints/";
import { updateDetails } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import ModalConfirmation from "components/Extra/ModalConfirmation";
import ModalPreview from "components/Extra/ModalPreview";
import InfoCard from "components/InfoCard/InfoCard";
import DataCard from "components/DataCard";
import { Checked, PointsIcon } from "assets/svg";
import { AccessDenied } from "assets/images";

const PAGESIZE = process.env.REACT_APP_PAGESIZE;

function paginationCount(length, currentPage) {
  return {
    total: length,
    per_page: PAGESIZE,
    current_page: currentPage,
    last_page: Math.ceil(length / PAGESIZE),
    from: (currentPage - 1) * PAGESIZE + 1,
    to: currentPage * PAGESIZE,
  };
}

const Points = (props) => {
  /* eslint-disable-next-line */
  const {
    setLoading,
    showToast,
    setLocation,
    setBreadcrumb,
    setConfirmModalIsOpen,
    toolsAction,
    setToolsAction,
  } = useContext(DataContext);
  const [action, setAction] = useState("all"); //edit, detail, create
  const [campaignData, setCampaignData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [campaignInfo, setCampaignInfo] = useState({});
  const [confirmAction, setConfirmAction] = useState("activate"); // activate, delete
  const [modalTotalVisible, setModalTotalVisible] = useState(false);
  const [previewAction, setPreviewAction] = useState("collect");
  const [spin, setSpin] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({});
  const [selected, setSelected] = useState(1);

  const fetchCampaignInfo = () => {
    client
      .authenticate()
      .then((auth) => {
        return axios.post(
          `${client.io.io.uri}getPointsInformation`,
          {
            userId: props.userInfo._id,
          },
          {
            headers: {
              Authorization: auth.accessToken,
            },
          }
        );
      })
      .then((res) => {
        setCampaignInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
        showToast("error", err.message);
      });
  };

  const back = () => {
    setAction("all");
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const toggleTotalModal = () => {
    setModalTotalVisible(!modalTotalVisible);
  };

  const modalCallback = () => {
    setLoading(true);

    if (confirmAction === "activate") {
      let status = dataEdit.active ? false : true;

      client
        .authenticate()
        .then(() => {
          return client
            .service("points")
            .patch(dataEdit._id, { active: status });
        })
        .then((res) => {
          let cloneData = cloneDeep(campaignData);
          let findKey = cloneData.findIndex((e) => e._id === dataEdit._id);
          if (findKey !== -1) {
            res.totalCollected = cloneData[findKey].totalCollected;
            res.totalRedeemed = cloneData[findKey].totalRedeemed;
            cloneData[findKey] = res;
          }

          fetchCampaignInfo();
          setCampaignData(cloneData);

          setLoading(false);
          showToast("success", "Campaign is Edited successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    } else {
      client
        .authenticate()
        .then(() => {
          return client.service("points").remove(dataEdit._id);
        })
        .then((res) => {
          let cloneData = cloneDeep(campaignData);
          let findKey = cloneData.findIndex((e) => e._id === dataEdit._id);
          if (findKey !== -1) {
            cloneData.splice(findKey, 1);
          }
          setTotal(total - 1);
          // getCampaignData(props.userInfo._id)

          fetchCampaignInfo();
          setCampaignData(cloneData);

          setLoading(false);
          showToast("success", "Campaign is Deleted successfully");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    }
  };

  const getCampaignData = (skip, currentPage, userId) => {
    setLoading(true);
    client
      .service("points")
      .find({
        query: {
          checkPointInfo: true,
          $skip: skip,
          $limit: PAGESIZE,
          userId,
        },
      })
      .then((res) => {
        setCampaignData(res.data);
        setTotal(res.total);
        setPagination(paginationCount(res.total, currentPage));
        setLoading(false);
        setToolsAction("all");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.name === "NotAuthenticated") {
        } else {
          showToast("error", err.message);
        }
      });
  };

  const createCampaign = () => {
    if (env.subscription[props.userInfo.subscription || "copper"].points) {
      setAction("create");
      setDataEdit({});
    } else {
      showToast("error", `You're not allow to create Points voucher!`);
    }
  };

  const downloadCSV = async () => {
    setSpin(true);

    if (previewAction === "collect") {
      let tmpData = [];
      let arr = dataEdit.totalCollectedUsers;

      for (let i = 0; i < arr.length; i++) {
        tmpData.push({
          Campaign: dataEdit.title,
          Email: arr[i].email,
          "First Name": arr[i].firstName,
          "Last Name": arr[i].lastName,
          "Phone Prefix": arr[i].phonePrefix,
          "Contact Phone": arr[i].contact_phone,
          Gender: arr[i].gender,
          Birthday: arr[i].birthday
            ? moment(arr[i].birthday).format("DD/MM/YYYY - HH:mm")
            : "",
          "Address 1": arr[i].address1,
          "Address 2": arr[i].address2,
          City: arr[i].city,
          Zip: arr[i].zip,
          Country: arr[i].country,
          "Claimed Date": moment(arr[i].createdAt).format("DD/MM/YYYY - HH:mm"),
          "Card Expiry": moment(arr[i].cardExpiry).format("DD/MM/YYYY - HH:mm"),
        });
      }

      setCsvData(tmpData);
      setTimeout(() => {
        setSpin(false);
      }, 2000);
    } else {
      let tmpData = [];
      let arr = dataEdit.totalRedeemedUsers;

      for (let i = 0; i < arr.length; i++) {
        tmpData.push({
          Campaign: dataEdit.title,
          Email: arr[i].email,
          "First Name": arr[i].firstName,
          "Last Name": arr[i].lastName,
          "Phone Prefix": arr[i].phonePrefix,
          "Contact Phone": arr[i].contact_phone,
          Gender: arr[i].gender,
          Birthday: arr[i].birthday
            ? moment(arr[i].birthday).format("DD/MM/YYYY - HH:mm")
            : "",
          "Address 1": arr[i].address1,
          "Address 2": arr[i].address2,
          City: arr[i].city,
          Zip: arr[i].zip,
          Country: arr[i].country,
          "Claimed Date": moment(arr[i].createdAt).format("DD/MM/YYYY - HH:mm"),
          "Card Expiry": moment(arr[i].cardExpiry).format("DD/MM/YYYY - HH:mm"),
          "Redeemed Date": arr[i].redeemDate
            ? moment(arr[i].redeemDate).format("DD/MM/YYYY - HH:mm")
            : moment(arr[i].updatedAt).format("DD/MM/YYYY - HH:mm"),
        });
      }

      setCsvData(tmpData);
      setTimeout(() => {
        setSpin(false);
      }, 2000);
    }
  };

  const onUpdatePoint = () => {
    let activePoint = 0;
    if (props.userInfo.activePoint === 0) {
      activePoint = 1;
      if (!campaignInfo.numActive) {
        showToast(
          "error",
          "Please set up one or more rewards in the catalogue to activate the points campaign"
        );
        return false;
      }
      if (!props.userInfo.pointPerDollar || !props.userInfo.dollarPoint) {
        showToast(
          "error",
          "Please set up your points in order to activate Point Campaign"
        );
        return false;
      }
    } else if (props.userInfo.activePoint === 1) {
      activePoint = 2;
    } else if (props.userInfo.activePoint === 2) {
      activePoint = 1;
      if (!campaignInfo.numActive) {
        showToast(
          "error",
          "Please set up one or more rewards in the catalogue to activate the points campaign"
        );
        return false;
      }
      if (!props.userInfo.pointPerDollar || !props.userInfo.dollarPoint) {
        showToast(
          "error",
          "Please set up your points in order to activate Point Campaign"
        );
        return false;
      }
    } else {
      // else active
      activePoint = 1;
      if (!campaignInfo.numActive) {
        showToast(
          "error",
          "Please set up one or more rewards in the catalogue to activate the points campaign"
        );
        return false;
      }
      if (!props.userInfo.pointPerDollar || !props.userInfo.dollarPoint) {
        showToast(
          "error",
          "Please set up your points in order to activate Point Campaign"
        );
        return false;
      }
    }

    setLoading(true);

    client
      .service("merchants")
      .patch(props.userInfo._id, {
        activePoint,
      })
      .then((res) => {
        props.updateDetails(res);
        showToast("success", "Status Updated Successfully!");

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const renderSteps = (order, validator, title, onClick, disabled) => {
    const isSelected = Number(order) === selected;
    return (
      <button
        className={classNames("step", validator)}
        style={{
          background: isSelected ? "#E6F1F1" : "transparent",
        }}
        onClick={onClick}
        disabled={disabled}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            justifyContent: "center",
            background: validator.checked ? "" : "white",
            fontWeight: "250",
            border: "1px solid #567877",
          }}
        >
          {validator.checked ? (
            <img src={Checked} alt='' width='13px' height='11px' />
          ) : (
            Number(order)
          )}
        </div>
        <div
          style={{
            textAlign: "left",
            color: isSelected ? "#567877" : "#6B7280",
            fontSize: "14px",
            fontWeight: "light",
          }}
        >
          {title}
        </div>
      </button>
    );
  };

  const renderStepsDetails = (icon, title, desc, renderOnClickHtml) => {
    return (
      <div className='step-details-container'>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            width: "100%",
          }}
        >
          <img src={icon} alt='' width='40px' height='40px' />
          <div className='step-details-header'>{title}</div>
          <div className='step-details-text'>{desc}</div>
        </div>
        <div className='button-wrapper'>{renderOnClickHtml}</div>
      </div>
    );
  };

  const renderContent = () => {
    if (props.userInfo.subscription !== "copper" && action === "all") {
      return (
        <>
          <div className='row-header' style={{ minHeight: "123px" }}>
            <div className='status-wrapper'>
              {props.userInfo.activePoint === 2 ||
                (props.userInfo.activePoint === 1 && (
                  <>
                    <DataCard
                      title='Redeemed Rewards'
                      data={campaignInfo.numExchanged}
                    />
                    <DataCard
                      title='Utilised Rewards'
                      data={campaignInfo.numRedeemed}
                    />
                  </>
                ))}
            </div>

            <button
              className='primary-button'
              onClick={onUpdatePoint}
              type='submit'
              disabled={
                total <= 0 ||
                (!props.userInfo.pointPerDollar && !props.userInfo.dollarPoint)
              }
              style={{
                maxWidth: "250px",
                background: `${
                  !props.userInfo.activePoint ||
                  props.userInfo.activePoint === 0 ||
                  props.userInfo.activePoint === 2
                    ? "#3b7779"
                    : "#F98686"
                }`,
              }}
            >
              {!props.userInfo.activePoint ||
              props.userInfo.activePoint === 0 ||
              props.userInfo.activePoint === 2
                ? "Activate"
                : "Deactivate Points Campaign"}
            </button>
          </div>

          <div className='row-header'>
            <div className='status-wrapper'>
              <MerchantPoints />
            </div>

            <button
              type='button'
              className='primary-button'
              onClick={() => {
                createCampaign();
              }}
            >
              <span>+ Points</span>
            </button>
          </div>

          <div className='row-container'>
            <Table
              rowKey='_id'
              columns={columns(
                setAction,
                setDataEdit,
                toggleModal,
                setConfirmAction,
                setPreviewAction,
                toggleTotalModal
              )}
              dataSource={campaignData}
              pagination={false}
            />
          </div>

          <div className='row-container'>
            <Pagination
              pageSize={PAGESIZE}
              total={pagination.total}
              showSizeChanger={false}
              onChange={(e) => {
                setPagination(paginationCount(pagination.total, e));
                getCampaignData((e - 1) * PAGESIZE, e, props.userInfo._id);
              }}
            />
          </div>

          {/* <div className='box-primary'>
            <h4 className='mb-4'>
              To activate points campaign, complete the following tasks:
            </h4>

            <div className='mrz-steps'>
              <div
                className={classNames("step", {
                  checked:
                    props.userInfo.pointPerDollar || props.userInfo.dollarPoint,
                })}
              >
                <div>
                  <span>
                    <i className='fa fa-check' />
                  </span>
                </div>
                <div>
                  <h4>Setup Points Conversion Rate</h4>
                </div>
              </div>
              <div
                className={classNames("step", {
                  checked: total > 0,
                })}
              >
                <div>
                  <span>
                    <i className='fa fa-check' />
                  </span>
                </div>
                <div>
                  <h4>Add 1 item for redemption in Redemption Catalogue</h4>
                </div>
              </div>
            </div>
          </div> */}

          {/* {
          props.userInfo.activePoint === 2 || props.userInfo.activePoint === 1 ?
          <PointsSubmissions />
          : null
        } */}
        </>
      );
    }
    return (
      <Edit
        userInfo={props.userInfo}
        fetchCampaignInfo={fetchCampaignInfo}
        campaignData={campaignData}
        setCampaignData={setCampaignData}
        action={action}
        data={dataEdit}
        total={total}
        setTotal={setTotal}
        back={back}
      />
    );
  };

  const renderFirstTime = () => {
    if (action === "create") {
      return (
        <Edit
          userInfo={props.userInfo}
          fetchCampaignInfo={fetchCampaignInfo}
          campaignData={campaignData}
          setCampaignData={setCampaignData}
          action={action}
          data={dataEdit}
          total={total}
          setTotal={setTotal}
          back={back}
          firstTime={campaignData.length <= 0}
        />
      );
    }
    return (
      <>
        <InfoCard
          desc={
            "To activate the points feature, it is mandatory to complete the two tasks below."
          }
        />
        <div className='start-guide-container'>
          <div className='mrz-steps step-container'>
            <div className='step-wrapper'>
              {renderSteps(
                "1",
                {
                  checked:
                    props.userInfo.pointPerDollar || props.userInfo.dollarPoint,
                },
                "Set up points conversion",
                () => setSelected(1)
              )}
              {renderSteps(
                "2",
                { checked: campaignData.length > 0 },
                "Add 1 item",
                () => setSelected(2),
                { disabled: campaignData.length <= 0 }
              )}
            </div>
          </div>
          {selected === 1 &&
            renderStepsDetails(
              PointsIcon,
              "Set up points conversion rate",
              <>
                <MerchantPoints />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    marginTop: "35px",
                  }}
                >
                  <button
                    type='button'
                    className='button'
                    onClick={() => setConfirmModalIsOpen(true)}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "white",
                        width: "160px",
                      }}
                    >
                      Set
                    </div>
                  </button>
                  {props.userInfo.pointPerDollar &&
                  props.userInfo.dollarPoint ? (
                    <button
                      type='button'
                      className='button-next'
                      onClick={() => setSelected(2)}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#567877",
                          width: "160px",
                        }}
                      >
                        Next step
                      </div>
                    </button>
                  ) : null}
                </div>
              </>
            )}
          {selected === 2 &&
            renderStepsDetails(
              PointsIcon,
              "Add 1 redemption item",
              <>
                <button
                  type='button'
                  className='button'
                  onClick={() => setAction("create")}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "white",
                      width: "160px",
                    }}
                  >
                    Add
                  </div>
                </button>
              </>
            )}
        </div>
      </>
    );
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb(["Campaign Tools", props.title]);
  }, []);

  useEffect(() => {
    if (toolsAction === "create") {
      if (campaignData && campaignData.length <= 0) {
        setAction("all");
      } else {
        setAction("create");
      }
    } else {
      setAction("all");
    }
  }, [campaignData]);

  useEffect(() => {
    getCampaignData(0, 1, props.userInfo._id);
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  useEffect(() => {
    fetchCampaignInfo();
    /* eslint-disable-next-line */
  }, [props.userInfo._id]);

  return (
    <PageContainer>
      {
        props.userInfo.subscription === "copper" && action === "all" ? (
          <div className='access-denied'>
            <div>
              <h1>OOPS!</h1>
              <p>You don’t have access to this feature.</p>
              <p>
                Take your experience to the next level by upgrading your plan!
              </p>
              <img src={AccessDenied} alt='' width={700} />
              <button
                className='btn-upgrade'
                onClick={() => props.history.push("/admin/upgrade")}
              >
                Upgrade Now!
              </button>
              {/* <UpgradePlan /> */}
            </div>
          </div>
        ) : campaignData.length <= 0 ? (
          renderFirstTime() // render steps for first time user
        ) : (
          renderContent()
        ) // render point dashboard
      }

      <ModalConfirmation
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              {"Confirmation"}
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>
              {confirmAction === "activate"
                ? dataEdit.active
                  ? "Proceed to deactivate this reward"
                  : "Proceed to activate this reward"
                : "Proceed to delete this Campaign"}
            </span>
          </>
        }
      />

      <ModalPreview
        modalVisible={modalTotalVisible}
        toggleModal={toggleTotalModal}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Total {previewAction === "collect" ? "Collected" : "Redeemed"}
            </span>
            <button
              type='button'
              className='btn-mrz-primary'
              style={{ position: "absolute", right: 10 }}
              disabled={spin}
              onClick={downloadCSV}
            >
              <div className='d-flex align-items-center'>
                {spin ? (
                  <Spinner className='mr-2' style={{ height: 15, width: 15 }}>
                    Loading...
                  </Spinner>
                ) : null}
                Export CSV
              </div>
            </button>
            {spin ? (
              <>
                <CSVDownload data={csvData} target='_blank' />
              </>
            ) : null}
          </>
        }
        body={
          <>
            {!isEmpty(dataEdit) ? (
              <span className='title2'>
                <Table
                  rowKey='_id'
                  columns={
                    previewAction === "collect"
                      ? [
                          {
                            title: (
                              <span>
                                Customer <br />
                                Email
                              </span>
                            ),
                            // dataIndex: 'email',
                            key: "email",
                            render: (record) => {
                              return (
                                <a
                                  href={`/admin/transactions?email=${record.email}&title=${dataEdit.title}&campaign=${dataEdit._id}&tab=3`}
                                >
                                  {record.email}
                                </a>
                              );
                            },
                          },
                          {
                            title: "Phone",
                            key: "phone",
                            render: (record) => (
                              <span>
                                {(record.phonePrefix || "-") +
                                  " " +
                                  (record.contact_phone || "")}
                              </span>
                            ),
                          },
                          {
                            title: "First Name",
                            dataIndex: "firstName",
                            key: "firstName",
                          },
                          {
                            title: "Last Name",
                            dataIndex: "lastName",
                            key: "lastName",
                          },
                          {
                            title: (
                              <span>
                                Claimed <br />
                                Date
                              </span>
                            ),
                            dataIndex: "createdAt",
                            key: "createdAt",
                            render: (record) => (
                              <span>
                                {moment(record).format("DD/MM/YYYY - HH:mm")}
                              </span>
                            ),
                          },
                          {
                            title: (
                              <span>
                                Card <br />
                                Expiry
                              </span>
                            ),
                            dataIndex: "cardExpiry",
                            key: "cardExpiry",
                            render: (record) => (
                              <span>
                                {moment(record).format("DD/MM/YYYY - HH:mm")}
                              </span>
                            ),
                          },
                        ]
                      : [
                          {
                            title: (
                              <span>
                                Customer <br />
                                Email
                              </span>
                            ),
                            // dataIndex: 'email',
                            key: "email",
                            render: (record) => {
                              return (
                                <a
                                  href={`/admin/transactions?email=${record.email}&title=${dataEdit.title}&campaign=${dataEdit._id}&tab=3`}
                                >
                                  {record.email}
                                </a>
                              );
                            },
                          },
                          {
                            title: "Phone",
                            key: "phone",
                            render: (record) => (
                              <span>
                                {(record.phonePrefix || "-") +
                                  " " +
                                  (record.contact_phone || "")}
                              </span>
                            ),
                          },
                          {
                            title: "First Name",
                            dataIndex: "firstName",
                            key: "firstName",
                          },
                          {
                            title: "Last Name",
                            dataIndex: "lastName",
                            key: "lastName",
                          },
                          {
                            title: (
                              <span>
                                Claimed <br />
                                Date
                              </span>
                            ),
                            dataIndex: "createdAt",
                            key: "createdAt",
                            render: (record) => (
                              <span>
                                {moment(record).format("DD/MM/YYYY - HH:mm")}
                              </span>
                            ),
                          },
                          {
                            title: (
                              <span>
                                Card <br />
                                Expiry
                              </span>
                            ),
                            dataIndex: "cardExpiry",
                            key: "cardExpiry",
                            render: (record) => (
                              <span>
                                {moment(record).format("DD/MM/YYYY - HH:mm")}
                              </span>
                            ),
                          },
                          {
                            title: "Redeemed Date",
                            // dataIndex: 'createdAt',
                            key: "redeemDate",
                            render: (records) => (
                              <span>
                                {records.redeemDate
                                  ? moment(records.redeemDate).format(
                                      "DD/MM/YYYY - HH:mm"
                                    )
                                  : moment(records.updatedAt).format(
                                      "DD/MM/YYYY - HH:mm"
                                    )}
                              </span>
                            ),
                          },
                        ]
                  }
                  dataSource={
                    previewAction === "collect"
                      ? dataEdit.totalCollectedUsers
                      : dataEdit.totalRedeemedUsers
                  }
                />
              </span>
            ) : null}
          </>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Points);
