import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { PageContainer } from "./style";
import { connect } from "react-redux";
import { updateDetails } from "redux/actions/roleActions";
import { getBalance } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import moment from "moment";
import { PlanChecked, PlanUnchecked } from "assets/svg";
import { StripeCreateCheckoutSession } from "../../../../api/stripe/StripeCreateCheckoutSession"
import { GetSubscription } from "../../../../api/stripe/GetSubscription"
import { ManageBilling } from "../../../../api/stripe/ManageBilling"
import { getBranches } from "../../../../api/getBranches"

const UpgradePlan = (props) => {
  const history = useHistory();
  const {
    setLoading,
    showToast,
    controlFailModal,
    setLocation,
    setBreadcrumb,
  } = useContext(DataContext);
  const [renewOption, setRenewOption] = useState("quarter");
  const [ subscription, setSubscription ] = useState({});
  const [ updateDisable, setUpdateDisable ] = useState(true);
  const [ planExpired, setPlanExpired ] = useState(false);
  const [ branch, setBranch ] = useState({});

  const check = <img src={PlanChecked} alt='' width='24px' height='24px' />;
  const uncheck = <img src={PlanUnchecked} alt='' width='14px' height='14px' />;
  
  const getBranch = async () => {
    const result = await getBranches({ $limit: 1 });
    if (result.data) {
        setBranch(result.data[0]);
    }
  };

  useEffect(() => {
    getBranch()
  }, [])

  const merchantSubscription = async() => {
    let result = await GetSubscription({
      accessToken: props.accessToken,
      subscriptionId: props.userInfo.subscriptionId,
    })
    if(result?.data?.data){
      setSubscription(result?.data?.data)
      let isExpired = moment.unix(result?.data?.data?.current_period_end).toDate() <= moment().toDate()
      if(isExpired){
        setPlanExpired(true)
      }else{
        setPlanExpired(false)
      }

      setUpdateDisable(false)
    }else{
      setUpdateDisable(false)
      // showToast('error', result.error.message);
    }
  }

  useEffect(() => {
    if(props.userInfo.subscriptionId){
      merchantSubscription()
    }else{
      setUpdateDisable(false)
    }
  }, [props.accessToken, props.userInfo._id, props.userInfo.subscriptionId])

  const handleUpgradePlan = async(priceId) => {
    
    if(!priceId){
      return showToast('error', `Sorry, You are not allow to upgrade to copper, Please contact administrator!`);
    }

    if(subscription?.plan?.active && subscription?.plan?.id === priceId){
      return showToast('error', `Sorry, You cannot proceed with the payment for your selected plan at this time. Your current plan is still active.`);
    }
    // if(!planExpired){
    //   return showToast('error', `You are not allowed to update your subscription plan at this time.<br/>Please come back at ${moment(subscription?.subEnd).subtract(7, 'days').format("DD/MM/YYYY")}`);
    // }
    
    setLoading(true)
    let result = await StripeCreateCheckoutSession({
      accessToken: props.accessToken,
      priceId,
      userId: props.userInfo._id,
    })
    if(result?.data?.url){
      window.open(result?.data?.url, '_blank');
      setLoading(false)
    }else{
      showToast('error', 'An error occurred. Please contact administrator.');
    }
  }

  const planFunctionHandler = (plan, priceId) => {
    // const dynamicUpgrade = planLogic[plan + "Upgrade"];
    // const dynamicExpired = planLogic[plan + "Expired"];

    // const currentPlan = props.userInfo.subscription;

    // const canRenew = currentPlan === plan && dynamicExpired;

    let isDisabled = (subscription?.plan?.active && subscription?.plan?.id === priceId) || updateDisable

    // if (currentPlan === plan && !dynamicExpired) {
    if (priceId === subscription?.plan?.id) {
      if(!planExpired){
        return (
          <button type='button' disabled className='plan-button-disabled'>
            CURRENT
          </button>
        );
      }else{
        return (
          <button
          type='button'
          disabled={isDisabled}
          className={
            isDisabled ? "plan-button-disabled" : "plan-button"
          }
          onClick={() => handleUpgradePlan(priceId)}
        >
          Renew
        </button>
        );
      }
    } else {
      return (
        <button
          type='button'
          disabled={isDisabled}
          className={
            isDisabled ? "plan-button-disabled" : "plan-button"
          }
          onClick={() => handleUpgradePlan(priceId)}
        >
          Select
        </button>
      );
    } 
  };

  useEffect(() => {
    setLocation(props.title);
    setBreadcrumb([props.title]);
  }, []);


  // stripe customer portal
  const handleManageBilling = async(event) => {
    event.preventDefault();

    let sub_prices = [
      {
        priceId: branch?.subscription?.bronze?.priceId_quarter,
        frequency: 'quarter',
        plan: 'bronze',
      },
      {
        priceId: branch?.subscription?.bronze?.priceId_monthly,
        frequency: 'monthly',
        plan: 'bronze',
      },
      {
        priceId: branch?.subscription?.bronze?.priceId_yearly,
        frequency: 'yearly',
        plan: 'bronze',
      },
      {
        priceId: branch?.subscription?.silver?.priceId_quarter,
        frequency: 'quarter',
        plan: 'silver',
      },
      {
        priceId: branch?.subscription?.silver?.priceId_monthly,
        frequency: 'monthly',
        plan: 'silver',
      },
      {
        priceId: branch?.subscription?.silver?.priceId_yearly,
        frequency: 'yearly',
        plan: 'silver',
      },
      {
        priceId: branch?.subscription?.gold?.priceId_quarter,
        frequency: 'quarter',
        plan: 'gold',
      },
      {
        priceId: branch?.subscription?.gold?.priceId_monthly,
        frequency: 'monthly',
        plan: 'gold',
      },
      {
        priceId: branch?.subscription?.gold?.priceId_yearly,
        frequency: 'yearly',
        plan: 'gold',
      },
    ]

    let getPlan = sub_prices.find(x => x.priceId === subscription.plan.id)
    if(!getPlan){ 
      return showToast('error', "You haven't subscribed to any of our plans.");
    }
    setLoading(true)
    // Handle click event here
    let result = await ManageBilling({
      accessToken: props.accessToken,
      customerId: subscription?.customer,
    })
    if(result?.data?.data){
      window.open(result?.data?.data?.url, '_blank');
      setLoading(false)
    }else{
      showToast('error', 'An error occurred. Please contact the admin.');
    }
  };

  return (
    <PageContainer>
      <div className='tab-container'>
        <div className='tab-row-container'>
          <button
            type='button'
            className={
              renewOption === "quarter" ? "tab-button" : "tab-button-disabled"
            }
            onClick={() => setRenewOption("quarter")}
          >
            <div>
              Quarterly
            </div>
          </button>
          <button
            type='button'
            className={
              renewOption === "yearly" ? "tab-button" : "tab-button-disabled"
            }
            onClick={() => setRenewOption("yearly")}
          >
            <div>
            Yearly
            <p className="small">Save 25%</p>
            </div>
          </button>
        </div>
        {/* <div className='tab-row-container'>
          <div className='tab-text'>
            {renewOption === "yearly" && "Save from 30% - 50% on yearly plans!"}
          </div>
        </div> */}
      </div>
      <div className='plan-container'>
        {
          props.userInfo.subscriptionId ?
          <div className='plan-content'>
            <div className='plan-inner-content'>
                <div className="mb-4 pb-3">
                  <div className='plan-inner-content-header'>Expiry:</div>
                  <div className=''>{subscription?.current_period_end && moment.unix(subscription?.current_period_end).format('DD/MM/YYYY')}</div>
                </div>
            </div>
            <button 
              type='button' 
              className='plan-button'
              onClick={(e) => handleManageBilling(e)}
              >
              Manage Billing
            </button>
          </div>
          :
          <div className='plan-content' /> 
        }
        
        <div className='plan-content'>
          <div className='plan-inner-content'>
            <div className='plan-inner-content-header'>Copper</div>
            <div className='plan-inner-content-big-text'>FREE</div>
          </div>
          {/* {planFunctionHandler("copper")} */}
          <button type='button' disabled className='plan-button-disabled'>
            CURRENT
          </button>
        </div>

        <div className='plan-content'>
          <div className='plan-inner-content'>
            <div className='plan-inner-content-header'>Bronze</div>
            <div className='plan-inner-content-big-text'>
              {renewOption === "quarter" ? "$99" : "$299"}
              <div className='plan-inner-content-small-text'>
                {renewOption === "quarter" ? "/ Quarter" : "/ Year"}
              </div>
            </div>
          </div>
          {renewOption === "quarter" ? 
            planFunctionHandler("bronze", branch?.subscription?.bronze?.priceId_quarter) :
            planFunctionHandler("bronze", branch?.subscription?.bronze?.priceId_yearly)
          }
        </div>

        <div className='plan-content'>
          <div className='plan-inner-content'>
            <div className='plan-inner-content-header'>Silver</div>
            <div className='plan-inner-content-big-text'>
              {renewOption === "quarter" ? "$299" : "$899"}
              <div className='plan-inner-content-small-text'>
                {renewOption === "quarter" ? "/ Quarter" : "/ Year"}
              </div>
            </div>
          </div>
          {renewOption === "quarter" ? 
            planFunctionHandler("silver", branch?.subscription?.silver?.priceId_quarter) :
            planFunctionHandler("silver", branch?.subscription?.silver?.priceId_yearly)
          }
        </div>

        <div className='plan-content'>
          <div className='plan-inner-content'>
            <div className='plan-inner-content-header'>Gold</div>
            <div className='plan-inner-content-big-text'>
              {renewOption === "quarter" ? "$999" : "$2999"}
              <div className='plan-inner-content-small-text'>
                {renewOption === "quarter" ? "/ Quarter" : "/ Year"}
              </div>
            </div>
          </div>
          {renewOption === "quarter" ? 
            planFunctionHandler("gold", branch?.subscription?.gold?.priceId_quarter) :
            planFunctionHandler("gold", branch?.subscription?.gold?.priceId_yearly)
          }
        </div>
      </div>
      <div className='table-container'>
        <div className='header-section'>Key Features</div>
        <div className='body-section'>
          <div className='body-content'>
            <div className='body-title-text'>Customers Registrations</div>
            <div className='body-info-text-alt'>up to 200</div>
            <div className='body-info-text-alt'>up to 1,000</div>
            <div className='body-info-text-alt'>up to 5,000</div>
            <div className='body-info-text-alt' style={{ color: "#2563EB" }}>
              up to 25,000
            </div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Interactions (monthly reset)</div>
            <div className='body-info-text-alt'>1,000</div>
            <div className='body-info-text-alt'>10,000</div>
            <div className='body-info-text-alt'>50,000</div>
            <div className='body-info-text-alt' style={{ color: "#2563EB" }}>
              250,000
            </div>
          </div>
        </div>

        <div className='header-section'>Campaign Page</div>
        <div className='body-section'>
          <div className='body-content'>
            <div className='body-title-text'>
              Customised campaign appearance
            </div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Contact detail</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Outlet Location</div>
            <div className='body-info-text'>1</div>
            <div className='body-info-text'>1</div>
            <div className='body-info-text'>5</div>
            <div className='body-info-text'>10</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Link Google Map</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Customise Social Sharing</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>
              Business Verification (Blue Tick)
            </div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>QR Code</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Add to favourite</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Like/Dislike</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Reviews</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Share</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>
        </div>

        <div className='header-section'>Campaign Tools</div>
        <div className='body-section'>
          <div className='body-content'>
            <div className='body-title-text'>Stamp cards</div>
            <div className='body-info-text'>1</div>
            <div className='body-info-text'>3</div>
            <div className='body-info-text'>5</div>
            <div className='body-info-text'>10</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Package</div>
            <div className='body-info-text'>1</div>
            <div className='body-info-text'>3</div>
            <div className='body-info-text'>5</div>
            <div className='body-info-text'>10</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Referral</div>
            <div className='body-info-text'>1</div>
            <div className='body-info-text'>3</div>
            <div className='body-info-text'>5</div>
            <div className='body-info-text'>10</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Voucher</div>
            <div className='body-info-text'>1</div>
            <div className='body-info-text'>3</div>
            <div className='body-info-text'>5</div>
            <div className='body-info-text'>10</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Points</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Wallet</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Marketing Broadcast</div>
            <div className='body-info-text'>1</div>
            <div className='body-info-text'>3</div>
            <div className='body-info-text'>5</div>
            <div className='body-info-text'>10</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Payment Mode</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>MRZScan App</div>
            <div className='body-info-text'>1 User</div>
            <div className='body-info-text'>5 Users</div>
            <div className='body-info-text'>15 Users</div>
            <div className='body-info-text'>30 Users</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Campaign Reminder</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>
        </div>

        <div className='header-section'>Others</div>
        <div className='body-section'>
          <div className='body-content'>
            <div className='body-title-text'>Customer Management</div>
            <div className='body-info-text'>View Only</div>
            <div className='body-info-text'>View + Download</div>
            <div className='body-info-text'>View + Download</div>
            <div className='body-info-text'>View + Download</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Reports</div>
            <div className='body-info-text'>View Only</div>
            <div className='body-info-text'>View + Download</div>
            <div className='body-info-text'>View + Download</div>
            <div className='body-info-text'>View + Download</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Analytics</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Campaign Consultation</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Onboarding</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{uncheck}</div>
            <div className='body-info-text'>{check}</div>
            <div className='body-info-text'>{check}</div>
          </div>

          <div className='body-content'>
            <div className='body-title-text'>Email Support</div>
            <div className='body-info-text'>48 Business Hours</div>
            <div className='body-info-text'>24 Business Hours</div>
            <div className='body-info-text'>12 Business Hours</div>
            <div className='body-info-text'>6 Business Hours</div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  balance: state.role.balance ? state.role.balance : 0,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
  accessToken: state.role.details.accessToken?state.role.details.accessToken:"",
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
  getBalance: getBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlan);

