import React, { useEffect, useState, useContext } from 'react';
import { Button, InputGroup } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import getSymbolFromCurrency from 'currency-symbol-map'
import client from "feathers.js"
import axios from "axios"
import { Modal } from "antd";

import { PageContainer } from "../style";
import { updateDetails } from 'redux/actions/roleActions';
import { getBalance } from 'redux/actions/roleActions';
import { DataContext } from "contexts/DataContextContainer";
import HitPayPayment from "components/Payment/HitPayPayment"
import InfoCard from 'components/InfoCard/InfoCard';
import DetailsSection from 'components/DetailsSection/RowContainer';
import { Decrement, Increment } from 'assets/svg';

const UpgradePlan = props => {
  const history = useHistory();
  const { setLoading, showToast, setLocation, setBreadcrumb } = useContext(DataContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ amount, setAmount ] = useState(200);
  const [ activeMethod, setActiveMethod ] = useState('hitpay')
  const [ apiKey, setApiKey ] = useState('')

  const inputGroupStyle = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexWrap: "nowrap",
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const topupBalance = () => {
    if(amount < 50){
      showToast('error', 'Amount cannot be less than $50!')
      return false
    }

    let multiplesOf = 10
    if(!Number.isInteger(amount / multiplesOf)){
      showToast('error', `Amount ${amount} is not allowed!`)
      return false
    }


    if(amount <= 0){
      showToast('error', 'Upgrade same plan as current is not allowed!')
    }else{
      showModal()
    }
  }

  const paymentMadeComplete = (paymentIntent, hitpayPaymentId) => {
    setLoading(true)

    // let paymentId = paymentIntent.id
    // let paymentMethodTypes = paymentIntent.payment_method_types
    let paymentId = hitpayPaymentId
    let paymentMethodTypes = "hitpay"
    
    client.authenticate()
    .then(() => {
      return client.service('transactions').create({
        type: 'balance',
        creditDebit: 'credit',
        paymentType: activeMethod,
        paymentId: paymentId,
        paymentMethodTypes: paymentMethodTypes,
        // userId: props.userInfo._id,
        merchantId: props.userInfo._id,
        amount: amount,
        currency: 'usd',
        desc: `You have Topup Balance ${getSymbolFromCurrency('usd')} ${amount}`
      })
    })
    .then(res => {

      client.authenticate()
      .then((auth) => {
        return axios.post(`${client.io.io.uri}topupsendmail`, {
          merchantId: props.userInfo._id,
          amount: res.amount,
          currency: 'usd',
          transactionId: res._id,
        },
        {
          headers: {
            'Authorization': auth.accessToken
          }
        })
      })
      .then(() => {
        setLoading(false)
        handleOk()
  
        props.getBalance(Number(props.balance) + Number(res.amount))
      })

    })
    .catch(err =>{
      console.log(err)
      setLoading(false)
      showToast('error', err.message)
    })
  }

  const renderIcons = (value, setValue) => {
    const incrementCounter = () => {
      setValue(value + 10);
    };
    const decrementCounter = () => {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 10);
      }
    };
    return (
      <div className='inc-dec-wrapper'>
        <button
          type='button'
          className='increment-button'
          onClick={() => incrementCounter()}
        >
          <img
            src={Increment}
            alt=''
            width='20px'
            height='20px'
            draggable={false}
          />
        </button>
        <button
          type='button'
          className='decrement-button'
          onClick={() => decrementCounter()}
        >
          <img
            src={Decrement}
            alt=''
            width='20px'
            height='18px'
            draggable={false}
          />
        </button>
      </div>
    );
  };

  const onKeyPressedChecking = (event, value, setValue) => {
    if (event.key === "ArrowUp") {
      setValue(value + 10);
    } else if (event.key === "ArrowDown") {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 10);
      }
    }
  };

  const inputChecking = (value, setValue) => {
    const cleanedValue = String(value)
      .replace(/[^0-9]/g, "")
      .replace(/^0+/, "");

    const newValue = cleanedValue || "0";
    const parsedValue = parseInt(newValue);

    setValue(parsedValue);
  };

  useEffect(() => {
    setLocation(props.title)
    setBreadcrumb(['Campaign Tools', 'Wallet', props.title])
  }, [])

  useEffect(() => {
    const fetchHitpayApiKey = async() => {
      const apiKeyTmp = await axios.get(`${client.io.io.uri}fetchHitpayApiKey`).then(r => r.data.apiKey)
      setApiKey(apiKeyTmp)
    }

    fetchHitpayApiKey()

    const script = document.createElement("script")
    script.src = `https://hit-pay.com/hitpay.js`
    document.body.appendChild(script)
  }, [])

  return (
    <PageContainer>
      <InfoCard desc='To activate the points feature, it is mandatory to complete the two tasks below.' />

      <DetailsSection
        leftHeader='Top up MR Wallet'
        leftDesc='Minimum top up amount is $50 with increments in multiples of $10. Example: $60, $110, $360'
        rightContent={
          <>
            <div className='input-container'>
              Amount
              <InputGroup style={inputGroupStyle}>
                <InputGroup.Text style={{borderRadius: '8px 0 0 8px'}}>{getSymbolFromCurrency('usd')}</InputGroup.Text>
                  <input 
                    type='text' 
                    className="input"
                    size="large" 
                    value={Number(amount).toString()}
                    style={{borderRadius:'0 8px 8px 0', display: 'inline-block'}}
                    onWheel={ event => event.currentTarget.blur() }
                    onKeyDown={(e) => onKeyPressedChecking(e, amount, setAmount)}
                    onChange={(e) =>
                      inputChecking(e.target.value, setAmount)
                    }
                    onBlur={() => amount === 0 && setAmount(50)}
                  />
                  {renderIcons(amount, setAmount)}
              </InputGroup>

              <div className='number-preset'>
                <button 
                className={amount === 500 ? "active" : ""}
                onClick={() => setAmount(500)}>500</button>
                <button 
                className={amount === 1000 ? "active" : ""}
                onClick={() => setAmount(1000)}>1,000</button>
                <button 
                className={amount === 2000 ? "active" : ""}
                onClick={() => setAmount(2000)}>2,000</button>
                <button 
                className={amount === 3000 ? "active" : ""}
                onClick={() => setAmount(3000)}>3,000</button>
                <button 
                className={(amount !== 500 && amount !== 1000 && amount !== 2000 && amount !== 3000) ? "active" : ""}
                onClick={() => setAmount(Number(amount).toString())}>Others</button>
              </div>
            </div>
          </>
        }
      />

      <DetailsSection
        leftHeader=''
        leftDesc=''
        rightContent={
          <>
            <div className='button-wrapper'>
              <button
                type='button'
                className='cancel-button'
                onClick={() => history.goBack()}
              >Back</button>
              <Button 
                className='primary-button' 
                variant="success"
                onClick={() => topupBalance()}
              >
                Proceed
              </Button>
            </div>
          </>
        }
        align='flex-end'
      />

      <Modal
        title='Upgrade Plan'
        centered
        visible={isModalVisible}
        onClick={showModal}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        <div>
          <HitPayPayment 
            apiKey={apiKey}
            formValue={{
              firstName: 'First Name',
              lastName: 'Last Name',
              email: props.userInfo.email,
              phone: 'Phone Number'
            }}
            totalAmount={amount}
            currency={'usd'}
            paymentMadeComplete={paymentMadeComplete}
          />
        </div>
      </Modal>
    </PageContainer>
  );
}

const mapStateToProps = state => ({
  auth: state.role.auth,
  balance: state.role.balance?state.role.balance:0,
  userId: state.role.details.user?state.role.details.user._id:'',
  username: state.role.details.user?state.role.details.user.username:'',
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
  getBalance: getBalance
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlan);
