import styled from "styled-components";
import { colors } from "style";
export const StyledButtonContainer = styled.div`
  .ant-radio-wrapper {
    margin-right: 0;
    .ant-radio {
      .ant-radio-input {
        display: none;
      }
      .ant-radio-inner {
        width: 20px;
        height: 20px;
        border-color: #567877;
        &:after {
          background-color: white;
        }
      }
    }
    .ant-radio.ant-radio-checked {
      .ant-radio-inner {
        width: 20px;
        height: 20px;
        background-color: white;
        border-color: none;
        border: 2px solid #567877;
        &:after {
          background-color: #567877;
        }
      }
    }
  }
`;
