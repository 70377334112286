import React from "react";
import { Tooltip } from "antd";
import { Lock } from "assets/svg";

const TooltipsLock = (props) => {
  return (
    <>
      <div className={{ ...props.className }}>
        <Tooltip
          title='Only Bronze and Above!'
          color='#4D7978'
          placement='right'
          className='d-inline-block'
        >
          <img
            src={Lock}
            alt=''
            style={{
              position: "absolute",
              width: 25,
              height: 25,
              right: 0,
              top: 0,
            }}
          />
        </Tooltip>
      </div>
    </>
  );
};

export default TooltipsLock;
