import { breakpoints, colors } from "style";
import styled from "styled-components";
import { Home4 } from "assets/images";
// import { BrandPreviewBottom } from "assets/images";

export const BrandContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  overflow: auto;
  img {
    max-width: 100%;
    object-fit: cover;
  }
  .left-image {
    height: 100%;
    background-image: url(${Home4});
    background-size: cover;
    background-position: center;
  }
  .steps-group{
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 300px;
    left: 5%;
    top: 50%;
    transform: translateY(-50%);
    justify-content: space-between;
  }
  .check-avilability-again {
        margin-top: 40px;
        display: flex;
        width: 45%;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        border: 1px solid  #567877;
        background: #FFF;
        color: #567877;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        &:focus {
          box-shadow: unset;
        }
      }
      .confirm-btn {
        margin-top: 40px;
        display: flex;
        width: 50%;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 10px;
        background: #567877;
        color: #FFFFFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        &:focus {
          box-shadow: unset;
        }
        a{
          width: 100%;
          height: 100%;
          color: #FFFFFF;
          background: transparent;
          text-decoration: none;
        }
    }
  .brand-container {
    width: 100%;
    max-width: 390px;
    margin: auto;
  }
  .brand-form {
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    h2 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #3B3054;    
    }
    h4 {
      color: ${colors.purple};
      font-size: bold;
    }
    .logo-upload {
      .logo-upload-btn {
        // background: white;
        background: ${(props) => props.logo ? `url(${props.logo})` : 'white'};
        background-size: cover;
        // padding: 50px;
        width: 45px;
        height: 45px;
        // margin: 12px;
        position: absolute;
        top: 10px;
        left: 10px;
        border: solid 1px;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        &:hover {
          /* background: ${colors.grey}; */
          -webkit-box-shadow: 3px 2px 9px -3px #707070;
          box-shadow: 3px 2px 9px -3px #707070;
        }
        img {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 45%;
          transform: translate(0, -50%);
          object-fit: contain;
        }
      }
    }
    .banner-upload {
      // margin-top: 24px;
      .banner-upload-btn {
        display: flex;
        justify-content: center;
        width: 100%;
        background-size: cover;
        padding: 40px;
        flex-direction: column;
        align-items: center;
        border-radius: 6px;
        cursor: pointer;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        &:hover {
          /* background: ${colors.grey}; */
          -webkit-box-shadow: 3px 2px 9px -3px #707070;
          box-shadow: 3px 2px 9px -3px #707070;
        }
        img {
          width: 40px;
          object-fit: contain;
        }
        h4 {
          margin-top: 16px;
          font-weight: 500;
          // color: ${colors.dark7};
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
