import React, { useContext } from "react";
import { withRouter } from 'react-router'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./Footer";
import { PageLayoutContainer } from "./style";
import Topbar from "./Topbar";
import { DataContext } from "contexts/DataContextContainer";
import LoadingView from "components/Elements/LoadingView";
import {connect} from 'react-redux';
import { logout } from 'redux/actions/roleActions';
import { getMerchant } from 'redux/actions/extraActions';

import client from "feathers.js"

const PageLayout = ({ children, ...props }) => {
  // const { setLoading, showToast } = useContext(DataContext);
  const { isLoading } = useContext(DataContext);

  const signout = () => {
    client.authenticate()
    .then((a) => {
      client.logout()
      props.logout()
    })
    .catch((err) => {
      props.logout()
    })
  }
  
  return (
    <PageLayoutContainer isCustomer={false}>
      <div className="page-content shadow-sm" style={{
        position: 'relative',
        // maxHeight: window.location.pathname === '/' || window.location.pathname === '/forgot-password' || window.location.pathname.includes('/signup') || window.location.pathname.includes('/reset-password') || window.location.pathname.includes('/poster') || window.location.pathname.includes('/verification')? 'unset' : 844,
        // maxWidth: window.location.pathname === '/' || window.location.pathname === '/forgot-password' || window.location.pathname.includes('/signup') || window.location.pathname.includes('/reset-password') || window.location.pathname.includes('/poster') || window.location.pathname.includes('/verification')? 'unset' : 390
        // maxHeight: 844,
        // maxWidth: 390
      }}>
        {
          // not apply mobile topbar
          window.location.pathname === '/signup' ||
          window.location.pathname.includes('/verification') ||
          window.location.pathname.includes('/poster')
          // window.location.pathname === '/forgot-password' || 
          // window.location.pathname.includes('/reset-password')
          ?
          null : null
          // : <Topbar />
        }
        {
          React.cloneElement(children, { 
            signout,
          })
        }
        {
          // not apply mobile topbar
          window.location.pathname === '/signup' ||
          window.location.pathname.includes('/verification')
          // window.location.pathname === '/forgot-password' || 
          // window.location.pathname.includes('/reset-password')
          ?
          null: null
          // : <Footer />
        }
      </div>
      <ToastContainer 
        className='toast-container'
        position="top-right"
        autoClose={1500}
        hideProgressBar
        pauseOnFocusLoss={false}
        closeOnClick
        limit={3}
      />
      <LoadingView isLoading={isLoading}/>
    </PageLayoutContainer>
  );
};

const mapStateToProps = state => ({
  auth: state.role.auth,
  username: state.role.details.user?state.role.details.user.username:'',
  role: state.role.details.user?state.role.details.user.role:'',
  userInfo: state.role.details.user?state.role.details.user:{},
  merchant: state.extra.merchant,
  balance: state.role.balance?state.role.balance:0,
});

const mapDispatchToProps = {
  getMerchant: getMerchant,
  logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));
