import React from "react";
import { Image } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Menu } from "antd";
import { SideMenuContainer } from "./style";
import InlineSVG from "react-inlinesvg";
import { ActiveSideMenuArrow, Info } from "assets/svg";
import { Badge } from "assets/images";

const { SubMenu } = Menu;

const SideMenu = (props) => {
  const history = useHistory();
  const { userInfo, menuItems, collapsed, logo, path } = props;

  const currentPlan = userInfo.subscription;
  const currentPlanFormatted =
    currentPlan && currentPlan[0].toUpperCase() + currentPlan.slice(1);

  const subMenuCondition = path.split("/");

  const createMenuItem = (menuItem) => {
    const isSelected = menuItem.key === path;
    const isSubMenu = subMenuCondition.length > 3;

    return menuItem.subs ? (
      <SubMenu
        className='mrz-parents-menu'
        key={menuItem.key}
        title={
          <span style={{ marginBottom: "8px" }} className='title-wrapper'>
            {menuItem.icon && (
              <InlineSVG
                src={menuItem.icon}
                alt=''
                width='24px'
                height='24px'
                className='menu-icon'
                style={{
                  stroke: `${isSelected ? "#567877" : ""}`,
                }}
              />
            )}
            <span className='side-menu-text'>{menuItem.title}</span>
          </span>
        }
      >
        {menuItem.subs.map((mis) => createMenuItem(mis))}
      </SubMenu>
    ) : menuItem.hide ? null : (
      <Menu.Item
        key={menuItem.key}
        className={isSelected ? "ant-menu-item-selected" : ""}
      >
        <Link to={menuItem.route || menuItem.key}>
          <span className='title-wrapper'>
            {menuItem.icon && (
              <InlineSVG
                src={menuItem.icon}
                alt=''
                width='24px'
                height='24px'
                className='menu-icon'
                style={{
                  stroke: `${isSelected ? "#567877" : ""}`,
                }}
              />
            )}
            <span className='side-menu-text'>
              {menuItem.title}
              {isSelected && isSubMenu && (
                <img
                  src={ActiveSideMenuArrow}
                  alt=''
                  width='16px'
                  height='16px'
                />
              )}
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  return (
    <SideMenuContainer
      breakpoint='lg'
      collapsedWidth='0'
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={300}
    >
      {logo && (
        <div className='logo-wrapper'>
          <Image src={logo} className='logo' />
        </div>
      )}

      <hr className='custom-top-hr' />

      <Menu
        mode='inline'
        style={{ height: "100%", borderRight: 0, borderLeft: 0 }}
      >
        {menuItems.map((mi) => createMenuItem(mi))}

        {userInfo.role !== "superadmin" ? (
          <Menu.Item>
            <a
              href={"https://morerewardz.com/help"}
              target='_blank'
              rel='noopener noreferrer'
              className='title-wrapper'
            >
              <img src={Info} alt='' width='24px' height='24px' />
              <span className='side-menu-text'>{"Help centre"}</span>
            </a>
          </Menu.Item>
        ) : null}
      </Menu>

      <hr className='custom-bottom-hr' />

      <div className='upgrade-now-container'>
        <div className='upgrade-now'>
          <img src={Badge} alt='' width='65px' height='70px' />

          <div className='upgrade-now-header'>Upgrade now</div>

          <div className='upgrade-now-text'>
            Upgrade now for a better experience!
          </div>

          <button
            type='button'
            className='upgrade-now-button'
            onClick={() => history.push("/admin/upgrade")}
          >
            Upgrade
          </button>

          <div className='upgrade-now-plan'>
            {`Current plan: ${currentPlanFormatted}`}
          </div>
        </div>
      </div>

      <div className='hidden-div' />
    </SideMenuContainer>
  );
};

export default SideMenu;
