import { colors, breakpoints } from "style";
import styled from "styled-components";

export const FooterContainer = styled.footer`
  background: #3b7779;
  color: ${colors.white} !important;
  position: absolute;
  bottom: 0;
  padding: 10px 14px;
  text-align: center;
  p {
    color: #FFFFFFB0;
    font-size: .6em;
    font-weight: 400;
    margin: 0;
  }
  .footer-col {
    display: flex;
    flex-direction: column;
    .social-wrapper {
      display: flex;
      a {
        font-size: 16px;
        margin-right: 27px;
      }
    }
    h4 {
      color: ${colors.white};
      font-weight: 500;
      margin-bottom: 20px;
    }
    a {
      color: ${colors.white};
      font-size: 18px;
      font-weight: 400;
    }
    .lang-selector {
      .dropdown-toggle {
        border: unset;
        padding: 0;
        color: white;
        font-size: 18px;
      }
      .dropdown-menu {
        background-color: #333333;
        max-width: 120px;
        a {
          color: ${colors.gray};
          font-size: 14px;
          padding: 5px 11px;
        }
      }
    }
  }
  .footer-bottom {
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
`;
