import React, { useContext, useEffect, useRef, useState } from "react";
import { InnerHeaderContainer } from "./style";

import {
  HomeActive,
  HomeInactive,
  CopyLink,
  Info,
  TooltipClose,
} from "assets/svg";

import { DataContext } from "contexts/DataContextContainer";
import { toast } from "react-toastify";

const AdminInnerHeader = (props) => {
  const { username } = props;
  const { breadcrumb } = useContext(DataContext);
  const [openTooltip, setOpenTooltip] = useState(false);

  const tooltipIconRef = useRef(null);
  const tooltipContainerRef = useRef(null);

  const onClickCopyHandler = () => {
    const linkUrl = document.getElementById("link");

    linkUrl.select();

    const link = document.execCommand("copy");

    if (link) {
      toast.success("Link Copied!");
    }
  };

  const renderBreadcrumbItems = () => {
    return breadcrumb.map((item, index) => {
      return (
        <>
          <div className='breadcrumb-item-wrapper'>
            {index === 0 && (
              <img
                src={breadcrumb.length > 1 ? HomeInactive : HomeActive}
                alt=''
                width='13px'
                height='13px'
              />
            )}
            <div className='breadcrumb-text'>{item}</div>
          </div>

          {index + 1 !== breadcrumb.length && (
            <div style={{ color: "#BDBDBD" }}>/</div>
          )}
        </>
      );
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipIconRef.current &&
        !tooltipIconRef.current.contains(event.target) &&
        tooltipContainerRef.current &&
        !tooltipContainerRef.current.contains(event.target)
      ) {
        setOpenTooltip(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <InnerHeaderContainer>
      <div className='breadcrumb-container'>{renderBreadcrumbItems()}</div>
      <div className='right w-100'>
        {/* {(userInfo.role !== "superadmin") && ( */}
        <div className='url-bar-wrapper'>
          <div className='input-container'>
            <button
              type='button'
              ref={tooltipIconRef}
              style={{
                display: "flex",
                alignItems: "center",
                border: "none",
                background: "transparent",
                cursor: "pointer",
                height: "15px",
              }}
              onClick={(e) => setOpenTooltip(!openTooltip)}
            >
              <img src={Info} alt='' width='15px' height='15px' />
            </button>
            <input
              type='text'
              id='link'
              value={`${process.env.REACT_APP_BASE_URL}${username}`}
              readOnly
              className='input-box'
            />
          </div>
          <button
            type='button'
            className='copy-button'
            onClick={() => onClickCopyHandler()}
          >
            <img src={CopyLink} alt='' width='13.75px' height='13.75px' />
            Copy
          </button>
        </div>
        {/* )} */}
      </div>
      {openTooltip && (
        <div className='tooltip-container' ref={tooltipContainerRef}>
          <div className='tooltip-header'>
            Campaign URL
            <button
              type='button'
              style={{
                display: "flex",
                alignItems: "center",
                border: "none",
                background: "transparent",
                cursor: "pointer",
                height: "16px",
                padding: "0",
              }}
              onClick={() => setOpenTooltip(false)}
            >
              <img src={TooltipClose} alt='' width='16px' height='16px' />
            </button>
          </div>
          <div className='tooltip-text'>
            This is your campaign URL where you can
          </div>
          <div className='tooltip-arrow' />
        </div>
      )}
    </InnerHeaderContainer>
  );
};

export default AdminInnerHeader;
