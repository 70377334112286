import React, { useEffect, useState, useContext } from "react";
import { PageContainer } from "./style";
import { connect } from "react-redux";
import { updateDetails } from "redux/actions/roleActions";
import { DataContext } from "contexts/DataContextContainer";
import { Form } from "react-bootstrap";
import ModalConfirmation from "components/Extra/ModalConfirmation";

import client from "feathers.js";

const MerchantPoints = (props) => {
  const { setLoading, showToast, setConfirmModalIsOpen, confirmModalIsOpen } =
    useContext(DataContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [pointPerDollar, setPointPerDollar] = useState(0);
  const [dollarPoint, setDollarPoint] = useState(0);

  const modalCallback = () => {
    setLoading(true);
    client
      .service("merchants")
      .patch(props.userInfo._id, {
        pointPerDollar,
        dollarPoint,
      })
      .then((res) => {
        props.updateDetails(res);
        showToast("success", "Points conversion Updated Successfully!");

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const toggleModal = () => {
    setConfirmModalIsOpen(!confirmModalIsOpen);
  };

  useEffect(() => {
    setPointPerDollar(props.userInfo.pointPerDollar);
    setDollarPoint(props.userInfo.dollarPoint);
  }, [
    props.userInfo._id,
    props.userInfo.pointPerDollar,
    props.userInfo.dollarPoint,
  ]);

  return (
    <PageContainer>
      <h3 className='title'>{props.title}</h3>
      <div className=''>
        <div className=''>
          <div className='d-inline-block'>
            <div className='d-flex align-items-center'>
              <p className='dollar-sign'>$</p>

              <Form.Control
                type='number'
                name='pointPerDollar'
                value={Number(pointPerDollar).toString()}
                onChange={(e) => setPointPerDollar(Number(e.target.value))}
                placeholder='Enter Amount'
                min='0'
                step='1'
                onWheel={(event) => event.currentTarget.blur()}
                className='money-input'
              />

              <p className='mx-2'> =</p>

              <Form.Control
                type='number'
                name='dollarPoint'
                value={Number(dollarPoint).toString()}
                onChange={(e) => setDollarPoint(Number(e.target.value))}
                placeholder={`Point(s)`}
                min='0'
                step='1'
                onWheel={(event) => event.currentTarget.blur()}
                className='point-input'
              />
              <p className='point-sign'>Points</p>

              {/* <Row>
                <Col md="12">
                  <h6 className="form-item-title">How many points is awarded per dollar?</h6>
                  <div className='mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='mr-2'>
                        
                      </div>
                      <div style={{fontWeight: 500}}>
                        Point per {getSymbolFromCurrency(props.userInfo.currency)} 1
                      </div>
                    </div>
                  </div>
                </Col>
              </Row> */}
            </div>
          </div>

          {/* // ? for set button logic later
          {
            props.setPoint ?
            (<div className='d-inline-block'>
              <button 
                className='btn btn-sm btn-warning'
                onClick={toggleModal}
              >
                Set
              </button>
            </div>) : null
          } */}
        </div>
        {/* <div className='preview'>
          <label>Preview</label>
          <div className='body'>
            <p>{getSymbolFromCurrency(props.userInfo.currency)} 1</p>
            <hr />
            <div className='points'>
              <img src={CampaignPoint} alt="" />
              <span>{pointPerDollar} Points</span>
            </div>
          </div>
        </div> */}
      </div>
      <ModalConfirmation
        modalVisible={confirmModalIsOpen}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Confirm Update
            </span>
          </>
        }
        body={
          <>
            <span className='title2'>Proceed to update points conversion?</span>
          </>
        }
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.role.auth,
  userId: state.role.details.user ? state.role.details.user._id : "",
  username: state.role.details.user ? state.role.details.user.username : "",
  role: state.role.details.user ? state.role.details.user.role : "",
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {
  updateDetails: updateDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantPoints);
