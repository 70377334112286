import { Menu, Dropdown } from 'antd';
import { EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { ActionMenuContainer } from "../../Admin/style";
import moment from 'moment';
import CountryList from 'country-list-with-dial-code-and-flag'

const menu = (record, setAction, setDataEdit) => {
  return (
    <ActionMenuContainer>
      <Menu.Item key="0" onClick={() => {
        setAction('edit')
        setDataEdit(record)
        }}>
        <FormOutlined /> View
      </Menu.Item>
      {/* <Menu.Item key="1">
        <PauseOutlined /> Pause
      </Menu.Item>
      <Menu.Item key="2">
        <FileTextOutlined /> Details
      </Menu.Item> */}
    </ActionMenuContainer>
  )
}

const columns = (setAction, setDataEdit) => [
    // {
    //   title: 'No.',
    //   dataIndex: 'no',
    //   render: (record, records, index) => 
    //   <span>{index+1}</span>
    // },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (record) => <span>{CountryList.find(e => e.code === record)?CountryList.find(e => e.code === record).name:""}</span>
    },
    {
      title: 'Merchant Name',
      dataIndex: 'username',
      key: 'username'
    },
    {
      title: 'Campaign URL',
      key: 'url',
      render: (record) => 
      <span><a href={`https://go.morerewardz.com/${record.username}`} target="_blank" rel="noopener noreferrer">https://go.morerewardz.com/{record.username}</a></span>
    },
    {
      title: 'Date Started',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'Plan',
      dataIndex: 'subscription',
      key: 'subscription',
      render: (record) => <span className='text-capitalize'>{record}</span>
    },
    {
      title: 'Renewal Frequency',
      dataIndex: 'planIn',
      key: 'planIn',
      render: (record) => <span className='text-capitalize'>{record? record === 'year'? "Annually": "Monthly": "Free"}</span>
    },
    {
      title: 'Next Renewal',
      dataIndex: 'subEnd',
      key: 'subEnd',
      render: (record) => <span>{moment(record).format("DD/MM/YYYY - HH:mm")}</span>
    },
    {
      title: 'MR Wallet',
      dataIndex: 'wallet',
      key: 'wallet',
      render: (record) => <span>${record}</span>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (record) => <span>-</span>
    },
    {
      title: 'Name',
      dataIndex: 'owner_name',
      key: 'owner_name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'contact_phone',
      render: (record, records) => 
      <span>{records.phonePrefix + " " + records.contact_phone}</span>
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (record) => (
        <Dropdown overlay={menu(record, setAction, setDataEdit)} trigger={['click']}>
          <a href="/#" className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: 'gray'}}>
            <EllipsisOutlined style={{fontSize: 20}}/>
          </a>
        </Dropdown>
      ),
    },
  ]

export default columns;
