import React, { useState, useContext, useEffect } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import moment from "moment";
import { cloneDeep, isEmpty } from "lodash";
import { Input } from "antd";
import client from "feathers.js";

import StyledQuill from "components/Elements/StyledQuill";
import StyledButton from "components/Elements/StyledButton";
import StyledRadio from "components/Elements/StyledRadio";
import ModalLanuch from "components/Extra/ModalLanuch";
import { DataContext } from "contexts/DataContextContainer";
import { PageContainer } from "../../style";
import { PreviewContainer } from "../../styleEdit";
import Datepicker from "../../../../../components/Datepicker";
import { Decrement, Increment, Success } from "assets/svg";
import StepsCounter from "components/StepsCounter";
import QuestionCard from "components/QuestionCard";

const durationOptions = [
  {
    value: "days",
    label: "Day(s)",
  },
  {
    value: "weeks",
    label: "Week(s)",
  },
  {
    value: "months",
    label: "Month(s)",
  },
  {
    value: "years",
    label: "Year(s)",
  },
];

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      // { list: "ordered" },
      { list: "bullet" },
      // { indent: "-1" },
      // { indent: "+1" },
    ],
  ],
};

const Edit = (props) => {
  const { setLoading, showToast, setToolsAction } = useContext(DataContext);
  const [title, setTitle] = useState("");
  const [count, setCount] = useState(1);
  const [periodStart, setPeriodStart] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);
  const [campaignValidity, setCampaignValidity] = useState(1);
  const [campaignValidityUnit, setCampaignValidityUnit] = useState("months");
  const [redeemValidity, setRedeemValidity] = useState(1);
  const [redeemValidityUnit, setRedeemValidityUnit] = useState("months");
  const [canEdit, setCanEdit] = useState(false);
  const [tos, setTos] = useState("");
  const [title_count, setTitle_count] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showSuccessCreate, setShowSuccessCreate] = useState(false);

  const description = [
    "Give your campaign a name",
    "Set campaign period",
    "Set no. of stamps required to claim reward",
    "Set campaign validity upon first stamp collection",
    "Set reward redemption vadility period",
    "Add extra Terms & Conditions",
  ];

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleTitle = (value) => {
    if (value.length <= 50) {
      setTitle(value);
      setTitle_count(value.length);
    }
  };

  const onChangeCampaignPeriod = (dates, dateStrings) => {
    setPeriodStart(dates[0]);
    setPeriodEnd(dates[1]);
  };

  const modalCallback = () => {
    if (canEdit) {
      if (title.trim() === "" || !periodStart || !periodEnd) {
        showToast("error", "Please fill all necessary information");
      } else {
        setLoading(true);
        if (props.action === "edit") {
          const campaignDetail = {
            title,
            count,
            period_start: periodStart.toDate(),
            period_end: periodEnd.toDate(),
            campaign_validity: campaignValidity,
            campaign_validity_unit: campaignValidityUnit,
            redeem_validity: redeemValidity,
            redeem_validity_unit: redeemValidityUnit,
            tos,
          };

          client
            .authenticate()
            .then(() => {
              return client.service("stamp-pouches").find({
                query: {
                  stampId: props.data._id,
                  merchantId: props.userInfo._id,
                  $limit: 1,
                },
              });
            })
            .then((res) => {
              if (res.data.length > 0) {
                setLoading(false);
                setCanEdit(false);
                showToast(
                  "error",
                  "Customer is using this Stamp Card! Not allow to edit!"
                );
              } else {
                return client
                  .service("stamps")
                  .patch(props.data._id, campaignDetail)
                  .then((res) => {
                    let cloneData = cloneDeep(props.campaignData);
                    let findKey = cloneData.findIndex(
                      (e) => e._id === props.data._id
                    );
                    if (findKey !== -1) {
                      res.totalCollected = cloneData[findKey].totalCollected;
                      res.totalCompleted = cloneData[findKey].totalCompleted;
                      res.totalRedeemed = cloneData[findKey].totalRedeemed;
                      cloneData[findKey] = res;
                    }

                    props.fetchCampaignInfo();
                    props.setCampaignData(cloneData);

                    setLoading(false);
                    setCanEdit(true);
                    showToast("success", "Campaign is updated successfully");
                    props.back();
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              showToast("error", err.message);
            });
        } else {
          const campaignDetail = {
            // merchant_id: props.currentMerchant._id,
            // campaign_type: 'stamp',
            userId: props.userInfo._id,
            title,
            count,
            period_start: periodStart.toDate(),
            period_end: periodEnd.toDate(),
            campaign_validity: campaignValidity,
            campaign_validity_unit: campaignValidityUnit,
            redeem_validity: redeemValidity,
            redeem_validity_unit: redeemValidityUnit,
            tos,
          };

          client
            .authenticate()
            .then(() => {
              return client.service("stamps").create(campaignDetail);
            })
            .then((res) => {
              let cloneData = cloneDeep(props.campaignData);
              res.totalCollected = 0;
              res.totalCompleted = 0;
              res.totalRedeemed = 0;

              props.fetchCampaignInfo();
              props.setCampaignData(cloneData.concat(res));

              setLoading(false);
              showToast("success", "Campaign is created successfully");
              props.back();
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              showToast("error", err.message);
            });
        }
      }
    } else {
      showToast(
        "error",
        "Customer is using this Stamp Card! Not allow to edit!"
      );
    }
  };

  const renderIcons = (value, setValue) => {
    const incrementCounter = () => {
      setValue(value + 1);
    };
    const decrementCounter = () => {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    };
    return (
      <div className='inc-dec-wrapper'>
        <button
          type='button'
          className='increment-button'
          onClick={() => incrementCounter()}
        >
          <img
            src={Increment}
            alt=''
            width='20px'
            height='20px'
            draggable={false}
          />
        </button>
        <button
          type='button'
          className='decrement-button'
          onClick={() => decrementCounter()}
        >
          <img
            src={Decrement}
            alt=''
            width='20px'
            height='18px'
            draggable={false}
          />
        </button>
      </div>
    );
  };

  const onKeyPressedChecking = (event, value, setValue) => {
    if (event.key === "ArrowUp") {
      setValue(value + 1);
    } else if (event.key === "ArrowDown") {
      if (value === 1 || value === 0) {
        setValue(value);
      } else {
        setValue(value - 1);
      }
    }
  };

  const inputChecking = (value, setValue) => {
    const cleanedValue = String(value)
      .replace(/[^0-9]/g, "")
      .replace(/^0+/, "");

    const newValue = cleanedValue || "0";
    const parsedValue = parseInt(newValue);

    setValue(parsedValue);
  };

  const onBackQuestion = () => {
    if (currentStep === 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const onNextQuestion = () => {
    if (currentStep === 1 && title.length > 0) {
      setCurrentStep(2);
    } else if (currentStep === 2 && periodStart && periodEnd) {
      setCurrentStep(3);
    } else if (currentStep === 3 && count > 0) {
      setCurrentStep(4);
    } else if (currentStep === 4 && campaignValidity > 0) {
      setCurrentStep(5);
    } else if (currentStep === 5 && redeemValidity > 0) {
      setCurrentStep(6);
    } else if (currentStep === 6) {
      setShowSuccessCreate(true);
    }
  };

  const renderQuestionCard = (q, d, c) => {
    return (
      <QuestionCard
        question={q}
        descriptions={<div className='description-text'>{d}</div>}
        totalQuestion={6}
        currentActive={currentStep}
        onClickBackButton={onBackQuestion}
        onClickNextButton={onNextQuestion}
        noPaginator
        questionHeight='auto'
        answerHeight='100px'
        children={c}
      />
    );
  };

  const renderFirstTimeEditContent = () => {
    return (
      <div className='first-time-edit-content'>
        <div className='first-time-step-container'>
          <div className='first-time-step-wrapper'>
            <StepsCounter
              currentStepNumber={currentStep}
              stepDescriptions={description}
              left='48%'
            />
          </div>
        </div>

        <div className='first-time-step-details-container'>
          {currentStep === 1 &&
            renderQuestionCard(
              "What will be the reward for this stamp card campaign?",
              "Provide an interesting promotion title which will capture customer's attention.",
              <div className='input-container'>
                <Input
                  size='large'
                  placeholder='Eg. Get 5% off any of my design services!'
                  value={title}
                  onChange={(e) => handleTitle(e.target.value)}
                  className='first-time-input'
                />
                <p className='answer-input-description'>
                  Characters ({title.trim().length}/50)
                </p>
              </div>
            )}
          {currentStep === 2 &&
            renderQuestionCard(
              "Set campaign period",
              "Set the start and end date for this campaign period.",
              <>
                <Datepicker
                  periodStart={periodStart}
                  periodEnd={periodEnd}
                  onChange={onChangeCampaignPeriod}
                  noLabel
                />
              </>
            )}
          {currentStep === 3 &&
            renderQuestionCard(
              "How many stamps should customers collect to redeem this reward?",
              "Specify the number of stamps required to redeem this reward.",
              <div className='input-container'>
                <div className='input-container'>
                  <Input
                    className='first-time-input'
                    type='text'
                    size='large'
                    value={count}
                    onKeyDown={(e) => onKeyPressedChecking(e, count, setCount)}
                    onChange={(e) =>
                      count <= 100
                        ? inputChecking(e.target.value, setCount)
                        : setCount(100)
                    }
                    onBlur={() => [
                      count === 0 && setCount(1),
                      count > 100 && setCount(100),
                    ]}
                  />
                  {renderIcons(count, setCount)}
                </div>
                <div className='number-preset'>
                  <button
                    className={count === 3 ? "active" : ""}
                    onClick={() => setCount(3)}
                  >
                    3
                  </button>
                  <button
                    className={count === 5 ? "active" : ""}
                    onClick={() => setCount(5)}
                  >
                    5
                  </button>
                  <button
                    className={count === 10 ? "active" : ""}
                    onClick={() => setCount(10)}
                  >
                    10
                  </button>
                  <button
                    className={count === 20 ? "active" : ""}
                    onClick={() => setCount(20)}
                  >
                    20
                  </button>
                  <button
                    className={
                      count !== 3 && count !== 5 && count !== 10 && count !== 20
                        ? "active"
                        : ""
                    }
                    onClick={() => setCount(1)}
                  >
                    Others
                  </button>
                </div>
              </div>
            )}
          {currentStep === 4 &&
            renderQuestionCard(
              "What is the duration of the stamp card's validity starting from the collection of the first stamp?",
              "Provide the period of time the stamp card stays valid the moment a customer collects his/her first stamp.",
              <div className='input-container'>
                <div className='input-container'>
                  <Input
                    className='first-time-input'
                    type='text'
                    size='large'
                    value={campaignValidity}
                    onKeyDown={(e) =>
                      onKeyPressedChecking(
                        e,
                        campaignValidity,
                        setCampaignValidity
                      )
                    }
                    onChange={(e) =>
                      inputChecking(e.target.value, setCampaignValidity)
                    }
                    onBlur={() =>
                      campaignValidity === 0 && setCampaignValidity(1)
                    }
                  />
                  {renderIcons(campaignValidity, setCampaignValidity)}
                </div>
                <div className='number-preset'>
                  <button
                    className={campaignValidityUnit === "days" ? "active" : ""}
                    onClick={() => setCampaignValidityUnit("days")}
                  >
                    Day(s)
                  </button>
                  <button
                    className={campaignValidityUnit === "weeks" ? "active" : ""}
                    onClick={() => setCampaignValidityUnit("weeks")}
                  >
                    Week(s)
                  </button>
                  <button
                    className={
                      campaignValidityUnit === "months" ? "active" : ""
                    }
                    onClick={() => setCampaignValidityUnit("months")}
                  >
                    Month(s)
                  </button>
                  <button
                    className={campaignValidityUnit === "years" ? "active" : ""}
                    onClick={() => setCampaignValidityUnit("years")}
                  >
                    Year(s)
                  </button>
                </div>
              </div>
            )}
          {currentStep === 5 &&
            renderQuestionCard(
              "How long after do customers have to redeem their reward after collecting all their stamps for this campaign?",
              "Provide the period of time customers have to redeem their reward before the stamp card expires.",
              <div className='input-container'>
                <div className='input-container'>
                  <Input
                    className='first-time-input'
                    type='text'
                    size='large'
                    value={redeemValidity}
                    onKeyDown={(e) =>
                      onKeyPressedChecking(e, redeemValidity, setRedeemValidity)
                    }
                    onChange={(e) =>
                      inputChecking(e.target.value, setRedeemValidity)
                    }
                    onBlur={() => redeemValidity === 0 && setRedeemValidity(1)}
                  />
                  {renderIcons(redeemValidity, setRedeemValidity)}
                </div>
                <div className='number-preset'>
                  <button
                    className={redeemValidityUnit === "days" ? "active" : ""}
                    onClick={() => setRedeemValidityUnit("days")}
                  >
                    Day(s)
                  </button>
                  <button
                    className={redeemValidityUnit === "weeks" ? "active" : ""}
                    onClick={() => setRedeemValidityUnit("weeks")}
                  >
                    Week(s)
                  </button>
                  <button
                    className={redeemValidityUnit === "months" ? "active" : ""}
                    onClick={() => setRedeemValidityUnit("months")}
                  >
                    Month(s)
                  </button>
                  <button
                    className={redeemValidityUnit === "years" ? "active" : ""}
                    onClick={() => setRedeemValidityUnit("years")}
                  >
                    Year(s)
                  </button>
                </div>
              </div>
            )}
          {currentStep === 6 &&
            renderQuestionCard(
              <>
                <div>Final step!</div>
                <div>Any other terms & conditions?</div>
              </>,
              <ul className='description-list'>
                <li>
                  Campaign Period{" "}
                  {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""}{" "}
                  - {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
                </li>
                <li>
                  Complete this stamp card within {campaignValidity}{" "}
                  {campaignValidityUnit.slice(0, -1)}(s) after collecting first
                  stamp
                </li>
                <li>
                  Redeem reward within {redeemValidity}{" "}
                  {redeemValidityUnit.slice(0, -1)}(s) upon completing
                </li>
              </ul>,
              <StyledQuill
                readOnly={false}
                modules={quillModules}
                value={tos}
                onChange={async (value) => {
                  setTos(value);
                }}
              />
            )}
        </div>
      </div>
    );
  };

  const successCreate = () => {
    return (
      <div className='first-time-edit-content'>
        <div className='success-container'>
          <img src={Success} alt='' width='50px' height='50px' />
          Almost Done!
          <StyledButton
            title='Preview'
            onClick={toggleModal}
            type='submit'
            className='primary-button'
            bgcolor={"#567877"}
          />
        </div>
      </div>
    );
  };

  const renderEditContent = () => {
    return (
      <>
        <div className='edit-content'>
          <div className='input-container'>
            Stamp campaign title
            <Input
              size='large'
              placeholder='Get 5% off any of my design services!'
              value={title}
              onChange={(e) => handleTitle(e.target.value)}
              className='input'
            />
          </div>

          <div className='input-container'>
            Stamp campaign period range
            <Datepicker
              periodStart={periodStart}
              periodEnd={periodEnd}
              onChange={onChangeCampaignPeriod}
              noLabel
            />
          </div>

          <div className='input-container'>
            Number of stamp(s) required to claim reward
            <Input
              className='input'
              type='text'
              size='large'
              value={count}
              onKeyDown={(e) => onKeyPressedChecking(e, count, setCount)}
              onChange={(e) =>
                count <= 100 && inputChecking(e.target.value, setCount)
              }
              onBlur={() => count === 0 && setCount(1)}
            />
            {renderIcons(count, setCount)}
          </div>

          <div className='input-container'>
            <div className='input-container'>
              Vadility of stamp card upon collection of first stamp
              <Input
                className='input'
                type='text'
                size='large'
                value={campaignValidity}
                onKeyDown={(e) =>
                  onKeyPressedChecking(e, campaignValidity, setCampaignValidity)
                }
                onChange={(e) =>
                  inputChecking(e.target.value, setCampaignValidity)
                }
                onBlur={() => campaignValidity === 0 && setCampaignValidity(1)}
              />
              {renderIcons(campaignValidity, setCampaignValidity)}
            </div>
            <StyledRadio
              options={durationOptions}
              value={campaignValidityUnit}
              onChange={(e) => setCampaignValidityUnit(e.target.value)}
            />
          </div>

          <div className='input-container'>
            <div className='input-container'>
              Vadility period for reward redemption
              <Input
                className='input'
                type='text'
                size='large'
                value={redeemValidity}
                onKeyDown={(e) =>
                  onKeyPressedChecking(e, redeemValidity, setRedeemValidity)
                }
                onChange={(e) =>
                  inputChecking(e.target.value, setRedeemValidity)
                }
                onBlur={() => redeemValidity === 0 && setRedeemValidity(1)}
              />
              {renderIcons(redeemValidity, setRedeemValidity)}
            </div>
            <StyledRadio
              options={durationOptions}
              value={redeemValidityUnit}
              onChange={(e) => setRedeemValidityUnit(e.target.value)}
            />
          </div>

          <div className='input-container'>
            Additional Terms and Conditions
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <li>
                Campaign Period{" "}
                {periodStart ? moment(periodStart).format("DD/MM/YYYY") : ""} -{" "}
                {periodEnd ? moment(periodEnd).format("DD/MM/YYYY") : ""}
              </li>
              <li>
                Complete this stamp card within {campaignValidity}{" "}
                {campaignValidityUnit.slice(0, -1)}(s) after collecting first
                stamp
              </li>
              <li>
                Redeem reward within {redeemValidity}{" "}
                {redeemValidityUnit.slice(0, -1)}(s) upon completing
              </li>
            </ul>
            <StyledQuill
              readOnly={false}
              modules={quillModules}
              value={tos}
              onChange={async (value) => {
                setTos(value);
              }}
            />
          </div>

          <div className='button-wrapper-container'>
            <button
              type='button'
              onClick={() => props.back()}
              className='cancel-button'
            >
              Back
            </button>
            <StyledButton
              title='Preview'
              onClick={toggleModal}
              type='submit'
              className='primary-button'
              bgcolor={"#567877"}
            />
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (props.data._id) {
      client
        .service("stamp-pouches")
        .find({
          query: {
            stampId: props.data._id,
            merchantId: props.userInfo._id,
            $limit: 1,
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            // cannot edit -> user is using this stamp
            showToast(
              "error",
              "Customer is using this Stamp Card! Not allow to edit!"
            );
          } else {
            setCanEdit(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          showToast("error", err.message);
        });
    } else {
      setCanEdit(true);
    }
    /* eslint-disable-next-line */
  }, [props.data._id, props.userInfo._id]);

  useEffect(() => {
    if (!isEmpty(props.data)) {
      setTitle(props.data.title);
      setTitle_count(props.data.title.length);
      setCount(props.data.count);
      setPeriodStart(moment(new Date(props.data.period_start), "DD/MM/YYYY"));
      setPeriodEnd(moment(new Date(props.data.period_end), "DD/MM/YYYY"));
      setCampaignValidity(props.data.campaign_validity);
      setCampaignValidityUnit(props.data.campaign_validity_unit);
      setRedeemValidity(props.data.redeem_validity);
      setRedeemValidityUnit(props.data.redeem_validity_unit);
      setTos(props.data.tos);
    }
  }, [props.data._id, props.data]);

  return (
    <PageContainer>
      {props.firstTime
        ? showSuccessCreate
          ? successCreate()
          : renderFirstTimeEditContent()
        : renderEditContent()}

      <ModalLanuch
        modalVisible={modalVisible}
        toggleModal={toggleModal}
        modalCallback={modalCallback}
        campaign='stamp'
        header={
          <>
            <span className='title1 text-center'>
              <br />
              Campaign Preview
            </span>
          </>
        }
        body={
          <PreviewContainer>
            <div className='preview-wrapper'>
              <div className='preview'>
                <div className='details'>
                  <div className='title'>
                    <h4>{title ? title : "--"}</h4>
                  </div>
                  <div className='stamps'>
                    <h4>{`Stamp Card Expiry : ${campaignValidity} ${campaignValidityUnit.slice(
                      0,
                      -1
                    )}(s) from first stamp`}</h4>
                    <div className='cards'>
                      {[...Array(parseInt(count ? count : 1) + 1).keys()].map(
                        (item) => (
                          <div className='card' key={item}>
                            <span className='card-no'>{item + 1}</span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className='terms'>
                    <h4>Terms & Condition</h4>
                    <ul style={{ marginBottom: 0 }}>
                      <li>
                        Campaign Period{" "}
                        {periodStart
                          ? moment(periodStart).format("DD/MM/YYYY")
                          : ""}{" "}
                        -{" "}
                        {periodEnd
                          ? moment(periodEnd).format("DD/MM/YYYY")
                          : ""}
                      </li>
                      <li>
                        Complete this stamp card within {campaignValidity}{" "}
                        {campaignValidityUnit.slice(0, -1)}(s) after collecting
                        first stamp
                      </li>
                      <li>
                        Redeem reward within {redeemValidity}{" "}
                        {redeemValidityUnit.slice(0, -1)}(s) upon completing
                      </li>
                    </ul>
                    {<div>{parse(tos)}</div>}
                  </div>
                </div>
              </div>
            </div>
          </PreviewContainer>
        }
        canEdit={canEdit}
      />
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
