//Action Types
export const GET_MERCHANT = 'GET_MERCHANT';
export const GET_LOGO_BANNER = 'GET_LOGO_BANNER';
export const GET_OUTLET_MODAL = 'GET_OUTLET_MODAL';

//Action Creator
export const getMerchant = (data) => ({
   type: GET_MERCHANT,
   data: data
});

export const getLogoBanner = (data) => ({
   type: GET_LOGO_BANNER,
   data: data
});

export const getOutletModal = (data) => ({
   type: GET_OUTLET_MODAL,
   data: data
});