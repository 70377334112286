import React from "react";
import moment from "moment";
import { GoogleContainer } from "./style";

const GoogleSEOPreview = (props) => {
  return (
    <GoogleContainer>
      <section>
        <div
          id='yoast-snippet-preview-container'
          className='SnippetPreview__MobileContainer-waz8ku-1 fRzIon'
        >
          <div className='SnippetPreview__MobilePartContainer-waz8ku-16 kEHeZL'>
            <span
              className='screen-reader-text'
              style={{
                clip: "rect(1px, 1px, 1px, 1px)",
                position: "absolute",
                height: "1px",
                width: "1px",
                overflow: "hidden",
              }}
            >
              Url preview:
            </span>
            <div className='SnippetPreview__BaseUrl-waz8ku-8 BolQT'>
              <div className='SnippetPreview__BaseUrl-waz8ku-8 SnippetPreview__BaseUrlOverflowContainer-waz8ku-9 gYnHlo'>
                <img
                  src='/logo.png'
                  alt=''
                  className='SnippetPreview__Favicon-waz8ku-20 hQKFKz'
                />
                <span className='SnippetPreview__UrlContentContainer-waz8ku-10 bxIhfU'>
                  <span className='SnippetPreview__UrlBaseContainer-waz8ku-11 jFdUzS'>
                    https://go.morerewardz.com/{props.username}{" "}
                  </span>
                </span>
              </div>
            </div>
            <span
              className='screen-reader-text'
              style={{
                clip: "rect(1px, 1px, 1px, 1px)",
                position: "absolute",
                height: "1px",
                width: "1px",
                overflow: "hidden",
              }}
            >
              SEO title preview:
            </span>
            <div className='SnippetPreview__BaseTitle-waz8ku-2 krRYdv'>
              <div className='SnippetPreview__Title-waz8ku-4 SnippetPreview__TitleBounded-waz8ku-5 dMErEv'>
                <span className='SnippetPreview__TitleUnboundedMobile-waz8ku-7 iLndVK'>
                  <span className='line-clamp-title'>{props.title}</span>
                </span>
              </div>
            </div>
          </div>
          <div className='SnippetPreview__MobilePartContainer-waz8ku-16 kEHeZL'>
            <span
              className='screen-reader-text'
              style={{
                clip: "rect(1px, 1px, 1px, 1px)",
                position: "absolute",
                height: "1px",
                width: "1px",
                overflow: "hidden",
              }}
            >
              Meta description preview:
            </span>
            <div className='SnippetPreview__MobileDescription-waz8ku-13 fIsZzD'>
              <div className='SnippetPreview__MobileDescription-waz8ku-13 fIsZzD'>
                <div className='SnippetPreview__MobileDescriptionImageContainer-waz8ku-14 hafxWX'>
                  {props.image && (
                    <img
                      src={props.image}
                      alt=''
                      className='SnippetPreview__MobileDescriptionImage-waz8ku-15 czWTEy'
                    />
                  )}
                </div>
                <span className='SnippetPreview__DatePreview-waz8ku-19 eeMkVu'>
                  {moment(new Date()).format("MMM DD, YYYY")} ⋅{" "}
                </span>
                <div className='line-clamp-desc'>{props.description}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </GoogleContainer>
  );
};

export default GoogleSEOPreview;
