import { Layout } from "antd";
import styled from "styled-components";
import { colors } from "style";
const { Header } = Layout;

export const HeaderContainer = styled(Header)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: clamp(940px, 100%, 100%);
  padding: 5px 40px;
  background: #ffffff;
  height: auto;
  box-shadow: 0px 5px 10px 0px #eaecee;
  z-index: 10;

  .breadcrumb-header-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    width: 100%;
  }
  ul {
    justify-content: flex-end;
  }
  ul li {
    padding: 0 !important;
  }
  .profile-menu-wrapper {
    display: flex;
    align-items: center;
    height: 51px;
    background: transparent;
    border-radius: 29px;
  }
  .custom-hr {
    width: 50px;
    transform: rotate(90deg);
    border: 0.5px solid black;
    opacity: 5%;
  }
  .notification {
    margin: auto;
    font-size: 20px;
    color: ${colors.darkGrey};
  }
  .admin-picture {
    width: 40px;
    border-radius: 30px;
    margin-right: 16px;
  }
  .info {
    display: flex;
    flex-direction: column;
    // margin-right: 16px;
    .admin-id {
      line-height: 24px;
      color: ${colors.darkGrey};
      font-weight: bold;
      font-size: 16px;
    }
    p {
      margin: 0;
    }
    .a {
      font-weight: 400;
      // font-size: 12px;
      line-height: 13px;
      color: rgba(65, 70, 77, 0.7);
      a {
        color: #fdb256;
      }
    }
    .b {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: black;
      text-transform: capitalize;
    }
  }
  .menu {
    font-size: 16px;
    color: rgba(65, 70, 77, 0.7);
    cursor: pointer;
  }

  .right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .screen-hide {
  }
  // @media only screen and (max-width: 992px) {
  //   .right {
  //     display: none;
  //   }
  // }
`;
