import styled from 'styled-components'
import { colors } from "style";

export const PageLayoutContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  .page-content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: ${(props) => props.isCustomer ? '600px' : 'unset'};
    padding-bottom: ${(props) => props.isCustomer ? '80px' : 'unset'};
    margin: 0 auto;
    .announcement-area{
      padding: 15px 0;
      background-color: ${colors.errorBackground};
      .message-area{
        max-width: 500px;
        text-align: center;
        margin: 0 auto;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.03em;
        color: #171753;
      }
    }
  }
  .toast-container {
    top: 100px;
    width: unset;
  }
  @media (max-width: 767px) {
  }
`;
