const subscription = {
  "copper": {
    "stamp": 1,
    "package": 1,
    "referral": 1,
    "voucher": 1,
    "points": false,
    "wallet": false,
    "interaction": 1000,
    "customers": 200,
    "staff": 1,
    "outlet": 1,
  },
  "bronze": {
    "stamp": 3,
    "package": 3,
    "referral": 3,
    "voucher": 3,
    "points": true,
    "wallet": true,
    "interaction": 10000,
    "customers": 1000,
    "staff": 5,
    "outlet": 1,
  },
  "silver": {
    "stamp": 5,
    "package": 5,
    "referral": 5,
    "voucher": 5,
    "points": true,
    "wallet": true,
    "interaction": 50000,
    "customers": 5000,
    "staff": 15,
    "outlet": 5,
  },
  "gold": {
    "stamp": 10,
    "package": 10,
    "referral": 10,
    "voucher": 10,
    "points": true,
    "wallet": true,
    "interaction": 250000,
    "customers": 25000,
    "staff": 30,
    "outlet": 10,
  },
}
  
// hitpay
// const hitpay_url = 'https://api.sandbox.hit-pay.com/v1/payment-requests'
// const hitPayWebhook = 'http://localhost:3030/hitPayWebhook'
// const default_url = 'https://securecheckout.sandbox.hit-pay.com/payment-request/@entreship'
// const hitpay_domain = "sandbox.hit-pay.com"
// const hitpay_apiDomain = "sandbox.hit-pay.com"

const hitpay_url = 'https://api.hit-pay.com/v1/payment-requests'
const hitPayWebhook = 'https://morerewardz-api.morerewardz.com/hitPayWebhook'
const default_url = 'https://securecheckout.hit-pay.com/payment-request/@expressprint-1'
const hitpay_domain = "hit-pay.com"
const hitpay_apiDomain = "hit-pay.com"

const env = {
  hitpay_url,
  hitPayWebhook,
  default_url,
  hitpay_domain,
  hitpay_apiDomain,
  subscription: subscription,
}
  
export default env;