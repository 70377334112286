import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from 'react-redux';
import { loginDetails, rememberMe } from 'redux/actions/roleActions';
import { getBalance } from 'redux/actions/roleActions';
import { DataContext } from "contexts/DataContextContainer";
// import { Link } from "react-router-dom";
import StyledButton from "components/Elements/StyledButton";
// import MorerewardzLayout from "components/MorerewardzLayout"
import { LoginContainer } from "./style";

import client from "feathers.js"
import moment from "moment";

const LoginSchema = Yup.object().shape({
  password: Yup.string()
  .required('Please Enter your password')
  .min(8,'password must contain 8 or more characters'),
  passwordConfirmation: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match')
  .required("Required!"),
});

function Login(props) {
  const params = useParams();
  const { setLoading, showToast } = useContext(DataContext);

  useEffect(() => {
    async function checkLink(){
      try{
        let getForgotPassword = await client.service('forgot-passwords').get(params.id)
        if(getForgotPassword){
          if(getForgotPassword.isReset === true){
            // showToast('error', 'Reset link is not valid!')  
            props.history.push('/')
          }

          if(!(new Date(moment(new Date()).subtract(2, 'days')) <= new Date(getForgotPassword.createdAt))){
            // showToast('error', 'Reset link is not valid!')
            props.history.push('/')            
          }
        }else{
          // showToast('error', 'Reset link is not valid!')
          props.history.push('/')
        }
      }catch(err){
        console.log(err)
        showToast('error', 'Reset link is not valid!')
        props.history.push('/')
      }
    }
    checkLink()
    /* eslint-disable-next-line */
  }, [params.id])
  
  const signin = async (value, { resetForm }) => {
    setLoading(true)
    
    try{
      let getForgotPassword = await client.service('forgot-passwords').get(params.id)
      if(!getForgotPassword.isReset){
        client.service('merchants').patch(getForgotPassword.user_id, {
          password: value.password
        })
        .then((res) => {
          client.service('forgot-passwords').patch(params.id, {
            isReset: true
          })

          resetForm()
          showToast('success', 'The new password has been successfully changed.')
          setLoading(false)
    
          setTimeout(() => {
            props.history.push('/')
          }, 
            500
          );
        })
        .catch((err)=>{
          showToast('error', err.message)
          setLoading(false)
        })
      }else{
        showToast('error', 'Reset link is not available!')
        setLoading(false)
      }
    }catch{
      showToast('error', 'Reset link is not valid!')
      setLoading(false)
    }
  }

  return (
      <LoginContainer>

        <div className="signup-form elementor-element elementor-element-55cf268 e-container--column e-container" data-id="55cf268" data-element_type="container">
          <div className="text-center elementor-element elementor-element-0a881c5 elementor-widget elementor-widget-heading" data-id="0a881c5" data-element_type="widget" data-widget_type="heading.default">
            <div className="elementor-widget-container">
              <h2 className="elementor-heading-title elementor-size-default">
                Reset Password
              </h2>
            </div>
          </div>
          <div className="text-center elementor-element elementor-element-f46e7bf elementor-widget elementor-widget-text-editor" data-id="f46e7bf" data-element_type="widget" data-widget_type="text-editor.default">
            <div className="elementor-widget-container">
                <h4>
                  Enter your new password below.
                  {/* Use at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character */}
                </h4>
              </div>
          </div>

          <div className="elementor-element elementor-element-4f80bd6 elementor-button-align-stretch elementor-widget elementor-widget-form" data-id="4f80bd6" data-element_type="widget" id="register_form" data-settings="{&quot;step_next_label&quot;:&quot;Next&quot;,&quot;step_previous_label&quot;:&quot;Previous&quot;,&quot;button_width&quot;:&quot;100&quot;,&quot;step_type&quot;:&quot;number_text&quot;,&quot;step_icon_shape&quot;:&quot;circle&quot;}" data-widget_type="form.default">

          <Formik
            validationSchema={LoginSchema}
            onSubmit={signin}
            initialValues={{
              password: "",
              passwordConfirmation: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              isValid,
              errors,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>

                <Form.Row className="mt-3">
                  <Col md="12">
                <div className="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100">
                  {/* <label htmlFor="form-field-name" className="elementor-field-label elementor-screen-only">
                    Password
                  </label> */}
                  <Form.Control
                    type="password" 
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                    className="elementor-field elementor-size-sm  elementor-field-textual" 
                    placeholder="Enter New Password" 
                  />
                  <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                </div>
                </Col>
                </Form.Row>

                <Form.Row className="mt-3">
                  <Col md="12">
                <div className="elementor-field-type-text elementor-field-group elementor-column elementor-field-group-name elementor-col-100">
                  {/* <label htmlFor="form-field-name" className="elementor-field-label elementor-screen-only">
                    Merchant ID
                  </label> */}
                  <Form.Control
                    type="password"
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    isInvalid={!!errors.passwordConfirmation}
                    onChange={handleChange}
                    className="elementor-field elementor-size-sm  elementor-field-textual" 
                    placeholder="Confirm Password"
                  />
                  <Form.Control.Feedback type="invalid">{errors.passwordConfirmation}</Form.Control.Feedback>
                </div>
                </Col>
                </Form.Row>

                <div className="w-100 d-flex justify-content-center">
                  <StyledButton
                    type="submit"
                    sizeh={56}
                    width={'35%'}
                    bgcolor={'#FDB256'}
                    className="btn-blue mt-3"
                    title="Confirm"
                  />
                </div>
              </Form>
            )}
          </Formik>
          
          {/* <span className="elementor-element elementor-element-5cb92bc elementor-widget elementor-widget-text-editor" data-id="5cb92bc" data-element_type="widget" data-widget_type="text-editor.default">
            <div className="elementor-widget-container">
            </div>
          </span> */}
        </div>
        {/* <span>Already have an account? <a href="https://app.morerewardz.com/" target="_blank" rel="noreferrer">Login</a> now!</span> */}
      </div>

    </LoginContainer>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  loginDetails: loginDetails,
  rememberMe: rememberMe,
  getBalance: getBalance
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
