import styled from "styled-components";

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 530px;
  position: relative;

  @media (max-width: 1720px) {
    width: 400px;
  }

  @media (max-width: 1325px) {
    width: 320px;
  }

  div {
    display: flex;
    flex-direction: column;
    position: relative;
    p {
      all: unset;
      margin: 5px;
      color: #344051;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    img {
      position: absolute;
      bottom: ${(props) => (props.noLabel ? "25%" : "15%")};
      right: 5%;
    }
  }
  .answer-date-picker {
    all: unset;
    width: 220px;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #e4e6f1;
    background: #fff;
    color: #637083;
    height: 25px;

    @media (max-width: 1720px) {
      width: 160px;
    }

    @media (max-width: 1325px) {
      width: 120px;
    }
  }
  .seperator {
    position: absolute;
    left: 50%;
    top: ${(props) => (props.noLabel ? "50%" : "70%")};
    transform: translate(-50%);
    width: 20px;
    border-top: 2px solid #191d23;

    @media (max-width: 1325px) {
      width: 10px;
    }
  }
  .range-picker {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: ${(props) => (props.noLabel ? "0px" : "28px")};
    width: 530px;
    height: 50px;
    border-radius: 8px;

    @media (max-width: 1720px) {
      width: 400px;
    }

    @media (max-width: 1325px) {
      width: 305px;
    }

    .anticon {
      display: none;
    }
    .ant-picker-range-separator {
      padding-right: 50px;
    }
    .ant-picker-active-bar {
      display: none;
    }
    .ant-picker-input {
      input {
        color: #637083;
        /* Paragraph M/Regular */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }
  }
`;
