import { breakpoints, colors } from "style";
import styled from "styled-components";
import { Home1 } from "assets/images";

export const SignupContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  img {
    max-width: 100%;
    object-fit: cover;
  }
  .is-invalid{
    background-image: none;
  }
  .left-image {
    height: 100%;
    background-image: url(${Home1});
    background-size: cover;
    background-position: center;
  }
  .home-container {
    flex: 1;
    max-width: 600px;
  }
  .signup-form {
    // max-width: 50%;
    padding: 30px 30px;
    // text-align: center;
    display: flex;
    flex-direction: column;
    h2 {
      color: #000;
      font-size: 35px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.35px;
    }
    h4 {
      margin-top: 18px;
      color: #567877;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.16px;
    }
    p {
      margin: 60px 0 0 0;
      color: #6B7280;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.14px;
    }
    .form-label{
      margin-top: 20px;
      color: #191D23;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.14px;
    }
    .form-group {
      margin-bottom: .5rem;
    }
    .ant-select-selector {
      border-radius: 12px;
      height: 48px;
      padding: .375rem .75rem;
    }
    .form-control {
      width: 171px;
      height: 48px;
      opacity: 0.97;
      width: 100%;
      border-radius: 12px;
      &:focus, &:active {
        outline:0px !important;
        -webkit-appearance:none;
        box-shadow: none !important;
      }
    }
    .input-group {
      height: 48px;
      opacity: 0.97;
      border: 1px solid #ced4da;
      border-radius: 12px;

      .input-group-text {
        width: 15%;
        background: transparent;
      }
      .form-control {
        border: none;
        width: 85%;
        background: transparent;
        padding-right: 10px;
      }
    }
    // span {
    //   margin-top: 40px;
    //   align-self: left;
    //   color: #6B7280;
    // }
    a {
        color: #567877;
        font-weight: bold;
    }
    .term-label{
      color: #6B7280;
    }
    .country {
      button {
        height: 40px;
        font-weight: normal;
      }
      span {
        margin-top: 0;
      }
    }
    .input-group-feedback {
      display: block;
    }
    .rounded-checkbox {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      vertical-align: middle;
      border: 1px solid black;
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
    }
    .rounded-checkbox:checked {
      appearance: auto;
      border: 1px solid #567877;
      clip-path: circle(50% at 50% 50%);
      background-color: #567877;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
