import React from "react";
import { CardContainer } from "./style";

const DataCard = (props) => {
  return (
    <CardContainer>
      <div className='counter-container-header'>{props.title}</div>
      {props.total ? (
        <div className='counter-container-activate'>
          {props.data}{" "}
          <div className='counter-container-total'>/ {props.total}</div>
        </div>
      ) : (
        <div className='counter-container-count'>{props.data || 0}</div>
      )}
    </CardContainer>
  );
};

export default DataCard;
