import {
  GET_MERCHANT,
  GET_LOGO_BANNER,
  GET_OUTLET_MODAL
} from '../actions/extraActions';

const INITIAL_STATE = {
  merchant: {},
  logo: '',
  banner: '',
  outletOpen: true,
}

export default function roleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MERCHANT:
      return {
        ...state, 
        merchant: action.data,
      }
    case GET_LOGO_BANNER:
      return {
        ...state, 
        logo: action.data.logo,
        banner: action.data.banner,
      }
    case GET_OUTLET_MODAL:
      return {
        ...state, 
        outletOpen: action.data,
      }
    default:
      return state
  }
}