import styled from "styled-components";
import { breakpoints, colors } from "style";

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  .wallet-bg {
    background: #fafafa;
    padding: 50px;
  }
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    // margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .info-header {
    display: flex;
    align-items: center;
    // .title {
    //   width: 170px;
    //   display: flex;
    //   align-items: center;
    //   border-right: 2px solid ${colors.bgGrey};
    //   h3 {
    //     font-weight: bold;
    //     color: ${colors.purple};
    //   }
    // }
    .plan-info {
      display: flex;
      .plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
        span {
          font-weight: bold;
          font-size: 18px;
          &:nth-child(1) {
            color: ${colors.purple};
          }
        }
      }
      .maximum {
        span:nth-child(2) {
          color: ${colors.red};
        }
      }
      .active {
        span:nth-child(2) {
          color: ${colors.blue};
        }
      }
      .balance {
        span:nth-child(2) {
          color: ${colors.yellow};
        }
      }
    }
    .btn-plan {
      margin-left: 24px;
      width: 200px;
      background: #3b7779;
      color: white;
      font-weight: normal;
      font-size: 15px;
      height: 34px;
      border-radius: 17px;
    }
  }
  .status-header {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    .status {
      width: 30%;
      // height: 90px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(77, 121, 120, 0.08);
      box-shadow: 0px 6px 17px rgba(217, 217, 217, 0.25);
      border-radius: 10px;
      label {
        font-weight: 600;
        color: #3b7779;
        margin: 0; 
      }
      h2 {
        display: inline-block;
      }
      p {
        display: inline-block;
        font-weight: 600;
        font-size: 14px;
        color: #3b7779;
      }
    }
  }
  .page-container {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    // p {
    //   margin-bottom: 0;
    // }
    span {
      font-size: 12px;
    }
    height: 100%;
    background: white;
    border-radius: 8px;
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
