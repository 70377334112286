import React from "react";

// reactstrap components
import { Modal, Button } from "react-bootstrap";

const Notifications = (props) => {
  return ( 
    <>
    {props.isOpen? (
       <Modal
        className="modal-dialog-centered modal-default"
        contentClassName="bg-gradient-default"
        show={props.isOpen}
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {props.title}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.content}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={() => props.handleOpen(false)}>
                    Close
                </Button>
                {/* <Button variant="primary" onClick={() => props.handleOpen(false)}>
                    Save Changes
                </Button> */}
            </Modal.Footer>
        </Modal>
    ): null}
    </>
  );
}
 
export default Notifications;