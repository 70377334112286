import React, { useContext } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { PageContainer } from "./styleEdit";
import StyledButton from "components/Elements/StyledButton";
import { connect } from "react-redux";
import { DataContext } from "contexts/DataContextContainer";
import { cloneDeep } from "lodash";
import { UserLogo } from "assets/images";
import { FaCamera } from "react-icons/fa";
import Resizer from "react-image-file-resizer";

import axios from "axios";
import client from "feathers.js";
import { UploadFile } from "assets/svg";

const validationSchema = Yup.object().shape({
  // owner_name: Yup.string()
  // .required('Owner Name is required'),
});

const isUrl = (string) => {
  try {
    return Boolean(new URL(string));
  } catch (e) {
    return false;
  }
};

const Edit = (props) => {
  const { setLoading, showToast } = useContext(DataContext);

  const uploadUserLogo = async (fileData) => {
    return axios
      .post(`${client.io.io.uri}uploadPublicImgAndDelete`, {
        _id: props.data._id,
        fileData: fileData,
        deleteFileName: props.data.profilePicURL,
        toFolder: "userLogos",
        prefix: "user",
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resizeFile = (file, getSize) => {
    let compressPercent = 0;
    if (file.size > 2000) {
      compressPercent = 50;
    }
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        getSize.width,
        getSize.height,
        file.name.split(".").pop(),
        compressPercent,
        0,
        (uri) => {
          return resolve(uri);
        },
        "base64"
      );
    });
  };

  const handleImgChange = async (e) => {
    var singleFile = e.target.files[0];
    // let fileType = (singleFile.type === "image/jpeg" || singleFile.type === "image/jpg" || singleFile.type === "image/png" || singleFile.type === "image/gif")

    // if(singleFile.size <= 2000000 && fileType){
    // var newFileName = (Math.random().toString(36).substring(2, 15) + "-" +
    //   singleFile.lastModified + "-" +
    //   singleFile.name)

    function getImageSize(file) {
      return new Promise((resolve) => {
        var fr = new FileReader();

        fr.onload = function () {
          var img = new Image();

          img.onload = function () {
            return resolve({
              width: img.width,
              height: img.height,
            });
          };

          img.src = fr.result;
        };

        fr.readAsDataURL(file);
      });
    }

    let imgSize = await getImageSize(singleFile);

    let getSize = {
      width: imgSize.width,
      height: imgSize.height,
    };

    setLoading(true);

    const image = await resizeFile(singleFile, getSize);
    var newFileName = await uploadUserLogo(image);

    if (newFileName) {
      client
        .authenticate()
        .then(() => {
          return client.service("users").patch(props.data._id, {
            profilePicURL: newFileName,
          });
        })
        .then((res) => {
          let cloneData = cloneDeep(props.campaignData);
          let findKey = cloneData.findIndex((e) => e._id === props.data._id);
          if (findKey !== -1) {
            cloneData[findKey] = res;
          }
          props.setCampaignData(cloneData);

          props.setDataEdit(res);
          // showToast('success', 'Update Successfully')
          setLoading(false);
        })
        .catch((err) => {
          showToast("error", err.message);
          setLoading(false);
        });
    } else {
      showToast("error", "Error with uploading Image!");
    }

    // }else{
    //   window.alert("File size Limit: 2000000 kb!")
    //   return false
    // }
  };

  const onSubmit = async (value) => {
    setLoading(true);

    client
      .authenticate()
      .then(() => {
        return client.service("users").patch(props.data._id, value);
      })
      .then((res) => {
        let cloneData = cloneDeep(props.campaignData);
        let findKey = cloneData.findIndex((e) => e._id === props.data._id);
        if (findKey !== -1) {
          cloneData[findKey] = res;
        }
        props.setCampaignData(cloneData);

        setLoading(false);
        showToast("success", "Staff ID is edited successfully");
        props.back();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        showToast("error", err.message);
      });
  };

  return (
    <PageContainer>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        initialValues={{
          owner_name: props.data.owner_name,
          logo: props.data.logo,
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form
            onSubmit={handleSubmit}
            id='form1'
            className='custom-form-styling'
          >
            <label htmlFor='img-logo' className='upload-image'>
              <img
                src={
                  props.data.profilePicURL
                    ? isUrl(props.data.profilePicURL)
                      ? props.data.profilePicURL
                      : client.io.io.uri + props.data.profilePicURL
                    : UploadFile
                }
                alt=''
                style={{ objectFit: "cover" }}
              />

              <input
                id='img-logo'
                type='file'
                onChange={(e) => handleImgChange(e)}
                style={{ display: "none", cursor: "pointer" }}
              />
            </label>

            <div className='input-container'>
              Name of user
              <Form.Control
                type='text'
                name='owner_name'
                value={values.owner_name}
                onChange={handleChange}
                isInvalid={!!errors.owner_name}
                placeholder='Name of User'
                className='input'
              />
              <Form.Control.Feedback type='invalid'>
                {errors.owner_name}
              </Form.Control.Feedback>
            </div>

            <div className='input-container'>
              Staff ID
              <Form.Control
                type='text'
                name='username'
                defaultValue={props.data.username}
                // onChange={handleChange}
                // isInvalid={!!errors.username}
                placeholder='Staff ID'
                disabled
                className='input-disabled'
              />
            </div>

            <div className='row-container'>
              <button
                type='button'
                className='cancel-button'
                onClick={() => props.back()}
              >
                Cancel
              </button>

              <StyledButton
                sizeh={48}
                title='Confirm'
                type='submit'
                className='save-button'
                bgcolor={"#567877"}
              />
            </div>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.role.details.user ? state.role.details.user : {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
