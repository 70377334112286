import styled from "styled-components";
import { breakpoints, colors } from "style";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 80px;
  .row-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .right-content {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  .input {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    width: 100%;
    border: 1px solid #eaecee;
    border-radius: 10px;
    height: 48px;
    gap: 50px;
    position: relative;
    background: white;
  }
  .message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
  }
  .application-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    & > .alert-primary {
      width: 100%;
    }
  }
  .checkmark-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 12px;
    gap: 12px;
    background: #e7eaee;
    color: #344051;
    font-size: 14px;
    border-radius: 10px;
  }
  .file-upload-button {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    border: 1px solid #eaecee;
    background: #eeeeee;
    color: #567877;
    font-size: 14px;
    padding: 2px 10px;
  }
  .remove-file-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .file-text {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: blue;
  }
  .placeholder-text {
    display: flex;
    align-items: center;
    color: #929da7;
    font-size: 14px;
  }
  .form-styling {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
  }
  .icon-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    width: auto;
    height: auto;
  }
  .button-icon-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
  }
  .button-text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  .confirm-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    max-width: 160px;
    font-size: 14px;
    color: white;
    background: #567877;
    cursor: pointer;
  }
  .confirm-button-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    max-width: 160px;
    font-size: 14px;
    color: white;
    cursor: not-allowed;
  }
  .error-message {
    color: #dc3545;
  }

  .business-bg {
    background: #fafafa;
    padding: 20px;
  }
  .business-checked {
    position: absolute;
    top: 24px;
    left: -15px;
    display: inline-block;
    background-color: #007bff;
    height: 18px;
    width: 18px;
    border: none;
    border-radius: 50%;
    color: white;
    text-align: center;
    margin-left: 6px;
    // position: absolute;
    // top: 50%;
    // transform: translate(0, -50%);
    svg {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    // margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .info-header {
    display: flex;
    align-items: center;
    // .title {
    //   width: 170px;
    //   display: flex;
    //   align-items: center;
    //   border-right: 2px solid ${colors.bgGrey};
    //   h3 {
    //     font-weight: bold;
    //     color: ${colors.purple};
    //   }
    // }
    .plan-info {
      display: flex;
      .plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
        span {
          font-weight: bold;
          font-size: 18px;
          &:nth-child(1) {
            color: ${colors.purple};
          }
        }
      }
      .maximum {
        span:nth-child(2) {
          color: ${colors.red};
        }
      }
      .active {
        span:nth-child(2) {
          color: ${colors.blue};
        }
      }
      .balance {
        span:nth-child(2) {
          color: ${colors.yellow};
        }
      }
    }
    .btn-plan {
      margin-left: 24px;
      width: 200px;
      background: #3b7779;
      color: white;
      font-weight: normal;
      font-size: 15px;
      height: 34px;
      border-radius: 17px;
    }
  }
  .status-header {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 20px;
    .status {
      width: 30%;
      height: 100px;
      background: linear-gradient(
        160deg,
        ${colors.red},
        ${colors.red},
        ${colors.red},
        ${colors.yellow}
      );
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span:nth-child(1) {
        color: white;
        font-size: 18px;
      }
      span:nth-child(2) {
        color: white;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
  .page-container {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    p {
      margin-bottom: 0;
    }
    span {
      font-size: 12px;
    }
    padding: 12px 0;
    height: 100%;
    background: white;
    border-radius: 8px;
  }
  @media (max-width: ${breakpoints.sm}px) {
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
