import { colors } from "style";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 80px;
  .code-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: white;
    border: 1px solid #e0e3de;
    border-radius: 8px;
  }
  .download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #567877;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    width: 100%;
    max-width: 157px;
    height: 40px;
    gap: 4px;
    margin: 15px 0 0 0;
  }
  .qrcode-styling {
    width: 100%;
    min-width: 180px;
    max-width: 180px;
    height: 180px;
  }
  .poster-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 262px;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1),
      -5px 5px 10px rgba(0, 0, 0, 0.1), -5px 5px 10px rgba(0, 0, 0, 0.1),
      5px -5px 10px rgba(0, 0, 0, 0);
  }
  .poster-header-container {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 10px 10px 25px;
    background: white;
    width: 100%;
    border-right: 1px solid black;
  }
  .poster-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .poster-header-text {
    display: flex;
    font-size: 14px;
    color: #567877;
    font-weight: bold;
  }
  .poster-header-message {
    display: flex;
    font-size: 9px;
    color: black;
    font-weight: bold;
  }
  .poster-body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ededed;
    width: 100%;
    gap: 5px;
    padding: 10px;
    border-right: 1px solid black;
  }
  .poster-body-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 15px;
  }
  .poster-body-qr {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 10px;
    width: 100%;
    min-width: 130px;
    max-width: 130px;
    height: 130px;
  }
  .poster-body-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    color: black;
  }
  .poster-footer-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 3px;
    background: #567877;
    width: 100%;
    height: 25px;
  }
  .ant-spin-dot-item {
    background: white;
  }

  .a4-overlay {
    overflow: hidden;
    ::after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      margin: auto;
      position: fixed;
      background: white;
      top: 0;
    }
  }
  .title {
    margin-bottom: 24px;
    // font-weight: bold;
    color: ${colors.purple};
  }
  .header {
    display: flex;
    align-items: center;
    // align-items: center;
    margin-bottom: 12px;
    .btn-back {
      background: #3b7779;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      margin-right: 8px;
    }
    h3 {
      color: ${colors.purple};
      margin-bottom: 0;
    }
  }
  .poster {
    width: 100%;
    border: 2px solid #c4c4c4;
    border-radius: 8px;
    position: absolute;
    background: rgb(237, 237, 237);
    .poster-head {
      background: white;
      width: 100%;
      padding: 12px;
      h3 {
        text-transform: capitalize;
        margin: 0;
      }
      img {
        float: right;
        width: 100px;
      }
    }
    #svg-1 {
      background: white;
      width: 44%;
      margin: auto;
    }
    .poster-footer {
      position: relative;
      height: 30px;
      width: 100%;
      background: rgb(73, 118, 119);
      text-align: end;
      padding: 0 12px;
    }
  }
  .page-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: 100%;
    .main-content {
      // flex: 1;
      // max-width: 1000px;
      background: white;
      border-radius: 16px;
      padding: 12px 0;
      .fa-file-pdf {
        font-size: 30px;
        color: ${colors.red};
        margin-right: 12px;
      }
      .code {
        .posters {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .poster {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            max-width: 340px;
          }
        }
        .qr {
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          .qrcode {
            width: 340px;
            height: 340px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 2px solid ${colors.bgGrey};
            border-radius: 8px;
            padding: 30px;
            h3 {
              font-size: 18px;
              font-weight: bold;
              margin: 0;
            }
            .anticon-qrcode {
              font-size: 160px;
            }
          }
          .btn-download {
            margin-top: 16px;
          }
        }
      }
      .btn-download {
        // color: ${colors.red};
        font-size: 16px;
        font-weight: normal;
        background: transparent;
        .fa-file-download {
          margin-right: 4px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .qrcode {
      padding: 0px !important;
    }
  }
  @media (max-width: 413px), (max-height: 845px) {
  }
`;
