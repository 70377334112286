import React from "react";
import { Modal } from "antd";
import { FaTimesCircle } from "react-icons/fa";
import StyledButton from "components/Elements/StyledButton";
import { ModalContainer } from "./style";
import {
  IphonePreviewStamp,
  IphonePreviewPackage,
  IphonePreviewVoucher,
  IphonePreviewReferral,
  IphonePreviewPoint,
} from "assets/images";
import classNames from "classnames";

const ModalConfirmation = (props) => {
  const confirmModal = () => {
    props.toggleModal();
    props.modalCallback(true);
  };

  const RenderPreviewImg = ({ campaignName }) => {
    if (campaignName === "package") {
      return <img src={IphonePreviewPackage} className='w-100' alt='' />;
    } else if (campaignName === "voucher") {
      return <img src={IphonePreviewVoucher} className='w-100' alt='' />;
    } else if (campaignName === "referral") {
      return <img src={IphonePreviewReferral} className='w-100' alt='' />;
    } else if (campaignName === "point") {
      return <img src={IphonePreviewPoint} className='w-100' alt='' />;
    } else {
      return <img src={IphonePreviewStamp} className='w-100' alt='' />;
    }
  };

  return (
    <>
      <Modal
        title={false}
        visible={props.modalVisible}
        onCancel={() => {
          props.toggleModal();
        }}
        centered
        closable={true}
        footer={false}
        // maskClosable={false}
        // getContainer={() => document.getElementById("stamp-container")}
        // width='80%'
        className='modal-preview-content'
        closeIcon={<FaTimesCircle className='close-icon' />}
      >
        <ModalContainer>
          <div className='header'>{props.header}</div>

          <div className='body w-100' style={{ position: "relative" }}>
            <RenderPreviewImg campaignName={props.campaign} />
            <div
              className={classNames("campaign-preview", {
                isReward: props.isReward,
              })}
            >
              {props.body}
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              onClick={() => props.toggleModal()}
              className='check-availability-again'
            >
              Cancel
            </button>
            <button
              disabled={!props.canEdit}
              onClick={confirmModal}
              type='submit'
              className='confirm-btn'
            >
              Confirm
            </button>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default ModalConfirmation;
