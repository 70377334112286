import { VerificationContainer } from "./style";
// import { Link } from "react-router-dom";
import StyledButton from "components/Elements/StyledButton";
import { CheckEmail, LinkBroke, Unlock, VerifiedEmail, VerifyEmail2 } from "assets/images"
import { useState } from "react";

function Verification() {
  return (
    <VerificationContainer>
      <div className="row no-gutters home-container">
        <div className="col-md-12">
          <div className="verification-form mx-auto">
            <img width={130} src={VerifyEmail2} alt="" />
            <h2>Great! We've sent a verification email to your inbox.</h2>
            <div className="verification-steps">
              <div className="steps-item">
                <div className="steps-icons">
                  <img width={28} src={CheckEmail} alt="" />
                </div>
                <div className="steps-text">
                  <p className="step-title">Step 1:</p>
                  <p>Open the <a href="https://app.morerewardz.com/" target="_blank" rel="noreferrer">email</a> inside your inbox.</p>
                </div>
              </div>
              <div className="steps-item">
                <div className="steps-icons">
                <img width={28} src={LinkBroke} alt="" />
                </div>
                <div className="steps-text">
                  <p className="step-title">Step 2:</p>
                  <p>Click on the link in the email.</p>
                </div>
              </div>
              <div className="steps-item">
                <div className="steps-icons">
                <img width={28} src={Unlock} alt="" />
                </div>
                <div className="steps-text">
                  <p className="step-title">Step 3:</p>
                  <p>Continue to verfication!</p>
                </div>
              </div>
            </div>
            <h6>If you haven't received the email within a few minutes, please check your spam folder. If you still encounter any issues, our support team is here to assist you.</h6>
              <StyledButton
                sizeh={56}
                width={'60%'}
                radius={12}
                bgcolor={'#567877'}
                className="btn-blue mt-5"
                title="I have verified!"
                onClick={() => {
                  window.open("https://app.morerewardz.com/", "_blank");
                }}              />
            {/* <span>Already have an account? <a href="https://app.morerewardz.com/" target="_blank" rel="noreferrer">Login</a> now!</span> */}
          </div>
        </div>
      </div>
    </VerificationContainer>
  );
}

export default Verification;
