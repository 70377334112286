import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';

const socket = io(process.env.REACT_APP_API_URL);
const app = feathers();

app.configure(socketio(socket)
// ,{
//   pingTimeOut:60000
// }
);
app.configure(authentication({
  // jwtStrategy: "merchant-local",
  storage: window.localStorage
}));

export default app;