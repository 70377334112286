import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 80px;
  .input {
    display: flex;
    align-items: center;
    padding: 10px 8px 10px 12px;
    border: 1px solid #ced2da;
    background: white;
    height: 48px;
    width: 100%;
    max-width: 476;
    border-radius: 8px;
    color: #637083;
    font-size: 16px;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #567877;
    border-radius: 10px;
    height: 48px;
    width: 100%;
    max-width: 160px;
    padding: 12px 24px;
    margin: 10px 0 0 0;
    color: white;
    font-size: 14px;
  }
`;
